import { Card, CardWrapper, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import { Form, FormikContextType, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import Aanvragen from "./aanvragen/aanvragen";
import BankgarantieKaart from "./bankgarantie-kaart/bankgarantie-kaart";
import Garantie from "./garantie/garantie";
import { BankgarantieProps, bankgarantieSchema, BankgarantieState, InstellingCodes } from "./infra/bankgarantie-schema";
import { syncBankgarantieSideEffects } from "./infra/determine-bankgarantie-side-effects";
import Notaris from "./notaris/notaris";
import OnroerendeZaak from "./onroerende-zaak/onroerende-zaak";
import Pand from "./pand/pand";
import Product from "./product/product";
import Verkopers from "./verkopers/verkopers";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const Bankgarantie = (props: FormikContextType<BankgarantieState> & BankgarantieProps): ReactElement => {
  const { isSubmitting, productInstellingOptions } = props;
  const formik = useFormikContext<BankgarantieState>();
  const { values } = formik;

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}
        <ISWSideEffects<BankgarantieState>
          runOnFirstRender
          sync={syncBankgarantieSideEffects({ notarissen: { partijen: props.notarissenData?.partijen ?? [] } })}
        />
        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        {!!values.platformApiFouten?.length && <PlatformFoutenSamenvatting />}
        <CardWrapper className="w-75 px-3">
          <Card title="Product">
            <Product productInstellingOptions={productInstellingOptions} />
          </Card>
          {(values.product.instelling === InstellingCodes.NWB) && (
            <Card title="Verkopers">
              <Verkopers />
            </Card>
          )}

          {(values.product.instelling === InstellingCodes.NWB || values.product.instelling === InstellingCodes.CA) && (
            <Card title="Onroerende zaak">
              <OnroerendeZaak />
            </Card>
          )}

          {values.product.instelling === InstellingCodes.NWB && (
            <Card title="Pand">
              <Pand />
            </Card>
          )}

          {(values.product.instelling === InstellingCodes.NWB || values.product.instelling === InstellingCodes.CA) && (
            <Card title="Notaris">
              <Notaris notarissen={props.notarissenData} />
            </Card>
          )}

          {(values.product.instelling === InstellingCodes.NWB || values.product.instelling === InstellingCodes.CA) && (
            <Card title="Bankgarantie">
              <BankgarantieKaart />
            </Card>
          )}

          {values.product.instelling === InstellingCodes.CA && (
            <Card title="Garantie">
              <Garantie instelling={values.product.instelling} />
            </Card>
          )}

          {(values.product.instelling === InstellingCodes.NWB || values.product.instelling === InstellingCodes.CA) && (
            <Card title="Bankgarantie aanvragen">
              <Aanvragen saveData={props.saveData} />
            </Card>
          )}
        </CardWrapper>
        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

Bankgarantie.displayName = "Bankgarantie";

export default withAdviesboxFormik<BankgarantieProps & WithSaveData<BankgarantieState>, BankgarantieState>({
  // Transform outer props into form values
  mapPropsToValues: (e: BankgarantieProps): BankgarantieState => e.data,
  validationSchema: bankgarantieSchema
})(withAITracking(insightsReactPlugin, Bankgarantie));
