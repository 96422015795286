import {
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledTextInput,
  TooltipWrap
} from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import { getHypotheekTextResources } from "../../hypotheek/infra/hypotheek-resources";
import { hypotheekFiscaleRegelingSchema } from "../../hypotheek/infra/hypotheek-schema";
import { HypotheekFiscaleRegelingType } from "../../hypotheek/infra/hypotheek-types";
import { FiscaalRegimeOptions, FiscaleTyperingOptions } from "../../.generated/forms/formstypes";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import { LabelValuePairs } from "../../shared/types";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import { hasValue } from "../../shared/utils/helpers";
import { FiscaleRegelingKenmerken } from "../infra/product-kenmerken-types";
import { FiscaleRegelingModalSideEffects } from "./fiscale-regeling-modal-side-effects";

export type FiscaleRegelingModalData = {
  data: HypotheekFiscaleRegelingType;
};

export type FiscaleRegelingModalProps = {
  onSave?: (data: HypotheekFiscaleRegelingType) => void;
  closeModal?: () => void;
  kenmerken: FiscaleRegelingKenmerken;
  fiscaleOption?: boolean;
};

const FiscaleTyperingOpties: LabelValuePairs = [
  {
    label: "Geen",
    value: FiscaleTyperingOptions.Geen
  },
  {
    label: "Kapitaalverzekering eigen woning (KEW)",
    value: FiscaleTyperingOptions.KapitaalverzekeringEigenWoningKew
  },
  {
    label: "Spaarrekening eigen woning (SEW)",
    value: FiscaleTyperingOptions.SpaarrekeningEigenWoningSew
  },
  {
    label: "Beleggingsrekening eigen woning (BEW)",
    value: FiscaleTyperingOptions.BeleggingsrekeningEigenWoningBew
  },
  {
    label: "Kapitaalverzekering zonder eigen woningclausule",
    value: FiscaleTyperingOptions.KapitaalverzekeringZonderEigenWoning
  }
];

const FiscaalRegimeOpties: LabelValuePairs = [
  {
    label: "Brede herwaardering",
    value: FiscaalRegimeOptions.BredeHerwaardering
  },
  {
    label: "IB 2001",
    value: FiscaalRegimeOptions.Ib2001
  },
  {
    label: "Pre brede herwaardering",
    value: FiscaalRegimeOptions.PreBredeHerwaardering
  }
];

const FiscaleRegelingModal = ({
  kenmerken,
  data,
  onSave,
  closeModal,
  fiscaleOption
}: FiscaleRegelingModalProps & FiscaleRegelingModalData): ReactElement => {
  const body = (values: HypotheekFiscaleRegelingType): ReactElement => (
    <>
      <LabeledTextInput caption="Polis-/contractnummer" name={`originelePolisnummer`} />

      <LabeledHdnKeuzelijst
        berichtSoortType="AX"
        caption="Externe maatschappij"
        name={`externeMaatschappijCode`}
        keuzelijst={"MaatschappijType"}
        emptyValue="Selecteer een optie"
      />

      {values.externeMaatschappijCode === "ZZ" && (
        <LabeledTextInput caption="Externe maatschapijnaam" name={`externeMaatschappijOmschrijving`} />
      )}

      {kenmerken?.lijfrenteclausuleTonen && (
        <LabeledBevestigingInput caption="Lijfrenteclausule" name={`lijfrenteclausuleOrigineel`} />
      )}

      <LabeledSelectInput
        caption="Fiscale typering"
        name={`fiscaleTypering`}
        options={FiscaleTyperingOpties}
        emptyValue="Selecteer een optie"
      />

      {(values.fiscaleTypering === FiscaleTyperingOptions.KapitaalverzekeringEigenWoningKew ||
        values.fiscaleTypering === FiscaleTyperingOptions.KapitaalverzekeringZonderEigenWoning) && (
        <LabeledBevestigingInput caption="Garantieverzekering" name={`garantieverzekering`} />
      )}

      {(values.fiscaleTypering === FiscaleTyperingOptions.KapitaalverzekeringEigenWoningKew ||
        values.fiscaleTypering === FiscaleTyperingOptions.KapitaalverzekeringZonderEigenWoning) && (
        <LabeledRadioInput caption="Fiscaal regime" name={`fiscaalRegime`} options={FiscaalRegimeOpties} />
      )}

      <LabeledDateInput
        caption="Oorspronkelijke ingangsdatum"
        name={`oorspronkelijkeIngangsdatum`}
        tooltip={getHypotheekTextResources("OorspronkelijkeIngangsdatumTooltip")}
        appendChildren={
          fiscaleOption &&
          !hasValue(values.oorspronkelijkeIngangsdatum) && (
            <div className="d-flex align-items-center">
              <TooltipWrap
                name="oorspronkelijkeIngangsdatum"
                warningText="Vul de oorspronkelijke ingangsdatum in."
                placement="bottom"
                iconType="waarschuwing"
                tooltipClasses="mt-1"
              />
            </div>
          )
        }
      />

      <LabeledDateInput
        caption="Ingangsdatum Box 1"
        name={`ingangsdatumBox1`}
        tooltip={getHypotheekTextResources("IngangsdatumBox1Tooltip")}
        appendChildren={
          fiscaleOption &&
          !hasValue(values.ingangsdatumBox1) && (
            <div className="d-flex align-items-center">
              <TooltipWrap
                name="ingangsdatumBox1"
                warningText="Vul de ingangsdatum box 1 in."
                placement="bottom"
                iconType="waarschuwing"
                tooltipClasses="mt-1"
              />
            </div>
          )
        }
      />

      <LabeledDateInput caption="Einddatum" name={`einddatum`} />

      <LabeledCurrencyInput caption="Doelkapitaal" name={`doelkapitaalBedrag`} />

      <LabeledCurrencyInput caption="Laagste jaarpremie / inleg ooit" name={`laagstePremieooitBedrag`} />

      <LabeledCurrencyInput caption="Hoogste jaarpremie / inleg ooit" name={`hoogstePremieOoitBedrag`} />

      <LabeledCurrencyInput caption="Huidige jaarpremie / inleg" name={`huidigeJaarPremieBedrag`} />

      <LabeledCurrencyInput caption="Premie / inleg lopend jaar" name={`premieLopendJaarBedrag`} />

      <LabeledCurrencyInput
        caption="Eerdere uitkeringen"
        name={`eerdereUitkeringenBedrag`}
        tooltip={getHypotheekTextResources("EerdereUitkeringenTooltip")}
      />

      <LabeledCurrencyInput caption="Ingebrachte waarde" name={`ingebrachteWaardeBedrag`} />
    </>
  );

  return (
    <>
      <AdviesBoxFormik<HypotheekFiscaleRegelingType>
        initialValues={{ ...data }}
        validationSchema={hypotheekFiscaleRegelingSchema}
        onSave={onSave}
        closeModal={closeModal}
        render={({ values, submitForm }: FormikProps<HypotheekFiscaleRegelingType>): ReactElement => (
          <>
            <ISWSideEffects<HypotheekFiscaleRegelingType> sync={FiscaleRegelingModalSideEffects} />
            <Modal
              title="Fiscale voortzetting"
              body={body(values)}
              onSubmitClick={submitForm}
              onCancelClick={closeModal}
            />
          </>
        )}
      />
    </>
  );
};

FiscaleRegelingModal.displayName = "FiscaleRegelingModal";

export default FiscaleRegelingModal;
