import React, { FC, useCallback, useMemo, useEffect } from "react";
import { useRequestInit } from "adviesbox-shared";
import { useFetchData } from "../../../shared/hooks/usefetchdata";
import LastenOverzichtTable from "../lastenoverzicht-table/lastenoverzicht-table";
import { LastenOverzicht } from "../../../.generated/rapportage/rapportagetypes";

interface LastenOverzichtFetcherProps {
  voorstelId?: string;
  beperkteLastenTonen: boolean;
  enabled?: boolean;
  onDataLoaded?: (data: LastenOverzicht) => void;
  jarenTonen?: boolean;
}

export const LastenOverzichtFetcher: FC<LastenOverzichtFetcherProps> = ({
  voorstelId: initialVoorstelId,
  beperkteLastenTonen,
  enabled = true,
  onDataLoaded,
  jarenTonen
}) => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const voorstelId = initialVoorstelId === "999" ? undefined : initialVoorstelId;

  const getLastenOverzichtUrl = useMemo(() => {
    const baseUrl = settings.rapportageOrigin;
    return voorstelId
      ? `${baseUrl}/Voorstellen/${voorstelId}/SnelInzicht/LastenOverzicht`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht`;
  }, [settings.rapportageOrigin, params.adviesdossier, voorstelId]);

  const getRequestInit = useCallback(() => ({
    ...requestInit,
    method: "POST",
    url: getLastenOverzichtUrl
  }), [requestInit, getLastenOverzichtUrl]);

  const { data, loading, error } = useFetchData<LastenOverzicht>(
    getLastenOverzichtUrl,
    getRequestInit,
    enabled
  );

  useEffect(() => {
    if (data && onDataLoaded) {
      onDataLoaded(data);
    }
  }, [data, onDataLoaded]);

  if (error) {
    return <div role="alert" className="error-message">Er is een fout opgetreden: {String(error)}</div>;
  }
  
  return (
    <LastenOverzichtTable 
      data={data} 
      beperkteLastenTonen={beperkteLastenTonen} 
      jarenTonen={jarenTonen} 
      loading={loading} 
    />
  );
};
