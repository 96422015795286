import React, { ReactElement, useCallback } from "react";
import { connect, FormikContextType } from "formik";
import WeigeringenModal from "../weigeringen-modal/weigeringen-modal";
import UitsluitingenModal from "../uitsluitingen-modal/uitsluitingen-modal";
import { Icon, LabeledDateInput, LabeledBevestigingInput, LabeledResult, SyncHeight, SyncHeightProps } from "adviesbox-shared";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { ModalButton } from "adviesbox-shared";
import { PersonaliaState } from "../infra/personalia-schema";

const Aanvrager1Levensgeschiedenis = ({
  formik: {
    setFieldValue,
    values: { aanvrager1Levensgeschiedenis }
  },
  targetRef: syncHeightRef,
  sourceRef: assignRef
}: {
  formik: FormikContextType<PersonaliaState>;
} & SyncHeightProps): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);

  const uitsluitingen = aanvrager1Levensgeschiedenis.uitsluitingenVerzekeringen;
  const weigeringen = aanvrager1Levensgeschiedenis.weigeringenVerzekeringen;
  return (
    <>
      <LabeledHdnKeuzelijst
        caption="Hoogst genoten opleiding"
        name="aanvrager1Levensgeschiedenis.hoogstGenotenOpleiding"
        berichtSoortType="AX"
        keuzelijst="OpleidingsNiveauType"
      />
      <SyncHeight targetRef={syncHeightRef} sourceRef={assignRef}>
        <LabeledBevestigingInput caption="Ooit gescheiden" name="aanvrager1Levensgeschiedenis.ooitGescheiden" />
        {aanvrager1Levensgeschiedenis.ooitGescheiden && (
          <LabeledDateInput caption="Datum echtscheiding" name="aanvrager1Levensgeschiedenis.datumEchtscheiding" />
        )}
        {aanvrager1Levensgeschiedenis.ooitGescheiden && (
          <LabeledHdnKeuzelijst
            caption="Scheiding"
            name="aanvrager1Levensgeschiedenis.scheiding"
            berichtSoortType="AX"
            keuzelijst="SoortOntbondenType"
          />
        )}
      </SyncHeight>
      <LabeledBevestigingInput
        caption="Hoofdelijk aansprakelijk"
        name="aanvrager1Levensgeschiedenis.hoofdelijkAansprakelijk"
      />
      <LabeledResult
        caption="Uitsluiting(en) verzekeringen"
        result={(): string =>
          uitsluitingen.filter(uitsluiting => uitsluiting.maatschappijCode.length).length.toString()
        }
        name="aanvrager1Levensgeschiedenis.uitsluitingenVerzekeringen"
        readonly
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="aanvrager1Levensgeschiedenis.uitsluitingenVerzekeringen"
            content={<Icon name="specificatie" alt="Uitsluitingen" />}
          >
            <UitsluitingenModal
              data={aanvrager1Levensgeschiedenis.uitsluitingenVerzekeringen}
              onSave={createSaveFunction("aanvrager1Levensgeschiedenis.uitsluitingenVerzekeringen")}
            />
          </ModalButton>
        }
      />
      <LabeledResult
        caption="Weigering(en) verzekeringen"
        result={(): string => weigeringen.filter(weigering => weigering.maatschappijCode.length).length.toString()}
        name="aanvrager1Levensgeschiedenis.weigeringenVerzekeringen"
        readonly
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="aanvrager1Levensgeschiedenis.weigeringenVerzekeringen"
            content={<Icon name="specificatie" alt="Weigeringen" />}
          >
            <WeigeringenModal
              data={aanvrager1Levensgeschiedenis.weigeringenVerzekeringen}
              onSave={createSaveFunction("aanvrager1Levensgeschiedenis.weigeringenVerzekeringen")}
            />
          </ModalButton>
        }
      />
    </>
  );
};

export default connect<SyncHeightProps, PersonaliaState>(Aanvrager1Levensgeschiedenis);
