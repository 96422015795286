import { jaarMaandInMaanden } from "adviesbox-shared";

import {
  KapitaalverzekeringVerpanding,
  BelastingBoxOptions,
  SoortLijfrenteUitkeringOptions,
  BetalingsTermijnType,
  VermogenVerpanding
} from "../../.generated/forms/formstypes";
import { VerpandingType } from "./producten-overzicht-types";
import { mapAflosproductUiToDl } from "./verpanding/map-verpanding-ui-to-dl";

export function mapVerpandingUiToDl(verpanding: VerpandingType): KapitaalverzekeringVerpanding | VermogenVerpanding {
  const indicatieveUitkerendeFaseSpecificatie = verpanding.indicatieveUitkerendeFaseSpecificatie.values;
  const uitkerendeFase = verpanding.indicatieveUitkerendeFase
    ? {
        belastingBox: indicatieveUitkerendeFaseSpecificatie.belastingBox as BelastingBoxOptions,
        soortLijfrenteUitkering: indicatieveUitkerendeFaseSpecificatie.soortLijfrenteUitkering as SoortLijfrenteUitkeringOptions,
        duurUitkeringInMaanden: indicatieveUitkerendeFaseSpecificatie.soortLijfrenteUitkering === SoortLijfrenteUitkeringOptions.Tijdelijk ?  
                jaarMaandInMaanden(indicatieveUitkerendeFaseSpecificatie.duurUitkering) : null,
        duurUitkeringHoogInMaanden: jaarMaandInMaanden(indicatieveUitkerendeFaseSpecificatie.duurUitkeringHoog),
        hooglaagVerhouding: indicatieveUitkerendeFaseSpecificatie.hoogLaagVerhouding,
        lijfrenteTariefPercentage: indicatieveUitkerendeFaseSpecificatie.lijfrenteTarief,
        overgangOp2eVerzekerde: indicatieveUitkerendeFaseSpecificatie.overgangOpTweedeVerzekerde,
        overgangOp2eVerzekerdePercentage: indicatieveUitkerendeFaseSpecificatie.overgangOpTweedeVerzekerdePercentage,
        lijfrenteUitkeringBedrag: indicatieveUitkerendeFaseSpecificatie.lijfrenteUitkering.bedrag,
        termijn: indicatieveUitkerendeFaseSpecificatie.termijn as BetalingsTermijnType
      }
    : null;

  return {
    uitkerendeFase,
    verpandAanGeldverstrekker: verpanding.verpandAanGeldverstrekker,
    bedoeldVoorAflossing: verpanding.bedoeldVoorAflossing,
    aflosproducten: verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten.map(mapAflosproductUiToDl)
  };
}
