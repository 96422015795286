import {
  DataGrid,
  ErrorPage,
  Icon,
  SettingsContext,
  useAdviesboxDataRepository,
  FetchDataButton
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { FinancieringsbehoeftenOutput, HypotheekOversluitenResultaat } from "../../.generated/forms/formstypes";
import { useInstellingenBeheerPartijenData } from "../../shared/hooks/use-instellingen-beheer-partijen-data";
import { RouteParams } from "../../shared/paramrouting/paramrouting-context";
import { HypotheekOversluitenPDFGenerator } from "../hypotheek-oversluiten-pdf/hypotheek-oversluiten-pdf-generator";
import { getHypotheekOversluitenTextResources } from "../infra/hypotheek-oversluiten-resources";
import {
  BerekenHypotheekOversluitenResponse,
  HypotheekOversluitenState,
  MappedBerekenHypotheekOversluitenResponse
} from "../infra/hypotheek-oversluiten-schema";
import { mapDlTargetToHypotheekOversluitenUiField } from "../infra/map-hypotheek-oversluiten";
import {
  mapFinancieringsbehoefteResultaat,
  mapFormikValues,
  mapHypotheekOversluitenResult
} from "./data-grid-helpers/data-grid-mapper/data-grid-mapper";
import { hypotheekOversluitenColumns } from "./data-grid-helpers/hypotheek-oversluiten-data-grid-columns";

export type HypotheekOversluitenDataGridProps = {
  setBerekend: React.Dispatch<React.SetStateAction<boolean>>;
  berekend: boolean;
};

export const HypotheekOversluitenDataGrid = ({
  berekend,
  setBerekend
}: HypotheekOversluitenDataGridProps): ReactElement => {
  const { values, isValid, setFieldValue } = useFormikContext<HypotheekOversluitenState>();
  const settings = useContext(SettingsContext);
  const { voorstel } = useParams<RouteParams>();
  const [fbBerekend, setFbBerekend] = useState(false);
  const [oversluitenBerekend, setOversluitenBerekend] = useState(false);

  const {
    data: enabledGeldverstrekkersData,
    loading: geldverstrekkersLoading,
    error: geldverstrekkersError
  } = useInstellingenBeheerPartijenData("Geldverstrekkers");

  const urlHypotheekOversluiten = `${settings.klantdossiersFormsOrigin}/Voorstellen/${voorstel}/HypotheekOversluiten`;
  const { fetchData: fetchHypotheekOversluiten, loading: hypotheekOversluitenLoading } = useAdviesboxDataRepository<
    HypotheekOversluitenResultaat,
    HypotheekOversluitenState
  >(urlHypotheekOversluiten, {
    method: "POST",
    mapUiToDl: values => mapFormikValues(values),
    mapDlToUi: (_, res) => {
      setFieldValue(
        "berekendeHypotheken",
        mapHypotheekOversluitenResult(
          Object.values(res?.resultatenPerLabel ?? []) as BerekenHypotheekOversluitenResponse[],
          enabledGeldverstrekkersData.geldverstrekkers?.partijen || []
        )
      );
      setOversluitenBerekend(true);
      return null;
    },
    mapTargetToUiField: mapDlTargetToHypotheekOversluitenUiField
  });

  const url = `${settings.klantdossiersFormsOrigin}/Voorstellen/${voorstel}/financieringsbehoefte`;
  const {
    fetchData: fetchFinancieringsbehoefteData,
    loading: financieringsBehoefteLoading
  } = useAdviesboxDataRepository<FinancieringsbehoeftenOutput, HypotheekOversluitenState>(url, {
    mapDlToUi: (_, res) => {
      setFieldValue(
        "extraPdfVelden",
        mapFinancieringsbehoefteResultaat(Object.values(res?.financieringsbehoeften ?? []))
      );
      setFbBerekend(true);
      return null;
    },
    mapTargetToUiField: mapDlTargetToHypotheekOversluitenUiField
  });

  useEffect(() => {
    setFieldValue("dataHasChanged", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fbBerekend && oversluitenBerekend) {
      setFieldValue("dataHasChanged", false);
      setBerekend(true);
    }
  }, [fbBerekend, oversluitenBerekend, setBerekend, setFieldValue]);

  if (!geldverstrekkersLoading && geldverstrekkersError)
    return <ErrorPage error={geldverstrekkersError} data={enabledGeldverstrekkersData} />;

  /* istanbul ignore next */
  const onDataGridChange = (data?: MappedBerekenHypotheekOversluitenResponse[]): void => {
    if (!data || !data.length) return;
    setFieldValue("sortedBerekendeHypotheken", data);
  };

  return (
    <div>
      {!!values.berekendeHypotheken.length && <HypotheekOversluitenPDFGenerator values={values} />}

      <div className="button-container">
        <FetchDataButton
          onClick={() => {
            setFbBerekend(false);
            setOversluitenBerekend(false);
            fetchHypotheekOversluiten();
            fetchFinancieringsbehoefteData();
          }}
          dataOutDated={values.dataHasChanged}
          invalid={!isValid}
          initialText={getHypotheekOversluitenTextResources("refetchInitialText")}
          loading={financieringsBehoefteLoading || hypotheekOversluitenLoading}
          keepVisible={true}
          disabled={!values.dataHasChanged}
        />
      </div>

      {values.berekendeHypotheken.length > 0 && (
        <div data-testid="data-table">
          <DataGrid
            name="berekendeHypotheken"
            initialSort="akkoordOversluiten"
            sortable={true}
            minRows={0}
            loading={false}
            defaultPageSize={values.berekendeHypotheken.length}
            columns={hypotheekOversluitenColumns(values)}
            showPagination={false}
            filteredCallback={onDataGridChange}
            sortedCallback={onDataGridChange}
          />
          <div className={"d-flex align-items-center justify-content-start my-3"}>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="vink" alt="vink" />
              <span className={"ml-2"}>Oversluitvoordeel van maatschappij is passend.</span>
            </div>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="uitroepteken" multiColor={true} alt="uitroepteken" />
              <span className={"ml-2"}>Oversluitvoordeel is niet passend.</span>
            </div>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="kruis" alt="kruis" />
              <span className={"ml-2"}>Geen van de oversluitvoordelen is passend.</span>
            </div>
          </div>
        </div>
      )}
      {berekend && !values.dataHasChanged && values.berekendeHypotheken.length < 1 && (
        <div className="d-flex align-items-center justify-content-center">
          <div>Er zijn geen beschikbare resultaten.</div>
        </div>
      )}
    </div>
  );
};
