import { Icon, ModalButton } from "adviesbox-shared";
import classNames from "classnames";
import { Field, getIn, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { RapportageSamenstellenState, RapportStructuurType } from "./infra/rapportage-samenstellen-types";
import { RapportageElementenType } from "./infra/rapportage-structuur-types";
import classes from "./rapportage-structuur.module.scss";
import { TitelAanpassenModal } from "./titel-aanpassen-modal";

export type RapportageStructuurSamenstellenProps = {
  naam: string;
  elementen: RapportageElementenType[];
  parentKey: number;
  parent: string;
  isDragging?: boolean;
};

export const RapportageStructuurElementen = ({
  elementen,
  naam,
  parentKey,
  parent,
  isDragging
}: RapportageStructuurSamenstellenProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<RapportageSamenstellenState>();
  const [draggedItem, setDraggedItem] = useState<number | null>(null);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number): void => {
    setDraggedItem(index);
    e.currentTarget.classList.add(classes.dragging);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.currentTarget.classList.add(classes.dragOver);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.currentTarget.classList.remove(classes.dragOver);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number): void => {
    e.preventDefault();
    e.currentTarget.classList.remove(classes.dragOver);

    /* istanbul ignore next */
    if (draggedItem !== null) {
      const parentState = getIn(values, parent);
      const newElements = [...parentState.elementen];
      const [draggedElement] = newElements.splice(draggedItem, 1);
      newElements.splice(dropIndex, 0, draggedElement);
      setFieldValue(`${parent}.elementen`, newElements);
      setDraggedItem(null);
    }
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>): void => {
    e.currentTarget.classList.remove(classes.dragging);
    setDraggedItem(null);
  };

  /* istanbul ignore next */
  const setAllChildrenValuesBasedOnParentReference = (elem: RapportageElementenType): void => {
    elem.elementen.forEach(e => {
      e.geselecteerd = elem.geselecteerd;
      if (e.elementen.length) {
        setAllChildrenValuesBasedOnParentReference(e);
      }
    });
  };

  /* istanbul ignore next */
  const setParentValueBasedOnChildReference = (
    elem: RapportageElementenType | RapportStructuurType,
    currentPath: string
  ): void => {
    if (!("geselecteerd" in elem)) return;
    const pathArray = currentPath.split(".");
    pathArray.pop();
    const path = pathArray.join(".");
    const parentElem: RapportageElementenType | RapportStructuurType = getIn(values, path);
    if ("geselecteerd" in parentElem) {
      parentElem.geselecteerd = true;
      setFieldValue(`${path}.geselecteerd`, true);
      setParentValueBasedOnChildReference(parentElem, path);
    }
  };

  return (
    <div key={parent} className={classes.elementContainer}>
      <div className={classes.main_cell}>
        {parentKey > 0 && (
          <div className={classes.drag_n_drop_wrapper}>
            <Icon name="drag" />
          </div>
        )}

        {[...Array(parentKey)].map((_v, index) => (
          <div key={`${parent}[${index}]`} className={classes.spacer} />
        ))}

        <span className={classes.expand_wrapper}>
          {elementen?.length > 0 && (
            <Button
              onClick={() => setOpen(!open)}
              aria-expanded={open}
              aria-controls={`table_collapse_${parentKey}`}
              variant="light"
              className={classNames(classes.expand_btn, "font-weight-bold")}
              data-testid={`${parent}-expand-btn`}
            >
              {open ? "-" : "+"}
            </Button>
          )}
        </span>

        <span className={classes.checkbox_wrapper}>
          <Field
            onChange={() => {
              const parentElementClone = { ...getIn(values, parent) };
              parentElementClone.geselecteerd = !parentElementClone.geselecteerd;
              setAllChildrenValuesBasedOnParentReference(parentElementClone);

              if (parentElementClone.geselecteerd) {
                setParentValueBasedOnChildReference(parentElementClone, parent);
              }

              setFieldValue(`${parent}`, parentElementClone);
            }}
            name={`${parent}.geselecteerd`}
            type="checkbox"
          />
        </span>

        <span className={classNames(classes.label_wrapper, "name-span")} data-testid={`${parent}.naam`}>
          {naam}
        </span>
      </div>

      {elementen?.length > 0 && (
        <Collapse in={open} key={`collapse_${parentKey}`}>
          <div id={`table_collapse_${parentKey}`}>
            <div className={classes.childrenContainer}>
              {elementen.map((v: RapportageElementenType, i: number) => (
                <div
                  key={`${parent}.elementen[${i}]`}
                  draggable={parentKey > 0}
                  onDragStart={e => handleDragStart(e, i)}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={e => handleDrop(e, i)}
                  onDragEnd={handleDragEnd}
                  data-testid={`${parent}.elementen[${i}].handle`}
                  className={classes.draggableElement}
                >
                  <RapportageStructuurElementen
                    naam={v.naam}
                    elementen={v.elementen}
                    parentKey={parentKey + 1}
                    parent={`${parent}.elementen[${i}]`}
                    isDragging={i === draggedItem}
                  />
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      )}

      {!isDragging && parentKey > 0 && (
        <div className={classes.edit_cellcontainer}>
          <div className={classes.edit_cell}>
            <ModalButton
              parent={`${parent}`}
              aria-label="Titel aanpassen"
              size="lg"
              content={<Icon name="pencil" />}
              data-testid={`${parent}.naam-aanpassen-btn`}
            >
              <TitelAanpassenModal
                parent={parent}
                onSave={(data: RapportageSamenstellenState) => {
                  /* istanbul ignore next */
                  setFieldValue("selectedStructuur", data.selectedStructuur);
                }}
              />
            </ModalButton>
          </div>
        </div>
      )}
    </div>
  );
};
