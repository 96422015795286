import { hasValue } from "adviesbox-shared";
import {
  Motivering as MotiveringAlgemeenDlEntry,
  MotiveringBeschikbareJaarruimte,
  MotiveringControleGewenstInkomen,
  MotiveringControleHypotheeknorm,
  MotiveringOutput as MotiveringAlgemeenDlOutput,
  MotiveringUitgangspunten,
  MotiveringUitgangspuntenArbeidsongeschiktheid,
  MotiveringUitgangspuntenDubbeleWoonlasten,
  MotiveringUitgangspuntenHypotheek,
  MotiveringUitgangspuntenOverlijden,
  MotiveringUitgangspuntenPensioen,
  MotiveringUitgangspuntenWerkloosheid,
  MotiveringVoorstel
} from "../../.generated/forms/formstypes";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  adviesSchema,
  afwijkendeKeuzeSchema,
  ArbeidsongeschiktheidDatagridItemType,
  beschikbareJaarruimteSchema,
  motiveringControleGewenstInkomenSchema,
  motiveringControleHypotheeknormSchema,
  MotiveringScenario,
  motiveringSchema,
  MotiveringState,
  uitgangspuntenArbeidsongeschiktheidSchema,
  uitgangspuntenDubbeleWoonlastenSchema,
  uitgangspuntenHypotheekSchema,
  uitgangspuntenOverlijdenSchema,
  uitgangspuntenPensioenSchema,
  uitgangspuntenVermogenSchema,
  uitgangspuntenWerkloosheidSchema,
  voorstelSchema,
  wensSchema
} from "./motivering-schema";

const mapWensen = createMapToUi(wensSchema).from<MotiveringAlgemeenDlEntry>({
  wensenAanvrager1: v => v.wensenAanvrager1,
  wensenAanvrager2: v => v.wensenAanvrager2,
  wensenAlgemeen: v => v.wensenAlgemeen
});

const mapControleHypotheekNorm = createMapToUi(motiveringControleHypotheeknormSchema).from<
  MotiveringControleHypotheeknorm
>({
  controleMaandlast: v => v.controleMaandlast,
  controleerMaximaleHypotheek: v => v.controleerMaximaleHypotheek
});

const mapMotiveringControleGewenstInkomen = createMapToUi(motiveringControleGewenstInkomenSchema).from<
  MotiveringControleGewenstInkomen
>({
  benodigdKapitaalTonen: v => v.benodigdKapitaalTonen,
  brutoInkomenswensPercentage: v => v.brutoInkomenswensPercentage,
  controleGewenstInkomen: v => v.controleGewenstInkomen,
  controleGewenstNbi: v => v.controleGewenstNbi,
  gewijzigdeJarenTonen: v => v.gewijzigdeJarenTonen,
  huidigNbiPercentage: v => v.huidigNbiPercentage,
  margeBovengrensPercentage: v => v.margeBovengrensPercentage,
  margeOndergrensPercentage: v => v.margeOndergrensPercentage,
  vastBedrag: v => v.vastBedrag
});
const mapVoorstel = createMapToUi(voorstelSchema).from<MotiveringVoorstel>({
  voorstelId: v => v.voorstelId,
  omschrijving: v => v.omschrijving,
  volgnummer: v => v.volgnummer
});

const mapUitgangspuntenOverlijden = createMapToUi(uitgangspuntenOverlijdenSchema).from<
  MotiveringUitgangspuntenOverlijden
>({
  controleGewenstInkomen: v => mapMotiveringControleGewenstInkomen(v.controleGewenstInkomen),
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.controleHypotheeknorm),
  duurOverzicht: v => v.duurOverzicht,
  overzichtDuurInJaren: v => v.overzichtDuurInJaren,
  overzichtTotLeeftijdInMaanden: v => v.overzichtTotLeeftijdInMaanden,
  werkenNaOverlijdenPartnerAanvrager1Percentage: v => v.werkenNaOverlijdenPartnerAanvrager1Percentage,
  werkenNaOverlijdenPartnerAanvrager2Percentage: v => v.werkenNaOverlijdenPartnerAanvrager2Percentage,
  voorstellen: v => v.voorstellen?.map(voorstel => mapVoorstel(voorstel)),
  motiveringVoorVoorstelId: v => v.motiveringVoorVoorstelId,
  scenarioVanKlantIds: v => v.scenarioVanKlantIds,
  overzichtBeideTonen: v => false
});

const mapUitgangspuntenArbeidsongeschiktheid = createMapToUi(uitgangspuntenArbeidsongeschiktheidSchema).from<
  MotiveringUitgangspuntenArbeidsongeschiktheid
>({
  arbeidsongeschiktheidExtra1Percentage: v => v.arbeidsongeschiktheidExtra1Percentage,
  arbeidsongeschiktheidExtra2Percentage: v => v.arbeidsongeschiktheidExtra2Percentage,
  arbeidsongeschiktheidStandaardPercentage: v => v.arbeidsongeschiktheidStandaardPercentage,
  benuttingVerdiencapaciteitExtra1Percentage: v => v.benuttingVerdiencapaciteitExtra1Percentage,
  benuttingVerdiencapaciteitExtra2Percentage: v => v.benuttingVerdiencapaciteitExtra2Percentage,
  benuttingVerdiencapaciteitStandaardPercentage: v => v.benuttingVerdiencapaciteitStandaardPercentage,
  controleGewenstInkomen: v => mapMotiveringControleGewenstInkomen(v.controleGewenstInkomen),
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.controleHypotheeknorm),
  duurzaamArbeidsongeschiktExtra1: v => v.duurzaamArbeidsongeschiktExtra1,
  duurzaamArbeidsongeschiktExtra2: v => v.duurzaamArbeidsongeschiktExtra2,
  duurzaamArbeidsongeschiktStandaard: v => v.duurzaamArbeidsongeschiktStandaard,
  scenarioDoorrekeningExtra1: v => v.scenarioDoorrekeningExtra1,
  scenarioDoorrekeningExtra2: v => v.scenarioDoorrekeningExtra2,
  toetsjaar: v => v.toetsjaar,
  voorstellen: v => v.voorstellen?.map(voorstel => mapVoorstel(voorstel)),
  motiveringVoorVoorstelId: v => v.motiveringVoorVoorstelId,
  scenarioVanKlantIds: v => v.scenarioVanKlantIds,
  overzichtBeideTonen: v => false
});

const mapUitgangspuntenWerkloosheid = createMapToUi(uitgangspuntenWerkloosheidSchema).from<
  MotiveringUitgangspuntenWerkloosheid
>({
  controleGewenstInkomen: v => mapMotiveringControleGewenstInkomen(v.controleGewenstInkomen),
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.controleHypotheeknorm),
  voorstellen: v => v.voorstellen?.map(voorstel => mapVoorstel(voorstel)),
  motiveringVoorVoorstelId: v => v.motiveringVoorVoorstelId,
  scenarioVanKlantIds: v => v.scenarioVanKlantIds,
  overzichtBeideTonen: v => false
});

const mapUitgangspuntenHypotheek = createMapToUi(uitgangspuntenHypotheekSchema).from<MotiveringUitgangspuntenHypotheek>(
  {
    controleGewenstInkomen: v => mapMotiveringControleGewenstInkomen(v.controleGewenstInkomen),
    controleHypotheeknorm: v => mapControleHypotheekNorm(v.controleHypotheeknorm),
    voorstellen: v => v.voorstellen?.map(voorstel => mapVoorstel(voorstel)),
    motiveringVoorVoorstelId: v => v.motiveringVoorVoorstelId
  }
);

const mapUitgangspuntenVermogen = createMapToUi(uitgangspuntenVermogenSchema).from<MotiveringUitgangspunten>({
  voorstellen: v => v.voorstellen?.map(voorstel => mapVoorstel(voorstel)),
  motiveringVoorVoorstelId: v => v.motiveringVoorVoorstelId
});

export const mapBeschikbareJaarruimte = createMapToUi(beschikbareJaarruimteSchema).from<
  MotiveringBeschikbareJaarruimte
>({
  benutteJaarruimteBerekendBedrag: v => v.benutteJaarruimteBerekendBedrag,
  benutteJaarruimteOvernemen: v => v.benutteJaarruimteOvernemen,
  benutteJaarruimteBedrag: v => v.benutteJaarruimteBedrag,
  beschikbareJaarruimteBedrag: v => v.beschikbareJaarruimteBedrag,
  buitenlandseInkomstenBedrag: v => v.buitenlandseInkomstenBedrag,
  franchiseBedrag: v => v.franchiseBedrag,
  jaarruimteBedrag: v => v.jaarruimteBedrag,
  maxPremieGrondslag: v => v.maxPremieGrondslag,
  overigeWerkzaamhedenBedrag: v => v.overigeWerkzaamhedenBedrag,
  overigeWerkzaamhedenBerekendBedrag: v => v.overigeWerkzaamhedenBerekendBedrag,
  overigeWerkzaamhedenOvernemen: v => v.overigeWerkzaamhedenOvernemen,
  pensioenaangroeiBedrag: v => v.pensioenaangroeiBedrag,
  pensioenaangroeiPercentage: v => v.pensioenaangroeiPercentage,
  periodiekeUitkeringenBedrag: v => v.periodiekeUitkeringenBedrag,
  premiegrondslagBedrag: v => v.premiegrondslagBedrag,
  premiegrondslagPercentage: v => v.premiegrondslagPercentage,
  reisaftrekBedrag: v => v.reisaftrekBedrag,
  subtotaalFranchise1Bedrag: v => v.subtotaalFranchise1Bedrag,
  subtotaalFranchise2Bedrag: v => v.subtotaalFranchise2Bedrag,
  subtotaalPensioenaangroeiBedrag: v => v.subtotaalPensioenaangroeiBedrag,
  subtotaalToevoegingOdrBedrag: v => v.subtotaalToevoegingOdrBedrag,
  tegenwoordigeDienstbetrekkingBedrag: v => v.tegenwoordigeDienstbetrekkingBedrag,
  tegenwoordigeDienstbetrekkingBerekendBedrag: v => v.tegenwoordigeDienstbetrekkingBerekendBedrag,
  tegenwoordigeDienstbetrekkingOvernemen: v => v.tegenwoordigeDienstbetrekkingOvernemen,
  toevoegingOdrBedrag: v => v.toevoegingOdrBedrag,
  vroegereDienstbetrekkingBedrag: v => v.vroegereDienstbetrekkingBedrag,
  vroegereDienstbetrekkingBerekendBedrag: v => v.vroegereDienstbetrekkingBerekendBedrag,
  vroegereDienstbetrekkingOvernemen: v => v.vroegereDienstbetrekkingOvernemen,
  winstBedrag: v => v.winstBedrag,
  winstBerekendBedrag: v => v.winstBerekendBedrag,
  winstOvernemen: v => v.winstOvernemen,
  gewensteDuurPensioenUitkering: v => v.gewensteDuurPensioenUitkering,
  pensioenuitkeringTotLeeftijdInMaanden: v => v.pensioenuitkeringTotLeeftijdInMaanden
});

const mapUitgangspuntenPensioen = createMapToUi(uitgangspuntenPensioenSchema).from<MotiveringUitgangspuntenPensioen>({
  beschikbareJaarruimteAanvrager1: v => mapBeschikbareJaarruimte(v.beschikbareJaarruimteAanvrager1),
  beschikbareJaarruimteAanvrager2: v => mapBeschikbareJaarruimte(v.beschikbareJaarruimteAanvrager2),
  controleGewenstInkomen: v => mapMotiveringControleGewenstInkomen(v.controleGewenstInkomen),
  controleHypotheeknorm: v => mapControleHypotheekNorm(v.controleHypotheeknorm),
  voorstellen: v => v.voorstellen?.map(voorstel => mapVoorstel(voorstel)),
  motiveringVoorVoorstelId: v => v.motiveringVoorVoorstelId,
  scenarioVanKlantIds: v => v.scenarioVanKlantIds,
  overzichtBeideTonen: v => false
});

const mapAdvies = createMapToUi(adviesSchema).from<MotiveringAlgemeenDlEntry>({
  adviesAanvrager1: v => v.onsAdvies,
  adviesAanvrager2: v => v.onsAdvies2
});

const mapAfwijkendeKeuze = createMapToUi(afwijkendeKeuzeSchema).from<MotiveringAlgemeenDlEntry>({
  afwijkendeKeuzeAanvrager1: v => v.afwijkendeKeuze,
  afwijkendeKeuzeAanvrager2: v => v.afwijkendeKeuze2
});

const mapUitgangspuntenDubbeleWoonlasten = createMapToUi(uitgangspuntenDubbeleWoonlastenSchema).from<
  MotiveringUitgangspuntenDubbeleWoonlasten
>({
  geschatteDuurOverbruggingInMaanden: v => v.geschatteDuurOverbruggingInMaanden
});

const mapMotivering = createMapToUi(motiveringSchema).from<MotiveringAlgemeenDlEntry>({
  wens: v => mapWensen(v),
  aanvrager1: v => mapKlantnaamDl2Ui(v.aanvrager1),
  aanvrager2: v => mapKlantnaamDl2Ui(v.aanvrager2),

  advies: v => mapAdvies(v),
  tabellenResourceUrl: v => v.tabellenResourceUrl,
  uitgangspuntenDubbeleWoonlasten: v =>
    v.uitgangspuntenDubbeleWoonlasten ? mapUitgangspuntenDubbeleWoonlasten(v.uitgangspuntenDubbeleWoonlasten) : null,
  afwijkendeKeuze: v => mapAfwijkendeKeuze(v),
  uitgangspuntenOverlijden: v =>
    v.uitgangspuntenOverlijden ? mapUitgangspuntenOverlijden(v.uitgangspuntenOverlijden) : null,
  uitgangspuntenWerkloosheid: v =>
    v.uitgangspuntenWerkloosheid ? mapUitgangspuntenWerkloosheid(v.uitgangspuntenWerkloosheid) : null,
  uitgangspuntenHypotheek: v =>
    v.uitgangspuntenHypotheek ? mapUitgangspuntenHypotheek(v.uitgangspuntenHypotheek) : null,
  uitgangspuntenVermogen: v => (v.uitgangspuntenVermogen ? mapUitgangspuntenVermogen(v.uitgangspuntenVermogen) : null),
  uitgangspuntenPensioen: v => (v.uitgangspuntenPensioen ? mapUitgangspuntenPensioen(v.uitgangspuntenPensioen) : null),
  uitgangspuntenArbeidsongeschiktheid: v =>
    v.uitgangspuntenArbeidsongeschiktheid
      ? mapUitgangspuntenArbeidsongeschiktheid(v.uitgangspuntenArbeidsongeschiktheid)
      : null,
  arbeidsongeschiktheidDatagrid: v => {
    const dataGridValues: ArbeidsongeschiktheidDatagridItemType[] = [];
    if (v.uitgangspuntenArbeidsongeschiktheid) {
      dataGridValues.push({
        aoPercentage: v.uitgangspuntenArbeidsongeschiktheid.arbeidsongeschiktheidStandaardPercentage,
        benuttingVerdiencapaciteit: v.uitgangspuntenArbeidsongeschiktheid.benuttingVerdiencapaciteitStandaardPercentage,
        duurzaamAo: v.uitgangspuntenArbeidsongeschiktheid.duurzaamArbeidsongeschiktStandaard,
        scenario: MotiveringScenario.Standaard,
        motiveringVoorVoorstelId: v.uitgangspuntenArbeidsongeschiktheid.motiveringVoorVoorstelId
      });
      hasValue(v.uitgangspuntenArbeidsongeschiktheid.arbeidsongeschiktheidExtra1Percentage) &&
        dataGridValues.push({
          aoPercentage: v.uitgangspuntenArbeidsongeschiktheid.arbeidsongeschiktheidExtra1Percentage,
          benuttingVerdiencapaciteit: v.uitgangspuntenArbeidsongeschiktheid.benuttingVerdiencapaciteitExtra1Percentage,
          duurzaamAo: v.uitgangspuntenArbeidsongeschiktheid.duurzaamArbeidsongeschiktExtra1,
          scenario: MotiveringScenario.Extra,
          motiveringVoorVoorstelId: v.uitgangspuntenArbeidsongeschiktheid.motiveringVoorVoorstelId
        });
      hasValue(v.uitgangspuntenArbeidsongeschiktheid.arbeidsongeschiktheidExtra2Percentage) &&
        dataGridValues.push({
          aoPercentage: v.uitgangspuntenArbeidsongeschiktheid.arbeidsongeschiktheidExtra2Percentage,
          benuttingVerdiencapaciteit: v.uitgangspuntenArbeidsongeschiktheid.benuttingVerdiencapaciteitExtra2Percentage,
          duurzaamAo: v.uitgangspuntenArbeidsongeschiktheid.duurzaamArbeidsongeschiktExtra2,
          scenario: MotiveringScenario.Extra,
          motiveringVoorVoorstelId: v.uitgangspuntenArbeidsongeschiktheid.motiveringVoorVoorstelId
        });
      return dataGridValues;
    }
    return null;
  },
  dubbeleWoonlasten: v => null,
  uitgangspunten: v => null,
  financiering: v => null,
  dataHasChanged: _ => false
});

export function mapMotiveringAlgemeenDlToUi(
  motiveringAlgemeenId: string,
  data: MotiveringAlgemeenDlOutput
): MotiveringState | null {
  const motivering = data && data.isValid && data.motiveringen ? data.motiveringen[motiveringAlgemeenId] : null;

  if (motivering) {
    return mapMotivering(motivering);
  }

  return null;
}
