import {
  BerekenCurrencyInput,
  Icon,
  LabeledCurrencyInput,
  LabeledNumberInput,
  LabeledRadioInput,
  LabeledResult
} from "adviesbox-shared";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Collapse } from "react-bootstrap";
import {
  GeslachtOpties,
  DuurPensioenuitkeringOptions,
  ControleGewenstInkomenOptions
} from "../../../.generated/forms/formstypes";
import { bedragFormat } from "../../../shared/utils/currency";
import { getNaam } from "../../../shared/utils/helpers";
import { DashboardState } from "../../infra/dashboard-types";
import { BeschikbareJaarruimteType } from "../../scenario-pensioen-modal/infra/scenario-pensioen-modal-schema";
import classes from "./../../dashboard.module.scss";

type NamePrefix = "beschikbareJaarruimteAanvrager" | "beschikbareJaarruimtePartner";
type BeschikbareJaarruimteProps = {
  namePrefix: NamePrefix;
  beschikbareJaarruimteValues: BeschikbareJaarruimteType;
};

export const BeschikbareJaarruimte = ({
  namePrefix,
  beschikbareJaarruimteValues
}: BeschikbareJaarruimteProps): ReactElement => {
  const [showSpecificatieBeschikbareJaarruimte, setSpecificatieBeschikbareJaarruimte] = useState(false);
  const { values } = useFormikContext<DashboardState>();

  const aanvrager = namePrefix === "beschikbareJaarruimteAanvrager" ? values.aanvrager1 : values.aanvrager2;
  const naamAanvrager = `${aanvrager?.geslacht === GeslachtOpties.Man ? "dhr." : "mvr."} ${getNaam(
    aanvrager,
    "Aanvrager"
  )}`;

  const gewenstePensioenUitkering =
    namePrefix === "beschikbareJaarruimteAanvrager"
      ? values.pensioen.beschikbareJaarruimteAanvrager?.gewenstePensioenUitkering
      : values.pensioen.beschikbareJaarruimtePartner?.gewenstePensioenUitkering;

  /* istanbul ignore next */
  if (aanvrager === null) {
    throw new Error(`${namePrefix} ontbreekt`);
  }

  return (
    <>
      {values.pensioen.controleNorm.controleGewenstInkomen === ControleGewenstInkomenOptions.Nbi && (
        <>
          <LabeledRadioInput
            caption="Gewenste duur pensioenuitkering"
            name={`pensioen.${namePrefix}.gewenstePensioenUitkering`}
            options={[
              { label: "Levenslang", value: DuurPensioenuitkeringOptions.Levenslang },
              { label: "Tot leeftijd", value: DuurPensioenuitkeringOptions.TotLeeftijd }
            ]}
          />
          {gewenstePensioenUitkering === DuurPensioenuitkeringOptions.TotLeeftijd && (
            <LabeledNumberInput
              caption="Leeftijd"
              name={`pensioen.${namePrefix}.gewenstePensioenLeeftijd`}
              appendChildren={<small className="pt-2">Jaar</small>}
            />
          )}
        </>
      )}
      <br />
      <LabeledResult
        caption="Beschikbare jaarruimte"
        name={`pensioen.${namePrefix}.beschikbareJaarruimte`}
        labelColSize={6}
        alignRight={true}
        currency={true}
        result={(): string => {
          return bedragFormat(beschikbareJaarruimteValues.beschikbareJaarruimteBedrag, 0, 0, false);
        }}
      />
      <div
        className={
          showSpecificatieBeschikbareJaarruimte ? classNames(classes.btn_striped, "text-center") : "text-center"
        }
      >
        <button
          onClick={() => {
            setSpecificatieBeschikbareJaarruimte(!showSpecificatieBeschikbareJaarruimte);
          }}
          className={"btn btn-light ml-1 position-relative"}
          type="button"
          aria-controls="beschikbare-jaarruimte"
          aria-expanded={showSpecificatieBeschikbareJaarruimte}
          style={{ zIndex: 10 }}
          data-testid={`beschikbarejaarrumte-${namePrefix}-btn`}
          id={`beschikbarejaarrumte-${namePrefix}-btn`}
        >
          {showSpecificatieBeschikbareJaarruimte ? (
            <>
              Specificatie beschikbare jaarruimte&nbsp;{naamAanvrager}
              <div className={classes.chevron_up}>
                <Icon name="chevron" alt="chevron-up" />
              </div>
            </>
          ) : (
            <>
              Specificatie beschikbare jaarruimte&nbsp;{naamAanvrager}
              <div className={classes.chevron}>
                <Icon name="chevron" alt="chevron" />
              </div>
            </>
          )}
        </button>
      </div>
      <Collapse in={showSpecificatieBeschikbareJaarruimte}>
        <div id="beschikbare-jaarruimte" data-testid={`${namePrefix}-beschikbare-jaarruimte-collapse`} className="pt-3">
          <BerekenCurrencyInput
            caption="Winst uit onderneming voor ondernemersaftrek"
            name={`pensioen.${namePrefix}.winstOndernemingsaftrek`}
          />
          <BerekenCurrencyInput
            caption="Inkomen uit huidige dienstbetrekking"
            name={`pensioen.${namePrefix}.inkomenHuidigeDienstbetrekking`}
          />
          <BerekenCurrencyInput
            caption="Inkomen uit vroegere dienstbetrekking"
            name={`pensioen.${namePrefix}.inkomenVroegereDienstbetrekking`}
          />
          <LabeledCurrencyInput
            caption="Buitenlandse inkomsten"
            name={`pensioen.${namePrefix}.buitenlandseInkomsten`}
          />
          <LabeledCurrencyInput
            caption="Reisaftrek woon- en werkverkeer"
            name={`pensioen.${namePrefix}.reisaftrekWoonWerkverkeer`}
          />
          <BerekenCurrencyInput
            caption="Resultaat overige werkzaamheden"
            name={`pensioen.${namePrefix}.resultaatOverigeWerkzaamheden`}
          />
          <LabeledCurrencyInput caption="Periodieke uitkeringen" name={`pensioen.${namePrefix}.periodiekeUitkering`} />

          <div className="row">
            <div className="col-7">
              <strong>Premiegrondslag (max. {bedragFormat(beschikbareJaarruimteValues.maxPremieGrondslag)})</strong>
              <br />
              Franchise
            </div>
            <div className="col-4">
              <div className="text-right pr-4 d-inline-block w-100">
                {bedragFormat(beschikbareJaarruimteValues.premiegrondslagBedrag)}
              </div>
              <div className="text-right pr-4 d-inline-block w-100">
                {bedragFormat(beschikbareJaarruimteValues.franchiseBedrag)}
              </div>
              <br />
              <div className="text-right pr-4 d-inline-block w-100">
                {bedragFormat(beschikbareJaarruimteValues.subtotaalFranchise1Bedrag)}
              </div>
              <div className="text-right pr-4 d-inline-block w-100">
                {beschikbareJaarruimteValues.premiegrondslagPercentage}
              </div>
              <br />
              <div className="text-right pr-4 d-inline-block w-100">
                {bedragFormat(beschikbareJaarruimteValues.subtotaalFranchise2Bedrag)}
              </div>
            </div>
          </div>

          <LabeledCurrencyInput
            caption="Pensioenaangroei (factor A)"
            name={`pensioen.${namePrefix}.pensioenaangroeiBedrag`}
          />

          <div className="row">
            <div className="col-6"></div>
            <div className="col-4">
              <div className="text-right pr-4 d-inline-block w-100">
                {beschikbareJaarruimteValues.pensioenaangroeiPercentage}
              </div>
              <div className="text-right pr-4 d-inline-block w-100">
                {bedragFormat(beschikbareJaarruimteValues.subtotaalPensioenaangroeiBedrag)}
              </div>
            </div>
          </div>

          <LabeledResult
            caption="Jaarruimte"
            name={`pensioen.${namePrefix}.jaarruimte`}
            labelColSize={7}
            alignRight={true}
            currency={true}
            result={() => bedragFormat(beschikbareJaarruimteValues.jaarruimteBedrag, 0, 0, false)}
            fontWeight="bold"
          />
          <BerekenCurrencyInput
            caption="Benutte jaarruimte"
            labelColSize={7}
            name={`pensioen.${namePrefix}.benutteJaarruimteBedrag`}
          />

          <div className="row">
            <div className="col-7"></div>
            <div className="col-4">
              <div className="border-container mb-2">
                <div className="calculated-border" />
                <div className={classNames(classes.calculation_line_with_minus, "calculated-icon")}>- / -</div>
              </div>
            </div>
          </div>

          <LabeledResult
            caption="Beschikbare jaarruimte"
            name={`pensioen.${namePrefix}.beschikbareJaarruimte`}
            labelColSize={7}
            alignRight={true}
            currency={true}
            result={(): string => {
              return bedragFormat(beschikbareJaarruimteValues.beschikbareJaarruimteBedrag, 0, 0, false);
            }}
            fontWeight="bold"
          />
        </div>
      </Collapse>
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  BeschikbareJaarruimte.displayName = "Beschikbare jaarruimte";
