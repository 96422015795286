import { hasValue, mapJaarMaandInputFromLooptijdDl2Ui, mapStringToLocalDate } from "adviesbox-shared";
import {
  HypotheekRenteaftrek,
  HypotheekvergelijkerLeningdeel,
  AflossingsVormType,
  RentevariantOptions
} from "../../../.generated/hypotheek-vergelijker/hypotheek-vergelijkertypes";
import { fiscalegegevensSchema, productSchema } from "../../../producten-overzicht/infra/producten-overzicht-schema";
import { mapBerekenInput } from "../../../shared/types";
import { createMapToUi } from "../../../shared/utils/create-map-to-ui";
import {
  hypotheekFiscaleRegelingSchema,
  hypotheekSchema,
  hypotheekVormSchema,
  leningdeelgegevensSchema
} from "../../infra/hypotheek-schema";
import { RenteAftrek, SpecificatieRenteaftrekModalType } from "../../infra/hypotheek-types";

export const mapHypotheekVergelijkerAflossingsVorm = (vorm: AflossingsVormType | null): AflossingsVormType => {
  if (vorm && AflossingsVormType[vorm]) {
    return AflossingsVormType[vorm];
  }
  return AflossingsVormType.Annuïteit;
};

export const mapHypotheekVergelijkerRentevariant = (
  variant: RentevariantOptions | null
): RentevariantOptions | null => {
  if (variant && RentevariantOptions[variant]) {
    return RentevariantOptions[variant];
  }
  return RentevariantOptions.Rentevast;
};

const mapHypotheekVorm = createMapToUi(hypotheekVormSchema).from<HypotheekvergelijkerLeningdeel>({
  aflossingsvorm: v => mapHypotheekVergelijkerAflossingsVorm(v.aflossingsvorm),
  isStartersLening: v => v.starterslening,
  isRestschuldLening: v => v.restschuldFinanciering,
  code: () => null,
  omschrijving: () => null
});

const mapLeningdeelGegevens = createMapToUi(leningdeelgegevensSchema).from<HypotheekvergelijkerLeningdeel>({
  oorspronkelijkeHoofdsom: v => v.leningdeelBedrag,
  renteVariant: v => mapHypotheekVergelijkerRentevariant(v.rentevariant),
  rentevastPeriodeJaar: v => v.rentevastperiodeInJaren,
  rentevastperiodeKeuze: v => v.rentevastperiodeInJaren,
  leningdeelHoofdsom: v => {
    const mappedBedragen = mapBerekenInput(v.leningdeelBedrag);
    const roundedBedragen = {
      bedrag: mappedBedragen.bedrag ? Math.round(mappedBedragen.bedrag) : null,
      berekendBedrag: mappedBedragen.berekendBedrag ? Math.round(mappedBedragen.berekendBedrag) : 0,
      berekenen: mappedBedragen.berekenen
    };
    return roundedBedragen;
  },
  automatischeRentedaling: v => null,
  automatischeRentedalingModal: v => null,
  datumOpgave: v => null,
  einddatum: v => {
    const aanvangDatum = mapStringToLocalDate(v.aanvangsdatum);
    if (!aanvangDatum) return null;
    return aanvangDatum.plusYears(v.rentevastperiodeInJaren ?? 0);
  },
  extraAflossing: v => null,
  garantie: v => null,
  leningDeelId: v => null,
  periodiekeAflossing: v => null,
  renteBedenktijdJaar: v => null,
  rentePercentage: v => null,
  renteScenarioModal: v => null,
  renteVariantenWaarschuwingText: _ => null
});

const mapProduct = createMapToUi(productSchema).from<HypotheekvergelijkerLeningdeel>({
  ingangsdatum: v => mapStringToLocalDate(v.aanvangsdatum),
  looptijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.looptijdInMaanden),
  doorlopend: v => v.doorlopend,
  einddatum: v => {
    const aanvangDatum = mapStringToLocalDate(v.aanvangsdatum);
    if (!aanvangDatum) return null;
    return aanvangDatum.plusMonths(v.looptijdInMaanden ?? 0);
  },
  meenemen: v => null,
  omschrijving: v => null,
  partijCodeSelectie: v => null,
  partijNaam: v => null,
  productNaam: v => null,
  productNummer: v => null,
  uwBemiddeling: v => null,
  wijzigingenInDoorlopendProductOvernemen: v => null,
  renteboxCode: v => v.hypotheekvorm?.renteboxCode
});

const mapFiscaleRegeling = createMapToUi(hypotheekFiscaleRegelingSchema).from<HypotheekvergelijkerLeningdeel>({
  ingebrachteWaardeBedrag: v => v.fiscaleVoortzetting?.ingebrachteWaardeBedrag,
  doelkapitaalBedrag: v => null,
  eerdereUitkeringenBedrag: v => null,
  einddatum: v => null,
  externeMaatschappijCode: v => null,
  externeMaatschappijOmschrijving: v => null,
  fiscaalRegime: v => null,
  fiscaleTypering: v => null,
  fiscaleVoortzetting: v => null,
  garantieverzekering: v => null,
  hoogstePremieOoitBedrag: v => null,
  huidigeJaarPremieBedrag: v => null,
  ingangsdatumBox1: v => null,
  kapitaalopbouw: v => null,
  laagstePremieooitBedrag: v => null,
  lijfrenteclausuleOrigineel: v => null,
  oorspronkelijkeIngangsdatum: v => null,
  originelePolisnummer: v => null,
  polisnummer: v => null,
  premieLopendJaarBedrag: v => null,
  productId: v => null
});

function mapRenteaftrekkenkDl2Ui(renteaftrekkenDl: HypotheekRenteaftrek[] | null): SpecificatieRenteaftrekModalType {
  const renteAftrekkenUi: RenteAftrek[] = [];

  if (renteaftrekkenDl) {
    renteaftrekkenDl.map(c =>
      renteAftrekkenUi.push({
        aanvangsdatum: c.aanvangsdatum ? mapStringToLocalDate(c.aanvangsdatum) : null,
        einddatum: c.einddatum ? mapStringToLocalDate(c.einddatum) : null,
        bedrag: c.bedrag ? Math.round(c.bedrag) : null // afronden, evenals bij deelBox1Bedrag (anders komt specificatiebedrag niet overeen)
      })
    );

    return { renteAftrekken: renteAftrekkenUi };
  }
  return {} as SpecificatieRenteaftrekModalType;
}

const mapFiscaleGegevens = createMapToUi(fiscalegegevensSchema).from<HypotheekvergelijkerLeningdeel>({
  deelBox1Bedrag: v => (v.deelBox1Bedrag ? Math.round(v.deelBox1Bedrag) : null),
  deelBox1Percentage: v =>
    v.leningdeelBedrag ? (Math.round(v.deelBox1Bedrag || 0) / Math.round(v.leningdeelBedrag)) * 100 : 100,
  begindatumRenteaftrek: v => {
    const begindatum =
      v.renteaftrekken && v.renteaftrekken.length > 0 ? v.renteaftrekken[0].aanvangsdatum : v.aanvangsdatum;
    return mapStringToLocalDate(begindatum);
  },
  deelBox3Bedrag: v => (hasValue(v.leningdeelBedrag) ? Math.round(v.leningdeelBedrag - (v.deelBox1Bedrag || 0)) : null),
  deelBox3Percentage: v =>
    v.leningdeelBedrag ? (1 - Math.round(v.deelBox1Bedrag || 0) / Math.round(v.leningdeelBedrag)) * 100 : null,
  einddatumRenteaftrek: v => {
    const beginDatum = mapStringToLocalDate(v.aanvangsdatum);
    if (!beginDatum || !v.looptijdInMaanden) return null;
    const einddatum =
      v.renteaftrekken && v.renteaftrekken.length > 0
        ? mapStringToLocalDate(v.renteaftrekken[0].einddatum)
        : beginDatum.plusYears(30);
    return einddatum;
  },
  renteaftrekSpecificatie: v => mapRenteaftrekkenkDl2Ui(v.renteaftrekken),
  consumptiefBedrag: v => null,
  bevatOpnames: v => null
});

export const mapHypotheekGenereerLeningdeelDl2Ui = createMapToUi(hypotheekSchema).from<HypotheekvergelijkerLeningdeel>({
  hypotheekVorm: v => mapHypotheekVorm(v),
  leningdeelgegevens: v => mapLeningdeelGegevens(v),
  product: v => {
    return { ...mapProduct(v), betreftOpname: false }; // in de hypotheekvergelijker geven we geen overwaarde producten terug.
  },
  fiscalegegevens: v => mapFiscaleGegevens(v),
  fiscaleRegeling: v => mapFiscaleRegeling(v),
  opnameBedrag: v => null,
  opnameMaanden: v => null,
  opnameSoort: v => null,
  marktwaardeWaarschuwingTonen: () => null,
  gekoppeldProduct: () => null,
  opmerking: () => null,
  hypotheekOptiesIngPriceToolLeningdeel: () => null,
  hypotheekProductDetails: () => null,
  nearMatchHypotheekvormen: () => null,
  isNieuw: () => null,
  kapitaalopbouw: () => null,
  labelCode: () => null,
  labelNaam: () => null,
  energieKlasse: () => null,
  premieGegevens: () => null,
  verzekerde: () => null,
  verzekeringnemers: () => null,
  schuldenaars: () => null,
  partijCode: () => null,
  productCode: () => null,
  soortOnderpand: () => null,
  volgnummer: () => null,
  renteBoxMaatschappijCode: () => null,
  renteBoxSoort: () => null,
  productwaarde: () => null
});
