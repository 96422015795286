import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import InbrengEigenGeldModal from "../inbreng-eigen-geld-modal/inbreng-eigen-geld-modal";
import { opgebouwdeWaardeBijRestschuldBerekening } from "./financieringsopzet-helper";
import { Icon, LabeledResult } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { optellen, bedragFormat } from "../../shared/utils/currency";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

const InbrengEigenGeld = ({
  formik: {
    values,
    setFieldValue,
    values: { financieringsopzet }
  }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const { inbrengEigenGeldModal } = financieringsopzet;
  return (
    <LabeledResult
      caption="Inbreng eigen geld"
      name="inbrengEigenGeldTotaal"
      alignRight={true}
      currency={true}
      result={(): string =>
        bedragFormat(
          optellen([
            inbrengEigenGeldModal.inbrengEigenGeld,
            inbrengEigenGeldModal.inbrengVoorVerlagenRestschuld,
            inbrengEigenGeldModal.eigenGeldOverwaarde.bedrag,
            inbrengEigenGeldModal.extraAflossing,
            inbrengEigenGeldModal.inbrengEigengeldBedrag1,
            inbrengEigenGeldModal.inbrengEigengeldBedrag2,
            inbrengEigenGeldModal.inbrengOpgebouwdeWaarde,
            inbrengEigenGeldModal.inbrengPolisBedrag
          ]),
          0,
          0,
          false
        )
      }
      appendChildren={
        <ModalButton
          resetSize={true}
          parent="financieringsopzet.inbrengEigenGeldModal"
          aria-label="Inbreng eigen geld button"
          content={<Icon name="specificatie" alt="Inbreng eigen geld" />}
        >
          <InbrengEigenGeldModal
            data={values.financieringsopzet.inbrengEigenGeldModal}
            onSave={(result): void => {
              const opgebouwdeWaardeBijRestschuld = opgebouwdeWaardeBijRestschuldBerekening(
                result,
                financieringsopzet.totaleRestschuldModal
              );
          
              const shouldUseOpgebouwdeWaarde = financieringsopzet.totaleRestschuldModal.inbrengOpgebouwdeWaardeRestschuld.berekenen || financieringsopzet.totaleRestschuldModal.inbrengOpgebouwdeWaardeRestschuld.berekenen === null;
              const bedrag = shouldUseOpgebouwdeWaarde ? opgebouwdeWaardeBijRestschuld : financieringsopzet.totaleRestschuldModal.inbrengOpgebouwdeWaardeRestschuld.bedrag;
          
              setFieldValue("financieringsopzet.inbrengEigenGeldModal", {
                ...result,
                waarvanVerlagenRestschuld: bedrag
              });
          
              setFieldValue("financieringsopzet.totaleRestschuldModal.inbrengOpgebouwdeWaardeRestschuld.bedrag", bedrag);
              setFieldValue("financieringsopzet.totaleRestschuldModal.inbrengOpgebouwdeWaardeRestschuld.berekendBedrag", opgebouwdeWaardeBijRestschuld);
              setFieldValue("financieringsopzet.inbrengOpgebouwdeWaarde", opgebouwdeWaardeBijRestschuld);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(InbrengEigenGeld);
