import React, { useCallback, useMemo } from "react";
import { LineChart, bedragFormat } from "adviesbox-shared";
import { LastenOverzicht, Lasten, RapportFinancieleGebeurtenis } from "../../../.generated/rapportage/rapportagetypes";
import "./lastenoverzicht-chart.scss";
import pDebounce from "p-debounce";

type LastenOverzichtChartProps = {
  onHoverCallback?: (jaarGegevens: Lasten & { gebeurtenissen: string[] }) => void;
  onClickCallback?: (jaarGegevens: Lasten & { gebeurtenissen: string[] }) => void;
  data: LastenOverzicht | null | undefined;
};

const getShortGebeurtenisOmschrijving = (omschrijving: string | null | undefined): string => {
  if (!omschrijving) return "";
  if (omschrijving.startsWith("Aankoop")) return "Aankoop";
  if (omschrijving.endsWith("18 jaar")) return "18 jaar";
  if (omschrijving.includes("AOW")) return "AOW";
  if (omschrijving.includes("Einde looptijd")) return "Lpt";
  if (omschrijving.startsWith("Einde rentevast")) return "Rente";
  return omschrijving;
};

const LastenOverzichtChart: React.FC<LastenOverzichtChartProps> = ({
  onHoverCallback,
  onClickCallback,
  data
}: LastenOverzichtChartProps) => {
  const gebeurtenissen = useMemo(() => {
    return Object.values(data?.belangrijkeGebeurtenissen || {}).filter((event): event is RapportFinancieleGebeurtenis => event !== null && event !== undefined);
  }, [data]);

  const jaarGegevens = useMemo(() => {
    if (!data?.jaarGegevens) return [];
    
    return data.jaarGegevens.map(c => {
      const jaartal = c.jaar.toString();
  
      const events = gebeurtenissen
        .filter(k => k.jaar === c.jaar)
        .map(val => val.omschrijving?.split("\r\n").map(getShortGebeurtenisOmschrijving))
        .flat(); // Flatten the nested arrays
  
      const uniqueEvents = Array.from(new Set(events));
  
      return [jaartal, uniqueEvents.length > 0 ? uniqueEvents : [""]];
    });
  }, [data, gebeurtenissen]);

  /* istanbul ignore next */
  const handleHover = useMemo(
    () =>
      pDebounce((e: any, items: any) => {
        if (items.length && onHoverCallback) {
          const year = items[0].element.$context.raw.id;
          const yearData = data?.jaarGegevens?.find(c => c.jaar === year);
          if (yearData) {
            const jaarGegevens = {
              ...yearData,
              gebeurtenissen: gebeurtenissen
              .filter(k => k.jaar === year)
              .flatMap(k => k.omschrijving?.split("\r\n")
                .map(i => (i))
                .flat()
              ) || []
            };
            onHoverCallback(jaarGegevens);
          }
        }
      }, 150),
    [data, gebeurtenissen, onHoverCallback]
  );

  /* istanbul ignore next */
  const handleClick = useCallback(
    (e: any, items: any) => {
      if (items.length && onClickCallback) {
        const year = items[0].element.$context.raw.id;
        const yearData = data?.jaarGegevens?.find(c => c.jaar === year);
        if (yearData) {
          const jaarGegevens = {
            ...yearData,
            gebeurtenissen: gebeurtenissen
            .filter(k => k.jaar === year)
            .flatMap(k => k.omschrijving?.split("\r\n")
              .map(i => (i))
              .flat()
            ) || []          };
          onClickCallback(jaarGegevens);
        }
      }
    },
    [data, gebeurtenissen, onClickCallback]
  );

  const datasets = useMemo(
    () => [
      {
        label: "Bruto maandlasten",
        data:
          data?.jaarGegevens?.map(c => ({
            id: c.jaar,
            value: c.brutoLast
          })) || [],
        backgroundColor: "#DF2935",
        borderColor: "#DF2935",
        borderWidth: 3,
        radius: 0
      },
      {
        label: "Netto maandlasten",
        backgroundColor: "#C2F970",
        borderColor: "#C2F970",
        data:
          data?.jaarGegevens?.map(c => ({
            id: c.jaar,
            value: c.nettoLast
          })) || [],
        borderWidth: 3,
        radius: 0
      }
    ],
    [data]
  );

  return (
    <div className="p-2 lastenoverzicht-chart" data-testid="line-chart">
      {!data?.jaarGegevens?.length && <>Geen lastenoverzicht gegevens beschikbaar</>}
      <LineChart
        labels={jaarGegevens}
        optionsObject={{
          maintainAspectRatio: false,
          parsing: {
            xAxisKey: "id",
            yAxisKey: "value"
          },
          scales: {
            x: {
              ticks: {
                maxRotation: 45,
                minRotation: 45
              },
              afterFit: (scale: any) => {
                scale.height = 80;
              }
            },
            y: {
              ticks: {
                callback: /* istanbul ignore next */ (val: any, _index: any) => {
                  return bedragFormat(val);
                }
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              align: "start",
              labels: {
                boxPadding: 16,
                usePointStyle: true,
                boxHeight: 8,
                boxWidth: 8
              }
            },
            tooltip: {
              callbacks: {
                label: /* istanbul ignore next */ (tooltipItem: any) => {
                  return " " + bedragFormat(tooltipItem.dataset.data[tooltipItem.dataIndex].value, 0);
                }
              }
            }
          },
          onHover: handleHover,
          onClick: handleClick
        }}
        datasets={datasets}
      />
    </div>
  );
};

export default LastenOverzichtChart;