import React from "react";
import { Table } from "react-bootstrap";
import { LastenOverzicht, Lasten, Totalen } from "../../../.generated/rapportage/rapportagetypes";
import { bedragFormatNull } from "../scenarios/infra/scenario-utils";
import "./lastenoverzicht-table.scss";
import classNames from "classnames";
import { CardLoadingSpinner } from "../scenarios/card-loading-spinner";

type LastenOverzichtTableProps = {
  data: LastenOverzicht | null | undefined;
  beperkteLastenTonen?: boolean;
  jarenTonen?: boolean;
  loading?: boolean;
};

type ColumnConfig = {
  headerTitle: string;
  isLeeftijd?: true;
  showTotal?: boolean;
  showAccented?: boolean;
  inBeperktTonen?: boolean;
  isCurrency?: boolean;
};

const lastenOverzichtColumnConfig=(beperktTonen?:boolean): Record<string, ColumnConfig>  => {return  {
  jaar: { headerTitle: "Jaar" },
  leeftijdAanvrager: { headerTitle: "Leeftijd aanvrager", isLeeftijd: true },
  leeftijdPartner: { headerTitle: "Leeftijd partner", isLeeftijd: true },
  brutoInkomen: { headerTitle: "Bruto inkomen", isCurrency: true },
  waardeopbouw: { headerTitle: "Waardeopbouw", isCurrency: true },
  somHypotheken: { headerTitle: beperktTonen ? "Restant schuld" : "Som hypotheken", isCurrency: true, showTotal: false, inBeperktTonen: true },
  rente: { headerTitle: "Rente", isCurrency: true },
  hypotheekAflossing: { headerTitle: "Hypotheek aflossing", isCurrency: true },
  uitkeringKapitaalverzekeringen: { headerTitle: "Uitkering kapitaalverz.", isCurrency: true },
  verzekeringspremies: { headerTitle: "Verzekering premies", isCurrency: true },
  spaarEnBeleggingsdepots: { headerTitle: "Spaar- en beleggingsdepots", isCurrency: true },
  opnameUitDepot: { headerTitle: "Opname uit depot", isCurrency: true },
  kredietlast: { headerTitle: "Kredietlast", isCurrency: true },
  overigeUitgaven: { headerTitle: "Overige uitgaven", isCurrency: true },
  huur: { headerTitle: "Huur", isCurrency: true },
  brutoLast: { headerTitle: "Bruto last", isCurrency: true, showAccented: true, inBeperktTonen: true },
  fiscaalVoordeel: { headerTitle: "Fiscaal voordeel", isCurrency: true },
  vrh: { headerTitle: "Belasting box 3", isCurrency: true, inBeperktTonen: true },
  nettoLast: { headerTitle: "Netto last", isCurrency: true, inBeperktTonen: true }
}};

const LastenOverzichtTable: React.FC<LastenOverzichtTableProps> = ({ data, beperkteLastenTonen: beperktTonen, jarenTonen, loading }: LastenOverzichtTableProps) => {
  // Helper function to format values and div 12
  const formatValue = (value: number | null, col: string, div12?: boolean): string | number => {
    if (value === null) return "";
    return lastenOverzichtColumnConfig(beperktTonen)[col].isCurrency
      ? bedragFormatNull(
          value,
          x => {
            return x * (div12 ? 12 : 1);
          },
          0
        )
      : value;
  };

  // Filter columns based on configuration only, not data
  const columnsWithData = Object.keys(lastenOverzichtColumnConfig(beperktTonen)).filter(
    col =>
      (!beperktTonen || lastenOverzichtColumnConfig(beperktTonen)[col].inBeperktTonen) || (col === "jaar" && jarenTonen)
  );

  // Only filter by data when we actually have data
  const columnsToShow = data 
    ? columnsWithData.filter(col =>
        data.jaarGegevens?.some(jaarData => jaarData[col as keyof Lasten] !== null && !!jaarData[col as keyof Lasten]) ||
        (data.totalen?.[col as keyof Totalen] != null && !!data.totalen?.[col as keyof Totalen])
      )
    : columnsWithData;

  return (
    <div className={classNames("lastenoverzicht-table")} data-testid="lastenoverzicht">
      {loading && <CardLoadingSpinner />}
      <Table striped bordered hover responsive className="scenario-table strong_last">
        <thead>
          <tr>
            {columnsToShow.map((col) => (
              <th key={col} className={classNames({ "accent-column": lastenOverzichtColumnConfig(beperktTonen)[col].showAccented }, { "width-80": col === "jaar" && jarenTonen })} >
                {lastenOverzichtColumnConfig(beperktTonen)[col].headerTitle}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(!data?.jaarGegevens || data?.jaarGegevens.length === 0) && Array.from({ length: 30 }).map((_, index) => (
            <tr key={index}>
              {columnsToShow.map((col) => (
                <td key={col}>&nbsp;</td>
              ))}
            </tr>
          ))}
          {data?.jaarGegevens?.map((jaarData: Lasten) => (
            <tr key={jaarData.jaar}>
              {columnsToShow.map((col, index) => (
                <td
                  key={col}
                  className={classNames(
                    { "accent-column": lastenOverzichtColumnConfig(beperktTonen)[col].showAccented },
                    { "text-right": lastenOverzichtColumnConfig(beperktTonen)[col].isCurrency },
                    { "text-center": lastenOverzichtColumnConfig(beperktTonen)[col].isLeeftijd }
                  )}
                >
                  {formatValue(jaarData[col as keyof Lasten] as number | null, col)}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            {columnsToShow.map((col, index) => (
              <td
                key={col}
                className={classNames(
                  { "accent-column": lastenOverzichtColumnConfig(beperktTonen)[col].showAccented },
                  { "text-right": lastenOverzichtColumnConfig(beperktTonen)[col].isCurrency },
                  { }
                )}
              >
                {index === 0
                  ? "Totaal"
                  : lastenOverzichtColumnConfig(beperktTonen)[col].showTotal === false
                  ? ""
                  : formatValue(data?.totalen?.[col as keyof Totalen] as number | null, col, true)}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default LastenOverzichtTable;
