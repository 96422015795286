import { HypotheekvormBasis, HypotheekvormenBasisOutput } from "../../.generated/producten/productentypes";
import { useRequestInit } from "adviesbox-shared";
import { useCallback } from "react";
import { useFetchData } from "./usefetchdata";

export type UseHypotheekvormenResult = {
  loading: boolean,
  error: null | Error;
  hypotheekvormen: { [index: string]: HypotheekvormBasis } | null;
};

export function useHypotheekVormData(geldverstrekkerCode: string): UseHypotheekvormenResult {
  const {
    requestInit,
    settings: { productenOrigin }
  } = useRequestInit();

  /* istanbul ignore next */ 
  const getRequestInit = useCallback(
    () => requestInit,
    [requestInit]
  );
  
  const url = geldverstrekkerCode
    ? `${productenOrigin}/MaatschappijCodes/${geldverstrekkerCode}/Hypotheekvormen`
    : "";
  const { error, data, loading } = useFetchData<HypotheekvormenBasisOutput>(url, getRequestInit, !!url);

  if (typeof data === "string") {
    return { hypotheekvormen: null, error: new Error("Fout bij het laden van hypotheekvorm"), loading: false };
  }

  return {
    loading: loading, 
    error: error ? Error(error) : null,
    hypotheekvormen:!data || !data.hypotheekvormen ? null : data.hypotheekvormen
  };
}

export default {
  useHypotheekVormData
};
