import React, { ReactElement } from "react";
import { connect, FormikContextType, getIn } from "formik";
import { InkomensverledenType } from "../inkomen-en-fiscus-schema";
import { LocalDate } from "@js-joda/core";
import { LabeledCurrencyInput, LabeledNumberInput } from "adviesbox-shared";

type InkomensverledenProps = {
  soort: WinstSoort;
  name: string;
};

export type ObjectWithInkomensverledenData = {
  [index: string]: InkomensverledenType;
};

export enum WinstSoort {
  Onderneming = "Onderneming",
  Arbeid = "Arbeid",
  OverigeWerkzaamheden = "OverigeWerkzaamheden",
  AanmerkelijkBelang = "AanmerkelijkBelang",
  BrutoJaarinkomen = "BrutoJaarinkomen",
  Inkomensverleden = "Inkomensverleden"
}
export const WinstCaption = (param: WinstSoort): string => {
  switch (param) {
    case WinstSoort.Onderneming:
      return "Winst uit onderneming";
    case WinstSoort.Arbeid:
      return "Inkomsten uit arbeid";
    case WinstSoort.OverigeWerkzaamheden:
      return "Resultaat overige werkzaamheden";
    case WinstSoort.AanmerkelijkBelang:
      return "Inkomsten uit aanmerkelijk belang";
    case WinstSoort.BrutoJaarinkomen:
      return "Bruto jaarinkomen in";
    default:
      return "Enum niet compleet, voeg soort toe.";
  }
};

export const JaarCaption = (param: WinstSoort): string => {
  switch (param) {
    case WinstSoort.Onderneming:
      return "Boekjaar";
    case WinstSoort.Arbeid:
      return "Jaar";
    case WinstSoort.OverigeWerkzaamheden:
      return "Jaar";
    case WinstSoort.AanmerkelijkBelang:
      return "Boekjaar";
    case WinstSoort.BrutoJaarinkomen:
      return "Jaartal laatste jaarinkomen";
    default:
      return "Enum niet compleet, voeg soort toe";
  }
};

let lastYear: number | null;

const Inkomensverleden = ({
  soort,
  name,
  formik: { values }
}: InkomensverledenProps & {
  formik: FormikContextType<{}>;
}): ReactElement => {
  const currObj = getIn(values, name) as InkomensverledenType;
  if (currObj.jaar && currObj.jaar >= LocalDate.now().year() - 5 && currObj.jaar <= LocalDate.now().year())
    lastYear = currObj.jaar;

  const getCaption = (num: number): string => {
    return `${WinstCaption(soort)} ${lastYear ? lastYear - num : LocalDate.now().year() - num}`;
  };

  return (
    <>
      <LabeledNumberInput caption={JaarCaption(soort)} name={`${name}.jaar`} labelColSize={6} noThousandsSeparator />
      <LabeledCurrencyInput caption={getCaption(0)} name={`${name}.resultaat1`} labelColSize={6} allowNegative={true} />
      <LabeledCurrencyInput caption={getCaption(1)} name={`${name}.resultaat2`} labelColSize={6} allowNegative={true} />
      <LabeledCurrencyInput caption={getCaption(2)} name={`${name}.resultaat3`} labelColSize={6} allowNegative={true} />
      <>
        <LabeledCurrencyInput
          caption="Gemiddeld"
          name={`${name}.gemiddeld`}
          labelColSize={6}
          asReadOnlyInput={true}
          allowNegative={true}
        />
      </>
    </>
  );
};

export default connect<InkomensverledenProps>(Inkomensverleden);
