import {
  Financieringsbehoefte as FinancieringsbehoefteDlEntry,
  Financieringsoort,
  GebruikPandSoort as PandGebruikPand,
  FinancieringsbehoefteFinancieringsopzet,
  FinancieringsbehoefteOverbrugging,
  Uitbetaling,
  RentelastenOverbrugging,
  FinancieringsbehoeftePand,
  BoeterenteBerekeningswijzeOpties,
  TeBeeindigenLeningdeel,
  FinancieringsbehoefteVerbouwing,
  Belastingbox,
  AflossingsVormType
} from "../../.generated/forms/formstypes";

import { FinancieringsbehoefteState, EigenwoningschuldModalCompleet } from "./financieringsbehoefte-types";

import {
  financieringsbehoefteSchema,
  financieringsopzetSchema,
  boeterenteSchema
} from "./financieringsbehoefte-schema";
import { UiName } from "../../shared/types";
import { getOverigeKosten, berekenOverigeKosten1Bedrag } from "./map-financieringsbehoefte-helper";
import { optellen } from "../../shared/utils/currency";
import { getKoopsomBedrag } from "./financieringsbehoefte-berekenen-helper";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { jaarMaandInMaanden, mapLocalDateToString } from "adviesbox-shared";

const isAankoop = (v: FinancieringsbehoefteState): boolean => {
  const AankoopValues = Object.values(Financieringsoort).filter(f => f.includes("Aankoop"));
  return AankoopValues.some(av => av === v.financiering.soortFinanciering);
};

const mapFinancieringsopzetUiToDl = createMapToDl(financieringsbehoefteSchema).to<
  FinancieringsbehoefteFinancieringsopzet
>({
  bouwrenteVoorAankoopBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.bouwrentePeriodeVoorAankoop
      : null,
  bouwrenteNaAankoopBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.bouwrentePeriodeNaAankoop
      : null,
  hypotheekakte: v => v.financieringsopzet.hypotheekakte.berekenen,
  hypotheekakteBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.Heropname ? null : v.financieringsopzet.hypotheekakte.bedrag,
  taxatiekosten: v => v.financieringsopzet.taxatie.berekenen,
  taxatiekostenBedrag: v => v.financieringsopzet.taxatie.bedrag,
  nhgKostenOvernemen: v =>
    v.onderpand.gebruik === PandGebruikPand.PrimaireWoning
      ? v.financieringsopzet.nationaleHypotheekGarantieBerekenen
      : null,
  nhgBedrag: v =>
    v.onderpand.gebruik === PandGebruikPand.PrimaireWoning ? v.financieringsopzet.nationaleHypotheekGarantie : null,
  bankgarantieBedrag: v => (isAankoop(v) ? v.financieringsopzet.bankgarantieModal.garantiebedrag : null),
  bankgarantieAanvragen: v =>
    isAankoop(v) ? v.financieringsopzet.bankgarantieModal.aanvragenBijGeldverstrekker : null,
  kostenBankgarantieBedrag: v => (isAankoop(v) ? v.financieringsopzet.bankgarantieModal.kostenBankgarantie : null),
  koopsomBedrag: v => (isAankoop(v) ? v.financieringsopzet.koopsom.koopsomBedrag : null),
  kostenGrondBedrag: v => (isAankoop(v) ? v.financieringsopzet.koopsom.kostenGrondBedrag : null),
  grondReedsInBezit: v => (isAankoop(v) ? v.financieringsopzet.koopsom.grondReedsInBezit : null),
  overdrachtsbelastingPercentage: () => null,
  soortFinanciering: v => v.financiering.soortFinanciering,
  renteverliesTijdensBouwBedrag: v => v.financieringsopzet.renteverliesTijdensBouw,
  startersleningAfsluitprovisieBedrag: v =>
    v.financieringsopzet.overigeFinancieringskostenModal.afsluitkostenSVnStarterslening,
  bereidstellingsprovisieBedrag: v => v.financieringsopzet.overigeFinancieringskostenModal.bereidstellingskosten,
  restschuldOvernemen: v => v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen,
  restschuldTotaalBedrag: v =>
    optellen([
      v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen === null ||
      v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen
        ? v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekendBedrag
        : v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.bedrag,
      v.financieringsopzet.totaleRestschuldModal.extraKostenHuidigeHypotheek
    ]),
  extraKostenHuidigBedrag: v => v.financieringsopzet.totaleRestschuldModal.extraKostenHuidigeHypotheek,
  eigenGeldRestschuldBedrag: v => v.financieringsopzet.totaleRestschuldModal.inbrengEigenGeldVoorVerlagenRestschuld,
  eigenGeldInbrengRestschuldOvernemen: v =>
    v.financieringsopzet.totaleRestschuldModal.inbrengOpgebouwdeWaardeRestschuld.berekenen,
  eigenGeldInbrengRestschuldBedrag: v =>
    v.financieringsopzet.totaleRestschuldModal.inbrengOpgebouwdeWaardeRestschuld.bedrag,
  meerwerkBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering
      : null,
  depotvergoedingPercentage: v => v.financieringsopzet.renteverliesTijdensBouwModal.depotvergoeding,
  soortNieuwbouw: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.financiering.soortNieuwbouw
        ? parseInt(v.financiering.soortNieuwbouw)
        : null
      : null,
  soortZelfbouw: v =>
    v.financiering.soortNieuwbouw === "02" //Zelfbouw
      ? v.financiering.soortZelfbouw
        ? parseInt(v.financiering.soortZelfbouw)
        : null
      : null,
  kostenKoper: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw ? v.financiering.kostenKoper : null,

  erfpachtAfkoopBedrag: v => v.financieringsopzet.afkoopErfpachtModal.afkoopBedrag,
  erfpachtAfgekochtTotDatum: v => mapLocalDateToString(v.financieringsopzet.afkoopErfpachtModal.afkoopEinddatum),
  doorlopendeLeningdelenBedrag: v => v.financieringsopzet.andereFinancieringenModal.doorlopendeHypotheken,
  inbrengKredietBedrag: v => v.financieringsopzet.andereFinancieringenModal.kortlopendeLeningKrediet,
  starterslening: v => (v.financieringsopzet.andereFinancieringenModal.startersleningBedrag ?? 0) > 0,
  startersleningBedrag: v => v.financieringsopzet.andereFinancieringenModal.startersleningBedrag,
  overigeFinanciering1Omschrijving: v =>
    v.financieringsopzet.andereFinancieringenModal.overigeFinanciering1Omschrijving,
  overigeFinanciering1Bedrag: v => v.financieringsopzet.andereFinancieringenModal.overigeFinanciering1Bedrag,
  overigeFinanciering2Omschrijving: v =>
    v.financieringsopzet.andereFinancieringenModal.overigeFinanciering2Omschrijving,
  overigeFinanciering2Bedrag: v => v.financieringsopzet.andereFinancieringenModal.overigeFinanciering2Bedrag,

  verbouwingBedrag: v =>
    v.financiering.soortFinanciering !== Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering
      : null,
  meerwerkWaardeloosBedrag: v => v.financieringsopzet.verbouwingVerbeteringModal.waarvanNietWaardevermeerderend,
  energiebesparendeMaatregelenBedrag: v =>
    v.financieringsopzet.verbouwingVerbeteringModal.waarvanEnergiebesparendeVoorzieningen,
  energiebespaarbudgetBedrag: v => v.financieringsopzet.verbouwingVerbeteringModal.waarvanEnergiebesparendeBudget,
  achterstalligOnderhoudBedrag: v => v.financieringsopzet.verbouwingVerbeteringModal.waarvanAchterstalligOnderhoud,

  //inbreng eigengeld

  inbrengEigenGeld: v => !!v.financieringsopzet.gewensteHypotheekBerekenen,
  eigenGeldOverwaardeOvernemen: v => v.financieringsopzet.inbrengEigenGeldModal.eigenGeldOverwaarde.berekenen,
  inbrengEigenGeldBedrag: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengEigenGeld,
  eigenGeldOverwaardeBedrag: v => v.financieringsopzet.inbrengEigenGeldModal.eigenGeldOverwaarde.bedrag,
  eigenGeldExtraAflossing: v => v.financieringsopzet.inbrengEigenGeldModal.extraAflossing,
  eigenGeldBedrag1: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengEigengeldBedrag1,
  eigenGeldOmschrijving1: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengEigengeldOmschrijving1,
  eigenGeldBedrag2: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengEigengeldBedrag2,
  eigenGeldOmschrijving2: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengEigengeldOmschrijving2,
  eigenGeldInbrengOverig1Bedrag: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengPolisBedrag,
  eigenGeldInbrengOverig1Omschrijving: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengPolisOmschrijving,
  // tijdelijk bij het ophalen op 0 gezet
  // eigenGeldInbrengBedrag:
  //   financieringsopzet.inbrengEigenGeldModal.inbrengOpgebouwdeWaarde,

  roerendeZakenBedrag: v => v.financieringsopzet.koopsomRoerendeZaken,
  overdrachtsbelasting: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw ||
    v.financiering.soortFinanciering === Financieringsoort.Oversluiten
      ? v.financieringsopzet.overdrachtsbelasting.berekenen
      : null,
  overdrachtsbelastingBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw ||
    v.financiering.soortFinanciering === Financieringsoort.Oversluiten
      ? v.financieringsopzet.overdrachtsbelasting.bedrag
      : null,
  courtageBedrag: v => v.financieringsopzet.makelaarscourtage.bedrag,
  courtage: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw
      ? v.financieringsopzet.makelaarscourtage.berekenen
      : null,
  transportakteBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw ||
    v.financiering.soortFinanciering === Financieringsoort.Oversluiten
      ? v.financieringsopzet.leveringsakte.bedrag
      : null,
  transportakte: v =>
    [
      Financieringsoort.AankoopBestaandeBouw,
      Financieringsoort.Oversluiten,
      Financieringsoort._1eHypotheek,
      Financieringsoort._2eHypotheek,
      Financieringsoort._3eHypotheek,
      Financieringsoort._4eHypotheek,
      Financieringsoort.Heropname
    ].includes(v.financiering.soortFinanciering)
      ? v.financieringsopzet.leveringsakte.berekenen
      : null,
  hypotheekAflossingBedrag: v => v.financieringsopzet.afTeLossenBestaandeHypotheken.bedrag,
  hypotheekAflossingOvernemen: v => v.financieringsopzet.afTeLossenBestaandeHypotheken.berekenen,
  hypotheekAflossingBerekendBedrag: v => v.financieringsopzet.afTeLossenBestaandeHypotheken.berekendBedrag,
  vergoedingPartnerBedrag: v => v.financieringsopzet.uitkoopPartner,
  eigenwoningschuldOvernemen: v => v.financieringsopzet.eigenwoningschuldModal.eigenwoningschuld.berekenen,
  eigenwoningschuldBedrag: v => v.financieringsopzet.eigenwoningschuldModal.eigenwoningschuld.bedrag,
  boeterenteBedrag: v => optellen(v.financieringsopzet.boeterenteModal.map((x): number | null => x.boeterente)),
  renteverliesHypotheekrentePercentage: v => v.financieringsopzet.renteverliesTijdensBouwModal.hypotheekrente,
  stichtingskostenBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.renteverliesTijdensBouwModal.stichtingskosten
      : null,

  gewenstConsumptiefBedrag: v => v.financieringsopzet.overigeKostenInHypotheekModal.gewenstConsumptiefBedrag,
  royementskostenBedrag: v => v.financieringsopzet.overigeKostenInHypotheekModal.royementskostenBedrag,
  bouwkundigeKeuringBedrag: v => getOverigeKosten(0, v.financieringsopzet).bedrag,
  bouwkundigeKeuringEenmaligAftrekbaar: v => getOverigeKosten(0, v.financieringsopzet).aftrekbaar,
  bouwkundigeKeuringBox: v => Belastingbox[getOverigeKosten(0, v.financieringsopzet).box],
  overigeKosten1Bedrag: v => getOverigeKosten(1, v.financieringsopzet).bedrag,
  overigeKosten1Omschrijving: v => getOverigeKosten(1, v.financieringsopzet).omschrijving,
  overigeKosten1EenmaligAftrekbaar: v => getOverigeKosten(1, v.financieringsopzet).aftrekbaar,
  overigeKosten1Box: v => Belastingbox[getOverigeKosten(1, v.financieringsopzet).box],
  overigeKosten2Bedrag: v => getOverigeKosten(2, v.financieringsopzet).bedrag,
  overigeKosten2Omschrijving: v => getOverigeKosten(2, v.financieringsopzet).omschrijving,
  overigeKosten2EenmaligAftrekbaar: v => getOverigeKosten(2, v.financieringsopzet).aftrekbaar,
  overigeKosten2Box: v => Belastingbox[getOverigeKosten(2, v.financieringsopzet).box],
  overigeKosten3Bedrag: v => getOverigeKosten(3, v.financieringsopzet).bedrag,
  overigeKosten3Omschrijving: v => getOverigeKosten(3, v.financieringsopzet).omschrijving,
  overigeKosten3EenmaligAftrekbaar: v => getOverigeKosten(3, v.financieringsopzet).aftrekbaar,
  overigeKosten3Box: v => Belastingbox[getOverigeKosten(3, v.financieringsopzet).box],
  kostenOmzettingBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.Omzetting
      ? v.financieringsopzet.kostenOmzettingBedrag
      : null,
  arbeidskostenNotarisBedrag: v => v.financieringsopzet.arbeidskostenNotaris,
  energieklasseToekomstig: v =>
    v.financieringsopzet.verbouwingVerbeteringModal?.energieklasseToekomstig
      ? parseInt(v.financieringsopzet.verbouwingVerbeteringModal.energieklasseToekomstig)
      : null,

  //TODO: Mapping
  restschuldTotaalBerekendBedrag: () => null,
  restschuldBijkomendeKosten: () => null,
  eigenGeldOverwaarde: () => null,
  eigenGeldInbrengBedrag: () => null
  //TODO: De volgende velden zijn in platform nieuw toegevoegd; mapping controleren zie task: 45357
  //soortberekeningen: FinancieringsbehoefteFinancieringsopzetSoortberekeningen.GewenstHypotheekBedrag,
  //hypothecaireInschrijving: null,
  //bevoorschottingPerctage: null,
  //maxBevoorschottingsPercentage: null,
  //nhgMogelijk: false
});

const mapOverbruggingskredietUiToDl = createMapToDl(financieringsopzetSchema).to<FinancieringsbehoefteOverbrugging>({
  afsluitkostenBedrag: v => v.overbruggingskredietModal.afsluitkosten,
  overwaardeMeenemen: v => v.overbruggingskredietModal.overwaardeMeenemen,
  overbruggingBedrag: v => v.overbruggingskredietModal.overwaardeHuidigeWoningen,
  meenemenInOverbruggingBedrag: v => v.overbruggingskredietModal.meenemenInOverbruggingskrediet,
  rentePercentage: v => v.overbruggingskredietModal.renteOverbruggingskrediet,
  einddatum: v => mapLocalDateToString(v.overbruggingskredietModal.einddatum),
  box1Bedrag: v => v.overbruggingskredietModal.fiscalegegevensDeelBox1Bedrag,
  einddatumMeenemen: () => null,
  aanvangsdatum: () => null,
  rentevastAantalMaanden: v => v.overbruggingskredietModal.rentevastAantalMaanden
});

const mapRentelastenOverbruggingskredietUiToDl = createMapToDl(financieringsopzetSchema).to<RentelastenOverbrugging>({
  rentelastMaandelijksBrutoBedrag: v => v.overbruggingskredietModal.brutoRentelastPerMaand,
  rentelastMaandelijksNettoBedrag: v => v.overbruggingskredietModal.nettoRentelastPerMaand,
  rentelastTotaalBrutoBedrag: v => v.overbruggingskredietModal.brutoTotaleRentelast,
  rentelastTotaalNettoBedrag: v => v.overbruggingskredietModal.nettoTotaleRentelast
});

const mapPandUiToDl = createMapToDl(financieringsbehoefteSchema).to<FinancieringsbehoeftePand>({
  duurBouwInMaanden: v => v.financieringsopzet.renteverliesTijdensBouwModal.duurVanDeBouw,

  taxatieBedrag: v => v.onderpand.marktwaardeVoorVerbouwing,
  marktwaardeNaVerbouwingBedrag: v => v.onderpand.marktwaardeNaVerbouwing,
  eigenWoningForfaitBedrag: v => v.onderpand.eigenwoningforfait,
  gebruikPand: v => v.onderpand.gebruik,
  wozWaarde: v => v.onderpand.wozWaarde,
  eigendom1Percentage: v => v.onderpand.eigendomsverhoudingAanvrager,
  eigendom2Percentage: v => v.onderpand.eigendomsverhoudingPartner,

  erfpacht: v => v.erfpacht.erfpacht || false,
  erfpachtBedrag: v => v.erfpacht.jaarlijksCanon,
  erfpachtEeuwigdurend: v => v.erfpacht.eeuwigdurendErfpacht,
  erfpachtEinddatum: v => mapLocalDateToString(v.erfpacht.einddatum),
  erfpachtParticulier: v => v.erfpacht.particulierErfpacht,

  erfpachtAfgekocht: v =>
    v.financieringsopzet.afkoopErfpachtModal.afkoopBedrag !== null &&
    v.financieringsopzet.afkoopErfpachtModal.afkoopEinddatum !== null,
  adres: () => null
});

const mapBoeterenteUiToDl = createMapToDl(boeterenteSchema)
  .with<boolean>()
  .to<TeBeeindigenLeningdeel>({
    boeterenteId: v => v.boeterenteId,
    boeterenteBerekeningswijze: v => v.berekenwijzeBoeterente,
    oorspronkelijkeHoofdsomBedrag: v => v.oorspronkelijkeHoofdsom,
    boeterenteDatum: v => mapLocalDateToString(v.datumBoeterente),
    resterendePeriodeWeergeven: (v, isEigenInvoer) => (isEigenInvoer ? null : !v.resterendePeriodeWeergeven),
    resterendePeriodeJaren: (v, isEigenInvoer) =>
      v.resterendePeriodeWeergeven || isEigenInvoer ? null : v.resterendePeriode.jaren,
    resterendePeriodeMaanden: (v, isEigenInvoer) =>
      v.resterendePeriodeWeergeven || isEigenInvoer ? null : v.resterendePeriode.maanden,
    boetevrijPercentage: (v, isEigenInvoer) => (isEigenInvoer ? null : v.boetevrijePercentage),
    huidigRentePercentage: (v, isEigenInvoer) => (isEigenInvoer ? null : v.huidigeContractrente),
    nieuwRentePercentage: (v, isEigenInvoer) => (isEigenInvoer ? null : v.nieuwRentepercentage),
    boeterentebedrag: v => v.boeterente || 0,
    leningdeelId: v => v.leningdeelId,
    opgebouwdeWaarde: _ => null, // TODO: correct after nswag update
    aflossingsvorm: _ => AflossingsVormType.Aflosvrij,
    maatschappijOmschrijving: v => v.maatschappij,
    rentevastperiodeEinddatum: v => mapLocalDateToString(v.einddatumRentevastperiode),
    restantHoofdsomBedrag: v => v.restantHoofdsom,
    restantLooptijdInMaanden: v => jaarMaandInMaanden(v.restantLooptijd)
  });

export const mapFinancieringsbehoefteUiToDl = createMapToDl(financieringsbehoefteSchema).to<
  FinancieringsbehoefteDlEntry
>({
  nhgMogelijkResultaat: v => null,
  advieskostenGespreidBedrag: v =>
    v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalen ? v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.advieskostenGespreid?.bedrag ?? 0 : null,
  advieskostenGespreidOvernemen: v => v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.advieskostenGespreid?.berekenen ?? false,
  bemiddelingsvergoedingGeldleningGespreidBedrag: v =>
    v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalen ? v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.bemiddelingsvergoedingGeldleningGespreid?.bedrag ?? 0 : null,
  bemiddelingsvergoedingGeldleningGespreidOvernemen: v => v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.bemiddelingsvergoedingGeldleningGespreid?.berekenen ?? false,
  financieringskostenAftrekbaarOvernemen: v =>
    v.financieringsopzet.eigenwoningschuldModal.financieringskostenAftrekbaarOvernemen,
  financieringskostenAftrekbaarPercentage: v =>
    v.financieringsopzet.eigenwoningschuldModal.financieringsKostenPercentage,
  financieringsopzet: mapFinancieringsopzetUiToDl,
  gespreidBetalen: v => v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalen,
  gespreidBetalenDuurInMaanden: v =>
    v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalen
      ? v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalenOver
      : null,
  gewensteHypotheekBedrag: v => v.financieringsopzet.gewensteHypotheek,
  inschrijvingBedrag: v => v.financieringsopzet.hypothecaireInschrijving.bedrag,
  inschrijvingOvernemen: v => v.financieringsopzet.hypothecaireInschrijving.berekenen,
  overbruggingskrediet: v => mapOverbruggingskredietUiToDl(v.financieringsopzet),
  overigeBemiddelingsvergoedingGespreidBedrag: v =>
    v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalen ? v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.overigeBemiddelingsvergoedingGespreid?.bedrag ?? 0 : null,
  overigeBemiddelingsvergoedingGespreidOvernemen: v => v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.overigeBemiddelingsvergoedingGespreid?.berekenen ?? false,
  pand: mapPandUiToDl,
  rentelastenOverbruggingskrediet: v => mapRentelastenOverbruggingskredietUiToDl(v.financieringsopzet),
  specificatiesVerbouwingVerbetering: v =>
    v.financieringsopzet.verbouwingVerbeteringModal.verbouwingVerbeteringSpecificaties
      .filter((p): boolean => !!p.werkzaamheden || !!p.werkzaamhedenBedrag)
      .map(
        (p): FinancieringsbehoefteVerbouwing => ({
          verbouwingId: p.verbouwingId,
          werkzaamheden: p.werkzaamheden,
          werkzaamhedenBedrag: p.werkzaamhedenBedrag,
          volgnummer: p.volgnummer,
          typeVerbouwing: null,
          omschrijving: null,
          verbouwingInEigenBeheer: null
        })
      ),
  teBeeindigenLeningdelen: v =>
    v.financieringsopzet.boeterenteModal.map(boeterente =>
      mapBoeterenteUiToDl(boeterente.berekenwijzeBoeterente === BoeterenteBerekeningswijzeOpties.EigenInvoer)(
        boeterente
      )
    ),
  uitbetalingen: v =>
    v.financieringsopzet.renteverliesTijdensBouwModal.renteverliesMaandelijks.map(
      (p, index): Uitbetaling => ({
        percentage: p.percentage,
        maand: index + 1
      })
    ),
  vrijVermogenBedrag: v => v.vermogen.vrijVermogen.bedrag,
  vrijVermogenBerekendBedrag: () => null,
  vrijVermogenOvernemen: v => v.vermogen.vrijVermogen.berekenen,
  advieskostenBedrag: v => v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.advieskosten,
  afTeLossenOverigeLeningenBedrag: v => v.financieringsopzet.afTeLossenoverigeLeningen,
  aanvrager1: _ => null, // TODO CONTROLEREN!!
  aanvrager2: _ => null, // TODO CONTROLEREN!!
  eerderOpslagen: _ => false, // TODO CONTROLEREN!!
  ewsResultaat: _ => null, // TODO CONTROLEREN!!
  hypotheekNorm: _ => null, // TODO CONTROLEREN!! ...
  overbruggingskredietMogelijk: _ => false,
  volgnummerVoorstel: _ => 0,
  bemiddelingsvergoedingGeldleningBedrag: _ => null,
  bevoorschottingspercentage: _ => null,
  dossiervergoedingBedrag: _ => null,
  heeftPartner: _ => false,
  maxBevoorschottingspercentage: _ => null,
  maximaleHypotheekKoopsom: _ => null,
  nhgMogelijk: _ => false,
  omschrijving: _ => null,
  omschrijvingVoorstel1: _ => null,
  overigeBemiddelingsvergoedingBedrag: _ => null,
  overwaardeVermogenBedrag: _ => null,
  passeerdatum: _ => null,
  restantSchuldBox1Bedrag: _ => null,
  restantSchuldBox3Bedrag: _ => null,
  specificatiesNettoVerkoopopbrengst: _ => null,
  totaalHuidigeLeningdelen: _ => null, // TODO CONTROLEREN!! tot hier
  doorlopendeLeningdelenBox1Bedrag: _ => null
});

export function mapFinancieringsbehoefteDlNameToUiName(dlName: string): UiName | null {
  const specificatiesVerbouwingVerbeteringExp = /specificatiesVerbouwingVerbetering\[(\d+)\].(\w+)/;
  const specificatiesVerbouwingVerbeteringMatch = specificatiesVerbouwingVerbeteringExp.exec(dlName);

  if (specificatiesVerbouwingVerbeteringMatch) {
    const index = +specificatiesVerbouwingVerbeteringMatch[1];
    const veld = specificatiesVerbouwingVerbeteringMatch[2];

    switch (veld) {
      case "volgnummer":
        return {
          field: `financieringsopzet.verbouwingVerbeteringModal.verbouwingVerbeteringSpecificaties[${index}].volgnummer`,
          label: `Verbouwing/verbetering specificatie ${index + 1} volgnummer`
        };
      default:
        return null;
    }
  } else {
    switch (dlName) {
      case "Financieringsopzet.KoopsomBedrag":
        return { field: "financieringsopzet.koopsom.koopsomBedrag", label: "Koopsom woning" };
      case "Financieringsopzet.SoortFinanciering":
        return { field: "financieringsopzet.financiering.soortFinanciering", label: "Soort financiering" };
      case "financieringskostenAftrekbaarOvernemen":
        return {
          field: "financieringsopzet.eigenwoningschuldModal.financieringskostenAftrekbaarOvernemen",
          label: "Financieringskosten aftrekbaar overnemen"
        };

      default:
        return null;
    }
  }
}

export const mapToEigenwoninschuldModal = (
  financieringbehoefteState: FinancieringsbehoefteState
): EigenwoningschuldModalCompleet => {
  return {
    ...financieringbehoefteState.financieringsopzet.eigenwoningschuldModal,

    arbeidskostenNotaris: financieringbehoefteState.financieringsopzet.arbeidskostenNotaris ?? 0,
    soortFinanciering: financieringbehoefteState.financiering.soortFinanciering,
    koopsomWoning: getKoopsomBedrag(financieringbehoefteState) || 0,
    bouwrente: financieringbehoefteState.financieringsopzet.bouwrentePeriodeVoorAankoop || 0,
    afTeLossenBestaandeHypotheken: financieringbehoefteState.financieringsopzet.afTeLossenBestaandeHypotheken,
    leningdelenWordenAfgelost: financieringbehoefteState.financieringsopzet.leningdelenWordenAfgelost,
    verbouwing:
      financieringbehoefteState.financiering.soortFinanciering !== Financieringsoort.AankoopNieuwbouw
        ? financieringbehoefteState.financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering || 0
        : 0,
    meerwerk:
      financieringbehoefteState.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
        ? financieringbehoefteState.financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering || 0
        : 0,
    overdrachtsbelasting: financieringbehoefteState.financieringsopzet.overdrachtsbelasting.bedrag || 0,
    makelaarsCourtage: financieringbehoefteState.financieringsopzet.makelaarscourtage.bedrag || 0,
    leveringsakte: financieringbehoefteState.financieringsopzet.leveringsakte.bedrag || 0,
    afkoopErfpacht: financieringbehoefteState.financieringsopzet.afkoopErfpacht || 0,
    vergoedingPartnerAkteVerdeling: financieringbehoefteState.financieringsopzet.uitkoopPartner || 0,
    hypotheekakte: financieringbehoefteState.financieringsopzet.hypotheekakte.bedrag || 0,
    aftrekbareAdvieskosten: financieringbehoefteState.financieringsopzet.eigenwoningschuldModal.ewsAdviesKosten || 0,
    nhgKosten: financieringbehoefteState.financieringsopzet.nationaleHypotheekGarantie || 0,
    taxatieKosten: financieringbehoefteState.financieringsopzet.taxatie.bedrag || 0,
    afsluitkostenSVnStarterslening:
      financieringbehoefteState.financieringsopzet.overigeFinancieringskostenModal.afsluitkostenSVnStarterslening,
    afsluitkostenOverbruggingsKrediet: 0,
    bereidstellingsKosten:
      financieringbehoefteState.financieringsopzet.overigeFinancieringskostenModal.bereidstellingskosten || 0,
    omzettingLeningdeelKosten: financieringbehoefteState.financieringsopzet.kostenOmzettingBedrag || 0,
    aftrekbareBoeterente: 0,
    overigekostenBox1:
      berekenOverigeKosten1Bedrag(
        financieringbehoefteState.financieringsopzet.overigeKostenInHypotheekModal.overigeKostenSpecificaties
      ) + (financieringbehoefteState.financieringsopzet.overigeKostenInHypotheekModal.royementskostenBedrag ?? 0),
    financieringsKostenTotaal:
      financieringbehoefteState.financieringsopzet.eigenwoningschuldModal.financieringsKosten *
      (financieringbehoefteState.financieringsopzet.eigenwoningschuldModal.financieringsKostenPercentage / 100),
    aflossingOpEigenwoningschuld: 0,
    maximaalGelijkAanGewensteHypotheek: financieringbehoefteState.financieringsopzet.gewensteHypotheek || 0,
    eigenWoningReserveVervreemdingSaldo:
      financieringbehoefteState.financieringsopzet.eigenwoningschuldModal.eigenWoningReserveVervreemdingSaldo,
    doorlopendeHypothekenBedrag: financieringbehoefteState.financiering.doorlopendeLeningdelenBedrag || 0,
    doorlopendeLeningdelenBox1Bedrag: financieringbehoefteState.doorlopendeLeningdelenBox1Bedrag || 0
  };
};
