import { DataGrid, LabeledResult } from "adviesbox-shared";
import React, { ReactElement, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { bedragFormat } from "../../shared/utils/currency";
import { hypotheekColumns } from "../hypotheek-columns";
import { HypotheekType, HypothekenState } from "../infra/hypotheek-types";
import classes from "./hypotheek-totale-hypotheek-modal.module.scss";

type ModalProps = {
  values: HypothekenState;
  situatie: SituatieSoort;
  setSelected: [number, React.Dispatch<React.SetStateAction<number>>];
  showModal?: boolean;
  onClose?: () => void;
  onSubmit?: (data: HypotheekType) => void;
};

const Body = ({ values, situatie, setSelected }: ModalProps): ReactElement => {
  const totaleHypotheek = values.panden.reduce((prev, curr) => {
    return prev + (curr.totaleHypotheekBedrag ?? /* istanbul ignore next */ 0);
  }, 0);
  let totaleHypotheekDoorlopend = 0;
  values.producten.forEach(x => {
    /* istanbul ignore next */
    if (x.leningdeelgegevens?.leningdeelHoofdsom?.berekenen === true) {
      /* istanbul ignore next */
      totaleHypotheekDoorlopend +=
        x.leningdeelgegevens?.leningdeelHoofdsom?.berekendBedrag ?? 0;
    } else {
      /* istanbul ignore next */
      totaleHypotheekDoorlopend += x.leningdeelgegevens?.leningdeelHoofdsom?.bedrag ?? 0;
    }
  });
  const productenFiltered = values.producten.filter(x => !x.product?.doorlopend);

  return (
    <div className={"mb-5"}>
      <span className={classes.margin_correction}>
        De som van de leningdelen is ongelijk aan het totaalbedrag van de hypotheek
      </span>

      <div>
        <LabeledResult
          caption={"Totale hypotheek"}
          name="hypotheekTotalen.totaleHypotheek"
          fontWeight="bold"
          labelColSize={6}
          currency={true}
          alignRight={true}
          result={(): string => {
            return bedragFormat(totaleHypotheek, 0, 0, false);
          }}
        />
      </div>

      <div>
        <LabeledResult
          caption={"Totale hypotheek doorlopend"}
          name="hypotheekTotalen.totaleHypotheekDoorlopend"
          fontWeight="bold"
          labelColSize={6}
          currency={true}
          alignRight={true}
          result={(): string => {
            return bedragFormat(totaleHypotheekDoorlopend, 0, 0, false);
          }}
        />
      </div>

      <span className={`${classes.margin_correction} mb-4`}>
        Kies welk leningdeel je wilt aanpassen of kies ‘niet aanpassen’ als je geen leningdeelbedrag aan wilt passen.
      </span>
      <DataGrid
        masterDetail={true}
        rowCaption="Hypotheek"
        columns={hypotheekColumns({ situatie, warningModal: true })}
        rowSelected={setSelected}
        name="producten"
        data={productenFiltered}
      />
    </div>
  );
};

export const HypotheekTotaleHypotheekModal = ({
  values,
  situatie,
  showModal = false,
  onClose,
  onSubmit
}: ModalProps): ReactElement => {
  const setAanpassen = useState(0);
  const [selected] = setAanpassen;
  const productenFiltered = values.producten.filter(x => !x.product?.doorlopend);

  return (
    <Modal size="xl" show={showModal}>
      <Modal.Header>
        <div className="d-flex w-100">
          <Modal.Title>
            <h6>Som van de leningdelen is ongelijk aan het totaalbedrag van de hypotheek</h6>
          </Modal.Title>
          <button type="button" onClick={onClose} className={"close ml-auto"} id={`btnClose-warningmodal`}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Body values={values} situatie={situatie} setSelected={setAanpassen} />
      </Modal.Body>
      <Modal.Footer>
        <Button id="btnannuleren" onClick={onClose} data-testid="btnannuleren" variant="link">
          Niet aanpassen
        </Button>
        <Button
          id="btnsluiten"
          onClick={() => onSubmit && onSubmit(productenFiltered[selected])}
          data-testid="btnsluiten"
          variant="primary"
        >
          Aanpassen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
