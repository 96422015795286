import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";
import { SoortCorrectieLastOptions, SoortCorrectieFiscaalOptions } from "../../.generated/forms/formstypes";
import { LabeledDateInput, LabeledNumberInput } from "adviesbox-shared";
import { assertNever } from "../../shared/utils/helpers";
import { LabeledBevestigingInput, LabeledPercentageInput, LabeledResult } from "adviesbox-shared";
import { CorrectieType, OverigePostenType } from "../infra/overige-posten-types";
import { getOverigePostenTextResources } from "../infra/overige-posten-resources";

export const bepaalEffectieveMaandlast = (correctie: CorrectieType): string => {
  const { correctieLast } = correctie;

  switch (correctieLast) {
    case SoortCorrectieLastOptions.Last:
      return "Verhoging";
    case SoortCorrectieLastOptions.Neutraal:
      return "Geen";
    case SoortCorrectieLastOptions.Bijdrage:
      return "Verlaging";
    default:
      return assertNever(correctieLast);
  }
};

export const bepaalEffectieveInkomstenBelasting = (correctie: CorrectieType): string => {
  const { correctieFiscaal } = correctie;

  switch (correctieFiscaal) {
    case SoortCorrectieFiscaalOptions.Aftrekpost:
      return "Verlaging";
    case SoortCorrectieFiscaalOptions.Bijtelling:
      return "Verhoging";
    case SoortCorrectieFiscaalOptions.Neutraal:
      return "Geen";
    default:
      return assertNever(correctieFiscaal);
  }
};

type Props = {
  selected: number;
};

const Verrekenen = ({
  selected,
  formik: {
    values: { producten },
    touched,
    setFieldTouched
  }
}: Props & {
  formik: FormikContextType<OverigePostenType>;
}): ReactElement => {
  const {
    correctie,
    verrekenen: { overlijden }
  } = producten[selected];

  const effectieveMaandlast = React.useMemo((): string => bepaalEffectieveMaandlast(correctie), [correctie]);
  const effectieveInkomstenBelasting = React.useMemo((): string => bepaalEffectieveInkomstenBelasting(correctie), [
    correctie
  ]);

  return (
    <>
      <LabeledResult
        name="verrekenen.correctieLast"
        caption="Correctie last"
        result={(): string => correctie.correctieLast}
        readonly
      />
      <LabeledResult
        name="verrekenen.correctieFiscaal"
        caption="Correctie fiscaal"
        result={(): string => correctie.correctieFiscaal}
        readonly
      />
      <LabeledResult
        name="verrekenen.effectMaandlast"
        caption="Effect maandlast"
        result={(): string => effectieveMaandlast}
        readonly
      />
      <LabeledResult
        name="verrekenen.effectIB"
        caption="Effect IB"
        result={(): string => effectieveInkomstenBelasting}
        readonly
      />

      <div className="subtitle ml-2 mt-1 mb-2">
        Hieronder kun je aangeven op welke scenario(&#39;s) de overige post van toepassing is.
      </div>

      <LabeledBevestigingInput
        caption="Langleven"
        name={`producten[${selected}].verrekenen.langleven`}
        tooltip={getOverigePostenTextResources("TooltipVerrekenenLangleven")}
      />
      <LabeledBevestigingInput
        caption="Arbeidsongeschiktheid"
        name={`producten[${selected}].verrekenen.arbeidsongeschiktheid`}
      />
      <LabeledBevestigingInput caption="Werkloosheid" name={`producten[${selected}].verrekenen.werkloosheid`} />
      <LabeledBevestigingInput caption="Overlijden" name={`producten[${selected}].verrekenen.overlijden`} />

      {overlijden && (
        <>
          <LabeledPercentageInput
            caption="Overgang naar partner"
            name={`producten[${selected}].verrekenen.overgangOpPartnerPercentage`}
            decimalen={2}
          />
          <LabeledDateInput caption="Einddatum" name={`producten[${selected}].verrekenen.overgangOpPartnerEinddatum`} />
          <LabeledNumberInput
            caption="Eindleeftijd"
            name={`producten[${selected}].verrekenen.eindleeftijd`}
            onInput={(): void => {
              if (touched.producten && touched.producten[selected]?.verrekenen?.overgangOpPartnerEinddatum) {
                // Maak validatie fouten op overgangOpPartnerEinddatum onzichtbaar tot we focus verliezen
                setFieldTouched(`producten[${selected}].verrekenen.overgangOpPartnerEinddatum`, false);
              }
            }}
            onBlur={(): void => setFieldTouched(`producten[${selected}].verrekenen.overgangOpPartnerEinddatum`, true)}
            appendChildren={"jaar"}
          />
        </>
      )}
    </>
  );
};

export default connect<Props, OverigePostenType>(Verrekenen);
