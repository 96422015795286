import React, { FC } from "react";
import { bedragFormatNull } from "../scenarios/infra/scenario-utils";

type VerschilRijProps = {
  eerste: number | null;
  tweede: number | null;
};

const formatVerschil = (eerste: number | null, tweede: number | null): string | null => {
  if (eerste === null || tweede === null) return null;
  return bedragFormatNull(tweede - eerste, (x: number): number => x, 0);
};

export const VerschilRij: FC<VerschilRijProps> = React.memo(({ eerste, tweede }) => {
  const verschil = formatVerschil(eerste, tweede);
  return <td className="text-right">{verschil ?? <>&nbsp;</>}</td>;
});

VerschilRij.displayName = 'VerschilRij'; 