import {
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledResult
} from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { AovVerzekerdeDekkingenOptions, AovBetalingMiddelsOptions } from "../../.generated/forms/formstypes";
import { DekkingKenmerken, PremieKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { getAovTextResources } from "../infra/aov-resources";
import { AovsType, PremieBetalingstermijn } from "../infra/aov-schema";

type PremiegegevensProps = {
  selected: number;
  kenmerken: PremieKenmerken;
  dekkingKenmerken: DekkingKenmerken;
};

function getBetalingsTermijnOptions(kenmerken: PremieKenmerken, dekkingKenmerken: DekkingKenmerken): LabelValuePairs {
  const options: LabelValuePairs = [];
  if (kenmerken.heeftBetalingstermijnMaand) {
    options.push({
      label: "Maand",
      value: PremieBetalingstermijn.Maand
    });
  }

  if (kenmerken.heeftBetalingstermijnTweeMaanden) {
    options.push({
      label: "2 Maanden",
      value: PremieBetalingstermijn.TweeMaanden
    });
  }

  if (kenmerken.heeftBetalingstermijnKwartaal) {
    options.push({
      label: "Kwartaal",
      value: PremieBetalingstermijn.Kwartaal
    });
  }

  if (kenmerken.heeftBetalingstermijnHalfJaar) {
    options.push({
      label: "Half Jaar",
      value: PremieBetalingstermijn.HalfJaar
    });
  }

  if (kenmerken.heeftBetalingstermijnJaar) {
    options.push({
      label: "Jaar",
      value: PremieBetalingstermijn.Jaar
    });
  }

  if (kenmerken.heeftBetalingstermijnKoopsom) {
    options.push({
      label: "Koopsom",
      value: PremieBetalingstermijn.Eenmalig
    });
  }

  return options;
}

const betalingMiddelsLabelValuePairs: LabelValuePairs = [
  {
    label: "Maandpremie",
    value: AovBetalingMiddelsOptions.Maandpremie
  },
  {
    label: "Koopsom",
    value: AovBetalingMiddelsOptions.Koopsom
  },
  {
    label: "Combinatie",
    value: AovBetalingMiddelsOptions.Combinatie
  }
];

export const Premiegegevens = ({ selected, kenmerken, dekkingKenmerken }: PremiegegevensProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<AovsType>();
  const selectedProduct = values.producten[selected];
  return (
    <>
      {(kenmerken.betalingstermijnTonen || dekkingKenmerken.heeftBetalingstermijnCombinatie) && (
        <>
          {dekkingKenmerken.heeftBetalingstermijnCombinatie ? (
            <div data-testid="BetalingstermijnMiddels">
              <LabeledRadioInput
                caption="Betalingstermijn"
                name={`producten[${selected}].premiegegevens.betalingMiddels`}
                options={betalingMiddelsLabelValuePairs}
              />
            </div>
          ) : (
            <LabeledSelectInput
              options={getBetalingsTermijnOptions(kenmerken, dekkingKenmerken)}
              name={`producten[${selected}].premiegegevens.betalingsTermijn`}
              caption="Betalingstermijn"
              readonly={!kenmerken.betalingstermijnEnabled}
            />
          )}
        </>
      )}

      {dekkingKenmerken.koopsomPeriodeTonen &&
        selectedProduct.premiegegevens.betalingMiddels === AovBetalingMiddelsOptions.Combinatie && (
          <LabeledNumberInput
            caption={"Koopsomperiode"}
            name={`producten[${selected}].premiegegevens.koopsomPeriodeInMaanden`}
            appendChildren={"maanden"}
          />
        )}

      {(selectedProduct.premiegegevens.betalingMiddels === AovBetalingMiddelsOptions.Koopsom ||
        selectedProduct.premiegegevens.betalingMiddels === AovBetalingMiddelsOptions.Combinatie) && (
        <LabeledCurrencyInput
          name={`producten[${selected}].premiegegevens.koopsomAO`}
          caption="Koopsom AO"
          decimalen={2}
        />
      )}

      {(selectedProduct.premiegegevens.betalingMiddels === AovBetalingMiddelsOptions.Koopsom ||
        selectedProduct.premiegegevens.betalingMiddels === AovBetalingMiddelsOptions.Combinatie) && (
        <LabeledCurrencyInput
          name={`producten[${selected}].premiegegevens.koopsomBedrag`}
          caption="Totale koopsom"
          decimalen={2}
        />
      )}
      {dekkingKenmerken.premieAoTonen &&
        selectedProduct.premiegegevens.betalingMiddels !== AovBetalingMiddelsOptions.Koopsom &&
        (selectedProduct.dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.Ao ? (
          <LabeledCurrencyInput
            name={`producten[${selected}].premiegegevens.premie`}
            caption="Premie totaal"
            decimalen={2}
            placeholder="-"
            readonly={kenmerken.berekenPremieKnopTonen}
          />
        ) : (
          <LabeledCurrencyInput
            name={`producten[${selected}].premiegegevens.premieAo`}
            caption="Premie AO"
            decimalen={2}
            placeholder="-"
            readonly={kenmerken.berekenPremieKnopTonen}
          />
        ))}

      {dekkingKenmerken.premieWwTonen &&
        (selectedProduct.dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
          selectedProduct.dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.AoWwEa ||
          selectedProduct.dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.Ww) && (
          <LabeledCurrencyInput
            name={`producten[${selected}].premiegegevens.premieWw`}
            caption="Premie WW"
            decimalen={2}
            placeholder="-"
            readonly={kenmerken.berekenPremieKnopTonen}
          />
        )}

      {kenmerken.totalePremieTonen &&
        kenmerken.berekenPremieKnopTonen &&
        dekkingKenmerken.premieAoTonen &&
        dekkingKenmerken.premieWwTonen &&
        selectedProduct.premiegegevens.betalingMiddels !== AovBetalingMiddelsOptions.Koopsom &&
        (selectedProduct.dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
          selectedProduct.dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.AoWwEa ||
          selectedProduct.dekking.dekkingGegevensVerzekerde1.dekking === AovVerzekerdeDekkingenOptions.AoEa) && (
          <>
            {kenmerken.totalePremieEnabled ? (
              <LabeledCurrencyInput
                name={`producten[${selected}].premiegegevens.premie`}
                caption="Totale premie"
                decimalen={2}
              />
            ) : (
              <>
                <LabeledResult
                  name="totaal"
                  caption="Premie totaal"
                  fontWeight="bold"
                  fontWeightResult="bold"
                  alignRight={true}
                  currency={true}
                  result={(): string => {
                    const result = optellen([
                      selectedProduct.premiegegevens.premieAo,
                      selectedProduct.premiegegevens.premieWw
                    ]);
                    return result ? bedragFormat(result, 2, 0, false) : "-";
                  }}
                />
              </>
            )}
          </>
        )}

      {dekkingKenmerken.premieAftrekbaarTonen && (
        <LabeledBevestigingInput
          caption="Premie aftrekbaar AO"
          name={`producten[${selected}].premiegegevens.premieAftrekbaarAO`}
          tooltip={kenmerken.hintPremieAftrekbaarTonen ? kenmerken.hintPremieAftrekbaar : undefined}
          readonly={!dekkingKenmerken.premieAftrekbaarEnabled}
        />
      )}

      {dekkingKenmerken.premieCollectiefTonen && (
        <LabeledBevestigingInput
          caption="Collectief"
          name={`producten[${selected}].premiegegevens.collectief`}
          tooltip={getAovTextResources("TooltipCollectief")}
        />
      )}

      {dekkingKenmerken.premieJaarlijkseIndexatieTonen && (
        <LabeledPercentageInput
          caption="Jaarlijkse indexatie"
          name={`producten[${selected}].premiegegevens.jaarlijksIndexatiePercentage`}
          decimalen={2}
        />
      )}

      {kenmerken.berekenPremieKnopTonen && (
        <div className="button-container px-2">
          <Button
            id={`producten[${selected}].premieGegevens.premieBerekenen-button`}
            variant="primary"
            onClick={(): void => {
              setFieldValue(`producten[${selected}].toggleBerekenen`, !values.producten[selected].toggleBerekenen);
            }}
          >
            Premie berekenen
          </Button>
        </div>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Premiegegevens.displayName = "Premiegegevens";
