import { LocalDate } from "@js-joda/core";
import { JaarMaandInputType, nullableLocalDateSchema } from "adviesbox-shared";
import * as Yup from "yup";
import { ValidationError } from "yup";
import {
  Belastingbox,
  Financieringsoort,
  GebruikPandSoort,
  BoeterenteBerekeningswijzeOpties
} from "../../.generated/forms/formstypes";
import {
  VastBedragPercentageOptions,
  VastBedragOptions
} from "../../.generated/instellingen-forms/instellingen-formstypes";
import { jaarMaandInputSchema } from "../../shared/generic-parts/jaar-maand/schema";
import { berekenInputSchema } from "../../shared/types";
import { afronden, bedragFormat } from "../../shared/utils/currency";
import { addMonths, addYears } from "../../shared/utils/dates";
import { hasValue } from "../../shared/utils/helpers";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getFinancieringsbehoefteTextResources } from "./financieringsbehoefte-resources";
import { FinancieringsbehoefteContextType } from "./financieringsbehoefte-types";

export const standaardStartersleningBedrag = 750;

export type VerbouwingVerbeteringModalSchemaContextType = {
  soortFinanciering: Financieringsoort;
};

export function bepaalAdviesTitel(context: VerbouwingVerbeteringModalSchemaContextType): string {
  if (context.soortFinanciering === Financieringsoort.AankoopNieuwbouw) {
    return "meerwerk";
  } else {
    return "verbouwing/verbetering";
  }
}

const soortFinancieringSchema = yupEnum(
  Financieringsoort,
  getFinancieringsbehoefteTextResources("ErrorOnbekendeFinanciering")
).default(Financieringsoort.Geen);

export const financieringOnderpandSchema = Yup.object({
  marktwaardeVoorVerbouwing: Yup.number()
    .nullable()
    .default(0),
  marktwaardeNaVerbouwing: Yup.number()
    .nullable()
    .default(0),
  wozWaarde: Yup.number()
    .nullable()
    .default(0),
  eigenwoningforfait: Yup.number()
    .nullable()
    .default(0),
  wozWaardeloket: Yup.string(),
  heeftPartner: Yup.boolean().default(false),
  geboorteDatumAanvrager: nullableLocalDateSchema,
  geboorteDatumPartner: nullableLocalDateSchema,
  eigendomsverhoudingAanvrager: Yup.number().default(100),
  eigendomsverhoudingPartner: Yup.number().default(0),
  soortFinanciering: soortFinancieringSchema,
  // TODO: is de gebruik enum errortekst correct?
  gebruik: yupEnum(GebruikPandSoort, getFinancieringsbehoefteTextResources("ErrorOnbekendeFinanciering")).default(
    GebruikPandSoort.Geen
  )
});

export const financieringSchema = Yup.object({
  soortFinanciering: soortFinancieringSchema,
  kostenKoper: Yup.boolean()
    .nullable()
    .default(null),
  soortNieuwbouw: Yup.string()
    .nullable()
    .default(null),
  soortZelfbouw: Yup.string()
    .nullable()
    .default(null),
  doorlopendeLeningdelenBedrag: Yup.number()
    .nullable()
    .default(null),
  heeftDoorlopendeLeningdelen: Yup.boolean()
    .nullable()
    .default(null),
  analyseHeeftLeningdelen: Yup.boolean()
    .nullable()
    .default(null)
});

export const erfpachtSchema = Yup.object({
  erfpacht: Yup.boolean()
    .nullable()
    .default(null),
  jaarlijksCanon: Yup.number()
    .nullable()
    .default(null),
  particulierErfpacht: Yup.boolean()
    .nullable()
    .default(null),
  eeuwigdurendErfpacht: Yup.boolean()
    .nullable()
    .default(null),
  einddatum: nullableLocalDateSchema.when(
    "passeerdatum",
    (passeerdatum: LocalDate | null): Yup.MixedSchema<LocalDate | null> => {
      return nullableLocalDateSchema.test({
        message: getFinancieringsbehoefteTextResources("ErrorEindDatumNietNaPasseerDatum"),
        test: function (value: LocalDate | null) {
          if (hasValue(value)) {
            const passeerdatum: LocalDate = this.parent.passeerdatum || LocalDate.now();
            return value >= passeerdatum;
          }
          return true;
        }
      });
    }
  ),
  passeerdatum: nullableLocalDateSchema
});

export const vermogenSchema = Yup.object({
  overwaardeBerekeningTonen: Yup.boolean().default(false),
  overwaarde: Yup.number().default(0),
  vrijVermogen: berekenInputSchema,
  totaalVermogen: Yup.number().default(0),
  berekendeSomVermogen: Yup.number().default(0)
});

export const overwaardeSchema = Yup.object({
  nettoVerkoopOpbrengst: Yup.number().default(0),
  restantSchuldBox1: Yup.number().default(0),
  vervreemdingsSaldo: Yup.number().default(0),
  restantSchuldBox3: Yup.number().default(0),
  totaalOverwaarde: Yup.number().default(0)
});

export const pandOpbrengstSchema = Yup.object({
  pandNaam: Yup.string().default(""),
  verkoopPrijs: Yup.number()
    .nullable()
    .default(null),
  verkoopKosten: Yup.number()
    .nullable()
    .default(null),
  subtotaal: Yup.number().default(0),
  percentageEigendom: Yup.string().default(""),
  nettoOpbrengst: Yup.number().default(0)
});

const cascadingValue = Yup.object({
  hasChanged: Yup.boolean().default(false),
  elementsChanged: Yup.array(Yup.string()).default([])
});

export const verbouwingVerbeteringSpecificatieSchema = Yup.object({
  verbouwingId: Yup.string()
    .nullable()
    .default(null),
  werkzaamheden: Yup.string()
    .nullable()
    .default(null),
  werkzaamhedenBedrag: Yup.number()
    .nullable()
    .default(null),
  volgnummer: Yup.number().default(0)
});

export const verbouwingVerbeteringModalSchema = Yup.object({
  totaalVerbouwingVerbetering: Yup.number()
    .nullable()
    .default(null)
    .test("max-check", "", function (): true | ValidationError {
      const valid =
        this.parent.waarvanNietWaardevermeerderend <= this.parent.totaalVerbouwingVerbetering ||
        this.parent.waarvanNietWaardevermeerderend === undefined;

      if (valid) {
        return true;
      } else {
        const context = this.options.context as VerbouwingVerbeteringModalSchemaContextType;
        const titel = bepaalAdviesTitel(context);

        const message = getFinancieringsbehoefteTextResources(
          "ErrorWaardevermeerderendNietGroterDanVerbouwingVerbetering"
        ).replace("{titel}", titel);

        return this.createError({
          message
        });
      }
    })
    .test("max-check", "", function (): true | ValidationError {
      const valid =
        this.parent.waarvanEnergiebesparendeBudget <= this.parent.totaalVerbouwingVerbetering ||
        this.parent.waarvanEnergiebesparendeBudget === undefined;

      if (valid) {
        return true;
      } else {
        const context = this.options.context as VerbouwingVerbeteringModalSchemaContextType;
        const titel = bepaalAdviesTitel(context);

        const message = getFinancieringsbehoefteTextResources(
          "ErrorEnergiebespaarBudgetNietGroterDanVerbouwingVerbetering"
        ).replace("{titel}", titel);

        return this.createError({
          message
        });
      }
    })
    .test("max-check", "", function (): true | ValidationError {
      const valid =
        this.parent.waarvanEnergiebesparendeVoorzieningen <= this.parent.totaalVerbouwingVerbetering ||
        this.parent.waarvanEnergiebesparendeVoorzieningen === undefined;

      if (valid) {
        return true;
      } else {
        const context = this.options.context as VerbouwingVerbeteringModalSchemaContextType;
        const titel = bepaalAdviesTitel(context);

        const message = getFinancieringsbehoefteTextResources(
          "ErrorEnergiebesparendeVoorzieningenNietGroterDanVerbouwingVerbetering"
        ).replace("{titel}", titel);

        return this.createError({
          message
        });
      }
    })
    .test("max-check", "", function (): true | ValidationError {
      const valid =
        this.parent.waarvanAchterstalligOnderhoud <= this.parent.totaalVerbouwingVerbetering ||
        this.parent.waarvanAchterstalligOnderhoud === undefined;

      if (valid) {
        return true;
      } else {
        const context = this.options.context as VerbouwingVerbeteringModalSchemaContextType;
        const titel = bepaalAdviesTitel(context);

        const message = getFinancieringsbehoefteTextResources(
          "ErrorAchterstalligOnderhoudNietGroterDanVerbouwingVerbetering"
        ).replace("{titel}", titel);

        return this.createError({
          message
        });
      }
    }),
  waarvanNietWaardevermeerderend: Yup.number()
    .nullable()
    .default(null),
  waarvanEnergiebesparendeBudget: Yup.number()
    .nullable()
    .default(null),
  waarvanEnergiebesparendeVoorzieningen: Yup.number()
    .nullable()
    .default(null),
  waarvanAchterstalligOnderhoud: Yup.number()
    .nullable()
    .default(null),
  totaal: Yup.number()
    .nullable()
    .default(0),
  verbouwingVerbeteringSpecificaties: Yup.array(verbouwingVerbeteringSpecificatieSchema).default([]),
  energieklasseToekomstig: Yup.string()
    .nullable()
    .default(null)
});

export const advieskostenBemiddelingsvergoedingModalSchema = Yup.object({
  advieskosten: Yup.number()
    .nullable()
    .default(null),
  bemiddelingsvergoedingGeldlening: Yup.number()
    .nullable()
    .default(null),
  overigeBemiddelingsvergoeding: Yup.number()
    .nullable()
    .default(null),
  dossiervergoedingGeldlening: Yup.number()
    .nullable()
    .default(null),
  advieskostenGespreid: berekenInputSchema
    .nullable()
    .test(
      "max-test",
      getFinancieringsbehoefteTextResources("ErrorMaxAdvieskosten"),
      function (value) {
        const maxValue = this.resolve(Yup.ref("advieskosten"));
        if (value==null) return true;
        return maxValue !== undefined && value.bedrag <= maxValue;
      }
    )
    .default(null),
  bemiddelingsvergoedingGeldleningGespreid: berekenInputSchema
    .nullable()
    .test(
      "max-test",
      getFinancieringsbehoefteTextResources("ErrorMaxBemiddelingsvergoeding"),
      function (value) {
        const maxValue = this.resolve(Yup.ref("bemiddelingsvergoedingGeldlening"));
        if (value==null) return true;
        return maxValue !== undefined && value.bedrag <= maxValue;
      }
    )
    .default(null),
  overigeBemiddelingsvergoedingGespreid: berekenInputSchema
    .nullable()
    .test(
      "max-test",
      getFinancieringsbehoefteTextResources("ErrorMaxBemiddelingsvergoeding"),
      function (value) {
        const maxValue = this.resolve(Yup.ref("overigeBemiddelingsvergoeding"));
        if (value==null) return true;
        return maxValue !== undefined && value.bedrag <= maxValue;
      }
    )
    .default(null),
  gespreidBetalen: Yup.boolean().default(false),
  gespreidBetalenOver: Yup.number()
    .nullable()
    .default(null)
    .when("gespreidBetalen", {
      is: true,
      then: Yup.number()
        .required(getFinancieringsbehoefteTextResources("ErrorGespreidBetalenVerplicht"))
        .min(1, getFinancieringsbehoefteTextResources("ErrorGespreidBetalenVerplicht"))
        .max(24, getFinancieringsbehoefteTextResources("ErrorGespreidBetalenMax"))
    })
});

export const bankgarantieModalSchema = Yup.object({
  garantiebedrag: Yup.number()
    .nullable()
    .default(null),
  aanvragenBijGeldverstrekker: Yup.boolean()
    .nullable()
    .default(null),
  kostenBankgarantie: Yup.number()
    .nullable()
    .default(null)
});

export const overigeFinancieringskostenModalSchema = Yup.object({
  afsluitkostenSVnStarterslening: Yup.number().default(0),
  bereidstellingskosten: Yup.number()
    .nullable()
    .default(null)
});

export const totaleRestschuldModalSchema = Yup.object({
  restschuldHuidigeWoning: berekenInputSchema,
  extraKostenHuidigeHypotheek: Yup.number()
    .nullable()
    .default(null),
  totaleRestschuld: Yup.number()
    .nullable()
    .default(null),
  inbrengOpgebouwdeWaarde: Yup.number()
    .nullable()
    .default(null),
  inbrengEigenGeldVoorVerlagenRestschuld: Yup.number()
    .nullable()
    .default(null),
  inbrengOpgebouwdeWaardeRestschuld: berekenInputSchema.test({
    message: "", //inieel leeg, wordt door createError bepaald
    name: "max",
    test: function (value): boolean | Yup.ValidationError {
      if (!this.parent.data) return true;
      const inbrengOpbouw = this.parent.data.inbrengOpgebouwdeWaarde || 0;

      if (this.parent.data.inbrengOpgebouwdeWaardeRestschuld.bedrag <= inbrengOpbouw) return true;

      return this.createError({
        message: `${getFinancieringsbehoefteTextResources("ErrorInbrengOpgebouwdeWaardeRestschuld")} ${bedragFormat(
          inbrengOpbouw
        )}.`,
        path: "data.inbrengOpgebouwdeWaardeRestschuld"
      });
    }
  }),
  meeTeFinancierenRestschuld: Yup.number()
    .nullable()
    .default(0)
    .test("max-check", getFinancieringsbehoefteTextResources("ErrorMeeTeFinancierenRestschuld"), function (): boolean {
      if (!this.parent.data) return true;
      const meeTeFinancierenRestschuld =
        (afronden(this.parent.data.restschuldHuidigeWoning.bedrag, 0) || 0) +
        (afronden(this.parent.data.extraKostenHuidigeHypotheek, 0) || 0) -
        (afronden(this.parent.data.inbrengEigenGeldVoorVerlagenRestschuld, 0) || 0) -
        (afronden(this.parent.data.inbrengOpgebouwdeWaardeRestschuld.bedrag, 0) || 0);

      return meeTeFinancierenRestschuld >= 0;
    })
});

export const overigeKostenSpecificatieSchema = Yup.object({
  omschrijving: Yup.string()
    .nullable()
    .default(null),
  bedrag: Yup.number()
    .nullable()
    .default(null),
  fiscalePlaatsing: yupEnum(Belastingbox),
  aftrekbaar: Yup.boolean().default(false)
});

export const overigeKostenInHypotheekModalSchema = Yup.object({
  lastenOverbruggingskrediet: Yup.number()
    .nullable()
    .default(null),
  gewenstConsumptiefBedrag: Yup.number()
    .nullable()
    .default(null),
  royementskostenBedrag: Yup.number()
    .nullable()
    .default(null),
  soortFinanciering: soortFinancieringSchema,
  overigeKostenSpecificaties: Yup.array(overigeKostenSpecificatieSchema).default([])
});

export const inbrengEigenGeldModalSchema = Yup.object({
  inbrengEigenGeld: Yup.number()
    .nullable()
    .default(null),
  inbrengVoorVerlagenRestschuld: Yup.number()
    .nullable()
    .default(null),
  eigenGeldOverwaarde: berekenInputSchema,
  totaalOverwaarde: Yup.number().nullable(),
  extraAflossing: Yup.number()
    .nullable()
    .default(null),
  inbrengEigengeldOmschrijving1: Yup.string()
    .nullable()
    .default(null),
  inbrengEigengeldBedrag1: Yup.number()
    .nullable()
    .default(null),
  inbrengEigengeldOmschrijving2: Yup.string()
    .nullable()
    .default(null),
  inbrengEigengeldBedrag2: Yup.number()
    .nullable()
    .default(null),
  inbrengOpgebouwdeWaarde: Yup.number()
    .nullable()
    .default(null),
  inbrengPolisOmschrijving: Yup.string()
    .nullable()
    .default(null),
  inbrengPolisBedrag: Yup.number()
    .nullable()
    .default(null),
  waarvanVerlagenRestschuld: Yup.number().nullable(),
  meenemenInOverbruggingskredietBedrag: Yup.number()
    .nullable()
    .default(null)
});

export const andereFinancieringenModalSchema = Yup.object({
  doorlopendeHypotheken: Yup.number()
    .nullable()
    .default(null),
  kortlopendeLeningKrediet: Yup.number()
    .nullable()
    .default(null),
  startersleningBedrag: Yup.number()
    .nullable()
    .default(null),
  overigeFinanciering1Omschrijving: Yup.string()
    .nullable()
    .default(null),
  overigeFinanciering1Bedrag: Yup.number()
    .nullable()
    .default(null),
  overigeFinanciering2Omschrijving: Yup.string()
    .nullable()
    .default(null),
  overigeFinanciering2Bedrag: Yup.number().nullable()
});

export const eigenwoningschuldCompleetModalSchema = Yup.object({
  arbeidskostenNotaris: Yup.number().default(0),
  soortFinanciering: soortFinancieringSchema,
  koopsomWoning: Yup.number().default(0),
  bouwrente: Yup.number().default(0),
  leningdelenWordenAfgelost: Yup.boolean().default(false),
  afTeLossenBestaandeHypotheken: berekenInputSchema,
  verbouwing: Yup.number().default(0),
  meerwerk: Yup.number().default(0),
  overdrachtsbelasting: Yup.number().default(0),
  makelaarsCourtage: Yup.number().default(0),
  leveringsakte: Yup.number().default(0),
  afkoopErfpacht: Yup.number().default(0),
  vergoedingPartnerAkteVerdeling: Yup.number().default(0),
  aflossingOpEigenwoningschuld: Yup.number().default(0),
  aftrekbareAdvieskosten: Yup.number().default(0),
  hypotheekakte: Yup.number()
    .nullable()
    .default(null),
  nhgKosten: Yup.number().default(0),
  taxatieKosten: Yup.number().default(0),
  afsluitkostenSVnStarterslening: Yup.number().default(0),
  afsluitkostenOverbruggingsKrediet: Yup.number().default(0),
  bereidstellingsKosten: Yup.number().default(0),
  omzettingLeningdeelKosten: Yup.number().default(0),
  aftrekbareBoeterente: Yup.number().default(0),
  overigekostenBox1: Yup.number().default(0),
  financieringsKosten: Yup.number().default(0),
  financieringsKostenPercentage: Yup.number().default(0),
  financieringsKostenTotaal: Yup.number().default(0),
  financieringskostenAftrekbaarOvernemen: Yup.boolean().default(false),
  maximaalTeFinancierenBox1: Yup.number().default(0),
  maximaalGelijkAanGewensteHypotheek: Yup.number().default(0),
  eigenWoningReserveVervreemdingSaldo: Yup.number().default(0),
  doorlopendeHypothekenBedrag: Yup.number().default(0),
  doorlopendeLeningdelenBox1Bedrag: Yup.number().default(0),
  eigenwoningschuld: berekenInputSchema,
  ewsAdviesKosten: Yup.number()
    .nullable()
    .default(null)
});

export const eigenwoningschuldModalSchema = Yup.object({
  financieringsKosten: Yup.number().default(0),
  financieringsKostenPercentage: Yup.number().default(0),
  financieringskostenAftrekbaarOvernemen: Yup.boolean().default(false),
  maximaalTeFinancierenBox1: Yup.number().default(0),
  eigenwoningschuld: berekenInputSchema,
  ewsAdviesKosten: Yup.number()
    .nullable()
    .default(null),
  eigenWoningReserveVervreemdingSaldo: Yup.number().default(0)
});

export const koopsomSchema = Yup.object({
  koopsomBedrag: Yup.number()
    .nullable()
    .default(0)
    .min(Yup.ref("kostenGrondBedrag"), getFinancieringsbehoefteTextResources("ErrorKoopsomBedrag")),
  koopsomBedragNieuwbouw: Yup.number()
    .nullable()
    .default(null),
  kostenGrondBedrag: Yup.number()
    .nullable()
    .default(0),
  grondReedsInBezit: Yup.boolean().default(false)
});

export const renteverliesMaandelijksSchema = Yup.object({
  percentage: Yup.number()
    .nullable()
    .min(0)
    .max(100),
  maand: Yup.number().nullable()
});

export const renteverliesTijdensBouwModalSchema = Yup.object({
  grondkosten: Yup.number()
    .nullable()
    .default(null),
  grondReedsInBezit: Yup.boolean()
    .nullable()
    .default(null),
  stichtingskosten: Yup.number()
    .nullable()
    .default(null),
  hypotheekbedrag: Yup.number()
    .nullable()
    .default(null),
  hypotheekrente: Yup.number()
    .nullable()
    .default(null),
  depotvergoeding: Yup.number()
    .nullable()
    .default(null),
  bouwdepotbedrag: Yup.number().default(0),
  duurVanDeBouw: Yup.number()
    .min(1, getFinancieringsbehoefteTextResources("ErrorDuurVanDeBouwMin"))
    .max(99, getFinancieringsbehoefteTextResources("ErrorDuurVanDeBouwMax"))
    .nullable()
    .default(null),
  soortBerekening: Yup.mixed<"Standaard" | "Geavanceerd">().oneOf(["Standaard", "Geavanceerd"]),
  renteverlies: Yup.number()
    .nullable()
    .default(null),
  renteverliesMaandelijks: Yup.array(renteverliesMaandelijksSchema).default([]),
  renteverliesMaandelijksTotaal: Yup.number()
    .nullable()
    .default(null)
    .test("min-max-check", getFinancieringsbehoefteTextResources("ErrorRenteverliesMaandelijksTotaal"), function (
      value
    ): boolean {
      return this.parent.soortBerekening === "Geavanceerd" && this.parent.duurVanDeBouw > 0 ? value === 100 : true;
    })
});

export const overbruggingskredietModalSchema = Yup.object({
  overwaardeMeenemen: Yup.boolean().default(false),
  overwaardeHuidigeWoningen: Yup.number()
    .nullable()
    .default(null),
  meenemenInOverbruggingskrediet: Yup.number()
    .nullable()
    .default(null),
  renteOverbruggingskrediet: Yup.number()
    .nullable()
    .default(null),
  afsluitkosten: Yup.number()
    .nullable()
    .default(null),
  ingangsdatum: nullableLocalDateSchema,
  einddatum: nullableLocalDateSchema.test({
    message: getFinancieringsbehoefteTextResources("ErrorEindDatum"),
    test: function (value: LocalDate | null) {
      if (hasValue(value)) {
        const ingangsdatum: LocalDate | null = this.parent.ingangsdatum;
        if (hasValue(ingangsdatum)) {
          return value >= ingangsdatum.plusMonths(1);
        }
      }
      return true;
    }
  }),
  fiscalegegevensDeelBox1Percentage: Yup.number()
    .nullable()
    .default(null),
  fiscalegegevensDeelBox1Bedrag: Yup.number()
    .nullable()
    .default(null),
  fiscalegegevensDeelBox3Percentage: Yup.number()
    .nullable()
    .default(null),
  fiscalegegevensDeelBox3Bedrag: Yup.number()
    .nullable()
    .default(null),
  brutoRentelastPerMaand: Yup.number()
    .nullable()
    .default(null),
  nettoRentelastPerMaand: Yup.number()
    .nullable()
    .default(null),
  brutoTotaleRentelast: Yup.number()
    .nullable()
    .default(null),
  nettoTotaleRentelast: Yup.number()
    .nullable()
    .default(null),
  bestaandeWoningInEigendomVerkocht: Yup.boolean().default(false),
  hypotheekNorm: Yup.object()
    .nullable()
    .shape({
      jaar: Yup.number(),
      maand: Yup.number()
    }),
  rentevastAantalMaanden: Yup.number()
    .nullable()
    .default(1)
});

export const OverbruggingskredietModalSchemaInPopup = overbruggingskredietModalSchema.shape({
  renteOverbruggingskrediet: Yup.number()
    .nullable()
    .when("bestaandeWoningInEigendomVerkocht", {
      is: true,

      then: Yup.number().test(
        "min-check",
        getFinancieringsbehoefteTextResources("ErrorRenteOverbruggingskrediet"),
        function (value): boolean {
          return value >= 1 && value < 21;
        }
      )
    })
});

export const boeterenteSchema = Yup.object({
  leningdeelId: Yup.string(),
  boeterenteId: Yup.string(),
  maatschappij: Yup.string()
    .nullable()
    .default(null),
  berekenwijzeBoeterente: Yup.mixed<BoeterenteBerekeningswijzeOpties>().oneOf(
    [BoeterenteBerekeningswijzeOpties.Contant, BoeterenteBerekeningswijzeOpties.EigenInvoer],
    getFinancieringsbehoefteTextResources("ErrorOnbekendeBerekenwijze")
  ),
  aflosvorm: Yup.string()
    .nullable()
    .default(null),
  oorspronkelijkeHoofdsom: Yup.number()
    .nullable()
    .default(null),
  restantHoofdsom: Yup.number()
    .nullable()
    .default(null),
  restantLooptijd: jaarMaandInputSchema,
  einddatumRentevastperiode: nullableLocalDateSchema,
  datumBoeterente: nullableLocalDateSchema.test({
    message: getFinancieringsbehoefteTextResources("ErrorDatumBoeterente"),
    test: function (value: LocalDate | null) {
      const context = this.options.context as FinancieringsbehoefteContextType;

      if (context && context.leningdelenWordenAfgelost) {
        return true;
      }
      if (hasValue(value)) {
        const einddatumRentevastperiode: LocalDate = this.parent.resterendePeriodeWeergeven
          ? addMonths(this.parent.einddatumRentevastperiode, -1)
          : addMonths(
            addYears(LocalDate.now(), this.parent.resterendePeriode.jaren || 0),
            this.parent.resterendePeriode.maanden || 0
          );
        return einddatumRentevastperiode >= value;
      }
      return true;
    }
  }),
  resterendePeriodeWeergeven: Yup.boolean().default(false),
  resterendePeriode: jaarMaandInputSchema.test({
    test: function (value: JaarMaandInputType) {
      const context = this.options.context as FinancieringsbehoefteContextType;
      if (context && context.leningdelenWordenAfgelost) {
        return true;
      }
      if (!this.parent.resterendePeriodeWeergeven) {
        if ((!value.jaren && !value.maanden) || (value.jaren === 0 && value.maanden === 1)) {
          return this.createError({
            path: `${this.path}.maanden`,
            message: getFinancieringsbehoefteTextResources("ErrorResterendePeriode")
          });
        }
      }

      return true;
    }
  }),
  boetevrijePercentage: Yup.number()
    .nullable()
    .default(null),
  huidigeContractrente: Yup.number()
    .nullable()
    .default(null),
  nieuwRentepercentage: Yup.number()
    .nullable()
    .default(null),
  boeterente: Yup.number()
    .nullable()
    .default(null),
  opgewbouwdewaardeBedrag: Yup.number()
    .nullable()
    .default(null)
});

export const boeterenteModalSchema = Yup.object({
  modal: Yup.array(boeterenteSchema)
});

export const afkoopErfpachtModelSchema = Yup.object({
  afkoopBedrag: Yup.number()
    .nullable()
    .default(null),
  afkoopEinddatum: nullableLocalDateSchema.test({
    message: getFinancieringsbehoefteTextResources("ErrorAfkoopEinddatum"),
    test: function (value: LocalDate | null) {
      if (hasValue(value)) {
        return value > LocalDate.now().plusDays(1);
      }
      return true;
    }
  })
});

export const instellingenTarievenSchema = Yup.object({
  leveringsakteVastBedrag: Yup.number(),
  leveringsakteVaststellingWaarde: yupEnum(VastBedragOptions),
  hypotheekakteVastBedrag: Yup.number(),
  hypotheekakteVaststellingWaarde: yupEnum(VastBedragOptions),
  taxatiekostenVastBedrag: Yup.number(),
  taxatiekostenVaststellingWaarde: yupEnum(VastBedragOptions),
  hypothecaireInschrijvingVastBedrag: Yup.number(),
  hypothecaireInschrijvingVaststellingWaarde: yupEnum(VastBedragPercentageOptions),
  hypothecaireInschrijvingPercentage: Yup.number()
});

export const financieringsopzetSchema = Yup.object({
  koopsomRoerendeZaken: Yup.number()
    .nullable()
    .default(null),
  overdrachtsbelasting: berekenInputSchema,
  makelaarscourtage: berekenInputSchema,
  leveringsakte: berekenInputSchema,
  afkoopErfpacht: Yup.number()
    .nullable()
    .default(null),
  hypotheekakte: berekenInputSchema,
  taxatie: berekenInputSchema,
  nationaleHypotheekGarantie: Yup.number()
    .nullable()
    .default(null),
  nationaleHypotheekGarantieBerekenen: Yup.boolean().default(true),
  nhgMogelijk: Yup.boolean().default(false),
  nhgMogelijkIngesteld: Yup.boolean(),
  nationaleHypotheekGarantieWarning: Yup.string().default(""),
  advieskosten: Yup.number()
    .nullable()
    .default(null),
  bankgarantie: Yup.number()
    .nullable()
    .default(null),
  afTeLossenoverigeLeningen: Yup.number()
    .nullable()
    .default(null),
  kostenOmzettingBedrag: Yup.number()
    .nullable()
    .default(null),
  inbrengEigenGeld: Yup.number()
    .nullable()
    .default(null),
  gewensteHypotheek: Yup.number()
    .nullable()
    .default(null),
  gewensteHypotheekBerekenen: Yup.boolean().default(false),
  eigenwoningschuld: Yup.number()
    .nullable()
    .default(0),
  hypothecaireInschrijving: berekenInputSchema.test({
    message: getFinancieringsbehoefteTextResources("ErrorHypotecaireInschrijvingLagerdanGewensteHypotheek"),
    test: function (): boolean {
      if (!this.parent.hypothecaireInschrijving.bedrag) return true;
      return this.parent.hypothecaireInschrijving.bedrag >= this.parent.gewensteHypotheek;
    }
  }),
  bevoorschottingspercentage: Yup.number()
    .nullable()
    .default(null),
  maxBevoorschottingspercentage: Yup.number()
    .nullable()
    .default(null),
  bouwrentePeriodeVoorAankoop: Yup.number()
    .nullable()
    .default(null),
  bouwrentePeriodeNaAankoop: Yup.number()
    .nullable()
    .default(null),
  renteverliesTijdensBouw: Yup.number()
    .nullable()
    .default(null),
  afTeLossenBestaandeHypotheken: berekenInputSchema,
  uitkoopPartner: Yup.number()
    .nullable()
    .default(null),
  leningdelenWordenAfgelost: Yup.boolean().default(false),
  bestaandeWoningInEigendomVerkocht: Yup.boolean().default(false),
  boeterenteBedrag: Yup.number()
    .nullable()
    .default(null),
  bestaandNhgBedrag: Yup.number()
    .nullable()
    .default(0),
  doorlopendZonderNhgBedrag: Yup.number()
    .nullable()
    .default(null),
  verbouwingVerbeteringModal: verbouwingVerbeteringModalSchema,
  advieskostenBemiddelingsvergoedingModal: advieskostenBemiddelingsvergoedingModalSchema,
  bankgarantieModal: bankgarantieModalSchema,
  overigeFinancieringskostenModal: overigeFinancieringskostenModalSchema,
  totaleRestschuldModal: totaleRestschuldModalSchema,
  overigeKostenInHypotheekModal: overigeKostenInHypotheekModalSchema,
  inbrengEigenGeldModal: inbrengEigenGeldModalSchema,
  andereFinancieringenModal: andereFinancieringenModalSchema,
  eigenwoningschuldModal: eigenwoningschuldModalSchema,
  koopsom: koopsomSchema,
  renteverliesTijdensBouwModal: renteverliesTijdensBouwModalSchema,
  overbruggingskredietModal: overbruggingskredietModalSchema,
  boeterenteModal: Yup.array(boeterenteSchema),
  afkoopErfpachtModal: afkoopErfpachtModelSchema,
  financieringsopzetInstellingenTarieven: instellingenTarievenSchema,
  arbeidskostenNotaris: Yup.number().nullable()
});

export const financieringsbehoefteSchema = Yup.object({
  volgnummerVoorstel: Yup.number().default(0),
  omschrijvingVoorstel1: Yup.string().default(""),
  onderpand: financieringOnderpandSchema,
  financiering: financieringSchema,
  totaalHuidigeLeningdelen: Yup.number().default(0),
  erfpacht: erfpachtSchema,
  vermogen: vermogenSchema,
  overwaarde: overwaardeSchema,
  nettoPandOpbrengst: Yup.array(pandOpbrengstSchema).default([]),
  cascadingValueChange: cascadingValue,
  financieringsopzet: financieringsopzetSchema,
  hypotheekNorm: Yup.object()
    .nullable()
    .shape({
      jaar: Yup.number(),
      maand: Yup.number()
    }),
  doorlopendeLeningdelenBox1Bedrag: Yup.number()
    .nullable()
    .default(null)
});
