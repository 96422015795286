import { HypotheekFiscaleRegelingType } from "../../hypotheek/infra/hypotheek-types";
import { FiscaleTyperingOptions } from "../../.generated/forms/formstypes";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

const FiscaleRegelingDraftSideEffects = createISWSideEffect<HypotheekFiscaleRegelingType>((bag): void => {
  const { has, draft } = bag;

  if (
    has.fiscaleTypering.changed &&
    draft.fiscaleTypering !== FiscaleTyperingOptions.KapitaalverzekeringEigenWoningKew &&
    draft.fiscaleTypering !== FiscaleTyperingOptions.KapitaalverzekeringZonderEigenWoning
  ) {
    // Zie GM 96374: Garantieverzekering en Fiscaal regime zijn alleen van toepasssing bij 'Kapitaalverzekering'
    draft.garantieverzekering = null;
    draft.fiscaalRegime = null;
  }
});

export const FiscaleRegelingModalSideEffects = initISWSideEffect(FiscaleRegelingDraftSideEffects)();
