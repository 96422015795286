/* istanbul ignore file */
import React, { FC, useState, useCallback, useContext } from "react";
import { Card, Icon, CardWrapper, CheckBox, Label, useRequestInit } from "adviesbox-shared";
import { ProductOverzicht } from "../productoverzicht/product-overzicht";
import { Formik, FormikProps } from "formik";
import LastenOverzichtChart from "../lastenoverzicht-chart/lastenoverzicht-chart";
import LastenOverzichtCard from "../lastenoverzicht-card/lastenoverzicht-card";
import { Lasten, LastenOverzicht } from "../../../.generated/rapportage/rapportagetypes";
import "./snel-inzicht-drawer.scss";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";
import { useCookieBoolean } from "../../../useCookieSettings";
import { AppDataContext } from "../../../navigation/appdata-context";
import { Table } from "react-bootstrap";
import { HypotheekOverzichtFetcher } from "./hypotheek-overzicht-fetcher";
import { LastenOverzichtFetcher } from "./lasten-overzicht-fetcher";
import { VerschilRij } from "./verschil-rij";

type KeuzeOpties = {
  MaandlastenoverzichtRadioButtons: string;
  selectedVoorstellen: { voorstelId: string; checked: boolean }[];
  "Overzicht vergelijking hypotheeklastenRadioButtons": string;
};

type SnelInzichtDrawerProps = {
  values?: KeuzeOpties;
}

export const SnelInzichtDrawer: FC<SnelInzichtDrawerProps> = ({ values }) => {
  const [lasten, setLasten] = useState<Lasten & { gebeurtenissen: string[] }>();
  const [isCollapsed, setCollapsed] = useCookieBoolean("scenario-instellingen-productoverzicht-weergeven", false);
  const { voorstellen } = useContext(AppDataContext);
  const [eersteLasten, setEersteLasten] = useState<LastenOverzicht | null>(null);
  const [tweedeLasten, setTweedeLasten] = useState<LastenOverzicht | null>(null);
  const { params } = useRequestInit();
  const handleDataLoaded = useCallback((data: LastenOverzicht, isFirst: boolean) => {
    if (isFirst) {
      setEersteLasten(data);
    } else {
      setTweedeLasten(data);
    }
  }, []);

  const initial = params.voorstel
    ? voorstellen.map(v => ({ voorstelId: v.voorstelId, checked: v.voorstelId === params.voorstel }))
    : [];
  const voorstellenWithHuidige = [...voorstellen, { voorstelId: "999", naam: "Huidige situatie" }];

  return (
    <Formik
      initialValues={
        values || {
          MaandlastenoverzichtRadioButtons: "tabel",
          selectedVoorstellen: initial,
          "Overzicht vergelijking hypotheeklastenRadioButtons": "tabel"
        }
      }
      onSubmit={/* istanbul ignore next */ () => {}}
    >
      {({
        values,
        setFieldValue
      }: FormikProps<{
        MaandlastenoverzichtRadioButtons: string;
        selectedVoorstellen: { voorstelId: string; checked: boolean }[];
        "Overzicht vergelijking hypotheeklastenRadioButtons": string;
      }>) => {
        const checkedVoorstellen = values.selectedVoorstellen.filter(v => v.checked) || [];
        const isCollapsedWrap = isCollapsed || checkedVoorstellen.length > 1;

        return (
          <div className="snel-inzicht">
            <CardWrapper maxRowCount={2} flexType="flex-row" >
              <div
                className={classNames("snel-inzicht__column col-12", {
                  "col-md-1": isCollapsedWrap,
                  "col-md-5 col-xl-3": !isCollapsedWrap
                })}
              >
                <Card
                  title={!isCollapsedWrap ? "Productoverzicht" : ""}
                  titlePrepend={
                    <IconButton
                      title="Instellingen"
                      className="snel-inzicht__overzicht-button"
                      onClick={() => setCollapsed(!isCollapsedWrap)}
                    >
                      <Icon name="hamburger" iconSize="l" />
                    </IconButton>
                  }
                >
                  <ProductOverzicht isCollapsed={isCollapsedWrap} />
                </Card>
              </div>
              <div
                className={classNames("snel-inzicht__column snel-inzicht__no-padding col-12", {
                  "col-md-11": isCollapsedWrap,
                  "col-md-7 col-xl-9": !isCollapsedWrap
                })}
              >
                <div>
                  <div className="d-flex flex-wrap flex-row">
                    <Card
                      title="Overzicht vergelijking hypotheeklasten"
                      radioOptions={[
                        { label: "Tabel", value: "tabel" },
                        { label: "Grafiek", value: "grafiek" }
                      ]}
                      className="col-12"
                    >
                      {values["Overzicht vergelijking hypotheeklastenRadioButtons"] === "tabel" && (
                        <>
                          <div className="d-flex flex-row px-2">
                            <Label caption={"Vergelijk de hypotheeklasten van (maximaal 3 voorstellen):"} />
                            {voorstellenWithHuidige.map((voorstel, index) => (
                              <CheckBox
                                key={voorstel.voorstelId}
                                caption={`${voorstel.naam}`}
                                name={`selectedVoorstellen[${index}].checked`}
                                onChange={() => {
                                  setFieldValue(
                                    "selectedVoorstellen",
                                    voorstellenWithHuidige.map((v, i) => ({
                                      voorstelId: v.voorstelId,
                                      checked:
                                        i === index
                                          ? !values.selectedVoorstellen.find(sv => sv.voorstelId === v.voorstelId)
                                              ?.checked
                                          : values.selectedVoorstellen.find(sv => sv.voorstelId === v.voorstelId)
                                              ?.checked ?? false
                                    }))
                                  );
                                }}
                                disabled={!values.selectedVoorstellen[index]?.checked && checkedVoorstellen.length >= 3}
                              />
                            ))}
                          </div>

                          {!!(checkedVoorstellen.length > 1) && (
                            <div className="d-flex flex-wrap flex-row">
                              <div
                                className={classNames("d-flex flex-wrap", {
                                  "col-10": checkedVoorstellen.length === 2,
                                  "col-12": checkedVoorstellen.length !== 2
                                })}
                              >
                                {checkedVoorstellen.map((voorstel, index) => {
                                  const voorstelId = voorstellenWithHuidige.find(
                                    v => v.voorstelId === voorstel.voorstelId
                                  )?.voorstelId;
                                  return voorstelId ? (
                                    <div
                                      key={voorstelId}
                                      className={`col-${12 /
                                        checkedVoorstellen.length} p-0  d-flex flex-column justify-content-between`}
                                    >
                                      <HypotheekOverzichtFetcher index={index} voorstelId={voorstelId} />
                                    </div>
                                  ) : null;
                                })}
                              </div>
                            </div>
                          )}
                          <div className="d-flex flex-wrap flex-row">
                            <div
                              className={classNames("d-flex flex-wrap", {
                                "col-10": checkedVoorstellen.length === 2,
                                "col-12": checkedVoorstellen.length !== 2
                              })}
                            >
                              {checkedVoorstellen.map((voorstel, index) => {
                                const voorstelId = voorstellenWithHuidige.find(
                                  v => v.voorstelId === voorstel.voorstelId
                                )?.voorstelId;
                                return voorstelId ? (
                                  <div key={voorstelId} className={`col-${12 / checkedVoorstellen.length} p-0`}>
                                    <LastenOverzichtFetcher
                                      key={`${voorstelId}-${index}`}
                                      voorstelId={voorstelId}
                                      beperkteLastenTonen={checkedVoorstellen.length > 1}
                                      onDataLoaded={data => {
                                        handleDataLoaded(data, index === 0);
                                      }}
                                      jarenTonen={index === 0}
                                    />
                                  </div>
                                ) : null;
                              })}
                            </div>
                            {checkedVoorstellen.length === 2 && (
                              <div className="col-2 p-0">
                                <Table striped bordered hover responsive className="scenario-table">
                                  <thead>
                                    <tr>
                                      <th>Verschil in netto last</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {eersteLasten?.jaarGegevens?.map((jaarData, index) => (
                                      <tr key={jaarData.jaar}>
                                        <VerschilRij
                                          eerste={jaarData.nettoLast ?? null}
                                          tweede={tweedeLasten?.jaarGegevens?.[index]?.nettoLast ?? null}
                                        />
                                      </tr>
                                    ))}
                                    {!eersteLasten &&
                                      Array.from({ length: 30 }).map((_, index) => (
                                        <tr key={index}>
                                          <td>&nbsp;</td>
                                        </tr>
                                      ))}
                                    {eersteLasten?.totalen && tweedeLasten?.totalen && (
                                      <tr>
                                        <VerschilRij
                                          eerste={eersteLasten.totalen.nettoLast ?? null}
                                          tweede={tweedeLasten.totalen.nettoLast ?? null}
                                        />
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {values["Overzicht vergelijking hypotheeklastenRadioButtons"] === "grafiek" && (
                        <LastenOverzichtChart
                          onHoverCallback={lasten => {
                            setLasten(lasten);
                          }}
                          onClickCallback={lasten => {
                            setLasten(lasten);
                          }}
                          data={eersteLasten}
                        />
                      )}
                    </Card>
                    {values["Overzicht vergelijking hypotheeklastenRadioButtons"] === "grafiek" && lasten && (
                      <Card title={`Jaar ${lasten.jaar}`} className="col-12">
                        <LastenOverzichtCard lasten={lasten} />
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            </CardWrapper>
          </div>
        );
      }}
    </Formik>
  );
};
