import { SettingsType, useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { AppDataContext } from "../../navigation/appdata-context";
import { UiError, UiName } from "../types";
import { saveDataRequestInit as createSaveData } from "../utils/save-data";
import { useFetchData } from "./usefetchdata";

const noop = async (): Promise<null> => null;

const noUserResult = {
  loading: false,
  error: new Error("Missing AuthContext"),
  data: null,
  platformData: null,
  saveData: noop
};

const invalidDataResult = {
  loading: false,
  error: new Error("Invalid data"),
  data: null,
  platformData: null,
  saveData: noop
};

const invalidDataIdResult = {
  loading: false,
  error: new Error("Invalid dataId"),
  data: null,
  platformData: null,
  saveData: noop
};

type UseAdviesboxDataReturnType<DL, UI> = {
  loading: boolean;
  error: Error | null;
  platformData: DL | null | undefined;
  data: UI | null;
  saveData: (values: UI, preventReloadNavigation?: boolean) => Promise<UiError[] | null>;
  params: ReturnType<typeof useRequestInit>["params"];
  refetch: () => Promise<void>;
};

export const useAdviesboxData = <DL, P extends ReturnType<typeof useRequestInit>["params"], UI>(
  getUrl: (settings: SettingsType, params: P) => string,
  getDataId: (params: P) => string | undefined,
  mapDlToUi: (dataId: string, values: DL) => UI | null,
  mapUiToDl: (values: UI) => any,
  mapDlTarget: (target: string) => UiName | null,
  preventNavigationReload?: boolean
): UseAdviesboxDataReturnType<DL, UI> => {
  const loadingDone = useContext(ForceRerenderContext);
  const { reloadNavigation } = useContext(AppDataContext);
  const { requestInit, settings, params, user } = useRequestInit<P>();

  const url = useMemo(() => getUrl(settings, params), [getUrl, settings, params]);
  const dataId = useMemo(() => getDataId(params), [getDataId, params]);


  const getRequestInit = useCallback(() => requestInit, [requestInit]);

  const { data: platformData, loading, error, refetch } = useFetchData<DL>(url, getRequestInit, !!user);
  
  useEffect((): void => {
    if (!loading && platformData && loadingDone) {
      loadingDone();
    }
  }, [loading, platformData, loadingDone]);

  const saveData = useCallback(
    user
      ? createSaveData(url, requestInit, mapUiToDl, mapDlTarget, preventNavigationReload ? () => {} : reloadNavigation)
      : noop,
    [user, params.vestiging, url, settings.OcpApimSubscriptionKey, mapUiToDl, mapDlTarget, preventNavigationReload, reloadNavigation]
  );

  const data = useMemo(
    () => (dataId && platformData ? mapDlToUi(dataId, platformData) : null),
    [dataId, platformData, mapDlToUi]
  );

  if (!user) {
    return {
      ...noUserResult,
      refetch,
      params
    };
  }

  if (!dataId) {
    return {
      ...invalidDataIdResult,
      refetch,
      params
    };
  }

  if (typeof platformData === "string") {
    return {
      ...invalidDataResult,
      refetch,
      params
    };
  }

  const mappingError =
    platformData && !data && !error ? new Error(`Unable to map platform data for dataId: ${dataId}`) : null;

  return { 
    loading, 
    error: error ? new Error(error) : mappingError, 
    data, 
    platformData, 
    saveData, 
    refetch, 
    params 
  };
};