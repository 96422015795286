/* istanbul ignore file */
import * as Yup from "yup";
import { ReactElement } from "react";
import { AsyncSideEffect } from "use-formik-side-effects";
import { Draft } from "immer";
import { JaarMaandInputType } from "./generic-parts/jaar-maand/schema";
import { adresSchema } from "./generic-parts/adres/schema";
import { LocalDate } from "@js-joda/core";
import { JaarMaand } from "../.generated/tarieven/tarieventypes";

export type LabelValuePairs = LabelValuePair[];
export type LabelValuePair = {
  label: string;
  value: string;
  tooltip?: string | ReactElement;
  description?: string[];
};

export const JaNeeOptions = [
  { label: "Ja", value: "true" },
  { label: "Nee", value: "false" }
];

export enum Direction {
  Vertical,
  Horizontal
}

export enum GebruikWoning {
  Primair = "Primair",
  TweedeWoning = "TweedeWoning"
}

export enum AanvragerKeuze {
  Beiden = "Beiden",
  Aanvrager1 = "Aanvrager1",
  Aanvrager2 = "Aanvrager2"
}

export type MaatschappijLijstType = {
  maatschappijNaamLang: string;
  maatschappijCode: string;
};

export type UiName = {
  field: string | null;
  label: string | null;
};

export type UiError = UiName & {
  error: string;
};

export type AdresType = Yup.InferType<typeof adresSchema>;

type primitives = string | number | boolean | undefined | null;
type buildin = Date | RegExp;

export type RecursivePartial<T, X = never> = {
  [P in keyof T]?: T[P] extends primitives | buildin | BerekenInputType | JaarMaandInputType | LocalDate | X | object[]
  ? T[P]
  : RecursivePartial<T[P], X>;
};

export type PlatformFoutenState = {
  platformApiFouten?: UiError[] | null;
};

export type SetFieldTouchedType = (field: string, isTouched?: boolean) => void;
export type SideEffectsWithTouched<T> = (
  currentValues: T,
  previousValues: T,
  setFieldTouched?: SetFieldTouchedType
) => T | null;

export type SideEffectsExtra<T> = (currentValues: T, previousValues: T, extra: string) => T | null;

type RemoveArray<T> = T extends (infer U)[] ? U : T;
export type FieldMap<T, N = { field: string; label: string }> = {
  [P in keyof T]?: T[P] extends primitives | buildin
  ? N
  : FieldMap<RemoveArray<T[P]>> & { field?: string; label?: string };
};

export type BerekenInputType = {
  bedrag: number | null;
  berekenen: boolean | null;
  berekendBedrag: number | null;
};

export const berekenInputSchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null),
  berekenen: Yup.boolean()
    .nullable()
    .default(null),
  berekendBedrag: Yup.number()
    .nullable()
    .default(null)
});

export function mapBerekenInput(
  bedrag?: number | null,
  berekenen?: boolean | null,
  berekendBedrag: number | null = null
): BerekenInputType {
  return {
    bedrag: typeof bedrag === "number" ? bedrag : null,
    berekenen: typeof berekenen === "boolean" ? berekenen : null,
    berekendBedrag: berekendBedrag
  };
}

export function berekenInputToSideEffectResult<T>(
  input: BerekenInputType,
  field: string,
  value: number | null = null
): AsyncSideEffect<T>[] {
  const result = [];

  result.push({
    field: `${field}.berekendBedrag`,
    value: value
  });

  if (input.berekenen) {
    result.push({
      field: `${field}.bedrag`,
      value: value
    });
  }

  return result;
}

export function berekenInputToDraftSideEffectResult<T>(input: BerekenInputType, value: number | null = null): void {
  input.berekendBedrag = value;

  if (input.berekenen || input.berekenen === null) {
    input.bedrag = value;
  }
}

export const scenarioCardInputSchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null),
  percentage: Yup.number()
    .nullable()
    .default(null)
});
export const scenarioCardSchema = Yup.array(scenarioCardInputSchema).default([scenarioCardInputSchema.default()]);

export type scenarioCardInputType = Yup.InferType<typeof scenarioCardInputSchema>;

export type errorUi = {
  error: string | null;
};

export type DraftSideEffects<T> = (curr: Draft<T>, prev: Readonly<T>) => void;
export type DraftSideEffectsWithContext<T, X> = (curr: Draft<T>, prev: Readonly<T>, extra: X) => void;

export type SelectedProps = {
  selected: number;
};

export type JaarMaandNoNull = { [K in keyof JaarMaand]: Exclude<JaarMaand[K], null> };
export type Common<A, B> = {
  [P in keyof A & keyof B]: A[P] | B[P];
};
