import React from "react";
import { Table } from "react-bootstrap";
import { InkomensanalyseNbiOverzichtJaar } from "../../../.generated/rapportage/rapportagetypes";
import { bedragFormat, mapStringToLocalDate } from "adviesbox-shared";

const calculateYear = (startDatum: string | undefined, jaar: number): string => 
  mapStringToLocalDate(startDatum)?.plusYears(jaar - 1).year().toString() ?? '';

interface NBITableProps {
  nbi: InkomensanalyseNbiOverzichtJaar[];
  voetnoot?: string;
  startDatum?: string; 
}

const bedragFormatNull = (bedrag: number | null, decimals = 0): string =>
  bedrag !== null ? bedragFormat(bedrag, decimals) : "";

export const TableNettoBesteedbaarInkomen: React.FC<NBITableProps> = ({ nbi, voetnoot, startDatum }) => {
  if (!nbi || nbi.length === 0) return <></>;
  return (
    <Table responsive striped bordered hover className="mb-2">
      <thead>
        <tr>
          <th>Jaar</th>
          <th>Leeftijd aanvrager</th>
          <th>Leeftijd partner</th>
          <th>Inkomsten</th>
          <th>Uitgaven</th>
          <th>Netto besteedbaar</th>
          <th>Gewenst netto besteedbaar</th>
          <th>Tekort/overschot</th>
          <th>Bijzonderheid</th>
        </tr>
      </thead>
      <tbody>
        {nbi?.map((nbiI, index) => (
          <tr key={index}>
            <td>{calculateYear(startDatum, nbiI.jaar)}</td>
            <td className="text-center">{nbiI.leeftijd1}</td>
            <td className="text-center">{nbiI.leeftijd2}</td>
            <td className="text-right">{bedragFormatNull(!!nbiI.inkomenBedrag ? nbiI.inkomenBedrag /12 : 0 )}</td>
            <td className="text-right">{bedragFormatNull(!!nbiI.uitgavenBedrag ? nbiI.uitgavenBedrag /12 : 0)}</td>
            <td className="text-right">{bedragFormatNull(!!nbiI.nbiBedrag ? nbiI.nbiBedrag /12 : 0 )}</td>
            <td className="text-right">{bedragFormatNull(!!nbiI.gewenstNbiBedrag ? nbiI.gewenstNbiBedrag /12 : 0)}</td>
            <td className="text-right">{bedragFormatNull(!!nbiI.tekortOverschotBedrag ? nbiI.tekortOverschotBedrag / 12 : 0)}</td>
            <td>{(nbiI.bijzonderheden?.map(c=> c.bijzonderheid) || [""]).join(", ")}</td>
          </tr>
        ))}
      </tbody>
      {voetnoot && (
        <tfoot>
          <tr>
            <td colSpan={9} className="text-left font-italic">
              {voetnoot}
            </td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
};