/* istanbul ignore file */
import {
  AdviseurMenu,
  DossierMenu,
  ErrorPage,
  getAppLocation,
  Icon,
  InvalidAdviesboxResultErrorPage,
  MenuSeperator,
  ModalButton,
  NavigationBar,
  NotificatiesMenu,
  PageLoading,
  SettingsType,
  SupportMenu,
  SupportRemoteAssistanceModal,
  SupportTicketModalAjax,
  useRequestInit,
  useFeature,
  Notificaties2Menu
} from "adviesbox-shared";
import { Formik } from "formik";
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { Dropdown, Toast } from "react-bootstrap";
import { AppDataContext } from "../../../navigation/appdata-context";
import { RapportageDownloadenModal } from "../../../rapportage/rapportage-downloaden-modal";
import { RapportageSamenstellenModalAjax } from "../../../rapportage/rapportage-samenstellen-modal-ajax";
import { SnelInzichtModal } from "../../../rapportage/snel-inzicht-modal";
import DossierAanvraagFoutenContext from "../../dossier-aanvraag-fouten/dossier-aanvraag-fouten-context";
import { useAdviesboxData } from "../../hooks/use-adviesbox-data";
import { SaveFormContext } from "../../save-form/save-form-context";
import { isOkSubmitResult } from "../../utils/save-validation";
import useAbortableFetch from "use-abortable-fetch";
import { useParams } from "react-router-dom";
import UserDetailsContext from "../../user-details/user-details-context";
import { OrganisatiesOutput, VestigingenOutput } from "../../../.generated/licenties/licentiestypes";
import { RouteParams } from "adviesbox-shared/utils/types";
import OmschrijvingModal from "../dossier/omschrijving-modal";
import DossierStatusModal from "../dossier/dossier-status/dossier-status-modal";
import { mapDossierDl2Ui, mapDossierUi2Dl, OmschrijvingType } from "../dossier/schema-and-mappers";
import DossierKopierenModal from "../dossier/dossier-kopiëren-modal/dossier-kopiëren-modal";
import DossierbalkFoutenDropdown from "../dossier/dossierbalk-fouten-dropdown/dossierbalk-fouten-dropdown";
import { SituatieSoort } from "../../../producten-overzicht/infra/producten-overzicht-types";
import classes from "./TopNavigation.module.scss";
import "./TopNavigation.scss";
import { getAanvragerLeeftijd, getAanvragerNaam } from "../../utils/menu-info";
import { getDossierbalkTextResources } from "../dossier/dossierbalk-resources";
import WijzigAdviseurModal from "../dossier/dossier-wijzig-adviseur-modal/dossier-wijzig-adviseur-modal";

const TopNavigation = (props: { situatie: SituatieSoort }): ReactElement => {
  const featureNotificaties2 = useFeature("FeatureNotificaties2");
  const { menuInfo, reloadCount, reloadNavigation, activePage } = useContext(AppDataContext);
  const { aanvullendeGegevens } = useContext(DossierAanvraagFoutenContext);
  const { settings, params, user, requestInit } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { vestiging: vestigingId } = useParams<{ vestiging: string }>();
  const [openModalIsClicked, setOpenModalIsClicked] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [downloadingAbox, setDownloadingAbox] = useState<boolean>(false);
  const [downloadingHbx, setDownloadingHbx] = useState<boolean>(false);
  const { saveData: saveFormData, isSaving, saveResult } = useContext(SaveFormContext);
  const { userDetails } = useContext(UserDetailsContext);
  const [keepDossierOpen, setKeepDossierOpen] = useState(false);
  const [keepRapportageOpen, setKeepRapportageOpen] = useState(false);
  const [forceOpenModalOf, setForceOpenModalOf] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [activeDistinguisher, setActiveDistinguisher] = useState<string | null>(null);
  const [aanvrager1, setAanvrager1] = useState<{ name: string; age: number }>({ name: "Aanvrager", age: 0 });
  const [aanvrager2, setAanvrager2] = useState<{ name: string; age: number }>({ name: "Medeaanvrager", age: 0 });
  const [downloadError, setDownloadError] = useState<any | null>(null);
  const [show, setShow] = useState(false);

  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);
  const [showAanvullendeVragen, setShowAanvullendeVragen] = useState(false);

  const vestiging = useAbortableFetch<VestigingenOutput>(
    `${settings.licentiesOrigin}/Vestigingen/${vestigingId}`,
    requestInit
  );

  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);

  const createUrl = useCallback(
    (s: SettingsType, p: RouteParams): string =>
      `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Dossierbalk#${reloadCount}`,
    [reloadCount]
  );

  const { data, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapDossierDl2Ui,
    mapDossierUi2Dl,
    /*istanbul ignore next */ () => null
  );

  const actualDownloadAbox = useCallback(async (): Promise<void> => {
    const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Export/Abox`;
    await fetch(url, requestInit)
      .then(response => {
        if (response.status === 401) {
          setShow(true);
          return;
        }
        if (!response.ok) throw new Error("Download is mislukt"); /* istanbul ignore else */
        return response.blob();
      })
      .then(blob => {
        if (blob) {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = `${params.adviesdossier}.abox`;
          a.innerText = "Downloading...";
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      })
      .finally(() => {
        setDownloadingAbox(false);
      })
      .catch(error => {
        // Als het geen 401 error is dan error showen
        if (!show) setDownloadError(error); // eslint-disable-line
      });
  }, [settings, params, requestInit, setDownloadingAbox, setDownloadError, show, setShow]);

  const actualDownloadHbx = useCallback(async (): Promise<void> => {
    const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Export/Hbx`;
    let filename = `${params.adviesdossier}.hbx`;
    await fetch(url, requestInit)
      .then(response => {
        if (response.status === 401) {
          setShow(true);
          return;
        }
        if (!response.ok) throw new Error("Download is mislukt"); /* istanbul ignore next */
        const contentDispositionHeader = response.headers.get("Content-Disposition");
        filename = contentDispositionHeader?.split("filename=")[1].split(";")[0] ?? filename;
        return response.blob();
      })
      .then(blob => {
        if (blob) {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = filename;
          a.innerText = "Downloading...";
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      })
      .finally(() => {
        setDownloadingHbx(false);
      })
      .catch(error => {
        // Als het geen 401 error is dan error showen
        if (!show) setDownloadError(error); // eslint-disable-line
      });
  }, [settings, params, requestInit, setDownloadingHbx, setDownloadError, show, setShow]);

  const saveDataOnForm = (distinguisher: string, setKeepOpen: Dispatch<SetStateAction<boolean>> | null): void => {
    // als er al een distinguisher actief is, dan niets doen
    if (activeDistinguisher) return;
    // als de state loading is, of er wordt al opgeslagen, dan niets doen
    if (isLoading || isSaving) return;
    setIsLoading(true); // geef aan dat we aan het laden zijn
    setActiveDistinguisher(distinguisher); // leg de distinguisher vast
    setKeepOpen && setKeepOpen(true); // zorg ervoor dat het menu open blijft staan (indien van toepassing)
    saveFormData(); // ga de gegevens opslaan
  };

  const saveDataOnFormForDossier = (distinguisher: string): void => {
    saveDataOnForm(distinguisher, setKeepDossierOpen);
  };

  const downloadAbox = async (): Promise<void> => {
    if (downloadingAbox) return;
    setDownloadingAbox(true);
    saveDataOnFormForDossier("dossier-download-abox");
  };

  const downloadHbx = async (): Promise<void> => {
    if (downloadingHbx) return;
    setDownloadingHbx(true);
    saveDataOnFormForDossier("dossier-download-hbx");
  };
  const saveDataOnFormForRapportage = (distinguisher: string): void => {
    saveDataOnForm(distinguisher, setKeepRapportageOpen);
  };

  const cleanUpState = (): void => {
    setActiveDistinguisher(null);
    setForceOpenModalOf(undefined);
  };

  useEffect(() => {
    // als de state loading is, dan is die fase nu voorbij
    if (isLoading) {
      setIsLoading(false);
      return;
    }

    if (isSaving) return;

    const handleDownload = (downloadFn: () => Promise<void>): void => {
      downloadFn()
        .finally(() => cleanUpState())
        .catch(err => /* istanbul ignore next */ setDownloadError(err));
    };

    if (isOkSubmitResult(saveResult)) {
      if (activeDistinguisher === "dossier-download-abox") {
        handleDownload(actualDownloadAbox);
        return;
      }

      if (activeDistinguisher === "dossier-download-hbx") {
        handleDownload(actualDownloadHbx);
        return;
      }
      // de gegevens zijn opgeslagen, dus het modal moet getoond worden
      setForceOpenModalOf(activeDistinguisher ?? "");
    } else {
      if (activeDistinguisher === "dossier-download-abox") {
        handleDownload(actualDownloadAbox);
      } else if (activeDistinguisher === "dossier-download-hbx") {
        handleDownload(actualDownloadHbx);
      } else {
        // er staat een fout op het scherm, dus we zijn klaar met de afhandeling
        cleanUpState();
      }
    }
  }, [
    isLoading,
    setIsLoading,
    isSaving,
    saveResult,
    setForceOpenModalOf,
    activeDistinguisher,
    setActiveDistinguisher,
    actualDownloadAbox,
    actualDownloadHbx,
    setDownloadError
  ]);

  useEffect(() => {
    // als de active distinguisher leeggemaakt is, dan kan de force van de open modal af
    if (!activeDistinguisher) {
      setForceOpenModalOf(undefined);
    }
  }, [activeDistinguisher, setForceOpenModalOf]);

  useEffect(() => {
    if (activeDistinguisher == null) {
      // als de afhandeling klaar is, dan kunnen zaken opgeruimd worden
      setKeepRapportageOpen(false);
    }
  }, [activeDistinguisher, setKeepRapportageOpen]);

  useEffect(() => {
    /* istanbul ignore next */
    if (downloadError) {
      /* eslint-disable-line no-console */ /* istanbul ignore next */ console.log(downloadError);
      alert(`${getDossierbalkTextResources("downloadError")} ${downloadError}`);
      setDownloadError(null);
    }
  }, [downloadError, setDownloadError]);

  useEffect(() => {
    if (menuInfo?.aanvrager1) {
      setAanvrager1({
        name: getAanvragerNaam(menuInfo.aanvrager1),
        age: getAanvragerLeeftijd(menuInfo.aanvrager1.geboortedatum)
      });
    }
    if (menuInfo?.aanvrager2) {
      setAanvrager2({
        name: getAanvragerNaam(menuInfo.aanvrager2),
        age: getAanvragerLeeftijd(menuInfo.aanvrager2.geboortedatum)
      });
    }
  }, [menuInfo]);

  useEffect(() => {
    if (aanvullendeGegevens.modalFoutenArray.length && !openModalIsClicked) {
      setShowAanvullendeVragen(true);
    }
  }, [aanvullendeGegevens, openModalIsClicked]);

  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  if (!user) {
    return <ErrorPage error={new Error("user not logged in")} />;
  }

  if (vestiging.error) {
    return <ErrorPage error={vestiging.error} />;
  }

  if (organisatie.error) {
    return <ErrorPage error={organisatie.error} />;
  }

  if (vestiging.loading || !vestiging.data || organisatie.loading || !organisatie.data) {
    return <PageLoading />;
  }

  const vestigingNaam =
    vestiging.data && typeof vestiging.data !== "string" && vestiging.data.isValid && params.vestiging
      ? vestiging.data.vestigingen?.[params.vestiging].naam
      : "";

  const organisatieNaam =
    organisatie.data && typeof organisatie.data !== "string" && organisatieId
      ? organisatie.data.organisaties?.[organisatieId].naam
      : "";

  return (
    <NavigationBar title={activePage}>
      <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
        <Toast.Body>U heeft geen toestemming om het adviesdossier te exporteren</Toast.Body>
      </Toast>
      <DossierMenu
        aanvrager1={aanvrager1}
        aanvrager2={aanvrager2}
        showAanvrager2={menuInfo.aanvrager2 ? true : false}
      />
      <MenuSeperator />
      <div className="top-navigation__dossier">
        <Dropdown className="top-navigation__item">
          <Dropdown.Toggle variant="link" id="dropdown-basic-dossier" size="sm">
            <div>
              Dossier
              <span className="ml-2">
                <Icon name="chevron" alt="drop-down" iconSize="xs" />
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {data || keepDossierOpen ? (
              <Formik initialValues={{ data: data }} onSubmit={/*istanbul ignore next */ (): void => {}}>
                <>
                  <ModalButton
                    parent="data"
                    content={<span>Omschrijving advies</span>}
                    className={"dropdown-item"}
                    distinguisher="dossier-omschrijving"
                  >
                    <OmschrijvingModal data={data || ({} as OmschrijvingType)} onSave={saveData} />
                  </ModalButton>
                  <ModalButton
                    parent="data"
                    content={<span>Status</span>}
                    className={"dropdown-item"}
                    distinguisher="dossier-status"
                  >
                    <DossierStatusModal />
                  </ModalButton>
                  <ModalButton
                    parent="data"
                    content={<span>Wijzig adviseur</span>}
                    className={"dropdown-item"}
                    distinguisher="dossier-wijzig-adviseur"
                    closeModal={cleanUpState}
                    onOpenModal={(d: string) => saveDataOnFormForDossier(d)}
                    openModalIfSelected={forceOpenModalOf}
                  >
                    <WijzigAdviseurModal />
                  </ModalButton>
                  <ModalButton
                    parent="data"
                    content={<span>Dossier kopiëren</span>}
                    className={"dropdown-item"}
                    distinguisher="dossier-kopieren"
                    closeModal={cleanUpState}
                    onOpenModal={(d: string) => saveDataOnFormForDossier(d)}
                    openModalIfSelected={forceOpenModalOf}
                  >
                    <DossierKopierenModal
                      data={data || ({} as OmschrijvingType)}
                      settings={settings}
                      user={user}
                      adviesdossierId={params.adviesdossier}
                      vestigingId={params.vestiging}
                      inBemiddeling={!!menuInfo.bemiddelingDossierStatus}
                    />
                  </ModalButton>
                  <Dropdown.Item
                    id="dossier-exporteren-abox"
                    onClick={() => {
                      downloadAbox(); // eslint-disable-line
                    }}
                  >
                    {downloadingAbox ? "Export actief..." : "Dossier exporteren (ABOX)"}
                  </Dropdown.Item>
                  <Dropdown.Item
                    id="dossier-exporteren-hbx"
                    onClick={() => {
                      downloadHbx(); // eslint-disable-line
                    }}
                  >
                    {downloadingHbx ? "Export actief..." : "Dossier exporteren (HBX)"}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href={`${window.location.origin}/vestiging/${params.vestiging}/zoeken`}
                    id="dossier-sluiten"
                    data-testid="dossier-sluiten"
                  >
                    Sluiten
                  </Dropdown.Item>
                </>
              </Formik>
            ) : (
              <Dropdown.Item>Bezig met laden...</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="top-navigation__item">
          <Dropdown.Toggle variant="link" id="dropdown-basic-rapportage" size="sm">
            <div>
              Rapportage
              <span className="ml-2">
                <Icon name="chevron" alt="drop-down" iconSize="xs" />
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={classes.rapportage_dropdown_menu}>
            {data || keepRapportageOpen ? (
              <Formik initialValues={{ data: { data } }} onSubmit={/*istanbul ignore next */ (): void => {}}>
                <>
                  <ModalButton
                    parent="data"
                    content={<span>Overzicht downloaden</span>}
                    className={"dropdown-item"}
                    distinguisher="overzicht-downloaden"
                    disabled={keepRapportageOpen}
                    closeModal={cleanUpState}
                    onOpenModal={(d: string) => saveDataOnFormForRapportage(d)}
                    openModalIfSelected={forceOpenModalOf}
                  >
                    <RapportageDownloadenModal />
                  </ModalButton>
                  <ModalButton
                    size={"lg"}
                    parent="data"
                    content={<span>Rapport samenstellen</span>}
                    className={"dropdown-item"}
                    distinguisher="rapport-samenstellen"
                    disabled={keepRapportageOpen}
                    closeModal={cleanUpState}
                    onOpenModal={(d: string) => saveDataOnFormForRapportage(d)}
                    openModalIfSelected={forceOpenModalOf}
                  >
                    <RapportageSamenstellenModalAjax />
                  </ModalButton>
                  <ModalButton
                    size={"lg"}
                    parent="data"
                    content={<span>Snel inzicht</span>}
                    className={"dropdown-item"}
                    distinguisher="snel-inzicht"
                    disabled={keepRapportageOpen}
                    closeModal={cleanUpState}
                    onOpenModal={(d: string) => saveDataOnFormForRapportage(d)}
                    openModalIfSelected={forceOpenModalOf}
                  >
                    <SnelInzichtModal />
                  </ModalButton>
                </>
              </Formik>
            ) : (
              <Dropdown.Item>Bezig met laden...</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {!!aanvullendeGegevens.modalFoutenArray.length && (
          <Dropdown
            className="top-navigation__item"
            show={showAanvullendeVragen}
            onClick={() => {
              setShowAanvullendeVragen(!openModalIsClicked && !showAanvullendeVragen);
            }}
          >
            <Dropdown.Toggle variant="link" id="dropdown-basic-fouten" className="nav-link" size="sm">
              <div className="dossier">
                <span className={classes.aantal_fouten}>
                  <span>{aanvullendeGegevens?.modalFoutenArray.length}</span>
                </span>
                Fouten
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DossierbalkFoutenDropdown
                modalFoutenArray={aanvullendeGegevens?.modalFoutenArray}
                vestigingId={aanvullendeGegevens.vestigingId}
                adviesdossierId={aanvullendeGegevens.adviesdossierId}
                analyseId={aanvullendeGegevens.analyseId}
                dekkingId={aanvullendeGegevens.dekkingId}
                ontvangerNaam={aanvullendeGegevens.ontvangerNaam}
                ontvangerNrHdn={aanvullendeGegevens.ontvangerNrHdn}
                softwareKoppelingId={aanvullendeGegevens.softwareKoppelingId}
                aanvullendeAntwoorden={aanvullendeGegevens.aanvullendeAntwoorden}
                nieuweVersie={aanvullendeGegevens.nieuweVersie}
                externeReferentie={aanvullendeGegevens.externeReferentie}
                reloadNavigation={reloadNavigation}
                openModalIsClicked={openModalIsClicked}
                setOpenModalIsClicked={setOpenModalIsClicked}
                showModal={showModal}
                setShowModal={setShowModal}
                maatschappijCode={aanvullendeGegevens.maatschappijCode}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}
        <>
          <button
            type="button"
            className={"no-btn ml-1 p-1 btn-link"}
            onClick={() => {
              const url = `${getAppLocation(window.location.origin, "ADV", "DOC", settings.baseUrls)}/vestiging/${
                params.vestiging
              }/adviesdossier/${params.adviesdossier}/documentbeheer`;
              window.open(url, "_blank");
            }}
            id="naar-DOC"
            data-testid="naar-DOC"
          >
            <Icon name="documenten" multiColor={true} />
          </button>
        </>
      </div>
      <MenuSeperator />
      <SupportMenu
        showMeldingMaken={() => setShowMeldingMaken(true)}
        showRemoteAssistance={() => setShowRemoteAssistance(true)}
        classes={{
          iconblack: classes.iconblack,
          iconpadding: classes.iconpadding
        }}
      />

      {!featureNotificaties2 && <NotificatiesMenu />}
      {featureNotificaties2 && <Notificaties2Menu />}
      <AdviseurMenu vestigingNaam={vestigingNaam} organisatieNaam={organisatieNaam} />
      {showMeldingmaken && user && (
        <SupportTicketModalAjax
          adviesdossierId={params.adviesdossier ?? null}
          user={user}
          closeModal={() => setShowMeldingMaken(false)}
          showInvalidResultErrorPage={() => setShowInvalidAdviesboxResultErrorPage(true)}
          vestigingId={params.vestiging}
        />
      )}
      {showRemoteAssistance && <SupportRemoteAssistanceModal closeModal={() => setShowRemoteAssistance(false)} />}
    </NavigationBar>
  );
};
TopNavigation.displayName = "TopNavigation";

export default TopNavigation;
