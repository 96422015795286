import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import {
  SoortCorrectieLastOptions,
  SoortCorrectieFiscaalOptions,
  BetalingsTermijnType
} from "../../.generated/forms/formstypes";

import BedragSpecificatieModal from "./bedrag-specificatie-modal";
import { LabeledCurrencyInput, Icon, LabeledSelectInput, LabeledBevestigingInput } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { OverigePostenType, BedragSpecificatieModalType } from "../infra/overige-posten-types";
import { getBetalingsTermijnLabel } from "../infra/helpers";

type Props = {
  selected: number;
};

const OverigePostenGegevens = ({
  selected,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: Props & {
  formik: FormikContextType<OverigePostenType>;
}): ReactElement => {
  const bewaarBedragScenario = React.useCallback(
    (result: BedragSpecificatieModalType) => {
      setFieldValue(`producten[${selected}].overigePostenGegevens.bedragScenario`, result);
      if (result.scenario.length) {
        setFieldValue(`producten[${selected}].overigePostenGegevens.bedrag`, result.scenario[0].bedrag);
      }
    },
    [setFieldValue, selected]
  );
  const selectedProduct = producten[selected];
  const {
    correctie,
    overigePostenGegevens,
    product: { looptijd }
  } = selectedProduct;

  return (
    <>
      <LabeledCurrencyInput
        caption="Bedrag"
        name={`producten[${selected}].overigePostenGegevens.bedrag`}
        appendChildren={
          overigePostenGegevens.termijn !== BetalingsTermijnType.Eenmalig && (
            <ModalButton
              parent={`producten[${selected}].overigePostenGegevens.bedrag`}
              size="lg"
              resetSize={true}
              aria-label="Scenario invullen knop"
              content={<Icon name="scenario" alt="Scenario invullen" />}
            >
              <BedragSpecificatieModal
                data={overigePostenGegevens.bedragScenario}
                entries={(looptijd.jaren ?? 0) + (looptijd.maanden ? 1 : 0)}
                startBedrag={overigePostenGegevens.bedrag}
                onSave={bewaarBedragScenario}
                firstDisabled={!!overigePostenGegevens.bedrag}
              />
            </ModalButton>
          )
        }
      />

      <LabeledSelectInput
        caption="Termijn"
        name={`producten[${selected}].overigePostenGegevens.termijn`}
        options={[
          {
            label: getBetalingsTermijnLabel(BetalingsTermijnType.Maand),
            value: BetalingsTermijnType.Maand
          },
          {
            label: getBetalingsTermijnLabel(BetalingsTermijnType.Kwartaal),
            value: BetalingsTermijnType.Kwartaal
          },
          {
            label: getBetalingsTermijnLabel(BetalingsTermijnType.HalfJaar),
            value: BetalingsTermijnType.HalfJaar
          },
          {
            label: getBetalingsTermijnLabel(BetalingsTermijnType.Jaar),
            value: BetalingsTermijnType.Jaar
          },
          {
            label: getBetalingsTermijnLabel(BetalingsTermijnType.Eenmalig),
            value: BetalingsTermijnType.Eenmalig
          }
        ]}
      />

      {correctie.correctieLast === SoortCorrectieLastOptions.Bijdrage &&
        correctie.correctieFiscaal === SoortCorrectieFiscaalOptions.Bijtelling && (
          <LabeledBevestigingInput
            caption="Inkomen uit arbeid"
            name={`producten[${selected}].overigePostenGegevens.inkomenUitArbeid`}
          />
        )}

      {overigePostenGegevens.inkomenUitArbeid && (
        <LabeledBevestigingInput
          caption="Onderdeel dagloon"
          name={`producten[${selected}].overigePostenGegevens.onderdeelDagloon`}
        />
      )}
    </>
  );
};

export default connect<Props, OverigePostenType>(OverigePostenGegevens);
