import { LabeledAfbeelding, LabeledSelectInput, LabeledText, SettingsContext, useRequestInit, LoadingSpinner } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useContext, useEffect, useCallback } from "react";
import { BankgarantieinstellingPartij } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { BankgarantieState, InstellingCodes } from "../infra/bankgarantie-schema";
import { useFetchData } from "../../shared/hooks/usefetchdata";
import { NwbArrangementenOutput, Financieringsoort } from "../../.generated/forms/formstypes";

type BankgarantieProductProps = { productInstellingOptions: BankgarantieinstellingPartij[] };


const getProductId = (soortFinanciering: Financieringsoort, starterslening:boolean):number => {
if (soortFinanciering === Financieringsoort.AankoopBestaandeBouw && starterslening) return 2; 

if (soortFinanciering === Financieringsoort.AankoopNieuwbouw) return 3;
if (soortFinanciering === Financieringsoort.AankoopBestaandeBouw) return 1;

// fallback bestaande bouw
return 1;

}

const Product = ({
  productInstellingOptions: instellingOptions,
  formik: {
    setFieldValue,
    values
  }
}: BankgarantieProductProps & {
  formik: FormikContextType<BankgarantieState>;
}): ReactElement => {
  const { productenOrigin, OcpApimSubscriptionKey } = useContext(SettingsContext);
const {product, pand} = values;
  const { requestInit, settings } = useRequestInit();

  /* istanbul ignore next */
  const getRequestInit = useCallback(() => requestInit, [requestInit]);

  const productInstellingOptions: any[] = [];

  instellingOptions
    ?.filter(optie => optie.code !== InstellingCodes.Onafhankelijk)
    .forEach(optie => {
      /* istanbul ignore else */
      if (optie.actief) productInstellingOptions.push({ label: optie.partijnaam, value: optie.code });
    });

  productInstellingOptions
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    .unshift({ label: InstellingCodes.Geen, value: InstellingCodes.Geen });

  const productId = getProductId(values.soortFinanciering || Financieringsoort.AankoopBestaandeBouw,pand.starterslening )

  const { data: nwbData, loading: nwbLoading, error: nwbError } = useFetchData<NwbArrangementenOutput>(
    `${settings.klantdossiersFormsOrigin}/GetArrangementen?productId=${productId}`,
    getRequestInit,
    product.instelling === InstellingCodes.NWB
  );

  const handleInstellingSelectie = (event: { target: { value: string | null } }): void => {
    if (event.target.value === InstellingCodes.Geen) {
      setFieldValue("product.instelling", InstellingCodes.Geen);
      setFieldValue("product.agentnummer", null);
    } else {
      const geselecteerdeOptie = instellingOptions?.find(e => e.code === event.target.value);

      setFieldValue("product.instelling", geselecteerdeOptie?.code);
      setFieldValue("product.agentnummer", geselecteerdeOptie?.agentnummer);
    }
  };

  // agentnummer wordt hier gevuld om mee te sturen bij de Aanvraag verzenden button
  useEffect(() => {
    if (!product.agentnummer && product.instelling !== InstellingCodes.Geen) {
      setFieldValue("product.agentnummer", instellingOptions?.find(v => v.code === product.instelling)?.agentnummer);
    }
  }, [instellingOptions, product.agentnummer, product.instelling, setFieldValue]);

  return (
    <>
      {productInstellingOptions.length > 1 ? (
        <LabeledSelectInput
          caption="Instellingen"
          name={`product.instelling`}
          options={productInstellingOptions}
          onChange={handleInstellingSelectie}
        />
      ) : /* istanbul ignore next */ (
        <LabeledText
          caption="Instellingen"
          fieldSize="no-size"
          value={ /* istanbul ignore next */ instellingOptions?.find(v => v.code === product.instelling)?.partijnaam ?? "Geen"}
        />
      )}

      {product.instelling !== InstellingCodes.Geen && (
        <div>
          <LabeledAfbeelding
            caption="Product logo"
            name={`product.instelling`}
            location={`${productenOrigin}/Logos/Logo/${product.instelling}?subscription-key=${OcpApimSubscriptionKey}`}
          />
        </div>
      )}

      {product.instelling === InstellingCodes.NWB && 
          <LabeledSelectInput
            caption="Aanvraag via"
            name={`product.aanvraagVia`}
            disabled={(nwbError!==null || nwbLoading || !nwbData?.arrangementen?.length) }
            options={nwbData?.arrangementen?.map(gv => ({ label: gv.omschrijving || "", value: gv.id || "" })) ?? []}
            appendChildren={nwbLoading && /* istanbul ignore next */ <LoadingSpinner size="S"/>}
          />
      }     
    </>
  );
};

export default connect<BankgarantieProductProps, BankgarantieState>(Product);
