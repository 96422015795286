import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { AdresInput } from "../../shared/components/adres/adres-input";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { LabeledTextInput, SyncMultipleHeightProps, SyncHeight } from "adviesbox-shared";
import { PersonaliaState, AdresSyncHeightIndex } from "../infra/personalia-schema";

const Aanvrager2Adres = ({
  formik: {
    values: { medeAanvragerOpties }
  },
  sourceRef,
  targetRef
}: {
  formik: FormikContextType<PersonaliaState>;
} & SyncMultipleHeightProps): ReactElement => {
  const hasAfwijkendAdres = medeAanvragerOpties.afwijkendAdres;
  return (
    <SyncHeight
      sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.All]}
      targetRef={targetRef && targetRef[AdresSyncHeightIndex.All]}
    >
      <AdresInput
        name={"aanvrager2Adres.adres"}
        plaatsCaption="Woonplaats"
        landCaption="Woonland"
        fieldSize="l"
        readonly={!hasAfwijkendAdres}
        sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.Adres]}
        targetRef={targetRef && targetRef[AdresSyncHeightIndex.Adres]}
      />
      <LabeledHdnKeuzelijst
        caption="Geboorteland"
        name="aanvrager2Adres.geboortelandCode"
        berichtSoortType="AX"
        keuzelijst="LandType"
      />
      <LabeledHdnKeuzelijst
        caption="Nationaliteit"
        name="aanvrager2Adres.nationaliteitCode"
        berichtSoortType="AX"
        keuzelijst="LandType"
      />
      <LabeledHdnKeuzelijst
        caption="Fiscale woonstaat"
        name="aanvrager2Adres.fiscaleWoonstaatCode"
        berichtSoortType="AX"
        keuzelijst="LandType"
      />
      <SyncHeight
        sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.TelefoonnummerPrive]}
        targetRef={targetRef && targetRef[AdresSyncHeightIndex.TelefoonnummerPrive]}
      >
        <LabeledTextInput
          caption="Telefoonnummer privé"
          name="aanvrager2Adres.telefoonnummerPrive"
          autoComplete="new-password"
        />
      </SyncHeight>
      <SyncHeight
        sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.TelefoonnummerWerk]}
        targetRef={targetRef && targetRef[AdresSyncHeightIndex.TelefoonnummerWerk]}
      >
        <LabeledTextInput caption="Telefoonnummer werk" name="aanvrager2Adres.telefoonnummerWerk" />
      </SyncHeight>
      <SyncHeight
        sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.TelefoonnummerMobiel]}
        targetRef={targetRef && targetRef[AdresSyncHeightIndex.TelefoonnummerMobiel]}
      >
        <LabeledTextInput caption="Telefoonnummer mobiel" name="aanvrager2Adres.telefoonnummerMobiel" />
      </SyncHeight>
      <LabeledTextInput autoComplete="new-password" caption="E-mailadres" name="aanvrager2Adres.emailAdres" />
    </SyncHeight>
  );
};

export default connect<SyncMultipleHeightProps, PersonaliaState>(Aanvrager2Adres);
