import React, { ReactElement, useState } from "react";
import { FormikProps, Form } from "formik";
import { PageLoading, DataGrid, AdviesBoxColumn, Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import OverigePostDetails from "./overige-post-details/overige-post-details";
import { SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import { bepaalOverigePostenKolommen } from "./overige-posten-kolommen";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { WithSaveData } from "../shared/utils/save-data";

import { SaveButton } from "../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { OverigePostenType, OverigePostType } from "./infra/overige-posten-types";
import { overigePostSchema, overigePostenSchema } from "./infra/overige-posten-schema";
import { getNewRowValues } from "./infra/helpers";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { useFeature } from "adviesbox-shared";
import { Dashboard } from "../dashboard/dashboard";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

type OverigePostenProps = OverigePostenType & { situatie: SituatieSoort };

const OverigePosten = (
  props: OverigePostenProps & FormikProps<OverigePostenType> & WithSaveData<OverigePostenType>
): ReactElement => {
  const { isSubmitting, values, situatie } = props;
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { producten, aanvrager1, aanvrager2, ingangsdatumVoorstel } = values;
  const selectedProduct = producten && producten[selected];
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  const overigePostenColumns: AdviesBoxColumn[] = React.useMemo(
    () => bepaalOverigePostenKolommen(situatie, aanvrager1, aanvrager2),
    [situatie, aanvrager1, aanvrager2]
  );

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {isSubmitting && <PageLoading />}

            <CardWrapper className="px-3">
              <div className="text-container">
                <div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />

            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50" title="Overig">
                <DataGrid
                  masterDetail
                  rowCaption="Overige post"
                  columns={overigePostenColumns}
                  rowSelected={selectedState}
                  name="producten"
                  validationSchema={overigePostSchema}
                  getNewRowValues={(): OverigePostType => getNewRowValues(aanvrager1, ingangsdatumVoorstel, situatie)}
                  additionalButton={
                    situatie === "huidig" &&
                    selectedProduct &&
                    selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null ? (
                      <ProductWijzigingenMeenemenButton
                        name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
                      />
                    ) : (
                      undefined
                    )
                  }
                />
              </Card>
            </CardWrapper>

            {selectedProduct && <OverigePostDetails selected={selected} situatie={situatie} />}

            <DevDebug />
          </div>
          { !featureNewDashboard && (
            <Dashboard situatie={situatie} saveData={props.saveData} formikParent={props} />
          )}
        </div>
      </Form>
    </FormFirstFocus>
  );
};

OverigePosten.displayName = "OverigePosten";

export default withAdviesboxFormik<OverigePostenProps & WithSaveData<OverigePostenType>, OverigePostenType>({
  // Transform outer props into form values
  mapPropsToValues: (e: OverigePostenProps): OverigePostenType => ({
    producten: e.producten,
    aanvrager1: e.aanvrager1,
    aanvrager2: e.aanvrager2,
    ingangsdatumVoorstel: e.ingangsdatumVoorstel
  }),
  validationSchema: overigePostenSchema
})(withAITracking(insightsReactPlugin, OverigePosten));
