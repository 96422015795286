import { EwsInput, EwsOutput, Financieringsoort, GebruikPandSoort } from "../../.generated/forms/formstypes";
import {
  FinancieringsbehoefteExtInput,
  Financieringsoort as HypotheekFinancieringsoort,
  FinancieringsbehoefteOutput,
  SoortWaardeberekeningOptions
} from "../../.generated/hypotheek/hypotheektypes";
import { EigenwoningforfaitInput, OverdrachtsbelastingPercentageInput } from "../../.generated/tarieven/tarieventypes";
import { berekenInputToDraftSideEffectResult } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { optellen } from "../../shared/utils/currency";
import { assertValue } from "../../shared/utils/helpers";
import {
  bepaalWaardeBerekeningParameters,
  bepaalWaardeBerekeningParametersAlleenBedrag,
  bepaalWaardeBerekeningParametersOpBasisTarieven,
  bepaalWaardeBerekeningSoort,
  berekenBeschikbaarEigenGeld,
  getKoopsomBedrag,
  getTotaalFinancieringsbehoefte,
  overigeKostenBedrag
} from "./financieringsbehoefte-berekenen-helper";
import { financieringsbehoefteSchema } from "./financieringsbehoefte-schema";
import { FinancieringsbehoefteState } from "./financieringsbehoefte-types";
import { mapDraftEwsResultaatDlToUi } from "./map-eigenwoningschuld";
import { hasValue } from "../../shared/utils/helpers";
import { mapNhgWarning } from "./map-financieringsbehoefte-helper";

export const mapGebruikPand = (gebruik: GebruikPandSoort): GebruikPandSoort => {
  switch (gebruik) {
    case "PrimaireWoning":
      return GebruikPandSoort.PrimaireWoning;
    case "TweedeWoning":
      return GebruikPandSoort.TweedeWoning;
    case "GeheelVerhuurd":
      return GebruikPandSoort.GeheelVerhuurd;
    case "DeelsVerhuurd":
      return GebruikPandSoort.DeelsVerhuurd;
    default:
    case "Geen":
      return GebruikPandSoort.Geen;
  }
};

export const berekenGewensteHypotheek = (v: FinancieringsbehoefteState): number => {
  const totaalFinancieringsBehoefte = getTotaalFinancieringsbehoefte(v) ?? 0;
  const inbrengEigenGeld = v.financieringsopzet.inbrengEigenGeld ?? 0;
  const overbruggingskrediet = v.financieringsopzet.overbruggingskredietModal.meenemenInOverbruggingskrediet ?? 0;
  const overigeFinancieringen =
    optellen([
      v.financieringsopzet.andereFinancieringenModal.doorlopendeHypotheken,
      v.financieringsopzet.andereFinancieringenModal.kortlopendeLeningKrediet,
      v.financieringsopzet.andereFinancieringenModal.startersleningBedrag,
      v.financieringsopzet.andereFinancieringenModal.overigeFinanciering1Bedrag,
      v.financieringsopzet.andereFinancieringenModal.overigeFinanciering2Bedrag
    ]) ?? 0;
  const resultaat = totaalFinancieringsBehoefte - inbrengEigenGeld - overigeFinancieringen - overbruggingskrediet;

  return v.financieringsopzet.gewensteHypotheekBerekenen ? resultaat : v.financieringsopzet.gewensteHypotheek ?? 0;
};

export const mapOverdrachtsbelastingPercentageInput = createMapToDl(financieringsbehoefteSchema).to<
  OverdrachtsbelastingPercentageInput
>({
  aanvrager1: v => ({
    eigendomPercentage: v.onderpand.eigendomsverhoudingAanvrager,
    geboortedatum: v.onderpand.geboorteDatumAanvrager?.toString() ?? null
  }),
  aanvrager2: v => ({
    eigendomPercentage: v.onderpand.eigendomsverhoudingPartner,
    geboortedatum: v.onderpand.geboorteDatumPartner?.toString() ?? null
  }),
  gebruikPand: v => mapGebruikPand(v.onderpand.gebruik),
  koopsomBedrag: v => v.financieringsopzet.koopsom.koopsomBedrag,
  passeerDatum: v => v.financieringsopzet.overbruggingskredietModal.ingangsdatum?.toString() ?? null,
  tarievenNorm: v => null // niet doorgeven, we willen op finbehoefte altijd rekenen met actuele meest recent beschikbare tarieven.
});

export const mapFinancieringsbehoefteExtInput = createMapToDl(financieringsbehoefteSchema)
  .with<{ overdrachtsbelastingPercentage: number | null }>()
  .to<FinancieringsbehoefteExtInput>({
    advieskosten: v => v.financieringsopzet.advieskosten,
    afTeLossenHypotheken: v => v.financieringsopzet.afTeLossenBestaandeHypotheken.bedrag,
    afkoopErfpacht: v => v.financieringsopzet.afkoopErfpachtModal.afkoopBedrag ?? null,
    bankgarantie: v =>
      bepaalWaardeBerekeningParametersAlleenBedrag(v.financieringsopzet.bankgarantieModal.kostenBankgarantie),
    beschikbaarEigenGeld: v => berekenBeschikbaarEigenGeld(v.financieringsopzet),
    bevoorschotting: () => ({
      soortWaardeberekening: SoortWaardeberekeningOptions.Default,
      bedrag: null,
      percentage: null
    }),
    boeterente: v => v.financieringsopzet.boeterenteBedrag,
    bouwrenteNaAankoop: v => v.financieringsopzet.bouwrentePeriodeNaAankoop ?? 0,
    geheelPerceel: () => null,
    bouwrenteVoorAankoop: v => v.financieringsopzet.bouwrentePeriodeVoorAankoop ?? 0,
    doorlopendeHypotheken: v => v.financieringsopzet.andereFinancieringenModal.doorlopendeHypotheken,
    eigenWoningSchuld: v => v.financieringsopzet.eigenwoningschuldModal.eigenwoningschuld.bedrag,
    gewensteHypotheek: v =>
      v.financieringsopzet.gewensteHypotheekBerekenen ? null : v.financieringsopzet.gewensteHypotheek || 0,
    hypotheekOpPandBedrag: () => null,
    hypotheekakteKosten: v =>
      bepaalWaardeBerekeningParametersOpBasisTarieven(
        v.financieringsopzet.hypotheekakte.berekenen,
        v.financieringsopzet.hypotheekakte.bedrag,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.hypotheekakteVaststellingWaarde,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.hypotheekakteVastBedrag
      ),
    koopsom: v =>
      v.financieringsopzet.koopsom.grondReedsInBezit
        ? (v.financieringsopzet.koopsom.koopsomBedrag || 0) - (v.financieringsopzet.koopsom.kostenGrondBedrag || 0)
        : v.financieringsopzet.koopsom.koopsomBedrag,
    koopsomRoerendeZaken: v => v.financieringsopzet.koopsomRoerendeZaken,
    kortlopendeKredieten: v =>
      optellen([
        v.financieringsopzet.andereFinancieringenModal.kortlopendeLeningKrediet,
        v.financieringsopzet.andereFinancieringenModal.overigeFinanciering1Bedrag,
        v.financieringsopzet.andereFinancieringenModal.overigeFinanciering2Bedrag
      ]),
    kostenOmzetting: v =>
      v.financieringsopzet.kostenOmzettingBedrag !== null && v.financieringsopzet.kostenOmzettingBedrag > 0
        ? {
            soortWaardeberekening: SoortWaardeberekeningOptions.VastBedrag,
            bedrag: v.financieringsopzet.kostenOmzettingBedrag,
            percentage: null
          }
        : null,

    leveringsakte: v =>
      bepaalWaardeBerekeningParametersOpBasisTarieven(
        v.financieringsopzet.leveringsakte.berekenen,
        v.financieringsopzet.leveringsakte.bedrag,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.leveringsakteVaststellingWaarde,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.leveringsakteVastBedrag
      ),
    ltvHypotheekBedrag: v => null, // dit veld in geen geval meegeven: (bestaand/nieuwbouw/hyp wijzigen), zie #79889
    makelaarscourtage: v =>
      bepaalWaardeBerekeningParameters(
        v.financieringsopzet.makelaarscourtage.berekenen,
        v.financieringsopzet.makelaarscourtage.bedrag,
        null
      ),
    marktwaardeNaVerbouwing: v =>
      v.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
        ? null
        : v.onderpand.marktwaardeNaVerbouwing,
    marktwaardeVoorVerbouwing: v => v.onderpand.marktwaardeVoorVerbouwing,
    nhgKosten: v =>
      bepaalWaardeBerekeningParameters(
        v.financieringsopzet.nationaleHypotheekGarantieBerekenen,
        v.financieringsopzet.nationaleHypotheekGarantie,
        null
      ),
    overbruggingskrediet: v => v.financieringsopzet.overbruggingskredietModal.meenemenInOverbruggingskrediet,
    overdrachtsbelasting: (v, c) =>
      v.financiering.kostenKoper || !isNieuwbouwBestaandebouwOfOmzetting(v.financiering.soortFinanciering)
        ? {
            soortWaardeberekening:
              v.financieringsopzet.overdrachtsbelasting.berekenen ||
              !hasValue(v.financieringsopzet.overdrachtsbelasting.berekenen)
                ? SoortWaardeberekeningOptions.Percentage
                : SoortWaardeberekeningOptions.VastBedrag,
            bedrag:
              hasValue(v.financieringsopzet.overdrachtsbelasting.berekenen) &&
              !v.financieringsopzet.overdrachtsbelasting.berekenen
                ? v.financieringsopzet.overdrachtsbelasting.bedrag
                : /* istanbul ignore next */ null,
            percentage: c.overdrachtsbelastingPercentage
          }
        : {
            bedrag: 0,
            soortWaardeberekening: SoortWaardeberekeningOptions.VastBedrag,
            percentage: c.overdrachtsbelastingPercentage
          },
    overigeAfTeLossen: v => v.financieringsopzet.afTeLossenoverigeLeningen,
    overigeKosten: v =>
      optellen([
        v.financieringsopzet.overigeKostenInHypotheekModal.lastenOverbruggingskrediet,
        v.financieringsopzet.overigeKostenInHypotheekModal.gewenstConsumptiefBedrag,
        v.financieringsopzet.overigeKostenInHypotheekModal.royementskostenBedrag,
        v.financieringsopzet.overigeKostenInHypotheekModal.overigeKostenSpecificaties.reduce(
          (prev, next): number => prev + (next.bedrag || 0),
          0
        )
      ]),
    renteverliesTijdensBouw: v => v.financieringsopzet.renteverliesTijdensBouw,
    soortFinancieringsbehoefte: v => (HypotheekFinancieringsoort as any)[v.financiering.soortFinanciering],
    specificatieAdviesBemidddelingskosten: v => ({
      advieskosten: v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.advieskosten,
      bemiddelingskostenGeldlening:
        v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.bemiddelingsvergoedingGeldlening,
      overigeBemiddelingskosten:
        v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.overigeBemiddelingsvergoeding,
      dossiervergoedingGeldlening:
        v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.dossiervergoedingGeldlening,
      afsluitkostenSvnStarterslening:
        v.financieringsopzet.overigeFinancieringskostenModal.afsluitkostenSVnStarterslening,
      afsluitkostenOverbruggingskrediet: 0,
      bereidstellingskosten: v.financieringsopzet.overigeFinancieringskostenModal.bereidstellingskosten
    }),
    specificatieVerbouwingskostenMeerwerk: v => ({
      kostenNietWaardevermeerderend: v.financieringsopzet.verbouwingVerbeteringModal.waarvanNietWaardevermeerderend,
      kostenEnergiebesparend:
        (v.financieringsopzet.verbouwingVerbeteringModal.waarvanEnergiebesparendeVoorzieningen ?? 0) +
        (v.financieringsopzet.verbouwingVerbeteringModal.waarvanEnergiebesparendeBudget ?? 0),
      kostenAchterstalligOnderhoud: v.financieringsopzet.verbouwingVerbeteringModal.waarvanAchterstalligOnderhoud,
      kostenWaardevermeerderend: null
    }),
    tarievenNorm: v => v.hypotheekNorm,
    taxatiekosten: v =>
      bepaalWaardeBerekeningParametersOpBasisTarieven(
        v.financieringsopzet.taxatie.berekenen,
        v.financieringsopzet.taxatie.bedrag,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.taxatiekostenVaststellingWaarde,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.taxatiekostenVastBedrag
      ),
    totaleRestschuld: v =>
      optellen([
        v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen === null ||
        v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen
          ? v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekendBedrag
          : v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.bedrag,
        v.financieringsopzet.totaleRestschuldModal.extraKostenHuidigeHypotheek
      ]),
    uitkoopPartner: v => v.financieringsopzet.uitkoopPartner,
    verbouwingskostenMeerwerk: v => v.financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering,
    wozWaarde: v => v.onderpand.wozWaarde,
    tweedeWoning: v => v.onderpand.gebruik === GebruikPandSoort.TweedeWoning,
    hypothecaireInschrijving: v => ({
      soortWaardeberekening:
        v.financieringsopzet.hypothecaireInschrijving.berekenen ||
        v.financieringsopzet.hypothecaireInschrijving.berekenen === null
          ? SoortWaardeberekeningOptions.Default
          : SoortWaardeberekeningOptions.VastBedrag,
      bedrag:
        v.financieringsopzet.hypothecaireInschrijving.berekenen ||
        v.financieringsopzet.hypothecaireInschrijving.berekenen === null
          ? null
          : v.financieringsopzet.hypothecaireInschrijving.bedrag,
      percentage: null
    }),
    inschrijvingInstellingPercentage: v =>
      bepaalWaardeBerekeningSoort(
        v.financieringsopzet.hypothecaireInschrijving.berekenen,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.hypothecaireInschrijvingVaststellingWaarde
      ) === "Percentage"
        ? v.financieringsopzet.financieringsopzetInstellingenTarieven.hypothecaireInschrijvingPercentage
        : null,
    inschrijvingInstellingVastBedrag: v =>
      bepaalWaardeBerekeningSoort(
        v.financieringsopzet.hypothecaireInschrijving.berekenen,
        v.financieringsopzet.financieringsopzetInstellingenTarieven.hypothecaireInschrijvingVaststellingWaarde
      ) === "VastBedrag"
        ? v.financieringsopzet.financieringsopzetInstellingenTarieven.hypothecaireInschrijvingVastBedrag
        : null,
    arbeidskostenNotarisBedrag: v => v.financieringsopzet.arbeidskostenNotaris,
    meeTeFinancierenRestschuld: v => {
      return (
        ((!v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekenen === false
          ? v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.bedrag
          : v.financieringsopzet.totaleRestschuldModal.restschuldHuidigeWoning.berekendBedrag) ?? 0) -
        (v.financieringsopzet.totaleRestschuldModal.inbrengEigenGeldVoorVerlagenRestschuld ?? 0)
      );
    },
    bestaandNhgBedrag: v => v.financieringsopzet.bestaandNhgBedrag,
    doorlopendZonderNhgBedrag: v => v.financieringsopzet.doorlopendZonderNhgBedrag,
    opgebouwdeWaardeBedrag: v => v.financieringsopzet.inbrengEigenGeldModal.inbrengOpgebouwdeWaarde,
    starterslening: v => v.financieringsopzet.andereFinancieringenModal.startersleningBedrag
  });

const berekenEigenGeldInbreng = (
  { financieringsopzet: { inbrengEigenGeldModal } }: FinancieringsbehoefteState,
  totaalBenodigdEigenGeld: number
): number => {
  const eigenGeldSources = [
    inbrengEigenGeldModal.inbrengEigengeldBedrag1,
    inbrengEigenGeldModal.inbrengEigengeldBedrag2,
    inbrengEigenGeldModal.extraAflossing,
    inbrengEigenGeldModal.eigenGeldOverwaarde.berekenen
      ? inbrengEigenGeldModal.eigenGeldOverwaarde.berekendBedrag
      : inbrengEigenGeldModal.eigenGeldOverwaarde.bedrag,
    inbrengEigenGeldModal.inbrengVoorVerlagenRestschuld,
    inbrengEigenGeldModal.inbrengOpgebouwdeWaarde,
    inbrengEigenGeldModal.inbrengPolisBedrag
  ];

  return (
    eigenGeldSources
      .filter(v => !!v)
      .reduce((prev, curr): number => {
        assertValue(curr);
        assertValue(prev);
        prev -= curr;
        return prev;
      }, totaalBenodigdEigenGeld) || 0
  );
};

export const mapBerekeningFinancieringsbehoefteExtOutput = (
  curr: FinancieringsbehoefteState,
  result: FinancieringsbehoefteOutput
): void => {
  if (result.resultaat) {
    const resultaatExt = result.resultaat;
    const { financieringsopzet } = curr;

    berekenInputToDraftSideEffectResult(financieringsopzet.overdrachtsbelasting, resultaatExt.overdrachtsbelasting);
    berekenInputToDraftSideEffectResult(financieringsopzet.makelaarscourtage, resultaatExt.makelaarskosten);
    berekenInputToDraftSideEffectResult(financieringsopzet.hypothecaireInschrijving, resultaatExt.bedragInschrijving);
    berekenInputToDraftSideEffectResult(financieringsopzet.leveringsakte, resultaatExt.leveringsaktekosten);
    berekenInputToDraftSideEffectResult(financieringsopzet.hypotheekakte, resultaatExt.hypotheekaktekosten);
    berekenInputToDraftSideEffectResult(financieringsopzet.taxatie, resultaatExt.taxatiekosten);

    curr.financieringsopzet.inbrengEigenGeldModal.inbrengEigenGeld = berekenEigenGeldInbreng(
      curr,
      resultaatExt.benodigdEigenGeld || 0
    );

    // Zet nationaleGarantieBerekenen als marktwaarde voor eerste keer is gewijzigd en nhgMogelijkIngesteld nog niet is gezet
    if (!curr.financieringsopzet.nhgMogelijkIngesteld && curr.onderpand.marktwaardeVoorVerbouwing) {
      curr.financieringsopzet.nationaleHypotheekGarantieBerekenen = resultaatExt.nhgMogelijk || false;
      curr.financieringsopzet.nhgMogelijkIngesteld = !curr.financieringsopzet.nhgMogelijkIngesteld;
    }

    if (curr.financieringsopzet.nationaleHypotheekGarantieBerekenen) {
      curr.financieringsopzet.nationaleHypotheekGarantie =
        resultaatExt.kostenNhg && resultaatExt.kostenNhg > 0 ? resultaatExt.kostenNhg : 0;
    }

    curr.financieringsopzet.gewensteHypotheek = resultaatExt.hypotheekbedrag;
    curr.financieringsopzet.nhgMogelijk = resultaatExt.nhgMogelijk || false;
    curr.financieringsopzet.afkoopErfpacht = resultaatExt.afkoopErfpacht;
    curr.financieringsopzet.bevoorschottingspercentage = resultaatExt.bevoorschottingspercentage;
    curr.financieringsopzet.maxBevoorschottingspercentage = resultaatExt.maxBevoorschottingspercentage;
    curr.financieringsopzet.nationaleHypotheekGarantieWarning = mapNhgWarning(
      resultaatExt.nhgMogelijkResultaat,
      curr.financieringsopzet.gewensteHypotheek ?? 0
    );
  }
};

export const mapEigenWoningSchuldInput = createMapToDl(financieringsbehoefteSchema).to<EwsInput>({
  koopsomBedrag: v => getKoopsomBedrag(v),
  bouwrenteVoorAankoopBedrag: v => v.financieringsopzet.bouwrentePeriodeVoorAankoop,
  verbouwingBedrag: v =>
    v.financiering.soortFinanciering !== Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering
      : null,
  meerwerkBedrag: v =>
    v.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw
      ? v.financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering
      : null,
  overdrachtsbelastingBedrag: v => v.financieringsopzet.overdrachtsbelasting.bedrag,
  courtageBedrag: v => v.financieringsopzet.makelaarscourtage.bedrag,
  transportakteBedrag: v => v.financieringsopzet.leveringsakte.bedrag,
  erfpachtAfkoopBedrag: v => v.financieringsopzet.afkoopErfpachtModal.afkoopBedrag,
  vergoedingPartnerBedrag: v => v.financieringsopzet.uitkoopPartner,
  advieskostenBedrag: v => v.financieringsopzet.advieskosten,
  nhgBedrag: v => v.financieringsopzet.nationaleHypotheekGarantie,
  hypotheekakteBedrag: v => v.financieringsopzet.hypotheekakte.bedrag,
  taxatiekostenBedrag: v => v.financieringsopzet.taxatie.bedrag,
  startersleningAfsluitprovisieBedrag: v =>
    v.financieringsopzet.overigeFinancieringskostenModal.afsluitkostenSVnStarterslening,
  afsluitkostenOverbruggingskredietBedrag: v => v.financieringsopzet.overbruggingskredietModal.afsluitkosten,
  bereidstellingsprovisieBedrag: v => v.financieringsopzet.overigeFinancieringskostenModal.bereidstellingskosten,
  kostenOmzettingBedrag: v => v.financieringsopzet.kostenOmzettingBedrag,
  bouwkundigeKeuringBedrag: v =>
    overigeKostenBedrag(v.financieringsopzet.overigeKostenInHypotheekModal.overigeKostenSpecificaties, 0),
  overigeKosten1Bedrag: v =>
    overigeKostenBedrag(v.financieringsopzet.overigeKostenInHypotheekModal.overigeKostenSpecificaties, 1),
  overigeKosten2Bedrag: v =>
    overigeKostenBedrag(v.financieringsopzet.overigeKostenInHypotheekModal.overigeKostenSpecificaties, 2),
  overigeKosten3Bedrag: v =>
    overigeKostenBedrag(v.financieringsopzet.overigeKostenInHypotheekModal.overigeKostenSpecificaties, 3),
  gewensteHypotheekBedrag: v => v.financieringsopzet.gewensteHypotheek,
  afsluitkostenBedrag: () => 0,
  bemiddelingsvergoedingGeldleningBedrag: v => v.financiering.doorlopendeLeningdelenBedrag,
  dossiervergoedingBedrag: v =>
    v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.dossiervergoedingGeldlening,
  gespreidBetalen: v => v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalen,
  gespreidBetalenDuurInMaanden: v => v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.gespreidBetalenOver,
  advieskostenGespreidBedrag: v =>
    v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.advieskostenGespreid?.bedrag ?? 0,
  bemiddelingsvergoedingGeldleningGespreidBedrag: v =>
    v.financieringsopzet.advieskostenBemiddelingsvergoedingModal.bemiddelingsvergoedingGeldleningGespreid?.bedrag ?? 0,
  royementskostenBedrag: v => v.financieringsopzet.overigeKostenInHypotheekModal.royementskostenBedrag,
  financieringskostenAftrekbaarPercentage: v =>
    v.financieringsopzet.eigenwoningschuldModal.financieringsKostenPercentage,
  financieringskostenAftrekbaarOvernemen: v =>
    v.financieringsopzet.eigenwoningschuldModal.financieringskostenAftrekbaarOvernemen,
  // nswag update
  arbeidskostenNotarisBedrag: v => null
});

export const mapEigenWoningSchuldOutput = (curr: FinancieringsbehoefteState, result: EwsOutput): void => {
  if (result.resultaat) {
    mapDraftEwsResultaatDlToUi(curr.financieringsopzet.eigenwoningschuldModal, result.resultaat);
    /* istanbul ignore else */
    if (
      curr.financieringsopzet.eigenwoningschuldModal.eigenwoningschuld.berekenen ||
      curr.financieringsopzet.eigenwoningschuldModal.eigenwoningschuld.berekenen === null
    ) {
      berekenInputToDraftSideEffectResult(
        curr.financieringsopzet.eigenwoningschuldModal.eigenwoningschuld,
        result.resultaat.ewsBedrag
      );
    }
  }
};

export const mapEigenWoningForfaitInput = createMapToDl(financieringsbehoefteSchema).to<EigenwoningforfaitInput>({
  wozWaarde: v => v.onderpand.wozWaarde || 0,
  tarievenNorm: () => null
});

function isNieuwbouwBestaandebouwOfOmzetting(soortFinanciering: Financieringsoort): boolean {
  return [
    Financieringsoort.AankoopNieuwbouw,
    Financieringsoort.AankoopBestaandeBouw,
    Financieringsoort.Omzetting
  ].includes(soortFinanciering);
}
