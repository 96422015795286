import {
  Icon,
  LabeledText,
  LabeledDateInput,
  ModalButton,
  TooltipWrap,
  LabeledBevestigingInput
} from "adviesbox-shared";
import { connect, FormikContextType, FormikHelpers } from "formik";
import React, { ReactElement, useCallback } from "react";
import { AdresInput } from "../../shared/components/adres/adres-input";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { OnderpandType } from "../../shared/generic-parts/onderpand/schema";
import { SelectedProps } from "../../shared/types";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { bedragFormat } from "../../shared/utils/currency";
import { getWoonsituatieTextResources } from "../infra/woonsituatie-resources";
import { WoonsituatieListState } from "../infra/woonsituatie-schema";
import OnderpandModalContent from "../../shared/components/onderpand/OnderpandModal";

export const setOnderpand = (
  result: OnderpandType,
  setFieldValue: FormikHelpers<WoonsituatieListState>["setFieldValue"],
  selected: number
): void => {
  setFieldValue(`woonsituaties[${selected}].huidigPand.onderpand`, result);
};

const HuidigPand = ({
  selected,
  formik: {
    setFieldValue,
    values: { woonsituaties }
  }
}: SelectedProps & {
  formik: FormikContextType<WoonsituatieListState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const selectedWoonsituatie = woonsituaties ? woonsituaties[selected] : null;

  if (!selectedWoonsituatie || !selectedWoonsituatie.huidigPand) return <> </>;

  const adresIsWoonadres = selectedWoonsituatie.huidigPand.adresIsWoonadres;

  let onderpandTekst = "Geen gegevens ingevuld";

  if (selectedWoonsituatie.huidigPand.onderpand.bouwjaar && selectedWoonsituatie.huidigPand.onderpand.serviceKosten) {
    onderpandTekst = `Bouwjaar: ${selectedWoonsituatie.huidigPand.onderpand.bouwjaar}, servicekosten: ${bedragFormat(
      selectedWoonsituatie.huidigPand.onderpand.serviceKosten,
      2
    )}`;
  }

  if (selectedWoonsituatie.huidigPand.onderpand.bouwjaar && !selectedWoonsituatie.huidigPand.onderpand.serviceKosten) {
    onderpandTekst = `Bouwjaar: ${selectedWoonsituatie.huidigPand.onderpand.bouwjaar}`;
  }

  if (selectedWoonsituatie.huidigPand.onderpand.serviceKosten && !selectedWoonsituatie.huidigPand.onderpand.bouwjaar) {
    onderpandTekst = `Servicekosten: ${bedragFormat(selectedWoonsituatie.huidigPand.onderpand.serviceKosten, 2)}`;
  }

  return (
    <>
      <LabeledHdnKeuzelijst
        caption="Object soort"
        name={`woonsituaties[${selected}].huidigPand.onderpand.objectSoort`}
        keuzelijst="ObjectSoortType"
        berichtSoortType="AX"
        emptyValue={"Selecteer een optie"}
        appendLabelChildren={
          <span style={{ marginLeft: "5px" }}>
            <sub>
              <a
                href={"https://support.adviesbox.nl/portal/nl/kb/articles/object-soort"}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Toelichting
              </a>
            </sub>
          </span>
        }
      />
      <LabeledText
        name={`woonsituaties[${selected}].huidigPand.onderpand-label`}
        caption="Onderpand"
        value={onderpandTekst}
        appendChildren={
          <ModalButton
            resetSize={true}
            parent={`woonsituaties[${selected}].huidigPand.onderpand`}
            key={`woonsituaties[${selected}].huidigPand.onderpand-modalknop`}
            content={
              <Icon
                name="specificatie"
                alt="Onderpand popup"
                key={`woonsituaties[${selected}].huidigPand.onderpand-modalicon`}
              />
            }
          >
            <OnderpandModalContent
              key={`woonsituaties[${selected}].huidigPand.onderpand-modal`}
              data={selectedWoonsituatie.huidigPand.onderpand}
              onSave={createSaveFunction(
                `woonsituaties[${selected}].huidigPand.onderpand`,
                /* istanbul ignore next */
                result => setOnderpand(result, setFieldValue, selected)
              )}
            />
          </ModalButton>
        }
      />

      <LabeledHdnKeuzelijst
        caption="Energielabel"
        name={`woonsituaties[${selected}].huidigPand.energielabel`}
        keuzelijst="EnergieKlasseType"
        berichtSoortType="AX"
        tooltip={getWoonsituatieTextResources("TooltipEnergieLabel")}
        emptyValue={"Selecteer een optie"}
        appendLabelChildren={
          !woonsituaties[selected].huidigPand?.energielabel ? (
            <TooltipWrap
              name={`woonsituaties[${selected}].huidigPand.energielabel`}
              warningText={
                "Let op! Als er geen energielabel is gekozen, wordt er standaard van energieklasse C uitgegaan."
              }
              placement="bottom"
              iconType="waarschuwing"
            />
          ) : null
        }
      />
      <LabeledDateInput
        caption="Datum energieklasse"
        name={`woonsituaties[${selected}].huidigPand.energieklasseDatum`}
      />

      <LabeledBevestigingInput
        caption="Adres gelijk aan woonadres"
        name={`woonsituaties[${selected}].huidigPand.adresIsWoonadres`}
      />

      <AdresInput readonly={adresIsWoonadres} name={`woonsituaties[${selected}].huidigPand.adres`} />
    </>
  );
};

export default connect<SelectedProps, WoonsituatieListState>(HuidigPand);
