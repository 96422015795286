import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { KapitaalopbouwKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { AanvragerKeuze } from "../../shared/types";
import { LabeledCurrencyInput, ResetButton, LabeledPercentageInput } from "adviesbox-shared";
import { HypothekenState } from "../infra/hypotheek-types";

type KapitaalopbouwSpaarProps = {
  selected: number;
  caption?: string | null;
  kenmerken: KapitaalopbouwKenmerken;
};

const KapitaalopbouwSpaar = ({
  kenmerken,
  selected,
  caption,
  formik: {
    values: { producten }
  }
}: {
  formik: FormikContextType<HypothekenState>;
} & KapitaalopbouwSpaarProps): ReactElement => {
  const aanvrager1True = producten[selected].verzekerde.verzekerde === AanvragerKeuze.Aanvrager1;
  const aanvrager2True = producten[selected].verzekerde.verzekerde === AanvragerKeuze.Aanvrager2;
  const BeideTrue = producten[selected].verzekerde.verzekerde === AanvragerKeuze.Beiden;
  return (
    <>
      {(aanvrager1True || BeideTrue) && (
        <LabeledCurrencyInput
          caption={caption ?? "Doelkapitaal"}
          name={`producten[${selected}].kapitaalopbouw.doelkapitaal1Bedrag`}
          fontWeight="normal"
          style={{ width: 100 }}
          readonly={!kenmerken.doelkapitaalEnabled}
          appendChildren={
            kenmerken.doelkapitaalEnabled ? (
              <ResetButton
                name={`producten[${selected}].kapitaalopbouw.doelkapitaal1Bedrag`}
                defaultValue={producten[selected].leningdeelgegevens.leningdeelHoofdsom.bedrag || null}
              />
            ) : null
          }
        />
      )}
      {(aanvrager1True || BeideTrue) && (
        <LabeledPercentageInput
          caption={caption ?? "Percentage kapitaalopbouw"}
          name={`producten[${selected}].kapitaalopbouw.doelkapitaal1Percentage`}
          fontWeight="normal"
          decimalen={2}
          readonly={true}
        />
      )}

      {aanvrager2True && (
        <LabeledCurrencyInput
          caption={caption ?? "Doelkapitaal"}
          name={`producten[${selected}].kapitaalopbouw.doelkapitaal2Bedrag`}
          fontWeight="normal"
          fieldSize="no-size"
          style={{ width: 100 }}
          readonly={!kenmerken.doelkapitaalEnabled}
          appendChildren={
            kenmerken.doelkapitaalEnabled ? (
              <ResetButton
                name={`producten[${selected}].kapitaalopbouw.doelkapitaal2Bedrag`}
                defaultValue={producten[selected].leningdeelgegevens.leningdeelHoofdsom.bedrag || null}
              />
            ) : null
          }
        />
      )}
      {aanvrager2True && (
        <LabeledPercentageInput
          caption={caption ?? "Percentage kapitaalopbouw"}
          name={`producten[${selected}].kapitaalopbouw.doelkapitaal2Percentage`}
          fontWeight="normal"
          decimalen={2}
          readonly={true}
        />
      )}
    </>
  );
};

KapitaalopbouwSpaar.displayName = "kapitaalopbouwSpaar";

export default connect<KapitaalopbouwSpaarProps, HypothekenState>(KapitaalopbouwSpaar);
