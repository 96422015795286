import { Icon, LabeledCurrencyInput, LabeledPercentageInput, LabeledRadioInput, ModalButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AovVerzekerdeDekkingenOptions, AovVoornaamsteInkomstenbronOptions } from "../../.generated/forms/formstypes";
import { AovsType } from "../infra/aov-schema";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";
import { VerzekerdBedragModal } from "../verzekerd-bedrag-modal/verzekerd-bedrag-modal";
import { dekkingOptions, DekkingProps } from "./dekking";

export const DekkingHypotheekAflosVerzekering = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const dekkingGegevens =
    verzekerde === 1
      ? values.producten[selected].dekking.dekkingGegevensVerzekerde1
      : values.producten[selected].dekking.dekkingGegevensVerzekerde2;

  const partijProductCode = mapPartijProduct(
    values.producten[selected].partijCode,
    values.producten[selected].productCode
  );

  return (
    <>
      {kenmerken.marktwaardeTonen && (
        <LabeledCurrencyInput
          readonly={!kenmerken.marktwaardeEnabled}
          name={`producten[${selected}].dekking.marktwaarde`}
          caption="Marktwaarde"
        />
      )}
      {kenmerken.hypotheekBedragTonen && (
        <LabeledCurrencyInput
          readonly={!kenmerken.hypotheekBedragEnabled}
          name={`producten[${selected}].dekking.hypotheekbedrag`}
          caption="Hypotheekbedrag"
        />
      )}
      {kenmerken.verzekerdeDekkingTonen && (
        <LabeledRadioInput
          caption={"Dekking"}
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.dekking`}
          options={dekkingOptions(
            kenmerken,
            dekkingGegevens?.voornaamsteInkomstenbron !== AovVoornaamsteInkomstenbronOptions.Zelfstandige,
            partijProductCode
          )}
        />
      )}
      {(dekkingGegevens?.dekking === AovVerzekerdeDekkingenOptions.Ao ||
        dekkingGegevens?.dekking === AovVerzekerdeDekkingenOptions.AoWw) &&
        kenmerken.premieAoTonen && (
          <div className="pt-4">
            <div>
              <h2 className="ml-3 mt-2">Arbeidsongeschiktheid</h2>
            </div>
            <div className="px-1">
              <LabeledCurrencyInput
                name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.verzekerdBedrag`}
                caption="Verzekerd bedrag (AO)"
                readonly={!kenmerken.verzekerdBedragAoEnabled}
                appendChildren={
                  kenmerken.verzekerdBedragAoVerloopTonen && (
                    <>
                      <ModalButton
                        resetSize={true}
                        parent="dekking.verzekerdBedragVerzekerdBedragModalValues"
                        aria-label="Verzekerd bedrag button"
                        size="lg"
                        content={<Icon name="specificatie" alt="Verzekerd bedrag modal" />}
                      >
                        <VerzekerdBedragModal
                          aanvangsjaar={values.producten[selected].product.ingangsdatum?.year()}
                          data={values.producten[selected].dekking.verzekerdBedragVerzekerdBedragModalValues}
                        />
                      </ModalButton>
                    </>
                  )
                }
              />
              {kenmerken.annuiteitspercentageTonen && (
                <LabeledPercentageInput
                  caption="Annuiteitspercentage"
                  name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ao.annuiteitsPercentage`}
                  readonly={!kenmerken.annuiteitspercentageEnabled}
                  verplicht={true}
                  decimalen={2}
                />
              )}
            </div>
          </div>
        )}
      {dekkingGegevens?.dekking === AovVerzekerdeDekkingenOptions.AoWw && kenmerken.premieWwTonen && (
        <div className="pt-4">
          <div>
            <h2>Werkloosheid</h2>
          </div>
          <div className="px-1">
            <LabeledCurrencyInput
              readonly={!kenmerken.verzekerdBedragWwEnabled}
              name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.ww.verzekerdBedrag`}
              caption="Verzekerd maandbedrag (WW)"
            />
          </div>
        </div>
      )}
      {kenmerken.verzekerdeKredietsomTonen && (
        <div className="px-1">
          <LabeledCurrencyInput
            readonly={true}
            caption="Verzekerde kredietsom"
            name={`producten[${selected}].dekking.verzekerdeKredietsom`}
          />
        </div>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  DekkingHypotheekAflosVerzekering.displayName = "Dekking";
