import { LabeledSelectInput, LabeledTextInput, LabeledResult } from "adviesbox-shared";
import { getIn, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import {
  ControleGewenstNbiOptions,
  ControleGewenstInkomenOptions,
  MotiveringOnderwerpsoort
} from "../../../.generated/forms/formstypes";
import { LabelValuePairs } from "../../../shared/types";
import { MotiveringState } from "../../infra/motivering-schema";
import { UitgangspuntenProperties } from "../uitgangspunten";

type ControleOpGewenstInkomenProps = {
  type: MotiveringOnderwerpsoort;
  properties: UitgangspuntenProperties;
};

type uitgangsPuntenBooleans = {
  hasBenodigdeKapitaal: boolean | undefined;
  hasGewijzigdeJaren: boolean | undefined;
};

const controleOpGewenstInkomenOptions = (): LabelValuePairs => {
  return [
    { label: "Netto besteedbaar inkomen", value: ControleGewenstInkomenOptions.Nbi },
    { label: "Bruto inkomenswens", value: ControleGewenstInkomenOptions.BrutoInkomenswens },
    { label: "Geen", value: ControleGewenstInkomenOptions.Geen }
  ];
};

const gewenstNettoBesteedbaarOptions = (): LabelValuePairs => {
  return [
    { label: "Behouden van huidig NBI", value: ControleGewenstNbiOptions.BehoudenHuidigNbi },
    {
      label: "Acceptabele marge op huidig NBI",
      value: ControleGewenstNbiOptions.MargeHuidigNbi
    },
    { label: "Overhouden van vast bedrag NBI", value: ControleGewenstNbiOptions.VastBedrag },
    {
      label: "Percentage van huidig NBI",
      value: ControleGewenstNbiOptions.PercentageHuidigNbi
    }
  ];
};

const getGewensteTabellenBooleans = (
  properties: UitgangspuntenProperties,
  values: MotiveringState
): uitgangsPuntenBooleans => {
  if (properties.isWerkloosheid) {
    return {
      hasBenodigdeKapitaal: values.uitgangspuntenWerkloosheid?.controleGewenstInkomen.benodigdKapitaalTonen,
      hasGewijzigdeJaren: values.uitgangspuntenWerkloosheid?.controleGewenstInkomen.gewijzigdeJarenTonen
    };
  }

  if (properties.isOverlijden) {
    return {
      hasBenodigdeKapitaal: values.uitgangspuntenOverlijden?.controleGewenstInkomen?.benodigdKapitaalTonen,
      hasGewijzigdeJaren: values.uitgangspuntenOverlijden?.controleGewenstInkomen?.gewijzigdeJarenTonen
    };
  }

  if (properties.isPensioen) {
    return {
      hasBenodigdeKapitaal: values.uitgangspuntenPensioen?.controleGewenstInkomen?.benodigdKapitaalTonen,
      hasGewijzigdeJaren: values.uitgangspuntenPensioen?.controleGewenstInkomen?.gewijzigdeJarenTonen
    };
  }

  if (properties.isArbeidsOngeschiktheid) {
    return {
      hasBenodigdeKapitaal: values.uitgangspuntenArbeidsongeschiktheid?.controleGewenstInkomen?.benodigdKapitaalTonen,
      hasGewijzigdeJaren: values.uitgangspuntenArbeidsongeschiktheid?.controleGewenstInkomen?.gewijzigdeJarenTonen
    };
  }

  return {
    hasBenodigdeKapitaal: undefined,
    hasGewijzigdeJaren: undefined
  };
};

const createGewensteTabellenTekst = (uitgangsPuntenBooleans: uitgangsPuntenBooleans): string[] => {
  const gewensteTabellenArray: string[] = [];

  if (uitgangsPuntenBooleans.hasGewijzigdeJaren) {
    gewensteTabellenArray.push("- Gewijzigde jaren");
  }

  if (uitgangsPuntenBooleans.hasBenodigdeKapitaal) {
    gewensteTabellenArray.push("- Benodigd kapitaal");
  }

  return gewensteTabellenArray;
};
export const ControleOpGewenstInkomen = ({ type, properties }: ControleOpGewenstInkomenProps): ReactElement => {
  const formik = useFormikContext<MotiveringState>();
  const values = formik.values;
  const gewensteTabellenTekst: string[] = createGewensteTabellenTekst(getGewensteTabellenBooleans(properties, values));
  return (
    <>
      <h2>Controle op gewenst inkomen</h2>
      <LabeledSelectInput
        options={controleOpGewenstInkomenOptions()}
        readonly={true}
        caption="Controle op gewenst inkomen"
        name={`${properties.name}.controleGewenstInkomen.controleGewenstInkomen`}
      />

      {getIn(values, `${properties.name}.controleGewenstInkomen.controleGewenstInkomen`) ===
        ControleGewenstInkomenOptions.BrutoInkomenswens && (
        <LabeledResult
          caption="Bruto inkomenswens"
          name={`${properties.name}.controleGewenstInkomen.brutoInkomenswensPercentage`}
          fieldSize="no-size"
          result={
            /* istanbul ignore next */ () => {
              return `${getIn(values, `${properties.name}.controleGewenstInkomen.brutoInkomenswensPercentage`) ?? 0}%`;
            }
          }
        />
      )}
      {!!getIn(values, `${properties.name}.controleGewenstInkomen.controleGewenstNbi`) && (
        <LabeledSelectInput
          options={gewenstNettoBesteedbaarOptions()}
          readonly={true}
          caption="Gewenst netto besteedbaar inkomen"
          name={`${properties.name}.controleGewenstInkomen.controleGewenstNbi`}
        />
      )}
      {getIn(values, `${properties.name}.controleGewenstInkomen.controleGewenstInkomen`) ===
        ControleGewenstInkomenOptions.Nbi && (
        <>
          {getIn(values, `${properties.name}.controleGewenstInkomen.controleGewenstNbi`) ===
            ControleGewenstNbiOptions.MargeHuidigNbi && (
            <>
              <LabeledResult
                caption="Acceptabele marge op huidig NBI (Bovengrens)"
                name={`${properties.name}.controleGewenstInkomen.margeBovengrensPercentage`}
                fieldSize="no-size"
                result={
                  /* istanbul ignore next */ () => {
                    return `${getIn(values, `${properties.name}.controleGewenstInkomen.margeBovengrensPercentage`) ??
                      0}%`;
                  }
                }
              />
              <LabeledResult
                caption="Acceptabele marge op huidig NBI (Ondergrens)"
                name={`${properties.name}.controleGewenstInkomen.margeOndergrensPercentage`}
                fieldSize="no-size"
                result={
                  /* istanbul ignore next */ () => {
                    return `${getIn(values, `${properties.name}.controleGewenstInkomen.margeOndergrensPercentage`) ??
                      0}%`;
                  }
                }
              />
            </>
          )}
          {getIn(values, `${properties.name}.controleGewenstInkomen.controleGewenstNbi`) ===
            ControleGewenstNbiOptions.VastBedrag && (
            <LabeledResult
              caption="Gewenst vast bedrag NBI"
              name={`${properties.name}.controleGewenstInkomen.vastBedrag`}
              fieldSize="no-size"
              currency={true}
              result={
                /* istanbul ignore next */ () => {
                  return `${getIn(values, `${properties.name}.controleGewenstInkomen.vastBedrag`) ?? 0}`;
                }
              }
            />
          )}
          {getIn(values, `${properties.name}.controleGewenstInkomen.controleGewenstNbi`) ===
            ControleGewenstNbiOptions.PercentageHuidigNbi && (
            <LabeledResult
              caption="Gewenst % van huidig NBI"
              name={`${properties.name}.controleGewenstInkomen.huidigNbiPercentage`}
              fieldSize="no-size"
              result={
                /* istanbul ignore next */ () => {
                  return `${getIn(values, `${properties.name}.controleGewenstInkomen.huidigNbiPercentage`) ?? 0}%`;
                }
              }
            />
          )}
        </>
      )}
      {gewensteTabellenTekst.length > 0 &&
        getIn(values, `${properties.name}.controleGewenstInkomen.controleGewenstNbi`) && (
          <LabeledTextInput
            caption={"Gewenste tabellen"}
            name={`${properties.name}.controleGewenstInkomen.gewensteTabellen`}
            readonly={true}
            appendChildren={
              <div>
                {gewensteTabellenTekst.map((tekst, index) => {
                  return (
                    <div key={index}>
                      <span>{tekst}</span>
                    </div>
                  );
                })}
              </div>
            }
          />
        )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  ControleOpGewenstInkomen.displayName = "ControleOpGewenstInkomen";
