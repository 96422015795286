import React, { ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormikProps } from "formik";

import { determineLegitimatieSideEffects } from "./determine-legitimatiebewijs-side-effects";

import { LabeledTextInput, LabeledDateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { Debug } from "../../shared/components/formik/Debug";
import { Legitimatiebewijs, legitimatiebewijsSchema, SoortLegitimatiebewijs } from "../infra/personalia-schema";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import classes from "./legitimatiebewijs-modal.module.scss";

export type LegitimatiebewijsModalData = {
  data: Legitimatiebewijs;
};

type LegitimatiebewijsModalProps = {
  onSave?: (data: Legitimatiebewijs) => void;
  closeModal?: () => void;
};

const LegitimatiebewijsModal = ({
  data,
  onSave,
  closeModal
}: LegitimatiebewijsModalData & LegitimatiebewijsModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<Legitimatiebewijs>
      initialValues={{ ...data }}
      validationSchema={legitimatiebewijsSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm, setFieldTouched, values }: FormikProps<Legitimatiebewijs>): ReactElement => {
        const hasLegitimatiebewijs =
          values.soortLegitimatiebewijs !== null && values.soortLegitimatiebewijs !== SoortLegitimatiebewijs.Geen;
        return (
          <>
            <ISWSideEffects<Legitimatiebewijs> sync={determineLegitimatieSideEffects({ setFieldTouched })} />
            <Modal.Header closeButton>
              <Modal.Title>Legitimatiebewijs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type={"text"} className={classes.hidden_input} readOnly={true}></input>
              <LabeledHdnKeuzelijst
                caption="Soort legitimatiebewijs"
                name="soortLegitimatiebewijs"
                berichtSoortType="AX"
                keuzelijst="LegitimatieSoortType"
                emptyValue="Geen"
              />
              <LabeledTextInput
                caption="Nummer legitimatiebewijs"
                name="nummerLegitimatiebewijs"
                visible={hasLegitimatiebewijs}
              />
              <LabeledTextInput caption="Plaatsnaam afgifte" name="plaatsnaamAfgifte" visible={hasLegitimatiebewijs} />
              <LabeledHdnKeuzelijst
                caption="Land afgifte"
                name="landAfgifte"
                berichtSoortType="AX"
                keuzelijst="LandType"
                visible={hasLegitimatiebewijs}
              />
              <LabeledDateInput caption="Datum afgifte" name="datumAfgifte" visible={hasLegitimatiebewijs} />
              <LabeledDateInput caption="Geldig tot" name="geldigTot" visible={hasLegitimatiebewijs} />
              <Debug />
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="btnannuleren" variant="link" onClick={closeModal} id="btnannuleren">
                Annuleren
              </Button>
              <Button data-testid="btnopslaan" variant="primary" onClick={submitForm} id="btnopslaan">
                Bevestigen
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};

export default LegitimatiebewijsModal;
