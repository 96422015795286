const formatter = (bedrag: number, decimals?: number, currency?: boolean): string => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals // TO-DO - Dit moet ook een default van 0 hebben.
  };

  if (currency) {
    options.style = "currency";
    options.currency = "EUR";
  } else {
    options.style = "decimal";
  }

  return new Intl.NumberFormat("nl-NL", options).format(bedrag);
};

export const optellen = (bedragen: (number | null)[]): number | null => {
  return bedragen.reduce((x, y): number => (x ? x : 0) + (y ? y : 0), null);
};

// Standaard afronden op 6 decimalen omdat dat de nauwkeurigheid is bij het opslaan bij platform
export const afronden = (bedrag: number | null, decimalen = 6): number | null => {
  if (typeof bedrag === "number") {
    return Math.round(Math.pow(10, decimalen) * bedrag) / Math.pow(10, decimalen);
  } else {
    return null;
  }
};

export const bedragFormat = (bedrag: number | null, decimals?: number, afrondenBoven?: number | boolean, currency = true): string => {
  if (
    bedrag &&
    (typeof afrondenBoven === "number" || typeof afrondenBoven === "undefined") &&
    Math.abs(bedrag) > (afrondenBoven ?? 1000)
  )
    bedrag = Math.round(bedrag);
  return formatter(bedrag || 0, decimals, currency);
};

export const nbspNaarSpaties = (tekst: string): string => {
  const re = new RegExp(String.fromCharCode(160), "g");
  return tekst.replace(re, " ");
};
