import React, { ReactElement, useCallback } from "react";
import { connect, FormikContextType } from "formik";

import ModalLeningdelen from "../modal-leningdelen/modal-leningdelen";
import { Icon, LabeledBevestigingInput, LabeledNumberInput } from "adviesbox-shared";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { ModalButton } from "adviesbox-shared";
import { MaximaleHypotheekState } from "../infra/maximale-hypotheek-schema";
import { getMaximaleHypotheekTextResources } from "../infra/maximale-hypotheek-resources";

const LeningdelenUitHetVerleden = ({
  formik: {
    setFieldValue,
    values: { leningdelenUitHetVerleden }
  }
}: {
  formik: FormikContextType<MaximaleHypotheekState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const leningdelenMeenemenBoolean = JSON.parse(String(leningdelenUitHetVerleden.leningdelenMeenemen));

  return (
    <>
      <LabeledBevestigingInput
        caption="Leningdelen uit het verleden meenemen"
        name="leningdelenUitHetVerleden.leningdelenMeenemen"
        tooltip={getMaximaleHypotheekTextResources(
          leningdelenMeenemenBoolean ? "tooltipLeningdelenMeenemenJa" : "tooltipLeningdelenMeenemenNee"
        )}
      />

      {leningdelenMeenemenBoolean && (
        <LabeledNumberInput
          caption="Mee te nemen leningdelen"
          name="leningdelenUitHetVerleden.meeTeNemenLeningdelen.length"
          readonly={true}
          appendChildren={
            <ModalButton
              resetSize={true}
              parent="leningdelenUitHetVerleden.meeTeNemenLeningdelen"
              size="lg"
              content={<Icon name="specificatie" alt="" />}
            >
              <ModalLeningdelen
                data={leningdelenUitHetVerleden}
                onSave={createSaveFunction("leningdelenUitHetVerleden.meeTeNemenLeningdelen")}
              />
            </ModalButton>
          }
        />
      )}
    </>
  );
};

export default connect<{}, MaximaleHypotheekState>(LeningdelenUitHetVerleden);
