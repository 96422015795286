import { logErrorToApplicationInsights, SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { AdviseurVestiging } from "../../.generated/forms/formstypes";
import { hasJSONResponse } from "../utils/ajax";
export type LogRequestBody = {
  username: string;
  datumTijdOpening: string;
};

export const logOpenDossier = async (
  settings: SettingsType,
  adviesdossierId: string,
  klantdossierId: string,
  vestigingId: string,
  user: User
): Promise<void> => {
  const url = `${settings.logOrigin}/Vestigingen/${vestigingId}/Klantdossiers/${klantdossierId}/Adviesdossiers/${adviesdossierId}/Openingen`;
  const urlKlantdossiersForms = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${adviesdossierId}/Recent/${klantdossierId}`;
  const body: LogRequestBody = {
    username: user.profile.name ?? "unknown-username",
    datumTijdOpening: new Date().toLocaleString("en-US", { timeZone: "Europe/Amsterdam" })
  };

  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json",
      vestigingId
    },
    body: JSON.stringify(body)
  });

  const rspKlantdossiersForms = await fetch(urlKlantdossiersForms, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json",
      vestigingId
    },    
  });

  if (!rsp.ok || !hasJSONResponse(rsp)) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'. Resultaat bevat geen JSON.`));
    return;
  }

  if (!rspKlantdossiersForms.ok) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${urlKlantdossiersForms}'`));
    return;
  }

  const json = await rsp.json();

  if (!json.isValid) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'. Input is niet valid.`));
  }
};

export const getAdviseurIds = async (settings: SettingsType, vestigingId: string, user: User): Promise<string[]> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviseurs`;
  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId
    }
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'. Resultaat bevat geen JSON.`));
  }

  const json = await rsp.json();

  if (!json.isValid) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'. Input is niet valid.`));
  }
  return json.adviseurs?.map((a: AdviseurVestiging) => a.adviseurId) ?? [];
};
