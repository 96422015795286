import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { AdvieskostenBemiddelingsvergoedingModal } from "../infra/financieringsbehoefte-types";
import { mapBerekenInput } from "../../shared/types";

const advieskostenBemiddelingsvergoedingDraftSideEffects = createISWSideEffect<AdvieskostenBemiddelingsvergoedingModal>(
  (bag): void => {
    const { has, draft } = bag;

    if (has.gespreidBetalen.changed) {
      if (draft.gespreidBetalen === false) {
        draft.advieskostenGespreid = null;
        draft.bemiddelingsvergoedingGeldleningGespreid = null;
        draft.overigeBemiddelingsvergoedingGespreid = null;
        draft.gespreidBetalenOver = null;
        return;
      }

      draft.advieskostenGespreid = mapBerekenInput(draft.advieskosten, true, draft.advieskosten);
      draft.bemiddelingsvergoedingGeldleningGespreid = mapBerekenInput(draft.bemiddelingsvergoedingGeldlening, true, draft.bemiddelingsvergoedingGeldlening);
      draft.overigeBemiddelingsvergoedingGespreid = mapBerekenInput(draft.overigeBemiddelingsvergoeding, true, draft.overigeBemiddelingsvergoeding);
      draft.gespreidBetalenOver = null;
    }
  }
);

export const advieskostenBemiddelingsvergoedingSideEffects = initISWSideEffect(
  advieskostenBemiddelingsvergoedingDraftSideEffects
)();
