import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import ModalHuidigeSituatie from "../modal-huidige-situatie/modal-huidige-situatie";
import { LabeledCurrencyInput, Icon, LabeledTextInput } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { HypotheekOversluitenState } from "../infra/hypotheek-oversluiten-schema";

const Uitgangspunten = ({
  formik: {
    values: { uitgangspunten }
  }
}: {
  formik: FormikContextType<HypotheekOversluitenState>;
}): ReactElement => {
  return (
    <>
      <LabeledCurrencyInput caption="Marktwaarde" name="uitgangspunten.marktwaarde" readonly={true} />

      <LabeledCurrencyInput caption="Gewenste hypotheek" name="uitgangspunten.gewensteHypotheek" readonly={true} />

      <LabeledCurrencyInput caption="Boeterente" name="uitgangspunten.boeterente" readonly={true} />

      <LabeledCurrencyInput
        caption="Totale bruto rentelast"
        name="uitgangspunten.totaleBrutoRentelast"
        readonly={true}
      />

      <LabeledTextInput
        readonly
        caption="Huidige situatie"
        name="uitgangspunten.huidigeSituatieGegevens"
        appendChildren={
          <>
            {uitgangspunten.huidigeSituatie.length > 0 ? (
              uitgangspunten.huidigeSituatie.length > 1 ? (
                <span id="huidige-situatie-tekst-meerdere-situaties">
                  {uitgangspunten.huidigeSituatie.length} leningen in huidige situatie
                </span>
              ) : (
                <span id="huidige-situatie-tekst-een-situatie">
                  {uitgangspunten.huidigeSituatie.length} lening in huidige situatie
                </span>
              )
            ) : (
              <span id="huidige-situatie-tekst-geen-situaties">Geen over te sluiten leningdelen</span>
            )}
            {uitgangspunten.huidigeSituatie.length === 0 ? null : (
              <ModalButton
                resetSize={true}
                parent="uitgangspunten.huidigeSituatie"
                size="lg"
                content={<Icon name="viewmodal" alt="Huidige situatie" />}
              >
                <ModalHuidigeSituatie data={uitgangspunten} />
              </ModalButton>
            )}
          </>
        }
      />
    </>
  );
};

export default connect<{}, HypotheekOversluitenState>(Uitgangspunten);
