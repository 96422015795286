import React, { FC, useCallback, useMemo } from "react";
import { useRequestInit } from "adviesbox-shared";
import { useFetchData } from "../../../shared/hooks/usefetchdata";
import {
  HypotheekHuidigeSituatieOutput,
  HypotheekVoorstelOutput
} from "../../../.generated/forms/formstypes";
import { HypotheekOverzichtTable } from "./hypotheek-overzicht-table";

type HypotheekData = HypotheekHuidigeSituatieOutput | HypotheekVoorstelOutput;

interface HypotheekOverzichtFetcherProps {
  voorstelId?: string;
  index: number;
  enabled?: boolean;
}

export const HypotheekOverzichtFetcher: FC<HypotheekOverzichtFetcherProps> = ({
  voorstelId: initialVoorstelId,
  enabled = true,
  index,
}) => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const voorstelId = initialVoorstelId === "999" ? undefined : initialVoorstelId;

  const getHypotheekOverzichtUrl = useMemo(() => {
    const baseUrl = settings.klantdossiersFormsOrigin;
    return voorstelId
      ? `${baseUrl}/Voorstellen/${voorstelId}/Hypotheek`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Hypotheek`;
  }, [params.adviesdossier, voorstelId, settings.klantdossiersFormsOrigin]);

  const getRequestInit = useCallback(() => ({
    ...requestInit,
    method: "GET",
    url: getHypotheekOverzichtUrl
  }), [requestInit, getHypotheekOverzichtUrl]);

  const { data, error } = useFetchData<HypotheekData>(
    getHypotheekOverzichtUrl,
    getRequestInit,
    enabled
  );

  if (error) {
    return <div role="alert" className="error-message">Er is een fout opgetreden bij het ophalen van de hypotheekgegevens</div>;
  }

  if (!data) {
    return null;
  }

  const hypotheekKey = Object.keys(data.hypotheken || {})[0];
  if (!hypotheekKey || !data.hypotheken?.[hypotheekKey]?.leningdelen?.length) {
    return null;
  }

  return <HypotheekOverzichtTable data={data} index={index} />;
};
