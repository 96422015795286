import { ErrorPage, PageLoading, SettingsType, withErrorBoundary, InvalidAdviesboxResultErrorPage } from "adviesbox-shared";
import React, { ReactElement, useEffect } from "react";
import { Acceptatie } from "./acceptatie";
import { mapDlTargetToAcceptatieUiField } from "./infra/map-acceptatie-dl-target-to-ui-field";
import { mapAcceptatieDlToUi } from "./infra/map-acceptatie-dl-to-ui";
import { useAdviesboxData } from "../../../../shared/hooks/use-adviesbox-data";
import { useRefreshTrigger } from "../../infra/use-refresh-trigger";

type RouteParams = {
  vestiging: string;
  adviesdosier: string;
  voorstel: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Acceptatie`;

export const AcceptatieAjaxComponent = (): ReactElement => {
  const { refreshTrigger } = useRefreshTrigger();
  const { loading, error, data, platformData, saveData, refetch } = useAdviesboxData(
    createUrl,
    p => p.voorstel,
    mapAcceptatieDlToUi,
    /* istanbul ignore next */
    state => {}, // Er is geen 'opslaan' mogelijkheid zit niet in scherm 'Acceptatie'.
    mapDlTargetToAcceptatieUiField
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (data) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTrigger])

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <Acceptatie saveData={saveData} {...data} />;
};

export const AcceptatieAjax = withErrorBoundary(AcceptatieAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") AcceptatieAjax.displayName = "AcceptatieAjax";
