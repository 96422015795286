import { LabeledDateInput, LabeledRadioInput, LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { GeslachtOpties } from "../../.generated/forms/formstypes";
import { AdresInput } from "../../shared/components/adres/adres-input";
import { Direction } from "../../shared/types";
import { TaxatieKenmerkenType, TaxatieSchermType } from "../infra/taxatie-scherm-schema";

const OpdrachtgeverTaxatie = ({
  formik: {
    values: { taxatieKenmerken }
  }
}: {
  formik: FormikContextType<TaxatieSchermType>;
}): ReactElement => {
  const kenmerken = taxatieKenmerken as TaxatieKenmerkenType;
  const telefoonnummerMobielTonen = kenmerken?.OpdrachtgeverTelefoonMobielTonen;
  const telefoonnummerWerkTonen = kenmerken?.OpdrachtgeverTelefoonWerkTonen;
  const telefoonnummerPriveTonen = kenmerken?.OpdrachtgeverTelefoonPriveTonen;
  const emailAdresTonen = kenmerken?.OpdrachtgeverEmailadresTonen;
  const geboortedatumTonen = kenmerken?.OpdrachtgeverGeboortedatumTonen;
  const adresTonen = kenmerken?.OpdrachtgeverAdresTonen;

  return (
    <>
      <LabeledTextInput name="opdrachtgever.achternaam" caption="Achternaam" />

      <LabeledTextInput name="opdrachtgever.voorletters" caption="Voorletters" />

      <LabeledTextInput name="opdrachtgever.voorvoegsel" caption="Tussenvoegsel" />

      <LabeledRadioInput
        name="opdrachtgever.geslacht"
        caption="Geslacht"
        layout={Direction.Vertical}
        options={[
          { label: "Man", value: GeslachtOpties.Man },
          { label: "Vrouw", value: GeslachtOpties.Vrouw }
        ]}
      />
      {geboortedatumTonen && <LabeledDateInput caption="Geboortedatum" name="opdrachtgever.geboortedatum" />}

      {adresTonen && <AdresInput name="opdrachtgever.adres" metLand={false} plaatsCaption="Woonplaats" />}
      {telefoonnummerPriveTonen && (
        <LabeledTextInput name="opdrachtgever.telefoonnummerPrive" caption="Telefoonnummer (privé)" />
      )}
      {telefoonnummerMobielTonen && (
        <LabeledTextInput name="opdrachtgever.telefoonnummerMobiel" caption="Telefoonnummer (mobiel)" />
      )}
      {telefoonnummerWerkTonen && (
        <LabeledTextInput name="opdrachtgever.telefoonnummerWerk" caption="Telefoonnummer (werk)" />
      )}
      {emailAdresTonen && (
        <LabeledTextInput autoComplete="new-password" name="opdrachtgever.emailAdres" caption="E-mailadres" />
      )}
    </>
  );
};

export default connect<{}, TaxatieSchermType>(OpdrachtgeverTaxatie);
