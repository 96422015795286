import { AdviesBoxColumn } from "adviesbox-shared";
import React from "react";
import { CellInfo } from "react-table-6";

export const staffelColumns: AdviesBoxColumn[] = [
  {
    Header: "Percentage AO van",
    accessor: "ondergrensPercentage",
    Cell: "PercentageInput"
  },
  {
    Header: "Percentage AO tot",
    accessor: "bovengrensPercentage",
    Cell: "PercentageInput"
  },
  {
    Header: "Percentage uitkering",
    accessor: "uitkeringPercentage",
    Cell: "PercentageInput"
  },
  {
    Cell: "DeleteButton"
  }
];

/* eslint-disable react/display-name */
export const indexeringenColumns: AdviesBoxColumn[] = [
  {
    Header: "Percentage AO van",
    accessor: "ondergrensPercentage",
    Cell: (c: CellInfo) => {
      return (
        <div className="input-group">
          <div className="col-form-label" style={{ width: "100%", justifyContent: "flex-end" }}>
            {`${c.row.ondergrensPercentage ?? 0}%`}
          </div>
        </div>
      );
    }
  },
  {
    Header: "Percentage AO tot",
    accessor: "bovengrensPercentage",
    Cell: (c: CellInfo) => {
      return (
        <div className="input-group">
          <div className="col-form-label" style={{ width: "100%", justifyContent: "flex-end" }}>
            {`${c.row.bovengrensPercentage ?? 0}%`}
          </div>
        </div>
      );
    }
  },
  {
    Header: "Percentage uitkering",
    accessor: "uitkeringPercentage",
    Cell: (c: CellInfo) => {
      return (
        <div className="input-group">
          <div className="col-form-label" style={{ width: "100%", justifyContent: "flex-end" }}>
            {c.row.uitkeringPercentage === null || c.row.uitkeringPercentage < 0
              ? "AO%"
              : `${c.row.uitkeringPercentage ?? 0}%`}
          </div>
        </div>
      );
    }
  }
];
