import {
  CurrencyInput,
  Icon,
  LabeledCurrencyInput,
  LabeledNumberInput,
  LabeledRadioInput,
  LabeledTextInput,
  LabeledToggleInput,
  ModalButton,
  SyncHeight,
  SyncMultipleHeightProps,
  TooltipWrap
} from "adviesbox-shared";
import { LocalDate } from "@js-joda/core";
import { connect, FormikContextType, FormikHelpers } from "formik";
import React, { ReactElement, useCallback } from "react";
import { Button } from "react-bootstrap";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { enum2options } from "../../shared/utils/enum-to-options";
import { getSum } from "../../shared/utils/helpers";
import { getInkomenEnFiscusTextResources } from "../infra/inkomen-en-fiscus-resources";
import {
  InkomenEnFiscusState,
  InkomenSyncHeightIndex,
  InkomenVerklaring,
  OverigeInkomstenModalType
} from "../infra/inkomen-en-fiscus-schema";
import InkomenUitArbeidModal from "../inkomen-uit-arbeid-modal/inkomen-uit-arbeid-modal";
import InkomenUitArbeidScenario from "../inkomen-uit-arbeid-modal/inkomen-uit-arbeid-scenario";
import InkomstenAanmerkelijkBelangModal from "../inkomsten-aanmerkelijk-belang-modal/inkomsten-aanmerkelijk-belang-modal";
import InkomstenAanmerkelijkBelangScenario from "../inkomsten-aanmerkelijk-belang-modal/inkomsten-aanmerkelijk-belang-scenario";
import InkomstenUitBeleggingenModal from "../inkomsten-uit-beleggingen-modal/inkomsten-uit-beleggingen-modal";
import InkomstenVerhuurOnroerendGoedModal from "../inkomsten-verhuur-onroerend-goed-modal/inkomsten-verhuur-onroerend-goed-modal";
import OndernemingModal from "../onderneming-modal/onderneming-modal";
import OndernemingScenario from "../onderneming-modal/onderneming-scenario";
import OverigeInkomstenModal from "../overige-inkomsten-modal/overige-inkomsten-modal";
import OverigeWerkzaamhedenModal from "../overige-werkzaamheden-modal/overige-werkzaamheden-modal";
import OverigeWerkzaamhedenScenario from "../overige-werkzaamheden-modal/overige-werkzaamheden-scenario";

export const aanvrager2CalcOverigeInkomstenBox1Totaal = (
  result: OverigeInkomstenModalType,
  setFieldValue: FormikHelpers<InkomenEnFiscusState>["setFieldValue"]
): void => {
  setFieldValue(
    "aanvrager2Inkomen.overigeInkomstenBox1.totaal",
    getSum([
      result.alimentatieExBedrag,
      result.anwUitkeringBedrag,
      result.ivaUitkeringBedrag,
      result.calculatedDate?.isAfter(LocalDate.now())
        ? result.nabestaandenpensioenVoorAowBedrag
        : result.nabestaandenpensioenNaAowBedrag,
      result.overigeInkomsten1Bedrag,
      result.overigeInkomsten2Bedrag,
      result.rwwUitkeringBedrag,
      result.wachtgeldBedrag,
      result.wajongUitkeringBedrag,
      result.waoUitkeringBlijvendBedrag,
      result.waoUitkeringTijdelijkBedrag,
      result.wazUitkeringBedrag,
      result.wgaUitkeringBedrag,
      result.wwUitkeringBedrag
    ])
  );
};

const Aanvrager2Inkomen = ({
  formik: {
    setFieldValue,
    values: { aanvrager2Inkomen, aanvrager2Aow }
  },
  targetRef,
  sourceRef
}: SyncMultipleHeightProps & {
  formik: FormikContextType<InkomenEnFiscusState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const firstInkomenUitArbeid = aanvrager2Inkomen.inkomenUitArbeid[0];
  const isCalculating =
    aanvrager2Inkomen.inkomenUitArbeid.length === 1 &&
    (aanvrager2Inkomen.brutoSalarisUitDienstverband || 0) !== (firstInkomenUitArbeid.totaalBruto || 0);

  return (
    <>
      <SyncHeight
        sourceRef={sourceRef && sourceRef[InkomenSyncHeightIndex.BrutoSalarisUitDienstverband]}
        targetRef={targetRef && targetRef[InkomenSyncHeightIndex.BrutoSalarisUitDienstverband]}
      >
        <LabeledToggleInput
          caption="Bruto salaris uit dienstverband"
          name="aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled"
          enableWarning={
            aanvrager2Inkomen.inkomenUitArbeid.length > 1 ||
            aanvrager2Inkomen.brutoSalarisUitDienstverband !== null ||
            !!aanvrager2Inkomen.inkomenUitArbeid[0].totaalBruto
          }
          warningTitle={getInkomenEnFiscusTextResources("WarningTitleInkomenUitArbeidVerwijderen")}
          warningTekst={getInkomenEnFiscusTextResources("WarningTekstInkomenUitArbeidVerwijderen")}
          warningConfirmButtonTekst={getInkomenEnFiscusTextResources("WarningConfirmButtonkomenUitArbeidVerwijderen")}
          appendChildren={
            <CurrencyInput
              name={"aanvrager2Inkomen.brutoSalarisUitDienstverband"}
              readonly={aanvrager2Inkomen.inkomenUitArbeid.length > 1}
              placeholder="-"
              appendChildren={
                <>
                  <ModalButton
                    parent="aanvrager2Inkomen.inkomenUitArbeid"
                    resetSize={true}
                    content={<Icon name="specificatie" alt="Inkomen uit arbeid" />}
                    size="lg"
                    disabled={isCalculating}
                  >
                    <InkomenUitArbeidModal
                      geboorteDatum={aanvrager2Aow.geboorteDatum}
                      data={aanvrager2Inkomen.inkomenUitArbeid}
                      onSave={createSaveFunction("aanvrager2Inkomen.inkomenUitArbeid")}
                    />
                  </ModalButton>
                  <ModalButton
                    distinguisher="modal-scenario"
                    parent="aanvrager2Inkomen.inkomenUitArbeid"
                    resetSize={true}
                    content={<Icon name="scenario" alt="Scenario inkomen" />}
                    disabled={isCalculating}
                  >
                    <InkomenUitArbeidScenario
                      geboorteDatum={aanvrager2Aow.geboorteDatum}
                      data={aanvrager2Inkomen.inkomenUitArbeid}
                      onSave={createSaveFunction("aanvrager2Inkomen.inkomenUitArbeid")}
                    />
                  </ModalButton>
                </>
              }
            />
          }
        />

        {aanvrager2Inkomen.brutoSalarisUitDienstverbandIsEnabled && (
          <div>
            <LabeledRadioInput
              caption="Verklaring inkomen"
              name="aanvrager2Inkomen.verklaringInkomen"
              options={enum2options(InkomenVerklaring)}
            />
          </div>
        )}

        {aanvrager2Inkomen.verklaringInkomen === InkomenVerklaring.ArbeidScan && (
          <>
            <LabeledCurrencyInput caption="Verdiencapaciteit" name="aanvrager2Inkomen.arbeidsscanVerdiencapaciteit" />
            <LabeledNumberInput caption="Score" name="aanvrager2Inkomen.arbeidsscanScore" />
            <LabeledTextInput caption="Rapportnummer" name="aanvrager2Inkomen.arbeidsscanRapportnummer" />
          </>
        )}

        {aanvrager2Inkomen.verklaringInkomen === InkomenVerklaring.UWVdocument && (
          <div className="form-group form-row mt-2">
            <div className="col-7">
              <a
                href={"https://www.toetsinkomenberekenen.nl/"}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="pl-3 pr-3">
                  <span>Toetsinkomen UWV</span>&nbsp;
                  <i className="icon externallink" />
                </span>
              </a>
              <TooltipWrap
                name="aanvrager2Inkomen.toetsinkomenUwv"
                warningText={getInkomenEnFiscusTextResources("TooltipToetsinkomenUwv")}
                placement="bottom"
                tooltipClasses="question-tip"
                iconType="questionmark"
              >
                <Button className="question-tip" id="aanvrager2Inkomen.toetsinkomenUwv-tooltip" />
              </TooltipWrap>
            </div>
            <div className="col-5">
              <CurrencyInput name={`aanvrager2Inkomen.toetsinkomenUwv`} decimalen={0} />
            </div>
          </div>
        )}
      </SyncHeight>

      <LabeledToggleInput
        caption="Onderneming"
        name="aanvrager2Inkomen.ondernemingIsEnabled"
        appendChildren={
          <CurrencyInput
            name="aanvrager2Inkomen.onderneming.inkomensverleden.gemiddeld"
            readonly={true}
            placeholder="-"
            appendChildren={
              <>
                <ModalButton
                  resetSize={true}
                  parent="aanvrager2Inkomen.onderneming"
                  content={<Icon name="specificatie" alt="Onderneming" />}
                >
                  <OndernemingModal
                    data={aanvrager2Inkomen.onderneming}
                    onSave={createSaveFunction("aanvrager2Inkomen.onderneming")}
                  />
                </ModalButton>
                <ModalButton
                  resetSize={true}
                  parent="aanvrager2Inkomen.onderneming.scenario"
                  content={<Icon name="scenario" alt="Scenario onderneming" />}
                  size="lg"
                >
                  <OndernemingScenario
                    geboorteDatum={aanvrager2Aow.geboorteDatum}
                    data={aanvrager2Inkomen.onderneming}
                    onSave={createSaveFunction("aanvrager2Inkomen.onderneming")}
                  />
                </ModalButton>
              </>
            }
          />
        }
      />

      <LabeledToggleInput
        caption="Overige werkzaamheden"
        name="aanvrager2Inkomen.overigeWerkzaamhedenIsEnabled"
        appendChildren={
          <CurrencyInput
            name="aanvrager2Inkomen.overigeWerkzaamheden.inkomensverleden.gemiddeld"
            readonly={true}
            placeholder="-"
            appendChildren={
              <>
                <ModalButton
                  resetSize={true}
                  parent="aanvrager2Inkomen.overigeWerkzaamheden"
                  content={<Icon name="specificatie" alt="Overige werkzaamheden" />}
                >
                  <OverigeWerkzaamhedenModal
                    data={aanvrager2Inkomen.overigeWerkzaamheden}
                    onSave={createSaveFunction("aanvrager2Inkomen.overigeWerkzaamheden")}
                  />
                </ModalButton>
                <ModalButton
                  resetSize={true}
                  parent="aanvrager2Inkomen.overigeWerkzaamheden.scenario"
                  content={<Icon name="scenario" alt="Scenario overige werkzaamheden" />}
                >
                  <OverigeWerkzaamhedenScenario
                    geboorteDatum={aanvrager2Aow.geboorteDatum}
                    data={aanvrager2Inkomen.overigeWerkzaamheden}
                    onSave={createSaveFunction("aanvrager2Inkomen.overigeWerkzaamheden")}
                  />
                </ModalButton>
              </>
            }
          />
        }
      />

      <LabeledToggleInput
        caption="Overige inkomsten box 1"
        name="aanvrager2Inkomen.overigeInkomstenBox1IsEnabled"
        appendChildren={
          <CurrencyInput
            name="aanvrager2Inkomen.overigeInkomstenBox1.totaal"
            placeholder="-"
            readonly={true}
            appendChildren={
              <ModalButton
                resetSize={true}
                parent="aanvrager2Inkomen.overigeInkomstenBox1"
                content={<Icon name="specificatie" alt="Overige inkomsten" />}
                size="lg"
              >
                <OverigeInkomstenModal
                  data={aanvrager2Inkomen.overigeInkomstenBox1}
                  onSave={createSaveFunction(
                    "aanvrager2Inkomen.overigeInkomstenBox1",
                    aanvrager2CalcOverigeInkomstenBox1Totaal
                  )}
                />
              </ModalButton>
            }
          />
        }
      />

      <LabeledToggleInput
        caption="Inkomsten aanmerkelijk belang"
        name="aanvrager2Inkomen.inkomstenAanmerkelijkBelangIsEnabled"
        appendChildren={
          <CurrencyInput
            name="aanvrager2Inkomen.inkomstenAanmerkelijkBelang.inkomensverleden.gemiddeld"
            readonly={true}
            placeholder="-"
            appendChildren={
              <>
                <ModalButton
                  resetSize={true}
                  parent="aanvrager2Inkomen.inkomstenAanmerkelijkBelang"
                  content={<Icon name="specificatie" alt="Inkomsten aanmerkelijk belang" />}
                >
                  <InkomstenAanmerkelijkBelangModal
                    data={aanvrager2Inkomen.inkomstenAanmerkelijkBelang}
                    onSave={createSaveFunction("aanvrager2Inkomen.inkomstenAanmerkelijkBelang")}
                  />
                </ModalButton>
                <ModalButton
                  resetSize={true}
                  parent="aanvrager2Inkomen.inkomstenAanmerkelijkBelang.scenario"
                  content={<Icon name="scenario" alt="Scenario inkomsten aanmerkelijk belang" />}
                  size="lg"
                >
                  <InkomstenAanmerkelijkBelangScenario
                    geboorteDatum={aanvrager2Aow.geboorteDatum}
                    data={aanvrager2Inkomen.inkomstenAanmerkelijkBelang}
                    onSave={createSaveFunction("aanvrager2Inkomen.inkomstenAanmerkelijkBelang")}
                  />
                </ModalButton>
              </>
            }
          />
        }
      />

      <SyncHeight
        sourceRef={sourceRef && sourceRef[InkomenSyncHeightIndex.InkomstenBox3]}
        targetRef={targetRef && targetRef[InkomenSyncHeightIndex.InkomstenBox3]}
      >
        <LabeledToggleInput caption="Inkomsten box 3" name="aanvrager2Inkomen.inkomstenBox3IsEnabled" />

        {aanvrager2Inkomen.inkomstenBox3IsEnabled && (
          <>
            <div>
              <LabeledCurrencyInput
                caption="Inkomsten verhuur onr. goed"
                name="aanvrager2Inkomen.inkomstenVerhuurOnroerendGoed.inkomstenVerhuurOnroerendGoed"
                readonly={true}
                placeholder="-"
                appendChildren={
                  <ModalButton
                    resetSize={true}
                    parent="aanvrager2Inkomen.inkomstenVerhuurOnroerendGoed"
                    content={<Icon name="specificatie" alt="Inkomsten verhuur onr. goed" />}
                  >
                    <InkomstenVerhuurOnroerendGoedModal
                      data={aanvrager2Inkomen.inkomstenVerhuurOnroerendGoed}
                      onSave={createSaveFunction("aanvrager2Inkomen.inkomstenVerhuurOnroerendGoed")}
                    />
                  </ModalButton>
                }
              />
            </div>
            <div>
              <LabeledCurrencyInput
                caption="Inkomsten uit beleggingen"
                name="aanvrager2Inkomen.inkomstenUitBeleggingen.inkomstenUitBeleggingen"
                readonly={true}
                placeholder="-"
                appendChildren={
                  <ModalButton
                    resetSize={true}
                    parent="aanvrager2Inkomen.inkomstenUitBeleggingen"
                    content={<Icon name="specificatie" alt="Inkomsten uit beleggingen" />}
                  >
                    <InkomstenUitBeleggingenModal
                      data={aanvrager2Inkomen.inkomstenUitBeleggingen}
                      onSave={createSaveFunction("aanvrager2Inkomen.inkomstenUitBeleggingen")}
                    />
                  </ModalButton>
                }
              />
            </div>
          </>
        )}
      </SyncHeight>
    </>
  );
};

export default connect<SyncMultipleHeightProps, InkomenEnFiscusState>(Aanvrager2Inkomen);
