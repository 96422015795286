import { jaarMaandInMaanden } from "adviesbox-shared";
import {
  Dashboard,
  DashboardScenarioArbeidsongeschiktheid,
  DashboardScenarioOverlijden,
  DashboardScenarioPensioen,
  DashboardScenarioWerkloosheid,
  DashboardUitgangspuntenArbeidsongeschiktheid,
  DashboardUitgangspuntenOverlijden,
  DashboardUitgangspuntenPensioen,
  DashboardUitgangspuntenWerkloosheid,
  MotiveringBeschikbareJaarruimte,
  MotiveringControleHypotheeknorm,
  MotiveringControleGewenstInkomen
} from "../../.generated/forms/formstypes";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { controleNormSchema } from "../components/controle-norm/infra/controle-norm-schema";
import { ScenarioVanType } from "../components/scenario-van/scenario-van-schema";
import { scenarioArbeidsongeschiktheidModalSchema } from "../scenario-arbeidsongeschiktheid-modal/infra/scenario-arbeidsongeschiktheid-modal-schema";
import { scenarioOverlijdenModalSchema } from "../scenario-overlijden-modal/infra/scenario-overlijden-modal-schema";
import {
  beschikbareJaarruimteSchema,
  scenarioPensioenModalSchema
} from "../scenario-pensioen-modal/infra/scenario-pensioen-modal-schema";
import { scenarioWerkloosheidModalSchema } from "../scenario-werkloosheidheid-modal/infra/scenario-werkloosheid-modal-schema";
import { dashboardSchema } from "./dashboard-schema";

type AanvragersType = { aanvrager1: KlantnaamType; aanvrager2: KlantnaamType | null };
const mapControleNorm = createMapToDl(controleNormSchema).to<MotiveringControleHypotheeknorm>({
  controleMaandlast: v => (v.controleMaandlast ? v.controleMaandLastKeuze : null),
  controleerMaximaleHypotheek: v => v.maximaleHypotheeksom
});

const mapKlantIds = (
  scenarioVan: ScenarioVanType,
  aanvrager1: KlantnaamType,
  aanvrager2: KlantnaamType | null
): string[] => {
  const res: string[] = [];
  if (scenarioVan.aanvrager1Geselecteerd || !aanvrager2) {
    res.push(aanvrager1.klantId);
  }
  if (aanvrager2 && scenarioVan.aanvrager2Geselecteerd) {
    res.push(aanvrager2.klantId);
  }

  return res;
};

const mapControleGewenstInkomen = createMapToDl(controleNormSchema).to<MotiveringControleGewenstInkomen>({
  controleGewenstInkomen: v => v.controleGewenstInkomen,
  brutoInkomenswensPercentage: v => v.controleGewenstInkomen === "BrutoInkomenswens" ? v.brutoInkomenswens : null,
  controleGewenstNbi: v => (v.controleGewenstInkomen === "Nbi" ? v.gewenstNettoBesteedbaarInkomen : null),
  margeOndergrensPercentage: v =>
    v.controleGewenstInkomen === "Nbi" && v.gewenstNettoBesteedbaarInkomen === "MargeHuidigNbi"
      ? v.margeOndergrensPercentage
      : null,
  margeBovengrensPercentage: v =>
    v.controleGewenstInkomen === "Nbi" && v.gewenstNettoBesteedbaarInkomen === "MargeHuidigNbi"
      ? v.margeBovengrensPercentage
      : null,
  vastBedrag: v =>
    v.controleGewenstInkomen === "Nbi" && v.gewenstNettoBesteedbaarInkomen === "VastBedrag"
      ? v.gewenstVastBedrag
      : null,
  huidigNbiPercentage: v =>
    v.controleGewenstInkomen === "Nbi" && v.gewenstNettoBesteedbaarInkomen === "PercentageHuidigNbi"
      ? v.gewenstPercentageHuidigNbi
      : null,
  gewijzigdeJarenTonen: v => v.gewijzigdeJaren || false,
  benodigdKapitaalTonen: v => v.benodigdKapitaal || false
});

const mapOrvUitgangspunten = createMapToDl(scenarioOverlijdenModalSchema)
  .with<AanvragersType>()
  .to<DashboardUitgangspuntenOverlijden>({
    controleHypotheeknorm: v => mapControleNorm(v.controleNorm),
    controleGewenstInkomen: v =>
      v.controleNorm.controleGewenstInkomen !== "Geen" ? mapControleGewenstInkomen(v.controleNorm) : null,
    duurOverzicht: v => v.orv.gewensteDuurOverzicht,
    overzichtDuurInJaren: v => (v.orv.gewensteDuurOverzicht === "VasteDuurInJaren" ? v.orv.aantalJaar : null),
    overzichtTotLeeftijdInMaanden: v =>
      v.orv.gewensteDuurOverzicht === "TotLeeftijd" ? jaarMaandInMaanden(v.orv.leeftijd) : null,
    scenarioVanKlantIds: (v, c) => mapKlantIds(v.scenarioVan, c.aanvrager1, c.aanvrager2),
    werkenNaOverlijdenPartnerAanvrager1Percentage: v => v.orv.aanvragerPercentage,
    werkenNaOverlijdenPartnerAanvrager2Percentage: v => v.orv.partnerPercentage,
    // nswag update
    overzichtBeideTonen: v => false,
    motiveringVoorVoorstelId: () => null
  });

const mapAoUitgangspunten = createMapToDl(scenarioArbeidsongeschiktheidModalSchema)
  .with<AanvragersType>()
  .to<DashboardUitgangspuntenArbeidsongeschiktheid>({
    controleGewenstInkomen: v =>
      v.controleNorm.controleGewenstInkomen !== "Geen"
        ? mapControleGewenstInkomen(v.controleNorm)
        : /* istanbul ignore next */ null,
    controleHypotheeknorm: v => mapControleNorm(v.controleNorm),
    arbeidsongeschiktheidExtra1Percentage: v =>
      v.scenarioDoorrekening.scenarios[1]
        ? v.scenarioDoorrekening.scenarios[1].aoPercentage
        : /* istanbul ignore next */ null,
    arbeidsongeschiktheidExtra2Percentage: v =>
      v.scenarioDoorrekening.scenarios[2]
        ? v.scenarioDoorrekening.scenarios[2].aoPercentage
        : /* istanbul ignore next */ null,
    arbeidsongeschiktheidStandaardPercentage: v =>
      v.scenarioDoorrekening.scenarios[0]
        ? v.scenarioDoorrekening.scenarios[0].aoPercentage
        : /* istanbul ignore next */ null,
    benuttingVerdiencapaciteitExtra1Percentage: v =>
      v.scenarioDoorrekening.scenarios[1]
        ? v.scenarioDoorrekening.scenarios[1].benuttingRvc
        : /* istanbul ignore next */ null,
    benuttingVerdiencapaciteitExtra2Percentage: v =>
      v.scenarioDoorrekening.scenarios[2]
        ? v.scenarioDoorrekening.scenarios[2].benuttingRvc
        : /* istanbul ignore next */ null,
    benuttingVerdiencapaciteitStandaardPercentage: v =>
      v.scenarioDoorrekening.scenarios[0]
        ? v.scenarioDoorrekening.scenarios[0].benuttingRvc
        : /* istanbul ignore next */ null,
    duurzaamArbeidsongeschiktExtra1: v =>
      v.scenarioDoorrekening.scenarios[1]
        ? v.scenarioDoorrekening.scenarios[1].aoPercentage && v.scenarioDoorrekening.scenarios[1].aoPercentage >= 80
          ? v.scenarioDoorrekening.scenarios[1].duurzaamAo
          : /* istanbul ignore next */ null
        : /* istanbul ignore next */ null,
    duurzaamArbeidsongeschiktExtra2: v =>
      v.scenarioDoorrekening.scenarios[2]
        ? v.scenarioDoorrekening.scenarios[2].aoPercentage && v.scenarioDoorrekening.scenarios[2].aoPercentage >= 80
          ? v.scenarioDoorrekening.scenarios[2].duurzaamAo
          : /* istanbul ignore next */ null
        : /* istanbul ignore next */ null,
    duurzaamArbeidsongeschiktStandaard: v =>
      v.scenarioDoorrekening.scenarios[0]
        ? v.scenarioDoorrekening.scenarios[0].aoPercentage && v.scenarioDoorrekening.scenarios[0].aoPercentage >= 80
          ? v.scenarioDoorrekening.scenarios[0].duurzaamAo
          : /* istanbul ignore next */ null
        : /* istanbul ignore next */ null,
    scenarioDoorrekeningExtra1: v => !!v.scenarioDoorrekening.scenarios[1],
    scenarioDoorrekeningExtra2: v => !!v.scenarioDoorrekening.scenarios[2],
    scenarioVanKlantIds: (v, c) => mapKlantIds(v.scenarioVan, c.aanvrager1, c.aanvrager2),
    toetsjaar: v => v.scenarioDoorrekening.toetsjaarTekort,
    // nswag update
    overzichtBeideTonen: v => false,
    motiveringVoorVoorstelId: () => null
  });

const mapWwUitgangspunten = createMapToDl(scenarioWerkloosheidModalSchema)
  .with<AanvragersType>()
  .to<DashboardUitgangspuntenWerkloosheid>({
    controleGewenstInkomen: v =>
      v.controleNorm.controleGewenstInkomen !== "Geen" ? mapControleGewenstInkomen(v.controleNorm) : null,
    controleHypotheeknorm: v => mapControleNorm(v.controleNorm),
    scenarioVanKlantIds: (v, c) => mapKlantIds(v.scenarioVan, c.aanvrager1, c.aanvrager2),
    // nswag update
    overzichtBeideTonen: v => false,
    motiveringVoorVoorstelId: () => null
  });

const mapBeschikbareJaarruimte = createMapToDl(beschikbareJaarruimteSchema).to<MotiveringBeschikbareJaarruimte>({
  winstBedrag: v => v.winstOndernemingsaftrek.bedrag || 0,
  winstOvernemen: v => v.winstOndernemingsaftrek.berekenen || false,
  winstBerekendBedrag: v => v.winstOndernemingsaftrek.berekendBedrag || 0,
  tegenwoordigeDienstbetrekkingBedrag: v => v.inkomenHuidigeDienstbetrekking.bedrag || 0,
  tegenwoordigeDienstbetrekkingBerekendBedrag: v => v.inkomenHuidigeDienstbetrekking.berekendBedrag || 0,
  tegenwoordigeDienstbetrekkingOvernemen: v => v.inkomenHuidigeDienstbetrekking.berekenen || false,
  vroegereDienstbetrekkingBedrag: v => v.inkomenVroegereDienstbetrekking.bedrag || 0,
  vroegereDienstbetrekkingOvernemen: v => v.inkomenVroegereDienstbetrekking.berekenen || false,
  vroegereDienstbetrekkingBerekendBedrag: v => v.inkomenVroegereDienstbetrekking.berekendBedrag || 0,
  buitenlandseInkomstenBedrag: v => v.buitenlandseInkomsten,
  reisaftrekBedrag: v => v.reisaftrekWoonWerkverkeer || 0,
  overigeWerkzaamhedenBedrag: v => v.resultaatOverigeWerkzaamheden.bedrag || 0,
  overigeWerkzaamhedenOvernemen: v => v.resultaatOverigeWerkzaamheden.berekenen || false,
  overigeWerkzaamhedenBerekendBedrag: v => v.resultaatOverigeWerkzaamheden.berekendBedrag || 0,
  periodiekeUitkeringenBedrag: v => v.periodiekeUitkering,
  pensioenaangroeiBedrag: v => v.pensioenaangroeiBedrag,
  toevoegingOdrBedrag: v => v.toevoegingOudedagreserve,
  benutteJaarruimteBedrag: v => v.benutteJaarruimteBedrag.bedrag || 0,
  benutteJaarruimteOvernemen: v => v.benutteJaarruimteBedrag.berekenen || false,
  benutteJaarruimteBerekendBedrag: v => v.benutteJaarruimteBedrag.berekendBedrag || 0,
  beschikbareJaarruimteBedrag: v => v.beschikbareJaarruimteBedrag,
  jaarruimteBedrag: v => v.jaarruimteBedrag,
  premiegrondslagBedrag: v => v.premiegrondslagBedrag,
  subtotaalFranchise1Bedrag: v => v.subtotaalFranchise1Bedrag,
  subtotaalFranchise2Bedrag: v => v.subtotaalFranchise2Bedrag,
  subtotaalPensioenaangroeiBedrag: v => v.subtotaalPensioenaangroeiBedrag,
  maxPremieGrondslag: v => v.maxPremieGrondslag,
  pensioenaangroeiPercentage: v => v.pensioenaangroeiPercentage,
  premiegrondslagPercentage: v => v.premiegrondslagPercentage,
  franchiseBedrag: v => v.franchiseBedrag,
  gewensteDuurPensioenUitkering: v => v.gewenstePensioenUitkering,
  pensioenuitkeringTotLeeftijdInMaanden: v =>
    v.gewenstePensioenUitkering === "TotLeeftijd" && v.gewenstePensioenLeeftijd
      ? v.gewenstePensioenLeeftijd * 12
      : null,
  subtotaalToevoegingOdrBedrag: _ => 0
});

const mapPensoenUitgangspunten = createMapToDl(scenarioPensioenModalSchema)
  .with<AanvragersType>()
  .to<DashboardUitgangspuntenPensioen>({
    beschikbareJaarruimteAanvrager1: v =>
      mapBeschikbareJaarruimte(v.beschikbareJaarruimteAanvrager || beschikbareJaarruimteSchema.default()),
    beschikbareJaarruimteAanvrager2: v =>
      mapBeschikbareJaarruimte(v.beschikbareJaarruimtePartner || beschikbareJaarruimteSchema.default()),
    controleGewenstInkomen: v =>
      v.controleNorm.controleGewenstInkomen !== "Geen" ? mapControleGewenstInkomen(v.controleNorm) : null,
    controleHypotheeknorm: v => mapControleNorm(v.controleNorm),
    scenarioVanKlantIds: (v, c) => mapKlantIds(v.scenarioVan, c.aanvrager1, c.aanvrager2),
    // nswag update
    overzichtBeideTonen: v => false,
    motiveringVoorVoorstelId: () => null
  });

const mapOverlijden = createMapToDl(scenarioOverlijdenModalSchema)
  .with<AanvragersType>()
  .to<DashboardScenarioOverlijden>({
    uitgangspunten: (v, c) => mapOrvUitgangspunten(c)(v),
    tekort: v => null
  });

const mapArbeidsongeschiktheid = createMapToDl(scenarioArbeidsongeschiktheidModalSchema)
  .with<AanvragersType>()
  .to<DashboardScenarioArbeidsongeschiktheid>({
    uitgangspunten: (v, c) => mapAoUitgangspunten(c)(v),
    tekort: v => null
  });

const mapWerkloosheid = createMapToDl(scenarioWerkloosheidModalSchema)
  .with<AanvragersType>()
  .to<DashboardScenarioWerkloosheid>({
    uitgangspunten: (v, c) => mapWwUitgangspunten(c)(v),
    tekort: v => null
  });

const mapPensioen = createMapToDl(scenarioPensioenModalSchema)
  .with<AanvragersType>()
  .to<DashboardScenarioPensioen>({
    uitgangspunten: (v, c) => mapPensoenUitgangspunten(c)(v),
    tekort: v => null
  });

export const mapDashboardUiToDl = createMapToDl(dashboardSchema).to<Dashboard>({
  overlijden: v => mapOverlijden({ aanvrager1: v.aanvrager1, aanvrager2: v.aanvrager2 })(v.overlijden),
  arbeidsongeschiktheid: v =>
    mapArbeidsongeschiktheid({ aanvrager1: v.aanvrager1, aanvrager2: v.aanvrager2 })(v.arbeidsongeschiktheid),
  pensioen: v => mapPensioen({ aanvrager1: v.aanvrager1, aanvrager2: v.aanvrager2 })(v.pensioen),
  werkloosheid: v => mapWerkloosheid({ aanvrager1: v.aanvrager1, aanvrager2: v.aanvrager2 })(v.werkloosheid),
  aanvrager1: v => mapKlantnaamUi2Dl(v.aanvrager1),
  aanvrager2: v => mapKlantnaamUi2Dl(v.aanvrager2)
});
