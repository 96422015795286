import React, { ReactElement, useCallback } from "react";
import { FormikContextType, connect } from "formik";

import HypotheekOptiesModal from "./hypotheekopties-modal";
import { Icon, LabeledResult } from "adviesbox-shared";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { ModalButton } from "adviesbox-shared";
import { HypothekenState } from "../infra/hypotheek-types";
import { getHypotheekTextResources } from "../infra/hypotheek-resources";

export type HypotheekOptiesProps = {
  selected: number;
};
const Hypotheekopties = ({
  selected,
  formik: { setFieldValue, values }
}: {
  formik: FormikContextType<HypothekenState>;
} & HypotheekOptiesProps): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);

  return (
    <>
      <LabeledResult
        caption="Hypotheekopties"
        name={`hypotheekOpties[${selected}]`}
        tooltip={getHypotheekTextResources("HypotheekOptiesTooltip")}
        appendChildren={
          <ModalButton
            resetSize={true}
            parent={`hypotheekOpties[${selected}]`}
            content={<Icon name="specificatie" alt="Hypotheekopties" />}
          >
            <HypotheekOptiesModal data={values.hypotheekOptie} onSave={createSaveFunction(`hypotheekOptie`)} />
          </ModalButton>
        }
        result={(): string =>
          `${values.hypotheekOptie.hypotheekOpties.filter(c => c.geselecteerd).length || 0} geselecteerd`
        }
      />
    </>
  );
};

export default connect<HypotheekOptiesProps, HypothekenState>(Hypotheekopties);
