import { LocalDate } from "@js-joda/core";
import { AdviesBoxFormik, RadioInputGroup, getFormattedDate } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { default as ModalBody } from "../../shared/components/modal/Modal";
import { Geslacht, OpslagWarningModalProps, personaliaSchema, PersonaliaState } from "../infra/personalia-schema";
import { useFormikContext } from "formik";

const OpslagWarningModal = ({
  showModal,
  setShowModal,
  setWarnings,
  warnings,
  saveData,
  clickedRoute,
  resetForm,
  setRouteToClickedPath
}: OpslagWarningModalProps): ReactElement => {
  const { values, setValues, initialValues, setFieldValue, setSubmitting } = useFormikContext<PersonaliaState>();

  const revertChangesWarningConfig = {
    aanvrager2Changed:
      initialValues.medeAanvragerOpties?.medeAanvrager !== values.medeAanvragerOpties?.medeAanvrager &&
      `Medeaanvrager terugzetten naar waarde ${initialValues.medeAanvragerOpties?.medeAanvrager ? "Ja" : "Nee"}`,
    aanvrager1Smokes:
      initialValues.aanvrager1Extra?.roker !== values.aanvrager1Extra?.roker &&
      `Aanvrager roker terugzetten naar waarde ${initialValues.aanvrager1Extra?.roker ? "Ja" : "Nee"}`,
    aanvrager2Smokes:
      initialValues.aanvrager2Extra?.roker !== values.aanvrager2Extra?.roker &&
      `Partner roker terugzetten naar waarde ${initialValues.aanvrager2Extra?.roker ? "Ja" : "Nee"}`,
    aanvrager1gender:
      initialValues.aanvrager1Extra?.geslacht !== values.aanvrager1Extra?.geslacht &&
      `Aanvrager geslacht terugzetten naar waarde ${initialValues.aanvrager1Extra?.geslacht}`,
    aanvrager2gender:
      initialValues.aanvrager2Extra?.geslacht !== values.aanvrager2Extra?.geslacht &&
      `Partner geslacht terugzetten naar waarde ${initialValues.aanvrager2Extra?.geslacht}`,
    aanvrager1birthdate:
      initialValues.aanvrager1Extra?.geboortedatum !== values.aanvrager1Extra?.geboortedatum &&
      `Aanvrager geboortedatum terugzetten naar waarde ${getFormattedDate(
        initialValues.aanvrager1Extra?.geboortedatum
      )}`,
    aanvrager2birthdate:
      initialValues.aanvrager2Extra?.geboortedatum !== values.aanvrager2Extra?.geboortedatum &&
      initialValues.aanvrager2Extra?.geboortedatum &&
      `Partner geboortedatum terugzetten naar waarde ${getFormattedDate(initialValues.aanvrager2Extra?.geboortedatum)}`
  };

  const closeModal = (): void => {
    setFieldValue("preventSave", true);
    setShowModal(false);
    setWarnings([]);
  };

  const confirmChosenOption = (herberekeningskeuze: string | null): (() => Promise<void>) => async (): Promise<
    void
  > => {
    setShowModal(false);

    /* istanbul ignore else */
    if (herberekeningskeuze === "reset") {
      /* istanbul ignore next */
      const update: PersonaliaState = {
        ...values,
        medeAanvragerOpties: {
          ...values.medeAanvragerOpties,
          medeAanvrager: values.initWaarschuwingen?.medeAanvrager
        },
        aanvrager1Extra: {
          ...values.aanvrager1Extra,
          roker: values.initWaarschuwingen?.aanvrager1roker,
          geslacht: values.initWaarschuwingen?.aanvrager1Geslacht,
          geboortedatum:
            values.initWaarschuwingen?.aanvrager1GeboorteDatum.toString() ===
            values.aanvrager1Extra.geboortedatum.toString()
              ? values.aanvrager1Extra.geboortedatum
              : values.initWaarschuwingen?.aanvrager1GeboorteDatum
        },
        aanvrager2Extra: values.initWaarschuwingen?.medeAanvrager
          ? {
              ...values.aanvrager2Extra,
              roker: values.initWaarschuwingen?.aanvrager2roker,
              geslacht: values.initWaarschuwingen?.aanvrager2Geslacht,
              geboortedatum:
                values.initWaarschuwingen?.aanvrager2GeboorteDatum.toString() ===
                values.aanvrager2Extra?.geboortedatum?.toString()
                  ? values.aanvrager2Extra?.geboortedatum
                  : values.initWaarschuwingen?.aanvrager2GeboorteDatum,
              hasTweedeAanvrager: true
            }
          : values.aanvrager2Extra,
        herberekeningskeuze: values.herberekeningskeuze,
        preventSave: false
      };

      setValues(update);

      /* istanbul ignore next */
      if (clickedRoute) {
        setRouteToClickedPath(true);
      }
      return;
    }

    const update: PersonaliaState = {
      ...values,
      preventSave: false,
      herberekeningskeuze: herberekeningskeuze,
      initWaarschuwingen: {
        aanvrager1GeboorteDatum: values.aanvrager1Extra.geboortedatum,
        aanvrager1Geslacht: values.aanvrager1Extra.geslacht,
        aanvrager1roker: values.aanvrager1Extra.roker,
        aanvrager2GeboorteDatum:
          values.aanvrager2Extra?.geboortedatum /* istanbul ignore next */ ??
          LocalDate.now()
            .minusYears(18)
            .minusMonths(2),
        aanvrager2Geslacht: values.aanvrager2Extra?.geslacht ?? /* istanbul ignore next */ Geslacht.Man,
        aanvrager2roker: values.aanvrager2Extra?.roker ?? /* istanbul ignore next */ false,
        medeAanvrager: values.medeAanvragerOpties.medeAanvrager
      }
    };

    setSubmitting(true);
    setValues(update);
    await saveData(update);

    resetForm();
    setSubmitting(false);

    /* istanbul ignore else */
    if (clickedRoute) {
      setRouteToClickedPath(true);
    }
  };

  return (
    <>
      <AdviesBoxFormik
        validationSchema={personaliaSchema}
        initialValues={{ ...values }}
        render={({ values: modalValues }) => (
          <Modal onHide={closeModal} show={showModal}>
            <ModalBody
              title={"Herberekening advies vanwege wijziging aan essentiële onderdelen"}
              onCancelClick={closeModal}
              onSubmitClick={confirmChosenOption(modalValues.herberekeningskeuze)}
              body={
                <>
                  <div>
                    <p>
                      Door deze wijziging zal een herberekening van het advies moeten plaatsvinden. Je kunt hieronder
                      aangeven welke mutaties op het advies moeten plaatsvinden.
                    </p>
                  </div>
                  <div>
                    <RadioInputGroup
                      options={[
                        ...warnings
                          .filter(warning => warning.some(wb => wb.message))
                          .map((warning, index) => ({
                            label: `optie ${index + 1}`,
                            value: `${index + 1}`,
                            description: warning.map(warningBody => warningBody.message as string)
                          })),
                        {
                          label: `optie ${warnings.filter(warning => warning.some(wb => wb.message)).length + 1}`,
                          description: Object.values(revertChangesWarningConfig).filter(warn => warn) as string[],
                          value: "reset",
                        }
                      ]}
                      className="w-100"
                      name={"herberekeningskeuze"}
                    />
                  </div>
                </>
              }
            />
          </Modal>
        )}
      />
    </>
  );
};

export default OpslagWarningModal;
