import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { bedragFormatNull } from "../scenarios/infra/scenario-utils";
import {
  HypotheekHuidigeSituatieOutput,
  HypotheekVoorstelOutput,
  HypotheekHuidigeSituatieLeningdeel,
  HypotheekVoorstelLeningdeel
} from "../../../.generated/forms/formstypes";

type Leningdeel = HypotheekHuidigeSituatieLeningdeel | HypotheekVoorstelLeningdeel;

const isVoorstelLeningdeel = (lening: Leningdeel): lening is HypotheekVoorstelLeningdeel => "doorlopend" in lening;

export const HypotheekOverzichtTable = ({
  data,
  index
}: {
  data: HypotheekHuidigeSituatieOutput | HypotheekVoorstelOutput;
  index: number;
}): ReactElement => {
  const hypotheekKey = data.hypotheken ? Object.keys(data.hypotheken)[0] : null;
  const hypotheek = hypotheekKey ? data.hypotheken?.[hypotheekKey] : null;
  if (!hypotheek || !hypotheek.leningdelen) return <></>;

  const hypotheeklabel = "hypotheeklabel" in hypotheek ? hypotheek.hypotheeklabel : null;
  const leningdelen = hypotheek.leningdelen;

  const leningdenDelen = ((leningdelen as unknown) as Leningdeel[]).filter(
    a => !isVoorstelLeningdeel(a) || (isVoorstelLeningdeel(a) && !a.doorlopend)
  );

  const doorlopendAanwezig =
    ((leningdelen as unknown) as Leningdeel[]).filter(a => isVoorstelLeningdeel(a) && a.doorlopend).length > 0;

  return (
    <>
      {leningdenDelen.length > 0 && (
        <Table striped bordered hover responsive className="scenario-table">
          <thead>
            <tr>
              {index === 0 && <th style={{ width: "80px" }}></th>}
              <th>
                {hypotheeklabel?.maatschappijOmschrijving ||
                  hypotheek.leningdelen?.[0]?.maatschappijOmschrijving ||
                  "Maatschappij productnaam"}
              </th>
            </tr>
          </thead>
          <tbody>
            {((leningdenDelen as unknown) as Leningdeel[]).map((lening: Leningdeel, indexLeningdeel: number) => (
              <React.Fragment key={indexLeningdeel}>
                <tr key={`main-${indexLeningdeel}`}>
                  {index === 0 && <td></td>}
                  <td className="">
                    {lening.hypotheekvorm.omschrijving || "aflosvorm"}
                    {": "}
                    {bedragFormatNull(lening.leningdeelBedrag, x => x, 0)}
                  </td>
                </tr>
                <tr key={`rate-${indexLeningdeel}`}>
                  {index === 0 && <td></td>}
                  <td>
                    Rentepercentage{": "}
                    {lening.renteScenario?.waarde}%
                  </td>
                </tr>
                <tr key={`period-${indexLeningdeel}`}>
                  {index === 0 && <td></td>}
                  <td>
                    Rentevastperiode{": "} {Math.floor((lening.rentevastAantalMaanden || 0) / 12)} jaar
                    {(lening.rentevastAantalMaanden || 0) % 12 > 0
                      ? ` en ${(lening.rentevastAantalMaanden || 0) % 12} maanden`
                      : ""}{" "}
                    | Looptijd {Math.floor((lening.looptijdInMaanden || 0) / 12)} jaar
                    {(lening.looptijdInMaanden || 0) % 12 > 0
                      ? ` en ${(lening.looptijdInMaanden || 0) % 12} maanden`
                      : ""}
                  </td>
                </tr>
                <tr key={`spacer-${indexLeningdeel}`}><td colSpan={2}></td></tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
      {doorlopendAanwezig && (
        <Table striped bordered hover responsive className="scenario-table my-2">
          <thead>
            <tr>
              {index === 0 && <th style={{ width: "80px" }}></th>}
              <th>Doorlopende leningen</th>
            </tr>
          </thead>
          <tbody>
            {((leningdelen as unknown) as Leningdeel[])
              .filter(a => isVoorstelLeningdeel(a) && a.doorlopend)
              .map((lening: Leningdeel, indexLeningdeel: number) => (
                <React.Fragment key={indexLeningdeel}>
                  {isVoorstelLeningdeel(lening) && lening.doorlopend && (
                    <tr key={`company-${indexLeningdeel}`}>
                      {index === 0 && <td></td>}
                      <td> {lening.maatschappijOmschrijving}</td>
                    </tr>
                  )}
                  <tr key={`main-${indexLeningdeel}`}>
                    {index === 0 && <td></td>}
                    <td className="">
                      {lening.hypotheekvorm.omschrijving || "aflosvorm"}
                      {": "}
                      {bedragFormatNull(lening.leningdeelBedrag, x => x, 0)}
                    </td>
                  </tr>
                  <tr key={`rate-${indexLeningdeel}`}>
                    {index === 0 && <td></td>}
                    <td>
                      Rentepercentage{": "}
                      {lening.renteScenario?.waarde}%
                    </td>
                  </tr>
                  <tr key={`period-${indexLeningdeel}`}>
                    {index === 0 && <td></td>}
                    <td>
                      Rentevastperiode{": "} {Math.floor((lening.rentevastAantalMaanden || 0) / 12)} jaar
                      {(lening.rentevastAantalMaanden || 0) % 12 > 0
                        ? ` en ${(lening.rentevastAantalMaanden || 0) % 12} maanden`
                        : ""}{" "}
                      | Looptijd {Math.floor((lening.looptijdInMaanden || 0) / 12)} jaar
                      {(lening.looptijdInMaanden || 0) % 12 > 0
                        ? ` en ${(lening.looptijdInMaanden || 0) % 12} maanden`
                        : ""}
                    </td>
                  </tr>
                  <tr key={`spacer-${indexLeningdeel}`}><td colSpan={2}></td></tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
}; 