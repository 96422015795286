import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import VerbouwingVerbeteringModal from "../verbouwing-verbetering-modal/verbouwing-verbetering-modal";
import { LabeledCurrencyInput, Icon } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

type verbouwingVerbeteringProps = {
  state: "bestaand" | "nieuwbouw" | "oversluiten";
};
const VerbouwingVerbetering = ({
  state,
  formik: { values, setFieldValue }
}: verbouwingVerbeteringProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const captionText = state === "nieuwbouw" ? "Meerwerk" : "Verbouwing/verbetering van de woning";

  return (
    <LabeledCurrencyInput
      caption={captionText}
      name="financieringsopzet.verbouwingVerbeteringModal.totaalVerbouwingVerbetering"
      appendChildren={
        <ModalButton
          resetSize={true}
          parent="financieringsopzet.verbouwingVerbeteringModal"
          aria-label="Verbouwing/verbetering button"
          content={<Icon name="specificatie" alt={captionText} />}
        >
          <VerbouwingVerbeteringModal
            soortFinanciering={values.financiering.soortFinanciering}
            data={values.financieringsopzet.verbouwingVerbeteringModal}
            onSave={(result): void => {
              setFieldValue("financieringsopzet.verbouwingVerbeteringModal", result, true);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<verbouwingVerbeteringProps, FinancieringsbehoefteState>(VerbouwingVerbetering);
