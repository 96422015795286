import React, { ReactElement } from "react";
import { Form, FormikProps } from "formik";
import { PageLoading, Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import AanTeKopenWoning from "./aan-te-kopen-woning/aan-te-kopen-woning";

import { determineWoningSideEffects } from "./infra/woning-side-effects";
import TeVerkopenWoning from "./te-verkopen-woning/te-verkopen-woning";
import BestaandeWoning from "./bestaande-woning/bestaande-woning";
import { woningSchema, WoningProps, WoningState } from "./infra/woning-schema";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { WithSaveData } from "../shared/utils/save-data";

import { SaveButton } from "../shared/components/save-button/save-button";
import { FinancieringOptions } from "../.generated/forms/formstypes";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const Woning = (props: FormikProps<WoningState> & WithSaveData<WoningState>): ReactElement => {
  const { values, isSubmitting } = props;

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}
        <ISWSideEffects<WoningState> sync={determineWoningSideEffects} />

        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        {values.financiering === FinancieringOptions.HuidigeHypotheekWijzigen ? (
          <CardWrapper maxRowCount={6} className="px-3">
            <Card title="Bestaande woning">
              <BestaandeWoning />
            </Card>
          </CardWrapper>
        ) : (
          <CardWrapper maxRowCount={6} className="px-3">
            {values.teVerkopenWoningen.map(
              (teVerkopenWoning, index): ReactElement => (
                <Card
                  title={`Huidige woning ${teVerkopenWoning.adres.straat} ${teVerkopenWoning.adres.huisnummer ?? ""}`}
                  key={index}
                >
                  <TeVerkopenWoning index={index} />
                </Card>
              )
            )}
            {values.aanTeKopenWoning && (
              <Card title="Aan te kopen woning">
                <AanTeKopenWoning />
              </Card>
            )}
          </CardWrapper>
        )}

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

Woning.displayName = "Woning";

export default withAdviesboxFormik<WoningProps, WoningState>({
  mapPropsToValues: (props): WoningState => ({
    financiering: props.financiering,
    hypotheekOpWoning: props.hypotheekOpWoning,
    teVerkopenWoningen: props.teVerkopenWoningen,
    aanTeKopenWoning: props.aanTeKopenWoning,
    huidigeWoningen: props.huidigeWoningen
  }),
  validationSchema: woningSchema
})(withAITracking(insightsReactPlugin, Woning));
