import {
  CardWrapper,
  PageLoading,
  FormFirstFocus,
  PlatformFoutenSamenvatting,
  ModalButton,
  useRequestInit,
  LoadingSpinner
} from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useMemo, useCallback } from "react";
import {
  KlantprofielOptieType,
  CardSpec,
  KlantprofielOptieProps,
  klantprofielOptieSchema,
  KlantProfielSchemaContextType
} from "./klantprofiel-schema";
import { WithSaveData } from "../../shared/utils/save-data";
import { renderSpec, combineQuestionSpecsFor } from "./question-helpers";
import { SaveButton } from "../../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../../shared/utils/with-adviesbox-formik";
import { DevDebug } from "../../shared/components/dev-debug/dev-debug";
import KlantprofielClassicModal from "../algemeen/klantprofiel-classic-viewer-popup/klantprofiel-classic-viewer";
import { useFetchData } from "../../shared/hooks/usefetchdata";

export const createValidationContext = (props: KlantprofielOptieProps): KlantProfielSchemaContextType => ({
  specs: combineQuestionSpecsFor(props.specs)
});

const RenderKlantProfielScherm = (
  props: FormikProps<KlantprofielOptieType> &
    WithSaveData<KlantprofielOptieType> & { specs: CardSpec[]; schermTitle: string }
): ReactElement => {
  const { isSubmitting, specs } = props;
  const vragen = renderSpec(specs, props);
  const { settings, params, requestInit } = useRequestInit<{ vestiging: string; adviesdossier: string }>();

  const getKlantprofielUrl = useMemo(() => {
    return `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Klantprofiel/abc`;
  }, [settings.klantdossiersFormsOrigin, params.adviesdossier]);

  const getRequestInit = useCallback(
    () => requestInit,
    [requestInit]
  );

  const { data, loading } = useFetchData<KlantprofielResponse>(getKlantprofielUrl, getRequestInit);
  /* istanbul ignore next */
  const profiel = data?.klantprofielen ? data.klantprofielen[Object.keys(data.klantprofielen)[0]] : null;

  return (
    <FormFirstFocus>
      <Form
        onKeyDown={
          /* istanbul ignore next */ event => {
            /* prevent validation triggered by using 'Enter'*/
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }
        }
      >
        {isSubmitting && <PageLoading />}
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>
              {profiel && (
                <ModalButton
                  validate={false}
                  parent=""
                  content={"Adviesbox antwoorden tonen"}
                  className={"ml-3 btn-secondary revert"}
                  size={"lg"}
                >
                  <KlantprofielClassicModal profiel={profiel} />
                </ModalButton>
              )}
              {loading && (
                <LoadingSpinner />
              )}
            </h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <div className="d-flex flex-wrap flex-row">
          <CardWrapper flexType="flex-column" className="pl-3">
            {vragen}
          </CardWrapper>
        </div>

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

RenderKlantProfielScherm.displayName = "RenderKlantProfielScherm";

export default withAdviesboxFormik<KlantprofielOptieProps, KlantprofielOptieType>({
  mapPropsToValues: (e: KlantprofielOptieProps): KlantprofielOptieType => ({
    aanvrager1: e.aanvrager1,
    aanvrager2: e.aanvrager2,
    vragen: e.vragen,
    risicoprofielStatus: e.risicoprofielStatus
  }),
  validationSchema: klantprofielOptieSchema,
  createValidationContext
})(RenderKlantProfielScherm);
