import { LocalDate } from "@js-joda/core";
import { AdviesBoxColumn, Card, CardWrapper, DataGrid, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useState } from "react";
import { Dashboard } from "../dashboard/dashboard";
import {
  SoortKapitaalverzekeringsrekeningOptions,
  SoortKapitaalverzekeringProductOptions
} from "../.generated/forms/formstypes";
import { SoortKapitaalverzekeringOptions } from "../.generated/producten/productentypes";
import { bepaalDefaultAflosproducten } from "../producten-overzicht/af-te-lossen-leningdelen-modal/bepaal-default-aflosproducten";
import { partijOnafhankelijk } from "../producten-overzicht/infra/product-constanten";
import {
  AlleMogelijkeProductBasisTypes,
  ProductSelectieType,
  SituatieSoort
} from "../producten-overzicht/infra/producten-overzicht-types";
import ProductSelectieAjax from "../producten-overzicht/product-selectie/product-selectie-ajax";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { SaveButton } from "../shared/components/save-button/save-button";
import { KredietType } from "../shared/generic-parts/krediet/schema";
import { LeningdeelType } from "../shared/generic-parts/leningdeel/schema";
import { assertNever } from "../shared/utils/helpers";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { bepaalKapitaalverzekeringKolommen } from "./bepaal-kapitaalverzekering-kolommen";
import { kapitaalverzekeringenSchema, kapitaalverzekeringSchema } from "./infra/kapitaalverzekering-schema";
import { KapitaalverzekeringenType, KapitaalverzekeringType } from "./infra/kapitaalverzekering-types";
import KapitaalverzekeringDetails from "./kapitaalverzekering-details/kapitaalverzekering-details";
import { useFeature } from "adviesbox-shared";
import { withAITracking } from "@microsoft/applicationinsights-react-js";


export const kapitaalverzekeringFilter = (product: AlleMogelijkeProductBasisTypes): boolean => {
  return product.maatschappijCode === partijOnafhankelijk && [1, 2].includes(product.code ?? 0);
};

export const newKapitaalverzekeringFactory = (
  productSelectie: ProductSelectieType,
  values: {
    ingangsdatumVoorstel: LocalDate | null;
    kredieten: KredietType[];
    leningdelen: LeningdeelType[];
  },
  situatie: SituatieSoort
): KapitaalverzekeringType => {
  const kapitaalverzekering = kapitaalverzekeringSchema.default();
  kapitaalverzekering.premieGegevens.looptijd = { maanden: 0, jaren: 30 };
  if(kapitaalverzekering.premieGegevens.premiedepot !== null) {
    kapitaalverzekering.premieGegevens.premiedepot.premieDuur = kapitaalverzekering.premieGegevens.looptijd;
  }
  kapitaalverzekering.product.wijzigingenInDoorlopendProductOvernemen = null;
  kapitaalverzekering.product.ingangsdatum =
    values.ingangsdatumVoorstel ??
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1);

  if (situatie === "huidig" && values.leningdelen[0]) {
    kapitaalverzekering.product.ingangsdatum = values.leningdelen[0].aanvangsdatum;
  }
  if (situatie === "voorstel" && values.ingangsdatumVoorstel) {
    kapitaalverzekering.product.ingangsdatum = values.ingangsdatumVoorstel;
  }

  kapitaalverzekering.product.einddatum = kapitaalverzekering.product.ingangsdatum.plusYears(
    kapitaalverzekering.product.looptijd.jaren || 30
  );

  if (productSelectie.codes.productVorm === SoortKapitaalverzekeringOptions.Lijfrenteverzekering) {
    kapitaalverzekering.soortProduct = SoortKapitaalverzekeringProductOptions.Levensverzekering;
  } else {
    kapitaalverzekering.soortProduct = productSelectie.codes.productVorm as SoortKapitaalverzekeringProductOptions;
  }

  switch (kapitaalverzekering.soortProduct) {
    case SoortKapitaalverzekeringProductOptions.Levensverzekering:
      kapitaalverzekering.kapitaalopbouw.soortRekening = SoortKapitaalverzekeringsrekeningOptions.Belegging;
      break;
    case SoortKapitaalverzekeringProductOptions.UniversalLifePremie:
      kapitaalverzekering.kapitaalopbouw.soortRekening = null;
      break;
    case SoortKapitaalverzekeringProductOptions.Product:
      break;
    default:
      assertNever(kapitaalverzekering.soortProduct);
  }

  kapitaalverzekering.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten = bepaalDefaultAflosproducten(values);

  return kapitaalverzekering;
};

type KapitaalverzekeringProps = KapitaalverzekeringenType & { situatie: SituatieSoort };

const Kapitaalverzekering = (
  props: KapitaalverzekeringProps & FormikProps<KapitaalverzekeringenType> & WithSaveData<KapitaalverzekeringenType>
): ReactElement => {
  const { isSubmitting, situatie, values } = props;
  const featureNewDashboard = useFeature("FeatureNewDashboard");
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { producten } = values;
  const selectedProduct = producten && producten[selected];

  const kapitaalverzekeringKolommen: AdviesBoxColumn[] = React.useMemo(
    () => bepaalKapitaalverzekeringKolommen(situatie),
    [situatie]
  ); 

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {isSubmitting && <PageLoading />}

            <CardWrapper className="px-3">
              <div className="text-container">
                <div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />

            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50" title="Kapitaalverzekering">
                <DataGrid
                  masterDetail
                  rowCaption="Kapitaalverzekering"
                  columns={kapitaalverzekeringKolommen}
                  rowSelected={selectedState}
                  name="producten"
                  validationSchema={kapitaalverzekeringSchema}
                  popup={
                    <ProductSelectieAjax
                      situatie={situatie}
                      productSoort="kapitaalverzekering"
                      productFilter={kapitaalverzekeringFilter}
                      productFactory={productSelectie =>
                        newKapitaalverzekeringFactory(productSelectie, values, situatie)
                      }
                    />
                  }
                  additionalButton={
                    situatie === "huidig" &&
                    selectedProduct &&
                    selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null && (
                      <ProductWijzigingenMeenemenButton
                        name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
                      />
                    )
                  }
                />
              </Card>
            </CardWrapper>

            {selectedProduct && <KapitaalverzekeringDetails situatie={situatie} selected={selected} />}

            <DevDebug />
          </div>

          { !featureNewDashboard && (
            <Dashboard situatie={situatie} saveData={props.saveData} formikParent={props} />
          )}
        </div>
      </Form>
    </FormFirstFocus>
  );
};

Kapitaalverzekering.displayName = "Kapitaalverzekering";

export default withAdviesboxFormik<
  KapitaalverzekeringProps & WithSaveData<KapitaalverzekeringenType>,
  KapitaalverzekeringenType
>({
  // Transform outer props into form values
  mapPropsToValues: (props: KapitaalverzekeringProps): KapitaalverzekeringenType => ({
    producten: props.producten,
    aanvrager1: props.aanvrager1,
    aanvrager2: props.aanvrager2,
    leningdelen: props.leningdelen,
    kredieten: props.kredieten,
    geldverstrekkerNaam: props.geldverstrekkerNaam,
    ingangsdatumVoorstel: props.ingangsdatumVoorstel
  }),
  validationSchema: kapitaalverzekeringenSchema
})(withAITracking(insightsReactPlugin, Kapitaalverzekering));
