import { Card, CardWrapper, PageLoading, useRequestInit, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import { Form, FormikContextType } from "formik";
import React, { ReactElement, useContext } from "react";
import { AppDataContext } from "../navigation/appdata-context";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import OpenDossierLogContext from "../shared/open-dossier-log/open-dossier-log-context";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import AanleidingGesprek from "./aanleiding-gesprek/aanleiding-gesprek";
import FinancielePlanning from "./financiele-planning/financiele-planning";
import FinancieringKeuze from "./financiering/financiering-keuze";
import { AanleidingProps, aanleidingSchema, AanleidingState, FinancieringOptions } from "./infra/aanleiding-schema";
import { aanleidingAsyncSideEffects } from "./infra/determine-aanleiding-async-side-effects";
import { determineAanleidingSideEffects } from "./infra/determine-aanleiding-side-effects";
import VoorstellenOverzicht from "./voorstellen/voorstellen-overzicht";
import { FinancieringWijzigenModal } from "./financiering/financiering-wijzigen-modal/financiering-wijzigen-modal";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const Aanleiding = (props: FormikContextType<AanleidingState> & AanleidingProps): ReactElement => {
  const {
    menuInfo: { adviseurIds }
  } = useContext(AppDataContext);
  const { isSubmitting, saveData } = props;
  const {
    lezenEnSchrijvenRechtenVestigingen: lezenEnSchrijvenRechten,
    getAdviseurIds,
    adviseurIds: adviseurIdsHuidigeGebruiker,
    adviseurIdsOphalen
  } = useContext(OpenDossierLogContext);
  const { params } = useRequestInit<{ vestiging: string }>();

  /* istanbul ignore next */
  if (adviseurIdsOphalen) {
    getAdviseurIds(params.vestiging);
  }

  /* istanbul ignore next */
  const huidigeGebruiker = adviseurIdsHuidigeGebruiker && adviseurIdsHuidigeGebruiker.map(e => e);
  const gebruikersEigenAangemaaktDossier = adviseurIds && huidigeGebruiker.includes(adviseurIds.toString());

  const opslaanRechten =
    lezenEnSchrijvenRechten === "LezenEnSchrijven" || gebruikersEigenAangemaaktDossier || !lezenEnSchrijvenRechten;
  const { seRunning, setSErunning } = useContext(AppDataContext);
  const modelStateModal = React.useState(false);
  const [showModal, setShowModal] = modelStateModal;
  const modelStatePrevFinancieringsKeuze = React.useState<FinancieringOptions | null>(null);
  // eslint-disable-next-line
  const [, setPrevFinancieringsKeuze] = modelStatePrevFinancieringsKeuze;
  const modelFinancieringsKeuzeOpslaan = React.useState<boolean | null>(null);
  const [, setFinancieringsKeuzeOpslaan] = modelFinancieringsKeuzeOpslaan;

  return (
    <FormFirstFocus>
      <Form>
        {(isSubmitting || seRunning) && <PageLoading />}

        <ISWSideEffects<AanleidingState>
          sync={determineAanleidingSideEffects({ modelStateModal, modelStatePrevFinancieringsKeuze, modelFinancieringsKeuzeOpslaan })}
          async={aanleidingAsyncSideEffects({ saveData, opslaanRechten, modelFinancieringsKeuzeOpslaan })}
          asyncStartStopCallback={val => {
            setSErunning(val);
          }}
        />

        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper maxRowCount={7} className="px-3">
          <Card title="Financiering">
              {showModal && <FinancieringWijzigenModal
                onCancel={() => {
                  setFinancieringsKeuzeOpslaan(false);
                  setShowModal(false);
                }}
                onSave={() => {
                  setFinancieringsKeuzeOpslaan(true);
                  setShowModal(false);
                }}
                data={props.data}></FinancieringWijzigenModal>
            }
            <FinancieringKeuze instellingen={props.adviesonderwerpenInstellingenData} />
          </Card>

          <Card title="Financiële planning">
            <FinancielePlanning instellingen={props.adviesonderwerpenInstellingenData} />
          </Card>

          <Card title="Aanleiding gesprek">
            <AanleidingGesprek />
          </Card>
          <Card title="Voorstellen" subtitle="&nbsp; Maximaal 3 voorstellen" subtitleItalic={true}>
            <VoorstellenOverzicht />
          </Card>
        </CardWrapper>

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

Aanleiding.displayName = "Aanleiding";

export default withAdviesboxFormik<AanleidingProps, AanleidingState>({
  mapPropsToValues: (props): AanleidingState => ({
    aanleiding: props.data.aanleiding,
    planningKeuze: props.data.planningKeuze,
    aanleidingGesprek: props.data.aanleidingGesprek,
    voorstellen: props.data.voorstellen,
    hasTweedeAanvrager: props.data.hasTweedeAanvrager,
    kopieOptie: props.data.kopieOptie,
    newKopieOptieNaam: props.data.newKopieOptieNaam,
    saving: false
  }),
  validationSchema: aanleidingSchema
})(withAITracking(insightsReactPlugin, Aanleiding));
