import {
  CardWrapper,
  FormFirstFocus,
  PlatformFoutenSamenvatting,
  useRequestInit,
  NotificatiesContext,
  LoadingSpinner,
  useFeature,
  SubscribeNotifyButton
} from "adviesbox-shared";
import { connect, Form, FormikContextType } from "formik";
import React, { ReactElement, useContext, useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { DevDebug } from "../../dev-debug/dev-debug";
import { renderAanvullendeGegevens } from "./aanvullende-gegevens-helper";
import { AanvullendeGegevensType } from "./aanvullende-gegevens-schema";
import classes from "./DossierbalkFoutenDropdown.module.scss";
import { nieuweAanvraagPost, requestInitNieuweAanvraag } from "./aanvullende-gegevens-modal-api";
import DossierAanvraagFoutenContext from "../../../dossier-aanvraag-fouten/dossier-aanvraag-fouten-context";

/* istanbul ignore file */
type AanvullendeGegevensProps = {
  onSave?: (data: any) => void;
  closeModal?: () => void;
};

const AanvullendeGegevensModal = ({
  closeModal,
  formik
}: AanvullendeGegevensProps & {
  formik: FormikContextType<AanvullendeGegevensType>;
}): ReactElement => {
  const featureNotificatie2 = useFeature("FeatureNotificaties2");
  const { values, isSubmitting } = formik;
  const { settings, user, params } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { setAanvullendeGegevens } = useContext(DossierAanvraagFoutenContext);
  const { subscribeCorrelationId } = useContext(NotificatiesContext);
  const { setNieuweAanvraag, nieuweAanvraag } = useContext(DossierAanvraagFoutenContext);
  const [isSubmittingOld, setIsSubmittingOld] = useState(false);

  useEffect(() => {
    //TODO: bij setsubmitting false uit modsal closen
    if (!featureNotificatie2 && nieuweAanvraag && nieuweAanvraag.closeModal) {
      setNieuweAanvraag({ ...nieuweAanvraag, closeModal: false });
      closeModal && closeModal();
    }
    if (featureNotificatie2) {
      if (!isSubmitting && isSubmittingOld) {
        closeModal && closeModal();
      }
      setIsSubmittingOld(isSubmitting);
    }
  }, [closeModal, nieuweAanvraag, setNieuweAanvraag, featureNotificatie2, isSubmitting, isSubmittingOld]);

  const commandRequest = useCallback(
    (correlationId: string) => {
      return requestInitNieuweAanvraag(settings, user, params.vestiging, values, correlationId);
    },
    [settings, user, params.vestiging, values]
  );

  return (
    <>
      <Modal.Header>
        <div className="d-flex w-100">
          <Modal.Title>Aanvullende gegevens invullen</Modal.Title>
          <button type="button" className={"close ml-auto"} onClick={closeModal} id={`close-modal`}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <PlatformFoutenSamenvatting className={classes.platform_errors} />
          <FormFirstFocus>
            <Form>
              <div className="d-flex flex-wrap flex-row">
                <CardWrapper flexType="flex-column" className="pl-3">
                  <div className={classes.aanvullende_card}>{renderAanvullendeGegevens(values)}</div>
                </CardWrapper>
              </div>

              <DevDebug />
            </Form>
          </FormFirstFocus>
        </>
      </Modal.Body>
      <Modal.Footer className={classes.modal_footer}>
        <button
          type="button"
          className="btn btn-link"
          id="annuleer-aanvullende-gegevens"
          data-testid="annuleer-aanvullende-gegevens"
          disabled={nieuweAanvraag?.isloading ?? false}
          onClick={closeModal}
        >
          Sluiten
        </button>

        {!featureNotificatie2 &&
          (nieuweAanvraag?.isloading ?? false ? (
            <button data-testid="btn-fetch" type="button" id="fetch-button" className={`btn btn-primary`}>
              <LoadingSpinner size="S" />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              id="bevestig-aanvullende-gegevens"
              data-testid="bevestig-aanvullende-gegevens"
              onClick={async () => {
                setAanvullendeGegevens(values);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                const result = await nieuweAanvraagPost(settings, user, params.vestiging, values);
                setNieuweAanvraag({
                  isloading: true,
                  receivedId: result,
                  partijCode: nieuweAanvraag?.partijCode ?? null,
                  productType: nieuweAanvraag?.productType ?? null,
                  closeModal: false
                });
                subscribeCorrelationId(result);
              }}
            >
              Aanvullende gegevens opslaan
            </button>
          ))}
        {featureNotificatie2 && (
          <SubscribeNotifyButton
            context={formik}
            icon={null}
            name="saveProduct"
            buttonText={"Aanvullende gegevens opslaan"}
            createCommandRequest={commandRequest}
            url={`${settings.foundationOrigin}/HdnOnline/bus/NieuweAanvraag`}
          />
        )}
      </Modal.Footer>
    </>
  );
};

AanvullendeGegevensModal.displayName = "AanvullendeGegevensModal";
export default connect<AanvullendeGegevensProps, AanvullendeGegevensType>(AanvullendeGegevensModal);
