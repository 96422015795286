import React, { ReactElement } from "react";
import { connect } from "formik";

import { AdresInput } from "../../shared/components/adres/adres-input";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { LabeledTextInput, SyncMultipleHeightProps, SyncHeight } from "adviesbox-shared";
import { AdresSyncHeightIndex, PersonaliaState } from "../infra/personalia-schema";

const Aanvrager1Adres = ({ sourceRef, targetRef }: SyncMultipleHeightProps): ReactElement => (
  <SyncHeight
    sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.All]}
    targetRef={targetRef && targetRef[AdresSyncHeightIndex.All]}
  >
    <AdresInput
      name="aanvrager1Adres.adres"
      plaatsCaption="Woonplaats"
      landCaption="Woonland"
      fieldSize="l"
      sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.Adres]}
      targetRef={targetRef && targetRef[AdresSyncHeightIndex.Adres]}
    />
    <LabeledHdnKeuzelijst
      caption="Geboorteland"
      name="aanvrager1Adres.geboortelandCode"
      berichtSoortType="AX"
      keuzelijst="LandType"
    />
    <LabeledHdnKeuzelijst
      caption="Nationaliteit"
      name="aanvrager1Adres.nationaliteitCode"
      berichtSoortType="AX"
      keuzelijst="LandType"
    />
    <LabeledHdnKeuzelijst
      caption="Fiscale woonstaat"
      name="aanvrager1Adres.fiscaleWoonstaatCode"
      berichtSoortType="AX"
      keuzelijst="LandType"
    />
    <SyncHeight
      sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.TelefoonnummerPrive]}
      targetRef={targetRef && targetRef[AdresSyncHeightIndex.TelefoonnummerPrive]}
    >
      <LabeledTextInput
        caption="Telefoonnummer privé"
        name="aanvrager1Adres.telefoonnummerPrive"
        autoComplete="new-password"
      />
    </SyncHeight>
    <SyncHeight
      sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.TelefoonnummerWerk]}
      targetRef={targetRef && targetRef[AdresSyncHeightIndex.TelefoonnummerWerk]}
    >
      <LabeledTextInput caption="Telefoonnummer werk" name="aanvrager1Adres.telefoonnummerWerk" />
    </SyncHeight>
    <SyncHeight
      sourceRef={sourceRef && sourceRef[AdresSyncHeightIndex.TelefoonnummerMobiel]}
      targetRef={targetRef && targetRef[AdresSyncHeightIndex.TelefoonnummerMobiel]}
    >
      <LabeledTextInput caption="Telefoonnummer mobiel" name="aanvrager1Adres.telefoonnummerMobiel" />
    </SyncHeight>
    <LabeledTextInput autoComplete="new-password" caption="E-mailadres" name="aanvrager1Adres.emailAdres" />
  </SyncHeight>
);
export default connect<SyncMultipleHeightProps, PersonaliaState>(Aanvrager1Adres);
