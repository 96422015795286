import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { overigeInkomstenModalSchema, OverigeInkomstenModalType } from "../infra/inkomen-en-fiscus-schema";
import { getInkomenEnFiscusTextResources } from "../infra/inkomen-en-fiscus-resources";
import { Button } from "react-bootstrap";
import { CurrencyInput, Label, TextInput, BerekenDate, TooltipWrap } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import classNames from "classnames";
import classes from "./overige-inkomsten-modal.module.scss";

export type OverigeInkomstenModalData = {
  data: OverigeInkomstenModalType;
};

type OverigeInkomstenModalProps = {
  onSave?: (data: OverigeInkomstenModalType) => void;
  closeModal?: () => void;
};

const OverigeInkomstenModal = ({
  data,
  onSave,
  closeModal
}: OverigeInkomstenModalData & OverigeInkomstenModalProps): ReactElement => {
  const mainTable = [
    { caption: "Alimentatie ex", name: "alimentatieEx" },
    { caption: "ANW-uitkering", name: "anwUitkering" },
    { caption: "WGA-uitkering", name: "wgaUitkering" },
    { caption: "IVA-uitkering", name: "ivaUitkering" },
    { caption: "WAZ-uitkering", name: "wazUitkering" },
    {
      caption: "WAO uitkering (tijdelijk, met herkeuring)",
      name: "waoUitkeringTijdelijk"
    },
    {
      caption: "WAO uitkering (blijvend, zonder herkeuring)",
      name: "waoUitkeringBlijvend"
    },
    { caption: "Wajong-uitkering", name: "wajongUitkering" },
    { caption: "WW-uitkering", name: "wwUitkering" },
    { caption: "RWW-uitkering", name: "rwwUitkering" },
    { caption: "Wachtgeld", name: "wachtgeld" }
  ];

  const aowTable = [
    {
      caption: "Nabestaandenpensioen voor AOW",
      name: "nabestaandenpensioenVoorAow"
    },
    {
      caption: "Nabestaandenpensioen na AOW",
      name: "nabestaandenpensioenNaAow"
    }
  ];

  const overigeInkomstenTable = [
    { placeholder: "Overige inkomsten", name: "overigeInkomsten1" },
    { placeholder: "Overige inkomsten", name: "overigeInkomsten2" }
  ];

  return (
    <AdviesBoxFormik<OverigeInkomstenModalType>
      initialValues={{ ...data }}
      validationSchema={overigeInkomstenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<OverigeInkomstenModalType>): ReactElement => {
        const body = (
          <>
            <div className="form-group form-row">
              <div className="col-5">
                <div className="pb-1 pl-3">Overige inkomsten box 1</div>
              </div>
              <div className="col-3">
                <div className="pb-1">Bedrag</div>
              </div>
              <div className="col-4">
                <div className="pb-1">
                  Inkomen meetellen
                  <TooltipWrap
                    name="box1"
                    warningText={getInkomenEnFiscusTextResources("TooltipOverigeInkomstenInkomenMeetellenTot")}
                    placement="bottom"
                    tooltipClasses="question-tip"
                    iconType="questionmark"
                  >
                    <Button className="question-tip" id="box1-tooltip-button" />
                  </TooltipWrap>
                </div>
              </div>
            </div>
            {mainTable.map(
              (row, index): ReactElement => {
                return (
                  <div className="form-group form-row" key={`main-${index}`}>
                    <div className="col-5">
                      <Label caption={row.caption} />
                    </div>
                    <div className="col-3">
                      <CurrencyInput name={`${row.name}Bedrag`} fieldSize="m" />
                    </div>
                    <div className="col-4">
                      <BerekenDate name={`${row.name}TotDatum`} fieldSize="m" />
                    </div>
                  </div>
                );
              }
            )}
            {aowTable.map(
              (row, index): ReactElement => (
                <div className="form-group form-row" key={`aow-${index}`}>
                  <div className="col-5">
                    <Label caption={row.caption} />
                  </div>

                  <div className="col-7">
                    <CurrencyInput name={`${row.name}Bedrag`} fieldSize="m" />
                  </div>
                </div>
              )
            )}
            {overigeInkomstenTable.map(
              (row, index): ReactElement => (
                <div className="form-group form-row" key={`overige-${index}`}>
                  <div className="col-5">
                    <TextInput
                      name={`${row.name}Omschrijving`}
                      className={classNames(classes.table_input)}
                      placeholder={`${row.placeholder}`}
                      tooltip={getInkomenEnFiscusTextResources("TooltipOverigeInkomstenVrijVeld")}
                    />
                  </div>

                  <div className="col-3">
                    <CurrencyInput name={`${row.name}Bedrag`} fieldSize="m" />
                  </div>
                  <div className="col-4">
                    <BerekenDate name={`${row.name}TotDatum`} fieldSize="m" />
                  </div>
                </div>
              )
            )}
          </>
        );

        return (
          <>
            <Modal title="Overige inkomsten box 1" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
          </>
        );
      }}
    />
  );
};

export default OverigeInkomstenModal;
