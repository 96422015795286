import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { GebruikPandSoort } from "../../.generated/forms/formstypes";
import OverbruggingskredietModal from "../overbruggingskrediet-modal/overbruggingskrediet-modal";
import OverigeKostenInHypotheekModal from "../overige-kosten-in-hypotheek-modal/overige-kosten-in-hypotheek-modal";
import { getTotaalFinancieringsbehoefte } from "../infra/financieringsbehoefte-berekenen-helper";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import classNames from "classnames";

import Boeterente from "../financieringsopzet/boeterente";
import VerbouwingVerbetering from "../financieringsopzet/verbouwing-verbetering";
import TotaleRestschuld from "../financieringsopzet/totale-restschuld";
import AndereFinanciering from "../financieringsopzet/andere-financiering";
import Eigenwoningschuld from "../financieringsopzet/eigenwoningschuld";
import Bankgarantie from "../financieringsopzet/bankgarantie";
import InbrengEigenGeld from "../financieringsopzet/inbreng-eigen-geld";
import AdvieskostenBemiddelingsvergoeding from "../financieringsopzet/advieskosten-bemiddelingsvergoeding";
import AfkoopErfpacht from "../financieringsopzet/afkoop-erfpacht";
import OverigeFinancieringskosten from "../financieringsopzet/overige-financieringskosten";
import {
  LabeledPercentageInput,
  BerekenCurrencyButton,
  LabeledCurrencyInput,
  Icon,
  BerekenCurrencyInput,
  LoadingSpinner
} from "adviesbox-shared";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { ModalButton, LabeledResult } from "adviesbox-shared";
import {
  FinancieringsbehoefteState,
  OverigeKostenInHypotheekModal as OverigeKostenInHypotheekModalType
} from "../infra/financieringsbehoefte-types";
import NationaleHypotheekGarantie from "../financieringsopzet/nationale-hypotheek-garantie";

type FinancieringsopzetBestaandebouwProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean | null>>;
  showModal: boolean | null;
  loading:boolean;
};

const FinancieringsopzetBestaandebouw = ({
  formik,
  formik: {
    values,
    setFieldValue,
    values: { financieringsopzet }
  },
  setShowModal,
  showModal,
  loading
}: FinancieringsopzetBestaandebouwProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const { overigeKostenInHypotheekModal } = financieringsopzet;

  return (
    <>
      <LabeledCurrencyInput
        caption={`Koopsom woning ${values.financiering.kostenKoper ? "k.k." : "v.o.n."}`}
        name="financieringsopzet.koopsom.koopsomBedrag"
      />

      <LabeledCurrencyInput caption="Koopsom roerende zaken" name="financieringsopzet.koopsomRoerendeZaken" />
      {values.financiering.kostenKoper && (
        <BerekenCurrencyInput caption="Overdrachtsbelasting" name="financieringsopzet.overdrachtsbelasting" />
      )}
      {/* TODO: Default makelaarcourtage bepalen (en in veld zetten bij "calculate") */}
      <BerekenCurrencyInput caption="Makelaarscourtage" name="financieringsopzet.makelaarscourtage" />
      {/* TODO: Default bedrag halen uit de instellingen */}
      {values.financiering.kostenKoper && (
        <BerekenCurrencyInput caption="Leveringsakte" name="financieringsopzet.leveringsakte" />
      )}

      <VerbouwingVerbetering state={"bestaand"} />

      {values.erfpacht.erfpacht && <AfkoopErfpacht />}
      <BerekenCurrencyInput
        caption="Hypotheekakte"
        name="financieringsopzet.hypotheekakte"
        tooltip={getFinancieringsbehoefteTextResources("TooltipHypotheekAkte")}
      />
      <LabeledCurrencyInput
        caption="Arbeidskosten notaris"
        tooltip={getFinancieringsbehoefteTextResources("TooltipArbeidskostenNotaris")}
        name="financieringsopzet.arbeidskostenNotaris"
      />
      <BerekenCurrencyInput
        caption="Taxatie"
        name="financieringsopzet.taxatie"
        tooltip={getFinancieringsbehoefteTextResources("TooltipTaxatie")}
      />

      {values.onderpand.gebruik === GebruikPandSoort.PrimaireWoning && (
        <NationaleHypotheekGarantie setShowModal={setShowModal} showModal={showModal} />
      )}

      <AdvieskostenBemiddelingsvergoeding />

      <Bankgarantie />

      <OverigeFinancieringskosten />

      {values.financieringsopzet.leningdelenWordenAfgelost && values.financieringsopzet.boeterenteModal?.length > 0 && (
        <Boeterente />
      )}
      <TotaleRestschuld />

      {typeof values.financieringsopzet.afTeLossenoverigeLeningen === "number" &&
        values.financieringsopzet.afTeLossenoverigeLeningen > 0 && (
          <LabeledCurrencyInput
            caption="Af te lossen overige lening(en)"
            name="financieringsopzet.afTeLossenoverigeLeningen"
            readonly={true}
            tooltip={getFinancieringsbehoefteTextResources("TooltipAftelossenOverigeLeningen")}
          />
        )}

      <LabeledResult
        caption="Overige kosten in hypotheek"
        name="overigeKostenInHypotheek"
        currency={true}
        result={(): string =>
          bedragFormat(
            optellen([
              overigeKostenInHypotheekModal.lastenOverbruggingskrediet,
              overigeKostenInHypotheekModal.gewenstConsumptiefBedrag,
              optellen(overigeKostenInHypotheekModal.overigeKostenSpecificaties.map((x): number | null => x.bedrag))
            ]),
            0,
            0,
            false
          )
        }
        alignRight={true}
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="financieringsopzet.overigeKostenInHypotheekModal"
            aria-label="Overige kosten in hypotheek button"
            content={<Icon name="specificatie" alt="Overige kosten in hypotheek" />}
          >
            <OverigeKostenInHypotheekModal
              data={{
                ...values.financieringsopzet.overigeKostenInHypotheekModal,
                soortFinanciering: values.financiering.soortFinanciering
              }}
              onSave={(result: OverigeKostenInHypotheekModalType): void => {
                setFieldValue("financieringsopzet.overigeKostenInHypotheekModal", result);
              }}
            />
          </ModalButton>
        }
      />
      <LabeledResult
        name="financieringsopzet.totaleFinancieringsbehoefte"
        caption="Totale financieringsbehoefte"
        fontWeight="bold"
        alignRight={true}
        currency={true}
        result={(): string => bedragFormat(getTotaalFinancieringsbehoefte(values), 0, 0, false)}
      />

      <InbrengEigenGeld />

      {values.financieringsopzet.bestaandeWoningInEigendomVerkocht && (
        <LabeledCurrencyInput
          caption="Overbruggingskrediet"
          name="financieringsopzet.overbruggingskredietModal.meenemenInOverbruggingskrediet"
          readonly={true}
          appendChildren={
            <ModalButton
              resetSize={true}
              parent="financieringsopzet.overbruggingskredietModal"
              aria-label="Overbruggingskrediet button"
              content={<Icon name="specificatie" alt="Overbruggingskrediet" />}
              size="lg"
            >
              <OverbruggingskredietModal
                data={values.financieringsopzet.overbruggingskredietModal}
                pandGebruik={values.onderpand.gebruik ? values.onderpand.gebruik : GebruikPandSoort.Geen}
                onSave={(result): void => {
                  formik.setFieldValue(
                    "financieringsopzet.overigeFinancieringskostenModal.afsluitkostenOverbruggingskrediet",
                    result.afsluitkosten || 0
                  );
                  formik.setFieldValue("financieringsopzet.overbruggingskredietModal", result);

                  formik.setFieldValue(
                    "financieringsopzet.inbrengEigenGeldModal.meenemenInOverbruggingskredietBedrag",
                    result.meenemenInOverbruggingskrediet
                  );
                }}
              />
            </ModalButton>
          }
        />
      )}

      <AndereFinanciering />

      <LabeledCurrencyInput
        caption="Gewenste hypotheek"
        name="financieringsopzet.gewensteHypotheek"
        disabled={financieringsopzet.gewensteHypotheekBerekenen}
        fontWeight="bold"
        appendChildren={
          <BerekenCurrencyButton
            name="financieringsopzet.gewensteHypotheekBerekenen"
            berekenen={financieringsopzet.gewensteHypotheekBerekenen}
          />
        }
      />
      {(values.onderpand.gebruik === GebruikPandSoort.PrimaireWoning ||
        values.onderpand.gebruik === GebruikPandSoort.Geen) && <Eigenwoningschuld />}

      <BerekenCurrencyInput caption="Hypothecaire inschrijving" name="financieringsopzet.hypothecaireInschrijving" />

      <LabeledPercentageInput
        additionalInputClass={classNames({
          "input-rood":
            values.financieringsopzet.bevoorschottingspercentage &&
            values.financieringsopzet.maxBevoorschottingspercentage &&
            values.financieringsopzet.bevoorschottingspercentage >
              values.financieringsopzet.maxBevoorschottingspercentage
        })}
        caption="Bevoorschottingspercentage"
        name="financieringsopzet.bevoorschottingspercentage"
        decimalen={2}
        readonly={true}
        appendChildren={
         <>{loading &&  <LoadingSpinner size="S" />}</>
        }
      />
    </>
  );
};

export default connect<FinancieringsopzetBestaandebouwProps, FinancieringsbehoefteState>(
  FinancieringsopzetBestaandebouw
);
