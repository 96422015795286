import * as Yup from "yup";
import { GedeeltelijkeUitkeringAoOpties, RvcAfwijkingsoort } from "../../.generated/forms/formstypes";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getSpecificatieAoUitkeringTextResources } from "../specificatie-ao-uitkering-modal/specificatie-ao-uitkering-resources";

export enum AoUitkeringswijze {
  Volledig = "Volledige uitkering",
  ProRata = "Uitkering naar rato"
}

export const aoIndexeringenSchema = Yup.object({
  ondergrensPercentage: Yup.number()
    .nullable()
    .default(null),
  bovengrensPercentage: Yup.number()
    .nullable()
    .default(null),
  uitkeringPercentage: Yup.mixed<string | number | null>()
    .nullable()
    .default(null),
  error: Yup.boolean()
    .test({
      message: "Indexeringen moet tussen 0% en 100% liggen.",
      test: function(val: boolean): boolean {
        return !val;
      }
    })
    .default(false)
});

export type AoIndexeringenType = Yup.InferType<typeof aoIndexeringenSchema>;

export const specificatieAoUitkeringModalSchema = Yup.object({
  volledigMinPercentage: Yup.number()
    .nullable()
    .test({
      message: getSpecificatieAoUitkeringTextResources("validatieAoModalMinPercentage"),
      test: function(val: number | null): boolean {
        if (val && this.parent.volledigVanafPercentage && val > this.parent.volledigVanafPercentage) {
          return false;
        }
        return true;
      }
    })
    .default(35),
  gedeeltelijkeUitkeringObv: Yup.mixed<GedeeltelijkeUitkeringAoOpties>()
    .oneOf(Object.values(GedeeltelijkeUitkeringAoOpties))
    .default(GedeeltelijkeUitkeringAoOpties.WerkelijkAoPercentage),

  volledigVanafPercentage: Yup.number()
    .nullable()
    .test({
      message: getSpecificatieAoUitkeringTextResources("validatieAoModalVanafPercentage"),

      test: function(val: number | null): boolean {
        if (val && this.parent.volledigMinPercentage && val < this.parent.volledigMinPercentage) {
          return false;
        }
        return true;
      }
    })
    .default(80),
  afwijkendToggle: Yup.boolean().default(false),
  afwijkendRestverdien: yupEnum(RvcAfwijkingsoort).default(RvcAfwijkingsoort.Tot),
  afwijkendRestverdienPercentage: Yup.number()
    .nullable()
    .default(50),
  afwijkendUitkeringspercentage: Yup.number()
    .nullable()
    .default(null),
  proRataMinPercentage: Yup.number()
    .nullable()
    .test({
      message: getSpecificatieAoUitkeringTextResources("validatieAoModalProRataMinPercentage"),
      test: function(val: number | null): boolean {
        if (val && this.parent.proRataTotPercentage && val > this.parent.proRataTotPercentage) {
          return false;
        }
        return true;
      }
    })
    .default(35),
  proRataTotPercentage: Yup.number()
    .nullable()
    .test({
      message: getSpecificatieAoUitkeringTextResources("validatieAoModalProRataTotPercentage"),
      test: function(val: number | null): boolean {
        if (val && this.parent.proRataMinPercentage && val < this.parent.proRataMinPercentage) {
          return false;
        }
        return true;
      }
    })
    .default(80),
  indexeringen: Yup.array(aoIndexeringenSchema).default([
    {
      ...aoIndexeringenSchema.default(),
      ondergrensPercentage: 0,
      bovengrensPercentage: 35,
      uitkeringPercentage: 0
    },
    {
      ...aoIndexeringenSchema.default(),
      ondergrensPercentage: 35,
      bovengrensPercentage: 100,
      uitkeringPercentage: 100
    }
  ])
});

export type SpecificatieAoUitkeringModalType = Yup.InferType<typeof specificatieAoUitkeringModalSchema>;
