import React, { ReactElement, useCallback } from "react";
import { connect, FormikContextType } from "formik";
import { Icon, LabeledCurrencyInput, ModalButton, LabeledDateInput } from "adviesbox-shared";

import { bindSaveFunction } from "../../shared/utils/bind-save-function";

import { KapitaalverzekeringenType } from "../infra/kapitaalverzekering-types";
import PoliswaardeModal from "../poliswaarde-modal/poliswaarde-modal";
import { PoliswaardeKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";

type PoliswaardeProps = {
  situatie: SituatieSoort;
  selected: number;
  kenmerken: PoliswaardeKenmerken;
};

const Poliswaarde = ({
  situatie,
  selected,
  kenmerken,
  formik: {
    setFieldValue,
    values: { producten }
  }
}: {
  formik: FormikContextType<KapitaalverzekeringenType>;
} & PoliswaardeProps): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const {
    poliswaarde: {
      poliswaardeModal: { waardeopbouwJaren }
    }
  } = producten[selected];

  return (
    <>
      {kenmerken.waardeopbouwBedragTonen && (
        <LabeledCurrencyInput
          caption="Waardeopbouw"
          name={`producten[${selected}].poliswaarde.waardeopbouwBedrag`}
          readonly={!kenmerken.waardeopbouwBedragEnabled}
          appendChildren={
            <ModalButton
              resetSize={true}
              parent={`producten[${selected}].poliswaarde.poliswaardeModal`}
              size={"lg"}
              content={<Icon name="specificatie" alt="Waardeopbouw specificatie" />}
              disabled={waardeopbouwJaren.length === 0}
            >
              <PoliswaardeModal
                data={producten[selected].poliswaarde.poliswaardeModal}
                onSave={createSaveFunction(`producten[${selected}].poliswaarde.poliswaardeModal`)}
              />
            </ModalButton>
          }
        />
      )}

      {kenmerken.reedsOpgebouwdBedragTonen && situatie !== "voorstel" && (
        <LabeledCurrencyInput
          caption="Reeds opgebouwd"
          name={`producten[${selected}].poliswaarde.reedsOpgebouwdBedrag`}
        />
      )}

      {kenmerken.reedsOpgebouwdDatumTonen && situatie !== "voorstel" && (
        <LabeledDateInput caption="Per datum" name={`producten[${selected}].poliswaarde.reedsOpgebouwdDatum`} />
      )}

      {kenmerken.afkoopwaardeBedragTonen && situatie !== "voorstel" && (
        <LabeledCurrencyInput caption="Afkoopwaarde" name={`producten[${selected}].poliswaarde.afkoopwaardeBedrag`} />
      )}

      {kenmerken.afkoopwaardeDatumTonen && situatie !== "voorstel" && (
        <LabeledDateInput caption="Per datum" name={`producten[${selected}].poliswaarde.afkoopwaardeDatum`} />
      )}
    </>
  );
};

export default connect<PoliswaardeProps, KapitaalverzekeringenType>(Poliswaarde);
