import {
  Vermogen as VermogenDlEntry,
  VermogenDepot,
  VermogenExtraOpnameInJaar,
  VermogenFiscaleRegeling,
  FiscaalRegimeOptions,
  FiscaleTyperingOptions,
  FiscaleVoortzettingOptions,
  KapitaalopbouwOptions,
  VermogenInleggegevens,
  VermogenKapitaalopbouw,
  SoortBerekeningOptions,
  SoortVermogensrekeningOptions,
  VermogenOnttrekkingen,
  VermogenPolis,
  VermogenProduct,
  SoortVermogenProductOptions,
  VermogenProductwaarde,
  WaardeopbouwInJaar
} from "../../.generated/forms/formstypes";
import { mapVerpandingUiToDl } from "../../producten-overzicht/infra/verpanding-mapper-ui-2-dl";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { mapAanvragerKeuzeNaarKlanten } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { mapKredietenUi2Dl } from "../../shared/generic-parts/krediet/map-ui-2-dl";
import { mapVermogenLeningdelenUi2Dl } from "../../shared/generic-parts/leningdeel/map-ui-2-dl";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  depotSchema,
  extraOpnameSchema,
  inleggegevensSchema,
  onttrekkingenSchema,
  vermogenFiscaleRegelingSchema,
  vermogenSchema,
  vermogensSchema,
  waardeopbouwJaarSchema
} from "./vermogen-schema";
import { mapLocalDateToString } from "adviesbox-shared";

const mapPolisUiToDl = createMapToDl(vermogenSchema).to<VermogenPolis>({
  maatschappijCode: v => v.partijCode,
  productcode: v => +v.productCode,
  productnummer: v => v.product.productNummer,
  maatschappijOmschrijving: v => v.product.partijNaam,
  maatschappijCodeHdn: v => v.product.partijCodeSelectie,
  productnaam: v => v.product.productNaam,
  ingangsdatum: v => mapLocalDateToString(v.product.ingangsdatum) ?? "",
  looptijdInMaanden: v => jaarMaandInMaanden(v.product.looptijd) ?? null,
  uwBemiddeling: v => v.product.uwBemiddeling
});

const mapDepotUiToDl = createMapToDl(depotSchema).to<VermogenDepot>({
  soortDepot: v => v.soortDepot,
  bestedingsdoel: v => v.bestedingsdoel,
  aankoopkostenPercentage: v => v.aankoopkostenPercentage,
  verkoopkostenPercentage: v => v.verkoopkostenPercentage,
  beheerkostenPercentage: v => v.beheerkostenPercentage
});

const mapWaardeopbouwJaarUiToDl = createMapToDl(waardeopbouwJaarSchema).to<WaardeopbouwInJaar>({
  jaar: v => v.jaar,
  beginwaardeBedrag: v => v.beginwaardeBedrag,
  stortingenBedrag: v => v.stortingenBedrag,
  onttrekkingenBedrag: v => v.onttrekkingenBedrag,
  eindwaardeBedrag: v => v.eindwaardeBedrag
});

const mapProductwaardeUiToDl = createMapToDl(depotSchema).to<VermogenProductwaarde>({
  reedsOpgebouwdBedrag: v => v.reedsOpgebouwdBedrag,
  reedsOpgebouwdDatum: v => mapLocalDateToString(v.reedsOpgebouwdDatum),
  afkoopwaardeBedrag: v => v.afkoopwaardeBedrag,
  afkoopwaardeDatum: v => mapLocalDateToString(v.afkoopwaardeDatum),
  waardeopbouwBedrag: v => v.waardeopbouwBedrag,
  waardeopbouwJaren: v => v.waardeopbouwJaren.map(mapWaardeopbouwJaarUiToDl),
  waardeopbouwMaanden: v => v.waardeopbouwMaanden
});

const mapInleggegevensUiToDl = createMapToDl(inleggegevensSchema).to<VermogenInleggegevens>({
  duurInMaanden: v => jaarMaandInMaanden(v.duur),
  duurHooglaagInMaanden: v => jaarMaandInMaanden(v.duurHoogLaag),
  stortingstermijn: v => v.stortingstermijn,
  inlegBedrag: v => v.inleg,
  hogeInlegBedrag: v => v.inlegHoog,
  premieAftrekbaarBedrag: v => v.aftrekbaar,
  eersteInlegBedrag: v => v.eersteInleg,
  extraInlegJaren: v =>
    v.extraInlegJaren.scenario.map((inleg, index) => ({
      jaar: index + 1,
      bedrag: inleg.bedrag ?? 0
    }))
});

const mapKapitaalopbouwUiToDl = createMapToDl(vermogenSchema).to<VermogenKapitaalopbouw>({
  soortRekening: v => v.kapitaalopbouw.soortRekening as SoortVermogensrekeningOptions,
  soortBerekening: v => v.kapitaalopbouw.soortBerekening as SoortBerekeningOptions,
  beleggersprofiel: v => v.kapitaalopbouw.beleggersprofiel,
  doelkapitaalBedrag: v => v.kapitaalopbouw.doelkapitaalBedrag,
  doelrendementPercentage: v => v.kapitaalopbouw.doelrendementPercentage,
  voorbeeldkapitaalBedrag: v => v.kapitaalopbouw.voorbeeldkapitaalBedrag,
  voorbeeldrendementPercentage: v => v.kapitaalopbouw.voorbeeldrendementPercentage,
  garantiekapitaalBedrag: v =>
    v.kapitaalopbouw.soortRekening === SoortVermogensrekeningOptions.Belegging ||
    v.soortProduct === SoortVermogenProductOptions.Betaalrekening
      ? null
      : v.kapitaalopbouw.garantiekapitaalBedrag,
  garantierendementPercentage: v => v.kapitaalopbouw.garantierendementPercentage
});

export const mapExtraOpnamesUi2Dl = createMapToDl(extraOpnameSchema).to<VermogenExtraOpnameInJaar>({
  jaar: v => v.jaar,
  opnameBedrag: v => v.opnameBedrag ?? 0
});

export const mapOnttrekkingenUiToDl = createMapToDl(onttrekkingenSchema).to<VermogenOnttrekkingen>({
  begindatum: v => mapLocalDateToString(v.begindatum),
  duurInMaanden: v => jaarMaandInMaanden(v.duur),
  onttrekkingstermijn: v => v.onttrekkingstermijn,
  onttrekkingBedrag: v => v.onttrekkingBedrag,
  extraOpnameJaren: v =>
    v.extraOpnames.scenario.length
      ? v.extraOpnames.scenario.filter(opname => !!opname.opnameBedrag).map(mapExtraOpnamesUi2Dl)
      : null
});

const mapFiscaleRegelingUiToDl = createMapToDl(vermogenFiscaleRegelingSchema).to<VermogenFiscaleRegeling>({
  polisnummer: v => v.polisnummer,
  originelePolisnummer: v => v.originelePolisnummer,
  kapitaalopbouw: v => v.kapitaalopbouw as KapitaalopbouwOptions | null,
  doelkapitaalBedrag: v => v.doelkapitaalBedrag,
  eerdereUitkeringenBedrag: v => v.eerdereUitkeringenBedrag,
  einddatum: v => mapLocalDateToString(v.einddatum),
  externeMaatschappijCode: v => v.externeMaatschappijCode,
  externeMaatschappijOmschrijving: v => v.externeMaatschappijOmschrijving,
  fiscaalRegime: v => v.fiscaalRegime as FiscaalRegimeOptions | null,
  fiscaleTypering: v => v.fiscaleTypering as FiscaleTyperingOptions | null,
  fiscaleVoortzetting: v => v.fiscaleVoortzetting as FiscaleVoortzettingOptions | null,
  garantieverzekering: v => v.garantieverzekering,
  hoogstePremieOoitBedrag: v => v.hoogstePremieOoitBedrag,
  huidigeJaarPremieBedrag: v => v.huidigeJaarPremieBedrag,
  ingangsdatumBox1: v => mapLocalDateToString(v.ingangsdatumBox1),
  ingebrachteWaardeBedrag: v => v.ingebrachteWaardeBedrag,
  laagstePremieooitBedrag: v => v.laagstePremieooitBedrag,
  lijfrenteclausule: v => v.lijfrenteclausule,
  lijfrenteclausuleOrigineel: v => v.lijfrenteclausuleOrigineel,
  oorspronkelijkeIngangsdatum: v => mapLocalDateToString(v.oorspronkelijkeIngangsdatum),
  premieLopendJaarBedrag: v => v.premieLopendJaarBedrag,
  productId: v => v.productId
});

export const mapVermogenUiToDl = createMapToDl(vermogenSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null }>()
  .to<VermogenProduct>({
    productId: v => v.productId,
    wijzigingenInDoorlopendeProductenOvernemen: v => v.product.wijzigingenInDoorlopendProductOvernemen ?? true,
    soortProduct: v => v.soortProduct,
    doorlopend: v => v.product.doorlopend,
    polis: v => mapPolisUiToDl(v),
    contractantKlantIds: (v, { aanvrager1, aanvrager2 }) =>
      mapAanvragerKeuzeNaarKlanten(v.verzekeringnemers.verzekeringnemers, aanvrager1, aanvrager2),
    depot: v => ((v.soortProduct === SoortVermogenProductOptions.Betaalrekening || v.soortProduct === SoortVermogenProductOptions.Spaarrekening) 
                ? null : mapDepotUiToDl(v.depot)),
    productwaarde: v => mapProductwaardeUiToDl(v.depot),
    inleggegevens: v => mapInleggegevensUiToDl(v.inleggegevens),
    kapitaalopbouw: v => mapKapitaalopbouwUiToDl(v),
    onttrekkingen: v => (v.onttrekkingen.onttrekkingenAanwezig ? mapOnttrekkingenUiToDl(v.onttrekkingen) : null),
    fiscaleRegeling: v =>
      v.soortProduct === SoortVermogenProductOptions.Betaalrekening
        ? null
        : mapFiscaleRegelingUiToDl(v.fiscaleRegeling),
    verpanding: v =>
      v.soortProduct === SoortVermogenProductOptions.Betaalrekening ||
      v.soortProduct === SoortVermogenProductOptions.Effectenlease
        ? null
        : mapVerpandingUiToDl(v.verpanding),
    meenemen: v => v.product.meenemen
  });

export const mapVermogensUiToDl = createMapToDl(vermogensSchema).to<VermogenDlEntry>({
  producten: v =>
    v.producten.map(vermogen => mapVermogenUiToDl({ aanvrager1: v.aanvrager1, aanvrager2: v.aanvrager2 })(vermogen)),
  aanvrager1: v => mapKlantnaamUi2Dl(v.aanvrager1),
  aanvrager2: v => mapKlantnaamUi2Dl(v.aanvrager2),
  leningdelen: v => mapVermogenLeningdelenUi2Dl(v.leningdelen),
  kredieten: v => mapKredietenUi2Dl(v.kredieten),
  geldverstrekkerNaam: v => v.geldverstrekkerNaam,
  ingangsdatumVoorstel: v => mapLocalDateToString(v.ingangsdatumVoorstel),
  fiscaleVoortzettingKeuzes: v => null // TODO: behoeft correcte implementatie, dit is een nswag update quickfix
});
