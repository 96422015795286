import { hasValue } from "adviesbox-shared";
import { ValidationResultModel } from "../../.generated/tarieven/tarieventypes";
import { ErrorDataType, ProductIdsType } from "./aanvraag-schema";
import { mapErrors } from "./map-aanvraag-dl-to-ui";
import {
  ErrorType,
  HdnValidatiemeldingFormApp,
  HdnValidatiemeldingForm,
  AanvullendeGegevensType,
  aanvullendeGegevensErrorsFESchema
} from "../../shared/components/dossier/dossierbalk-fouten-dropdown/aanvullende-gegevens-schema";
import { nieuweAanvraagType } from "../../shared/dossier-aanvraag-fouten/dossier-aanvraag-fouten-provider";

export const valueOrEmptyString = (value: string | null | undefined): string => value ?? "";
export const valueOrNull = (value: any | null | undefined): any | null => value || null;

export const aanvraagProductTitle = (title: string, index: number, length: number): string => {
  if (length > 1) return `${title} ${index + 1}`;

  return title;
};

export const aanvraagProductTitleFromProductId = (
  productIdsEnTypes: ProductIdsType[],
  productId: string | null
): string => {
  const product = productIdsEnTypes?.find(v => v.productId === productId);
  if (!product?.productType) return "";
  const repeatedProduct = productIdsEnTypes?.filter(v => v.productType === product.productType);

  let title = "";
  repeatedProduct.forEach((v, index) => {
    if (v.productId === productId)
      title = aanvraagProductTitle(valueOrEmptyString(v.productType), index, repeatedProduct.length);
  });

  return title;
};

export const nameFormater = (name: string | null): string => {
  /*return if null/afkorting*/
  if (!name || name === name.toUpperCase()) return name ?? "";

  let newName = name;
  /*Beautify*/
  newName = newName.replace("JN", "");
  newName = newName.replace("Nr", "nummer");
  newName = newName.replace("DtInMnd", "datum in maanden");
  newName = newName.replace("Dt", "datum");
  const heeftGedeeltelijkeAfkorting = newName.match(/^.*[A-Z]{2,}.*$/);
  if (heeftGedeeltelijkeAfkorting || name.includes("KvK")) return newName;
  /*Add spaces between capital letters*/
  newName = newName.replace(/([A-Z])/g, " $1").trim();
  /*Caps*/
  newName = newName.charAt(0).toUpperCase() + newName.slice(1).toLowerCase();
  newName = newName.replace("mnd", "maanden");

  return newName;
};

const mapInsufficientDossierDataRelativeUrls = (locaties: string[]): string => {
  /* istanbul ignore next */
  return locaties
    .map(url =>
      url.match(/\bklantprofiel\b/i)
        ? "/vestiging/{vestigingId}/adviesdossier/{adviesdossierId}/Klantprofiel/doelstelling"
        : "/vestiging/{vestigingId}/adviesdossier/{adviesdossierId}/Motivering/algemeen"
    )
    .join(", ");
};

export const mapInsufficientDossierData = (errors: ValidationResultModel[]): ErrorType[] => {
  return errors
    .filter(error => !!error.message?.match(/\bklantprofiel|motivering\b/gi))
    .map(error => {
      const errType = error.message?.match(/\bklantprofiel|motivering\b/gi);
      return {
        FEVeldInfo: null,
        app: HdnValidatiemeldingFormApp.Advies,
        bemiddelingId: null,
        errorId: valueOrEmptyString(error.code),
        locatie: valueOrEmptyString(errType?.join(", ")),
        omschrijving: `${errType?.join(" en ") ?? "Veld"} is verplicht.`,
        parentOccurance: 1,
        soortPartijRef: null,
        partijCode: null,
        platformVeldInfo: {
          datatype: error.code,
          maxOccurs: null,
          naam: valueOrEmptyString(errType?.join(", ")),
          omschrijving: error.message,
          verplicht: true,
          xPath: null
        },
        analyseId: null,
        dekkingId: null,
        relativeUrl: errType ? mapInsufficientDossierDataRelativeUrls(errType) : "",
        foutmeldingControleSchema: null,
        aanvullendeAntwoorden: [],
        extraInfo: null
      };
    });
};

export const mapAllErrorsData = (message: any, nieuweAanvraag: nieuweAanvraagType | null): AanvullendeGegevensType => {
  const errors = (message?.validatiemeldingen || []) as HdnValidatiemeldingForm[];
  const analyseId = message?.repliesTo?.analyseId;
  const dekkingId = message?.repliesTo?.dekkingId;
  const ontvangerNaam = message?.repliesTo?.ontvangerNaam;
  const ontvangerNrHdn = message?.repliesTo?.ontvangerNrHdn;
  const softwareKoppelingId = message?.repliesTo?.softwareKoppelingId;
  const adviesdossierId = message?.repliesTo?.adviesdossierId;
  const aanvullendeAntwoorden = message?.repliesTo?.aanvullendeAntwoorden;
  const nieuweVersie = message?.repliesTo?.nieuweVersie;
  const externeReferentie = message?.repliesTo?.externeReferentie;
  const vestigingId = message?.vestigingId;
  const maatschappijCode = message?.repliesTo?.maatschappijCode;

  const mappedErrorArray: ErrorType[] = errors?.map((error, i) => {
    return mapErrors({
      errorId: `${i}`,
      partijCode: nieuweAanvraag?.partijCode ?? message?.repliesTo?.maatschappijCode ?? "",
      bemiddelingId: ""
    })(error);
  });

  let newData = [...mappedErrorArray];
  /* istanbul ignore next */
  newData = newData.map(v => {
    const FEVeldInfo = aanvullendeGegevensErrorsFESchema.default();
    if (!v.locatie && !v.app && !v.relativeUrl) {
      switch (true) {
        case v.platformVeldInfo?.datatype?.includes("Datum"):
          FEVeldInfo.dataType = ErrorDataType.date;
          break;
        case v.platformVeldInfo?.naam?.includes("E-mailadres"):
          FEVeldInfo.dataType = ErrorDataType.email;
          FEVeldInfo.maxDigits = 69;
          break;
        case v.platformVeldInfo?.naam?.includes("Postcode"):
          FEVeldInfo.dataType = ErrorDataType.postcode;
          FEVeldInfo.maxDigits = 9;
          break;
        case v.platformVeldInfo?.datatype?.includes("KvKnrType") || v.platformVeldInfo?.naam?.includes("KvKnummer"):
          FEVeldInfo.dataType = ErrorDataType.alphanumeric;
          FEVeldInfo.maxDigits = 8;
          break;
        case !!v.platformVeldInfo?.datatype?.match(/^AN[0-9]+Type$/):
          FEVeldInfo.dataType = ErrorDataType.alphanumeric;
          FEVeldInfo.maxDigits = Number(v.platformVeldInfo.datatype?.replace(/(AN|Type)/g, "").trim());
          break;
        case v.platformVeldInfo?.datatype?.includes("Telefoon"):
          FEVeldInfo.dataType = ErrorDataType.telefoon;
          FEVeldInfo.maxDigits = 25;
          break;
        case v.platformVeldInfo?.datatype?.includes("IBANType"):
          FEVeldInfo.dataType = ErrorDataType.IBAN;
          FEVeldInfo.maxDigits = 34;
          break;
        case v.platformVeldInfo?.datatype?.includes("Boolean"):
          FEVeldInfo.dataType = ErrorDataType.boolean;
          break;
        case !!v.platformVeldInfo?.datatype?.match(/^N[0-9]+Type$/):
          FEVeldInfo.dataType = ErrorDataType.integer;
          FEVeldInfo.maxDigits = Number(v.platformVeldInfo.datatype?.replace(/(N|Type)/g, "").trim());
          break;
        case !!v.platformVeldInfo?.datatype?.match(/^N[0-9]+_[0-9]Type$/):
          FEVeldInfo.dataType = ErrorDataType.decimal;
          FEVeldInfo.maxDigits = Number(v.platformVeldInfo.datatype?.replace(/(N|Type)/g, "").split("_")[0]);
          FEVeldInfo.maxAllowedDecimalpoints = Number(
            v.platformVeldInfo.datatype?.replace(/(N|Type)/g, "").split("_")[1]
          );
          break;
        case v.platformVeldInfo?.datatype?.includes("/"):
          FEVeldInfo.dataType = ErrorDataType.decimal;
          FEVeldInfo.maxDigits = Number(v.platformVeldInfo.datatype?.replace(/(N|Type)/g, "").split("/")[0]);
          FEVeldInfo.maxAllowedDecimalpoints = Number(
            v.platformVeldInfo.datatype?.replace(/(N|Type)/g, "").split("/")[1]
          );
          break;
        case hasValue(v.platformVeldInfo?.maxOccurs) || v.platformVeldInfo?.datatype?.includes("EntiteitType"):
          FEVeldInfo.dataType = ErrorDataType.entityObject;
          break;
        default:
          FEVeldInfo.dataType = ErrorDataType.HDNKeuzelijst;
      }
    }

    FEVeldInfo.veldNaam = nameFormater(v.platformVeldInfo.naam);
    v.FEVeldInfo = FEVeldInfo;
    return v;
  });

  const result: AanvullendeGegevensType = {
    adviesdossierId: adviesdossierId,
    analyseId: analyseId,
    dekkingId: dekkingId,
    modalFoutenArray: newData,
    aanvullendeAntwoorden: aanvullendeAntwoorden,
    vestigingId: vestigingId,
    ontvangerNaam: ontvangerNaam,
    ontvangerNrHdn: ontvangerNrHdn,
    maatschappijCode: maatschappijCode,
    softwareKoppelingId: softwareKoppelingId,
    nieuweVersie: nieuweVersie,
    externeReferentie: externeReferentie
  };
  return result;
};
