import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { ModalButton, BerekenCurrencyInput } from "adviesbox-shared";
import NettoVerkoopopbrengstComponent from "./netto-verkoopopbrengst-modal/netto-verkoopopbrengst-modal";
import { bedragFormat } from "../../shared/utils/currency";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import { LabeledCurrencyInput, Icon, LabeledResult } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

type VermogenProps = {
  readonly?: boolean;
};

const Vermogen = ({
  readonly = false,
  formik
}: VermogenProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const vermogen = formik.values.vermogen;
  const overwaarde = formik.values.overwaarde;

  const calcTotaalOverwaarde = (): string => {
    const res = overwaarde.nettoVerkoopOpbrengst - overwaarde.restantSchuldBox1 - overwaarde.restantSchuldBox3;
    return res > 0 ? bedragFormat(res, 0, 0, false) : bedragFormat(0, 0, 0, false);
  };

  const calcTotaalVermogen = (): string => {
    return bedragFormat(vermogen.overwaarde + (vermogen.vrijVermogen.bedrag || 0), 0, 0, false);
  };

  return (
    <>
      {vermogen.overwaardeBerekeningTonen && (
        <>
          <LabeledCurrencyInput
            caption="Netto verkoopopbrengst"
            name="overwaarde.nettoVerkoopOpbrengst"
            readonly={readonly}
            appendChildren={
              <ModalButton
                parent="overwaarde"
                resetSize={true}
                content={<Icon name="specificatie" alt="Netto verkoopopbrengst" />}
              >
                <NettoVerkoopopbrengstComponent />
              </ModalButton>
            }
          />

          <LabeledCurrencyInput
            caption="Restant schuld box 1"
            name="overwaarde.restantSchuldBox1"
            readonly={readonly}
          />
          <LabeledCurrencyInput caption="Vervreemdingssaldo" name="overwaarde.vervreemdingsSaldo" readonly={readonly} />
          <LabeledCurrencyInput
            caption="Restant schuld box 3"
            name="overwaarde.restantSchuldBox3"
            readonly={readonly}
          />

          <LabeledResult
            caption="Overwaarde"
            name="overwaarde"
            alignRight={true}
            currency={true}
            result={calcTotaalOverwaarde}
          />
        </>
      )}
      {vermogen.overwaardeBerekeningTonen && <h2 className="pl-2">Vermogen</h2>}

      <LabeledCurrencyInput
        caption="Overwaarde"
        name="vermogen.overwaarde"
        readonly={true}
        tooltip={getFinancieringsbehoefteTextResources("TooltipVermogenOverwaarde")}
      />

      <BerekenCurrencyInput
        caption="Vrij vermogen"
        name="vermogen.vrijVermogen"
        tooltip={getFinancieringsbehoefteTextResources("TooltipVrijeVermogen")}
      />

      <LabeledResult
        caption="Totaal vermogen"
        name="totaleVermogen"
        currency={true}
        alignRight={true}
        result={calcTotaalVermogen}
      />
    </>
  );
};

export default connect<VermogenProps, FinancieringsbehoefteState>(Vermogen);
