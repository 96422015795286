import localforage from "localforage";
import { SettingsContext } from "adviesbox-shared";
import React, { useContext, useEffect } from "react";

const FetchInterceptor: React.FC = (): JSX.Element | null => {
  const { caching } = useContext(SettingsContext);
  const enabled: boolean = caching.enabled;
  const methodsToCache: Array<string> = caching.methodsToCache;
  const pathsToCache: Array<string> = caching.pathsToCache;

  useEffect(() => {
    // Return if caching is disabled in settings
    if (!enabled) {
      return;
    }

    // Used to enable the .json() method used in other components
    const mapToResponse = (cacheItem: CacheItem): Response => {
      return new Response(JSON.stringify(cacheItem.response.data), {
        status: cacheItem.response.status,
        statusText: cacheItem.response.statusText,
        headers: new Headers(cacheItem.response.headers)
      });
    };

    // Not the whole Reponse can usually be stored in cache, so filtering out
    // the important items
    const mapFromReponse = (response: Response, responseData: object): CacheItem => {
      const expiredDate = new Date();
      expiredDate.setSeconds(expiredDate.getSeconds() + caching.timeToLiveInSeconds);

      return {
        expiresAt: expiredDate,
        response: {
          status: response.status,
          statusText: response.statusText,
          headers: Array.from(response.headers.entries()),
          data: responseData
        }
      };
    };

    // Check if cache item is valid to keep
    const validateCache = (cacheItem: CacheItem): boolean => {
      const currentDate = new Date();

      if (currentDate > cacheItem.expiresAt) {
        return false;
      }

      if (cacheItem.response.status !== 200) {
        return false;
      }

      return true;
    };

    // Check if response is valid to store in cache
    const validateResponse = (response: Response): boolean => {
      if (response.status < 200 || response.status >= 300) {
        return false;
      }

      const contentTypeHeader = response.headers.get("content-type");
      if (contentTypeHeader !== "application/json; charset=utf-8") {
        return false;
      }

      return true;
    };

    // Save original fetch to use in interceptor
    const originalFetch = window.fetch;

    window.fetch = async (...args) => {
      const [url, config = {}] = args;
      const isCachableMethod = config.method && methodsToCache.includes(config.method);
      const vestigingId = config.headers?.vestigingId ?? "";
      const cacheKey = vestigingId + "-" + url.toString();
      const isCachablePath = pathsToCache.some(cachepath => cacheKey.includes(cachepath));

      // Temp safety measure: always clear cache when it is a non cachable method
      if (!isCachableMethod) {
        await localforage.clear();
      }

      // Default behavior when conditions to properly cache are not met
      if (!vestigingId || vestigingId === "" || !isCachableMethod || !isCachablePath) {
        return await originalFetch(url, config);
      }

      // Return from cache if available
      const cacheItem = await localforage.getItem<CacheItem>(cacheKey);

      if (cacheItem) {
        const isValidCache = validateCache(cacheItem);

        if (isValidCache) {
          return mapToResponse(cacheItem);
        } else {
          await localforage.removeItem(cacheKey);
        }
      }

      // Send original fetch
      const response = await originalFetch(url, config);

      // Store in cache
      const isValidReponse = validateResponse(response);

      if (isValidReponse) {
        const responseData = await response.clone().json();
        await localforage.setItem(cacheKey, mapFromReponse(response, responseData));
      }

      return response;
    };
  }, [caching.timeToLiveInSeconds, enabled, methodsToCache, pathsToCache]);

  return null;
};

interface CacheItem {
  expiresAt: Date;
  response: CachedResponse;
}

interface CachedResponse {
  readonly data: object;
  readonly headers: [string, string][];
  readonly status: number;
  readonly statusText: string;
}

export default FetchInterceptor;
