import { Card, CardWrapper, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useContext } from "react";
import { RisicoprofielOptions } from "../../../.generated/forms/formstypes";
import { DevDebug } from "../../../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../../../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../../../shared/components/save-button/save-button";
import { WithSaveData } from "../../../shared/utils/save-data";
import { withAdviesboxFormik } from "../../../shared/utils/with-adviesbox-formik";
import {
  CardSpec,
  KlantprofielOptieProps,
  klantprofielOptieSchema,
  KlantprofielOptieType
} from "../../infra/klantprofiel-schema";
import { alleVragenMetAntwoordCheck, renderSpec } from "../../infra/question-helpers";
import classes from "./cell-group.module.scss";
import { determineRisicoprofielAsyncSideEffects } from "./determine-risicoprofiel-async-side-effects";
import { getKlantprofielResources } from "../../infra/klantprofiel-resources";
import { AppDataContext } from "../../../navigation/appdata-context";

const Risicoprofiel = (
  props: FormikProps<KlantprofielOptieType> &
    WithSaveData<KlantprofielOptieType> & { specs: CardSpec[]; schermTitle: string }
): ReactElement => {
  const { isSubmitting, specs, values } = props;
  const risicoprofiel = values.risicoprofielStatus;
  const isZeerDefensief = risicoprofiel === RisicoprofielOptions.ZeerDefensief;
  const isDefensief = risicoprofiel === RisicoprofielOptions.Defensief;
  const isNeutraal = risicoprofiel === RisicoprofielOptions.Neutraal;
  const isOffensief = risicoprofiel === RisicoprofielOptions.Offensief;
  const isZeerOffensief = risicoprofiel === RisicoprofielOptions.ZeerOffensief;
  const vragen = renderSpec(specs, props);
  const { setSErunning } = useContext(AppDataContext);

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}
        <ISWSideEffects<KlantprofielOptieType>
          async={determineRisicoprofielAsyncSideEffects({
            alleVragenMetAntwoord: alleVragenMetAntwoordCheck(values.vragen)
          })}
          asyncStartStopCallback={setSErunning}
        />

        <CardWrapper className="px-3">
          <div className="text-container">
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>
        <PlatformFoutenSamenvatting />
        <div className="d-flex flex-wrap flex-row">
          <CardWrapper flexType="flex-column" className="pl-3">
            {vragen}
            <Card title="Risicoprofiel" data-testid="Risicoprofiel-card" key="Risicoprofiel-key-card">
              <div className="px-3 mx-2 mt-3">
                {!alleVragenMetAntwoordCheck(values.vragen) && getKlantprofielResources("AlleVragenBeantworden")}
              </div>
              <div className={classes.cellGroup}>
                <div
                  className={isZeerDefensief ? classes.highlighted : ""}
                  data-testid="risicoprofiel-ZeerDefensief"
                  id="risicoprofiel-0"
                >
                  Zeer defensief
                </div>
                <div
                  className={isDefensief ? classes.highlighted : ""}
                  data-testid="risicoprofiel-Defensief"
                  id="risicoprofiel-1"
                >
                  Defensief
                </div>
                <div
                  className={isNeutraal ? classes.highlighted : ""}
                  data-testid="risicoprofiel-Neutraal"
                  id="risicoprofiel-2"
                >
                  Neutraal
                </div>
                <div
                  className={isOffensief ? classes.highlighted : ""}
                  data-testid="risicoprofiel-Offensief"
                  id="risicoprofiel-3"
                >
                  Offensief
                </div>
                <div
                  className={isZeerOffensief ? classes.highlighted : ""}
                  data-testid="risicoprofiel-ZeerOffensief"
                  id="risicoprofiel-4"
                >
                  Zeer offensief
                </div>
              </div>
            </Card>
          </CardWrapper>
        </div>

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

Risicoprofiel.displayName = "Risicoprofiel";

export default withAdviesboxFormik<KlantprofielOptieProps, KlantprofielOptieType>({
  mapPropsToValues: (e: KlantprofielOptieProps): KlantprofielOptieType => ({
    aanvrager1: e.aanvrager1,
    aanvrager2: e.aanvrager2,
    vragen: e.vragen,
    risicoprofielStatus: e.risicoprofielStatus
  }),
  validationSchema: klantprofielOptieSchema
})(Risicoprofiel);
