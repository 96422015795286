import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { ImportTaakOutput, ImportTaak } from "../../.generated/externekoppelingen/externekoppelingentypes";
import { importSchema, importState, fieldMap, validationErrorType } from "./import-schema";
import { UiName } from "../../shared/types";

let indexAanvrager = 0;

// Vertaal de parent naar een leesbare naam
const getParent = (prefix: string, parent: string): string => {
  if (prefix.includes("Klanten") && parent.includes("Persoon")) {
    indexAanvrager++;
    return `Aanvrager ${indexAanvrager}`;
  }
  return parent;
};

// Flatten de XML naar een lijst van velden
const flattenObject = (fieldMaps: fieldMap[], obj: Record<string, any>, prefix = "", parent = ""): void => {
  const pairs: { key: string; value: string }[] = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const newKey = prefix ? `${prefix}/${key}` : key;
      if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        flattenObject(fieldMaps, value, newKey, key);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          flattenObject(fieldMaps, item, `${newKey}/${index}`);
        });
      } else {
        pairs.push({ key: key, value: value });
      }
    }
  }
  if (pairs.length === 0) return;

  const fieldMap: fieldMap = {
    prefix: prefix,
    parent: getParent(prefix, parent),
    labelValues: pairs.map(pair => ({
      jsonPath: `${prefix}/${pair.key}`,
      label: pair.key,
      value: pair.value,
      validationErrors: []
    }))
  };
  const index = fieldMaps.findIndex(x => x.prefix.includes(prefix));
  if (index >= 0) {
    fieldMaps.splice(index, 0, fieldMap);
  } else {
    fieldMaps.push(fieldMap);
  }
};

// Maak een lijst van velden (inclusief validatie fouten)
const createFieldMapList = (convertedContent: string, validationErrors: string | null): fieldMap[] => {
  const categorie = window.location.search ? new URLSearchParams(window.location.search).get("categorie") : null;
  const content = JSON.parse(convertedContent);
  const result: fieldMap[] = [];
  flattenObject(result, content);

  // Voeg validatiefouten toe
  const validationErrorsObject: validationErrorType[] = validationErrors
    ? JSON.parse(validationErrors).map(
        (x: {
          JsonPath: any;
          DataType: any;
          MaxLength: any;
          PlatformDataType: any;
          FoutType: any;
          Omschrijving: any;
          MogelijkeWaarden: any;
        }): validationErrorType => {
          return {
            jsonPath: x.JsonPath,
            dataType: x.DataType,
            maxLength: x.MaxLength,
            platformDataType: x.PlatformDataType,
            foutType: x.FoutType,
            omschrijving: x.Omschrijving,
            mogelijkeWaarden: x.MogelijkeWaarden
          };
        }
      )
    : [];

  if (validationErrorsObject.length > 0) {
    validationErrorsObject.forEach(validationMember => {
      let errorMapped = false;
      // veld staat in convertedcontent
      result.forEach(resultCategorie => {
        resultCategorie.labelValues.forEach(resultMember => {
          if (validationMember.jsonPath === resultMember.jsonPath) {
            resultMember.validationErrors.push(validationMember);
            errorMapped = true;
          }
        });
      });
      // veld staat niet in convertedcontent
      if (!errorMapped) {
        const parentPrefix =
          validationMember.jsonPath.lastIndexOf("/") > 0
            ? validationMember.jsonPath.substring(0, validationMember.jsonPath.lastIndexOf("/"))
            : validationMember.jsonPath;
        const parent = result.find(v => v.prefix === parentPrefix);
        parent?.labelValues.push({
          jsonPath: validationMember.jsonPath,
          label: validationMember.jsonPath.substring(validationMember.jsonPath.lastIndexOf("/") + 1),
          value: null,
          validationErrors: [validationMember]
        });
      }
    });
  }
  switch (categorie) {
    case "Personalia":
      return result.filter(v => v.prefix.includes("Klanten")); // TODO: Filtering verder uitwerken als er meerdere categorieën zijn
    default:
      return result.filter(v => v.prefix.includes("Klanten")); // TODO: Filtering verder uitwerken als er meerdere categorieën zijn
  }
};

const dl2ui = createMapToUi(importSchema).from<ImportTaak>({
  koppelingKey: v => v.koppelingKey,
  importTaakId: v => v.importTaakId,
  fieldMaps: v => (v.convertedContent ? createFieldMapList(v.convertedContent, v.validationErrors) : null),
  status: v => v.status
});

export function mapImportDlToUi(_: string, data: ImportTaakOutput): importState | null {
  const importTaak = data && data.importTaak ? data.importTaak : null;
  if (!importTaak) return null;
  return dl2ui(importTaak);
}

export function mapImportDlNameToUiName(dlName: string): UiName | null {
  return null;
}
