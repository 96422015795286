import { AoIndexering, AoUitkering } from "../../.generated/forms/formstypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  AoIndexeringenType,
  AoUitkeringswijze,
  specificatieAoUitkeringModalSchema
} from "./specificatie-ao-uitkering-schema";

function mapAoIndexeringenUi2Dl(indexeringen: undefined): null;
function mapAoIndexeringenUi2Dl(indexeringen: AoIndexeringenType[]): AoIndexering[] | null;
function mapAoIndexeringenUi2Dl(indexeringen?: AoIndexeringenType[]): AoIndexering[] | null {
  if (!indexeringen) return null;
  if (indexeringen.length === 0) return null;
  return indexeringen.map(
    (x: AoIndexeringenType, index: number): AoIndexering => {
      const uitkeringsPercentage =
        typeof x.uitkeringPercentage === "string" ? Number.parseInt(x.uitkeringPercentage) : x.uitkeringPercentage;
      return {
        bovengrensPercentage: x.bovengrensPercentage,
        ondergrensPercentage: x.ondergrensPercentage,
        uitkeringPercentage: uitkeringsPercentage && uitkeringsPercentage >= 0 ? uitkeringsPercentage : null,

        volgnummer: index + 1
      };
    }
  );
}

function mappedIndexeringDl2Ui(aoIndexeringen: AoIndexering[] | null): AoIndexeringenType[] | null {
  return aoIndexeringen
    ? aoIndexeringen.map(
        (x: AoIndexering): AoIndexeringenType => ({
          bovengrensPercentage: x.bovengrensPercentage ?? null,
          ondergrensPercentage: x.ondergrensPercentage ?? null,
          uitkeringPercentage: x.uitkeringPercentage ?? 0,
          error: false
        })
      )
    : specificatieAoUitkeringModalSchema.default().indexeringen;
}

export const mapSpecificatieAoUitkeringModalUi2Dl = createMapToDl(specificatieAoUitkeringModalSchema)
  .with<AoUitkeringswijze | null>()
  .to<AoUitkering>({
    aoIndexeringen: v => mapAoIndexeringenUi2Dl(v.indexeringen),
    gedeeltelijkeUitkeringObv: (v, context) =>
      context === AoUitkeringswijze.ProRata ? v.gedeeltelijkeUitkeringObv : null,
    minimaalAoPercentage: v => v.volledigMinPercentage,
    rvcAfwijkingCode: v => v.afwijkendRestverdien,
    rvcAfwijkingPercentage: v => (v.afwijkendToggle ? v.afwijkendRestverdienPercentage : null),
    rvcUitkeringPercentage: v => v.afwijkendUitkeringspercentage,
    totAoPercentage: v => v.volledigVanafPercentage,
    isAfwijkendBijRestVerdienCapaciteit: v => v.afwijkendToggle
  });

export const mapSpecificatieAoUitkeringModalDl2Ui = createMapToUi(specificatieAoUitkeringModalSchema).from<AoUitkering>(
  {
    afwijkendRestverdien: v =>
      v.rvcAfwijkingCode ? v.rvcAfwijkingCode : specificatieAoUitkeringModalSchema.default().afwijkendRestverdien,
    afwijkendRestverdienPercentage: v =>
      v.rvcAfwijkingPercentage || specificatieAoUitkeringModalSchema.default().afwijkendRestverdienPercentage,
    gedeeltelijkeUitkeringObv: v =>
      v.gedeeltelijkeUitkeringObv
        ? v.gedeeltelijkeUitkeringObv
        : specificatieAoUitkeringModalSchema.default().gedeeltelijkeUitkeringObv,
    afwijkendToggle: v => v.isAfwijkendBijRestVerdienCapaciteit,
    afwijkendUitkeringspercentage: v =>
      v.rvcUitkeringPercentage || specificatieAoUitkeringModalSchema.default().afwijkendUitkeringspercentage,
    indexeringen: v => mappedIndexeringDl2Ui(v.aoIndexeringen),
    proRataMinPercentage: v =>
      v.minimaalAoPercentage || specificatieAoUitkeringModalSchema.default().proRataMinPercentage,
    proRataTotPercentage: v => v.totAoPercentage || specificatieAoUitkeringModalSchema.default().proRataTotPercentage,
    volledigMinPercentage: v =>
      v.minimaalAoPercentage || specificatieAoUitkeringModalSchema.default().volledigMinPercentage,
    volledigVanafPercentage: v =>
      v.totAoPercentage || specificatieAoUitkeringModalSchema.default().volledigVanafPercentage
  }
);
