import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import {
  ScenarioInputParams,
  ScenarioWrappers,
  ScenarioCard
} from "../../shared/components/scenario-input/scenario-card";

import { CurrencyInput, Label } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { PremieSpecificatieModalType } from "../infra/producten-overzicht-types";
import { premieSpecificatieModalSchema } from "../infra/producten-overzicht-schema";

export type PremieSpecificatieModalData = {
  selected: number;
  data: PremieSpecificatieModalType;
};

type PremieSpecificatieModalProps = {
  onSave?: (data: PremieSpecificatieModalType) => void;
  closeModal?: () => void;
};

const PremieSpecificatieModal = ({
  data,
  onSave,
  closeModal
}: PremieSpecificatieModalData & PremieSpecificatieModalProps): ReactElement => {
  const scenarioCardWrapper = (
    itemIndex: number,
    entries: number,
    scenarieInputParams: ScenarioInputParams
  ): ReactElement => {
    return (
      <div className="d-flex flex-wrap scenario-container" key={itemIndex}>
        <div className="pr-2">
          <Label caption={scenarieInputParams.captionStart + itemIndex} name={`termijnPremie[${itemIndex}].bedrag`} />
        </div>
        <div className="pr-1">
          <CurrencyInput name={`termijnPremie[${itemIndex}].bedrag`} decimalen={2} readonly={true} />
        </div>
      </div>
    );
  };

  const scenarioHeaderWrapper = (columnIndex: number): ReactElement => {
    return (
      <div className="d-flex flex-wrap scenario-container" key={`header-${columnIndex}`}>
        <div className="pr-2">Jaar</div>
        <div className="pr-1">Termijnpremie</div>
      </div>
    );
  };

  const scenarieWrappers: ScenarioWrappers = {
    scenarioCardWrapper: scenarioCardWrapper,
    scenarioHeaderWrapper: scenarioHeaderWrapper
  };

  return (
    <AdviesBoxFormik<PremieSpecificatieModalType>
      initialValues={{ ...data }}
      validationSchema={premieSpecificatieModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ values, submitForm }: FormikProps<PremieSpecificatieModalType>): ReactElement => {
        const body = (
          <div className="scenario-read-only">
            <ScenarioCard name={`termijnPremie`} readonly={true} scenarioWrappers={scenarieWrappers} />
          </div>
        );

        return (
          <>
            <Modal title="Premiespecificatie" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
          </>
        );
      }}
    />
  );
};

PremieSpecificatieModal.displayName = "PremieSpecificatieModal";

export default PremieSpecificatieModal;
