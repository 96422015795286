import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import AndereFinancieringenModal from "../andere-financieringen-modal/andere-financieringen-modal";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { ModalButton, LabeledResult, Icon, TooltipWrap } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";

const AndereFinanciering = ({
  formik: {
    values,
    setFieldValue,
    values: { financieringsopzet }
  }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const { andereFinancieringenModal } = financieringsopzet;

  return (
    <LabeledResult
      caption="Andere financiering(en)"
      name="andereFinancieringen"
      alignRight={true}
      currency={true}
      result={(): string =>
        bedragFormat(
          optellen([
            andereFinancieringenModal.doorlopendeHypotheken,
            andereFinancieringenModal.kortlopendeLeningKrediet,
            andereFinancieringenModal.startersleningBedrag,
            andereFinancieringenModal.overigeFinanciering1Bedrag,
            andereFinancieringenModal.overigeFinanciering2Bedrag
          ]),
          0,
          0,
          false
        )
      }
      appendChildren={
        <>
          <ModalButton
            resetSize={true}
            parent="financieringsopzet.andereFinancieringenModal"
            aria-label="Andere financieringen button"
            content={<Icon name="specificatie" alt="Andere financieringen popup" />}
          >
            <AndereFinancieringenModal
              data={values.financieringsopzet.andereFinancieringenModal}
              startersLeningToegestaan={
                !!values.financieringsopzet.nationaleHypotheekGarantie &&
                values.financieringsopzet.nationaleHypotheekGarantie > 0
              }
              onSave={(result): void => {
                setFieldValue("financieringsopzet.andereFinancieringenModal", result);
              }}
            />
          </ModalButton>
          {values.financieringsopzet.andereFinancieringenModal.startersleningBedrag && (
            <TooltipWrap
              name="starterslening"
              warningText={getFinancieringsbehoefteTextResources("TooltipStartersleningVanToepassing")}
              placement="bottom"
              iconType="waarschuwing"
              tooltipClasses="d-inline-block align-text-top ml-1"
            />
          )}
        </>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(AndereFinanciering);
