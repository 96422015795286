import React, { ReactElement, useCallback } from "react";
import { connect, FormikContextType } from "formik";
import MaatwerkoplossingenModal from "../maatwerkoplossingen-modal/maatwerkoplossingen-modal";
import { useHdnData } from "../../shared/hooks/use-hdn-data";
import { Icon, LabeledBevestigingInput, WarningPopup, LabeledNumberInput } from "adviesbox-shared";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { ModalButton } from "adviesbox-shared";
import { AanvraagLeningState } from "../infra/aanvraag-lening-schema";
import { getAanvraagLeningTextResources } from "../infra/aanvraag-lening-resources";

const Maatwerkoplossing = ({
  formik: {
    setFieldValue,
    values: { maatwerkoplossing, lening, aanvrager1, aanvrager2 }
  }
}: {
  formik: FormikContextType<AanvraagLeningState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);

  const { hdnKeuzelijst } = useHdnData("AX", "MaatwerkOplCodeMijType");

  return (
    <>
      <LabeledBevestigingInput
        caption="Maatwerkoplossing"
        name="maatwerkoplossing.maatwerkoplossing"
        tooltip={getAanvraagLeningTextResources("maatwerkoplossingMaatwerkoplossing")}
      />

      {!maatwerkoplossing.maatwerkoplossing && maatwerkoplossing.maatwerkredenen.length > 0 ? (
        <WarningPopup
          title="Maatwerkoplossing uitzetten?"
          infotekst={getAanvraagLeningTextResources("maatwerkoplossingPopupText")}
          confirmButtonTekst="Maatwerkoplossing uitzetten"
          show={true}
          onHide={(): void => setFieldValue("maatwerkoplossing.maatwerkoplossing", true)}
          onSubmit={(): void => {
            setFieldValue("maatwerkoplossing.maatwerkredenen", []);
          }}
        />
      ) : null}

      {maatwerkoplossing.maatwerkoplossing && (
        <LabeledNumberInput
          caption="Redenen"
          name="maatwerkoplossing.maatwerkredenen.length"
          readonly={true}
          appendChildren={
            <ModalButton
              resetSize={true}
              parent="maatwerkoplossing.maatwerkredenen"
              size="lg"
              content={<Icon name="specificatie" alt="Maatwerkoplossingen" />}
            >
              <MaatwerkoplossingenModal
                maatwerkLijst={hdnKeuzelijst}
                maatschappijCode={lening.geselecteerdVoorstel.maatschappijCode}
                aanvrager1={aanvrager1}
                aanvrager2={aanvrager2}
                data={maatwerkoplossing.maatwerkredenen}
                onSave={createSaveFunction("maatwerkoplossing.maatwerkredenen")}
              />
            </ModalButton>
          }
        />
      )}
    </>
  );
};

export default connect<{}, AanvraagLeningState>(Maatwerkoplossing);
