/* istanbul ignore file */
/* eslint-disable */
import { bedragFormat } from "adviesbox-shared";
import classNames from "classnames";
import { NewDashboardType } from "../../new-dashboard-context";
import { ControleMaandlastOptions } from "../../../../.generated/forms/formstypes";

export const bedragFormatNull = (
    bedrag: number | null | undefined, 
    op: (input: number) => number | undefined = (input: number) => input, 
    decimals: number = 0,
    abs: boolean = false
): string => {
    if (bedrag === null || bedrag === undefined) {
        return bedragFormat(0, decimals);
    }

    let processedBedrag = bedrag;

    if (abs && bedrag < 0) {
        processedBedrag = Math.abs(Math.min(bedrag, 0));
    } else if (abs) {
        processedBedrag = Math.abs(bedrag);
    }

    if (op) {
        processedBedrag = op(processedBedrag) || 0;
    }

    return bedragFormat(processedBedrag, decimals);
};

export const monthDivision = (x:number):number=> x > 0 ? x/12 : x;

export const getConclusieArcering = (conclusie: string) => {
    const lowerCaseConclusion = conclusie?.toLowerCase();
    return classNames({
      "table-warning": lowerCaseConclusion === "passend",
      "table-success": lowerCaseConclusion === "verantwoord",
      "table-danger": lowerCaseConclusion === "overschrijdt"
    });
  };
  
  export const getTrArcering = (tekort: boolean) => {
    return classNames({
      "table-danger": tekort,
      "table-success": !tekort
    });
  };

export const getKlantIdRequired = (soort: NewDashboardType, selectedKlantId: string) => {
  if (soort === NewDashboardType.Arbeidsongeschiktheid || soort === NewDashboardType.Werkloosheid) return false
  return !!selectedKlantId;
};

export const getMaandlastKeuzeTekst = (keuze: ControleMaandlastOptions | null): string=> {
  if (keuze === ControleMaandlastOptions.BenodigdInkomen) return "inkomensnorm";
  if (keuze === ControleMaandlastOptions.WerkelijkeLast) return "hypotheeknorm";
  return "controle-maandlastkeuze-onbekend"; 
}