import {
  hasValue,
  LabeledBevestigingInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledPostcodeInput,
  LabeledTextInput
} from "adviesbox-shared";
import React, { ReactElement } from "react";
import { HdnBerichtAanvulling } from "../../../../.generated/bemiddeling/bemiddelingtypes";
import { HdnBerichtSoortType } from "../../../hooks/use-hdn-data";
import { createMapToDl } from "../../../utils/create-map-to-dl";
import LabeledHdnKeuzelijst from "../../hdn-keuzelijst/LabeledHdnKeuzelijst";
import {
  ErrorDataType,
  HdnBerichtenSupplementSchema,
  AanvullendeGegevensType,
  SoortHdnPartijOptions
} from "./aanvullende-gegevens-schema";
import classes from "./DossierbalkFoutenDropdown.module.scss";

export const renderAanvullendeGegevens = (aanvullendeGegevens: AanvullendeGegevensType): ReactElement => {
  const getPartij = (parentOccurance: number | null, soortPartijRef: SoortHdnPartijOptions | null): string => {
    let partij = "";
    if (soortPartijRef === SoortHdnPartijOptions.Aanvrager) {
      if (parentOccurance === 1) {
        partij = " (aanvrager)";
      } else if (parentOccurance === 2) {
        partij = "(partner)";
      }
    }
    return partij;
  };

  const renderedComponents: ReactElement[] = [];
  /* istanbul ingore next */
  aanvullendeGegevens.modalFoutenArray.forEach((e, i) => {
    const maxDigits = e.FEVeldInfo?.maxDigits ?? 0;
    const xPathArray = e.platformVeldInfo?.xPath?.split("/");
    const partij = getPartij(e.parentOccurance, e.soortPartijRef);
    const caption = `${xPathArray && xPathArray.length > 1 && xPathArray[xPathArray.length - 2]} (${e.parentOccurance}${
      e.extraInfo ? " - " : ""
    }${e.extraInfo ?? ""}) - ${e.FEVeldInfo?.veldNaam}${partij}`;

    const tooltip = `${e.omschrijving} ${e.foutmeldingControleSchema ?? e.platformVeldInfo?.omschrijving}`;

    if (
      (e.FEVeldInfo?.dataType === ErrorDataType.alphanumeric && maxDigits <= 100) ||
      e.FEVeldInfo?.dataType === ErrorDataType.email ||
      e.FEVeldInfo?.dataType === ErrorDataType.IBAN
    ) {
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledTextInput
            caption={caption}
            name={`modalFoutenArray[${i}].FEVeldInfo.waarde`}
            tooltip={tooltip}
          />
        </div>
      );
    }
    if (e.FEVeldInfo?.dataType === ErrorDataType.alphanumeric && maxDigits > 100) {
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledTextInput
            caption={caption}
            name={`modalFoutenArray[${i}].FEVeldInfo.waarde`}
            textarea={3}
            tooltip={tooltip}
          />
        </div>
      );
    }

    if (e.FEVeldInfo?.dataType === ErrorDataType.integer) {
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledNumberInput
            caption={caption}
            name={`modalFoutenArray[${i}].FEVeldInfo.waarde`}
            tooltip={tooltip}
          />
        </div>
      );
    }

    if (e.FEVeldInfo?.dataType === ErrorDataType.decimal && hasValue(e.FEVeldInfo.maxAllowedDecimalpoints)) {
      const decimalPoints: any = e.FEVeldInfo.maxAllowedDecimalpoints > 3 ? 3 : e.FEVeldInfo.maxAllowedDecimalpoints;
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledNumberInput
            caption={caption}
            name={`modalFoutenArray[${i}].FEVeldInfo.waarde`}
            decimalen={decimalPoints}
            tooltip={tooltip}
          />
        </div>
      );
    }

    if (e.FEVeldInfo?.dataType === ErrorDataType.date) {
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledDateInput caption={caption} name={`modalFoutenArray[${i}].FEVeldInfo.waarde`} tooltip={tooltip} />
        </div>
      );
    }

    if (e.FEVeldInfo?.dataType === ErrorDataType.telefoon) {
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledTextInput caption={caption} name={`modalFoutenArray[${i}].FEVeldInfo.waarde`} tooltip={tooltip} />
        </div>
      );
    }

    if (e.FEVeldInfo?.dataType === ErrorDataType.boolean) {
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledBevestigingInput
            caption={caption}
            name={`modalFoutenArray[${i}].FEVeldInfo.waarde`}
            tooltip={tooltip}
          />
        </div>
      );
    }

    if (e.FEVeldInfo?.dataType === ErrorDataType.postcode) {
      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledPostcodeInput caption={caption} name={`modalFoutenArray[${i}].FEVeldInfo.waarde`} tooltip={tooltip} />
        </div>
      );
    }
    if (e.FEVeldInfo?.dataType === ErrorDataType.HDNKeuzelijst) {
      const soortType: HdnBerichtSoortType = aanvullendeGegevens.analyseId ? "AX" : "LX";

      renderedComponents.push(
        <div key={i} className={classes.aanvullende_entry}>
          <LabeledHdnKeuzelijst
            caption={caption}
            name={`modalFoutenArray[${i}].FEVeldInfo.waarde`}
            keuzelijst={e.platformVeldInfo.datatype ?? ""}
            berichtSoortType={soortType}
            filter={e.partijCode}
            tooltip={tooltip}
            ontvangerCode={e.partijCode}
          />
        </div>
      );
    }
  });

  return <>{renderedComponents.map(v => v)}</>;
};

export const mapAanvullendeGegevensUi2Dl = createMapToDl(HdnBerichtenSupplementSchema).to<HdnBerichtAanvulling>({
  bemiddelingProductId: v => v.bemiddelingProductId ?? "",
  aanvullingBerichtType: v => v.aanvullingBerichtType,
  isAankoop: v => false, //Todo
  ontbrekendeVelden: v =>
    v.ontbrekendeVelden.map(v => ({ xPath: v.xPath, parentOccurance: v.parentOccurance, waarde: v.waarde }))
});
