import React, { InputHTMLAttributes, ReactElement } from "react";
import { Omit } from "react-router";
import { CurrencyInput, LabelProps, Label, PercentageProps, PercentageInput } from "adviesbox-shared";

export type ScenarioInputProps = {
  currencyName: string;
  percentageName: string;
  disableCurrency?: boolean;
  disablePercentage?: boolean;
  hideCurrency?: boolean;
  hidePercentage?: boolean;
  decimalen?: 0 | 2;
} & Partial<Omit<PercentageProps, "name">> &
  LabelProps;

const LabeledScenarioInput = ({
  currencyName,
  percentageName,
  caption,
  tooltip,
  decimalen = 2,
  disabled = false,
  readonly = false,
  disableCurrency = false,
  disablePercentage = false,
  hideCurrency = false,
  hidePercentage = false,
  additionalInputClass = "",
  fontWeight,
  appendChildren,
  tabIndex
}: ScenarioInputProps & InputHTMLAttributes<HTMLInputElement>): ReactElement => (
  <>
    <div className="d-flex flex-wrap scenario-container">
      <div className="pr-2">
        <Label
          caption={caption}
          name={hideCurrency ? percentageName : currencyName}
          tooltip={tooltip}
          fontWeight={fontWeight}
        />
      </div>
      {hideCurrency === false ? (
        <div className="pr-1">
          <CurrencyInput
            name={currencyName}
            decimalen={decimalen}
            disabled={disabled || disableCurrency}
            readonly={readonly || disableCurrency}
            tabIndex={tabIndex}
          />
        </div>
      ) : (
        <></>
      )}
      {hidePercentage === false ? (
        <PercentageInput
          name={percentageName}
          decimalen={decimalen}
          disabled={disabled || disablePercentage}
          readonly={readonly || disablePercentage}
          additionalInputClass={additionalInputClass}
          appendChildren={appendChildren}
          tabIndex={tabIndex}
          allowAllValues={true}
          allowNegative={true}
        />
      ) : (
        <></>
      )}
    </div>

    {/* <ErrorMessage name={currencyName} component="div" className="foutmelding" />
    <ErrorMessage name={percentageName} component="div" className="foutmelding" /> */}
  </>
);

export default LabeledScenarioInput;
