import React, { ReactElement, useMemo } from "react";
import { mapAflosvormProducten } from "../hypotheek-selectie-helper";
import { HypotheekvormBasis } from "../../../.generated/producten/productentypes";
import { useFormikContext } from "formik";

import { SituatieSoort } from "../../../producten-overzicht/infra/producten-overzicht-types";
import { RadioInputGroup, SelectInput, FieldSize } from "adviesbox-shared";
import { AflosProductType } from "../../infra/hypotheek-types";
import { LabelValuePairs } from "../../../shared/types";

type AflossingProductKeuzelijstPresentationProps = {
  aflosProductKeuzeLijst: { [index: string]: HypotheekvormBasis };
  name: string;
  readonly?: boolean;
  emptyValue?: string;
  isRestschuldHypotheek?: boolean;
  situatie: SituatieSoort;
  asSelectElement?: boolean;
  fieldSize?: FieldSize;
};

const AflossingProductKeuzelijstPresentation = ({
  isRestschuldHypotheek,
  aflosProductKeuzeLijst,
  name,
  situatie,
  asSelectElement,
  fieldSize = "l"
}: AflossingProductKeuzelijstPresentationProps): ReactElement => {
  const { setFieldValue } = useFormikContext<any>();
  const aflosProducten = useMemo(
    (): HypotheekvormBasis[] => Object.keys(aflosProductKeuzeLijst).map(c => aflosProductKeuzeLijst[c]),
    [aflosProductKeuzeLijst]
  );
  const options = useMemo((): LabelValuePairs => {
    return mapAflosvormProducten(situatie, aflosProductKeuzeLijst, isRestschuldHypotheek || false);
  }, [aflosProductKeuzeLijst, isRestschuldHypotheek, situatie]);

  /* istanbul ignore next */
  return options.length === 0 ? (
    <p>Er zijn geen producten beschikbaar</p>
  ) : !!asSelectElement || options.length <= 3 ? (
    <RadioInputGroup
      name={`${name}.code`}
      options={options}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        // ophalen uit aflosproducten met gekozen productcode

        const selectedProduct = aflosProducten.find(c => `${c.code}` === e.target.value);
        if (selectedProduct) {
          if (!selectedProduct.code || !selectedProduct.aflossingsvorm || !selectedProduct.productnaam) {
            throw new Error("ongeldige product configuratie");
          }
          setFieldValue(name, {
            code: `${selectedProduct.code}`,
            productOmschrijving: selectedProduct.productnaam,
            aflosvorm: selectedProduct.aflossingsvorm,
            renteboxCode: selectedProduct.renteboxCode
          } as AflosProductType);
        }
      }}
    />
  ) : (
    <SelectInput
      name={`${name}.code`}
      options={options}
      fieldSize={fieldSize}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
        // ophalen uit aflosproducten met gekozen productcode

        const selectedProduct = aflosProducten.find(c => `${c.code}` === e.target.value);
        if (selectedProduct) {
          if (!selectedProduct.code || !selectedProduct.aflossingsvorm || !selectedProduct.productnaam) {
            throw new Error("ongeldige product configuratie");
          }
          setFieldValue(name, {
            code: `${selectedProduct.code}`,
            productOmschrijving: selectedProduct.productnaam,
            aflosvorm: selectedProduct.aflossingsvorm,
            renteboxCode: selectedProduct.renteboxCode
          } as AflosProductType);
        }
      }}
    />
  );
};

AflossingProductKeuzelijstPresentation.displayName = "AflossingProductKeuzelijstPresentation";

export default AflossingProductKeuzelijstPresentation;
