import { FormikContextType, connect } from "formik";

import React, { ReactElement } from "react";
import AfkoopErfpachtModal from "../afkoop-erfpacht-modal/afkoop-erfpacht-modal";
import { LabeledCurrencyInput, Icon } from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { FinancieringsbehoefteState } from "../infra/financieringsbehoefte-types";

const AfkoopErfpacht = ({
  formik: { values, setFieldValue }
}: {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  return (
    <LabeledCurrencyInput
      caption="Afkoop erfpacht"
      name="financieringsopzet.afkoopErfpachtModal.afkoopBedrag"
      readonly={true}
      appendChildren={
        <ModalButton
          resetSize={true}
          parent="financieringsopzet.afkoopErfpachtModal"
          aria-label="Erfpacht button"
          content={<Icon name="specificatie" alt="Afkoop erfpacht" />}
        >
          <AfkoopErfpachtModal
            data={values.financieringsopzet.afkoopErfpachtModal}
            onSave={(result): void => {
              setFieldValue("financieringsopzet.afkoopErfpachtModal", result);
            }}
          />
        </ModalButton>
      }
    />
  );
};

export default connect<{}, FinancieringsbehoefteState>(AfkoopErfpacht);
