import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import Koopsom from "../koopsom/koopsom";
import { GebruikPandSoort } from "../../.generated/forms/formstypes";
import RenteverliesTijdensBouwModal from "../renteverlies-tijdens-bouw/renteverlies-tijdens-bouw";
import OverigeKostenInHypotheekModal from "../overige-kosten-in-hypotheek-modal/overige-kosten-in-hypotheek-modal";
import OverbruggingskredietModal from "../overbruggingskrediet-modal/overbruggingskrediet-modal";
import OverigeFinancieringskostenModal from "../overige-financieringskosten-modal/overige-financieringskosten-modal";
import { getTotaalFinancieringsbehoefte } from "../infra/financieringsbehoefte-berekenen-helper";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import classNames from "classnames";

import Boeterente from "../financieringsopzet/boeterente";
import VerbouwingVerbetering from "../financieringsopzet/verbouwing-verbetering";
import TotaleRestschuld from "../financieringsopzet/totale-restschuld";
import AndereFinanciering from "../financieringsopzet/andere-financiering";
import Eigenwoningschuld from "../financieringsopzet/eigenwoningschuld";
import Bankgarantie from "../financieringsopzet/bankgarantie";
import InbrengEigenGeld from "../financieringsopzet/inbreng-eigen-geld";
import AdvieskostenBemiddelingsvergoeding from "../financieringsopzet/advieskosten-bemiddelingsvergoeding";
import AfkoopErfpacht from "../financieringsopzet/afkoop-erfpacht";
import {
  LabeledCurrencyInput,
  BerekenCurrencyButton,
  Icon,
  LabeledPercentageInput,
  BerekenCurrencyInput,
  LabeledResult,
  LoadingSpinner
} from "adviesbox-shared";
import { ModalButton } from "adviesbox-shared";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import {
  FinancieringsbehoefteState,
  OverigeKostenInHypotheekModal as OverigeKostenInHypotheekModalType
} from "../infra/financieringsbehoefte-types";
import NationaleHypotheekGarantie from "../financieringsopzet/nationale-hypotheek-garantie";

type FinancieringsopzetNieuwbouwProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean | null>>;
  showModal: boolean | null;
  loading: boolean;
};

const Financieringsopzet = ({
  formik,
  formik: {
    setFieldValue,
    values,
    values: { financieringsopzet }
  },
  setShowModal,
  showModal,
  loading
}: FinancieringsopzetNieuwbouwProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const { overigeKostenInHypotheekModal, overigeFinancieringskostenModal } = financieringsopzet;

  return (
    <>
      <LabeledCurrencyInput
        caption="Koopsom woning v.o.n."
        name="financieringsopzet.koopsom.koopsomBedragNieuwbouw"
        appendChildren={
          <ModalButton
            parent="financieringsopzet.koopsom"
            resetSize={true}
            content={<Icon name="specificatie" alt="Koopsom v.o.n." />}
          >
            <Koopsom
              data={values.financieringsopzet.koopsom}
              onSave={(result): void => {
                const koopsomResultaat = result.grondReedsInBezit
                  ? (result.koopsomBedrag || 0) - (result.kostenGrondBedrag || 0)
                  : result.koopsomBedrag;
                formik.setFieldValue("financieringsopzet.koopsom", {
                  ...result,
                  koopsomBedragNieuwbouw: koopsomResultaat
                });
              }}
            />
          </ModalButton>
        }
      />

      <VerbouwingVerbetering state="nieuwbouw" />

      <LabeledCurrencyInput
        caption="Bouwrente over periode vóór de aankoop"
        name="financieringsopzet.bouwrentePeriodeVoorAankoop"
      />
      <LabeledCurrencyInput
        caption="Bouwrente over periode na de aankoop"
        name="financieringsopzet.bouwrentePeriodeNaAankoop"
      />

      {values.erfpacht.erfpacht && <AfkoopErfpacht />}

      <BerekenCurrencyInput
        caption="Hypotheekakte"
        name="financieringsopzet.hypotheekakte"
        tooltip={getFinancieringsbehoefteTextResources("TooltipHypotheekAkte")}
        // calculate={() => hypotheekakteBerekenen(values.financieringsopzet)}
      />

      <LabeledCurrencyInput
        caption="Arbeidskosten notaris"
        tooltip={getFinancieringsbehoefteTextResources("TooltipArbeidskostenNotaris")}
        name="financieringsopzet.arbeidskostenNotaris"
      />
      <BerekenCurrencyInput
        caption="Taxatie"
        name="financieringsopzet.taxatie"
        tooltip={getFinancieringsbehoefteTextResources("TooltipTaxatie")}
        // calculate={() => taxatieBerekenen(values.financieringsopzet)}
      />
      {values.onderpand.gebruik === GebruikPandSoort.PrimaireWoning && (
        <NationaleHypotheekGarantie setShowModal={setShowModal} showModal={showModal} />
      )}

      <AdvieskostenBemiddelingsvergoeding />

      <Bankgarantie />

      <LabeledCurrencyInput
        caption="Renteverlies tijdens bouw"
        name="financieringsopzet.renteverliesTijdensBouw"
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="financieringsopzet.renteverliesTijdensBouwModal"
            aria-label="Renteverlies tijdens bouw button"
            content={<Icon name="specificatie" alt="Renteverlies tijdens bouw" />}
          >
            <RenteverliesTijdensBouwModal
              data={values.financieringsopzet.renteverliesTijdensBouwModal}
              onSave={(result): void => /* istanbul ignore next */ {
                formik.setFieldValue("financieringsopzet.renteverliesTijdensBouwModal", result);
                formik.setFieldValue("financieringsopzet.renteverliesTijdensBouw", result.renteverlies);
              }} // TODO: Kijken of renteverliesTijdensBouw nog nodig is (gebruik renteverliesTijdensBouwModal.renteverlies) + check of berekening renteverlies klopt (en vervolgens de unit test aanpassen/ aanvullen)
            />
          </ModalButton>
        }
      />

      <LabeledResult
        caption="Overige financieringskosten"
        name="overigeFinancieringskosten"
        alignRight={true}
        currency={true}
        result={(): string =>
          bedragFormat(
            optellen([
              overigeFinancieringskostenModal.afsluitkostenSVnStarterslening,
              overigeFinancieringskostenModal.bereidstellingskosten
            ]),
            0,
            0,
            false
          )
        }
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="financieringsopzet.overigeFinancieringskostenModal"
            content={<Icon name="specificatie" alt="Overige financieringskosten" />}
          >
            <OverigeFinancieringskostenModal
              data={values.financieringsopzet.overigeFinancieringskostenModal}
              onSave={(result): void => {
                formik.setFieldValue(
                  "financieringsopzet.overigeFinancieringskosten",
                  [result.afsluitkostenSVnStarterslening, result.bereidstellingskosten || 0].reduce(
                    (x, y): number => x + y
                  )
                );
                formik.setFieldValue("financieringsopzet.overigeFinancieringskostenModal", result);
              }}
            />
          </ModalButton>
        }
      />

      {values.financieringsopzet.leningdelenWordenAfgelost && <Boeterente />}

      <TotaleRestschuld />

      {typeof values.financieringsopzet.afTeLossenoverigeLeningen === "number" &&
        values.financieringsopzet.afTeLossenoverigeLeningen > 0 && (
          <LabeledCurrencyInput
            caption="Af te lossen overige lening(en)"
            name="financieringsopzet.afTeLossenoverigeLeningen"
            readonly={true}
            tooltip={getFinancieringsbehoefteTextResources("TooltipAftelossenOverigeLeningen")}
          />
        )}
      <LabeledResult
        caption="Overige kosten in hypotheek"
        name="overigeKostenInHypotheek"
        alignRight={true}
        currency={true}
        result={(): string =>
          bedragFormat(
            optellen([
              overigeKostenInHypotheekModal.lastenOverbruggingskrediet,
              overigeKostenInHypotheekModal.gewenstConsumptiefBedrag,
              optellen(overigeKostenInHypotheekModal.overigeKostenSpecificaties.map((x): number | null => x.bedrag))
            ]),
            0,
            0,
            false
          )
        }
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="financieringsopzet.overigeKostenInHypotheekModal"
            content={<Icon name="specificatie" alt="Overige kosten in hypotheek" />}
          >
            <OverigeKostenInHypotheekModal
              data={{
                ...values.financieringsopzet.overigeKostenInHypotheekModal,
                soortFinanciering: values.financiering.soortFinanciering
              }}
              onSave={(result: OverigeKostenInHypotheekModalType): void => {
                setFieldValue("financieringsopzet.overigeKostenInHypotheekModal", result);
              }}
            />
          </ModalButton>
        }
      />

      <LabeledResult
        name="financieringsopzet.totaleFinancieringsbehoefte"
        caption="Totale financieringsbehoefte"
        fontWeight="bold"
        alignRight={true}
        currency={true}
        result={(): string => bedragFormat(getTotaalFinancieringsbehoefte(values), 0, 0, false)}
      />

      <InbrengEigenGeld />

      {values.financieringsopzet.bestaandeWoningInEigendomVerkocht && (
        <LabeledCurrencyInput
          caption="Overbruggingskrediet"
          name="financieringsopzet.overbruggingskredietModal.meenemenInOverbruggingskrediet"
          readonly={true}
          appendChildren={
            <ModalButton
              resetSize={true}
              parent="financieringsopzet.overbruggingskredietModal"
              content={<Icon name="specificatie" alt="Overbruggingskrediet" />}
              size="lg"
            >
              <OverbruggingskredietModal
                data={values.financieringsopzet.overbruggingskredietModal}
                pandGebruik={values.onderpand.gebruik ? values.onderpand.gebruik : GebruikPandSoort.Geen}
                onSave={(result): void => {
                  formik.setFieldValue(
                    "financieringsopzet.overigeFinancieringskostenModal.afsluitkostenOverbruggingskrediet",
                    result.afsluitkosten || 0
                  );
                  formik.setFieldValue("financieringsopzet.overbruggingskredietModal", result);

                  formik.setFieldValue(
                    "financieringsopzet.inbrengEigenGeldModal.meenemenInOverbruggingskredietBedrag",
                    result.meenemenInOverbruggingskrediet
                  );
                }}
              />
            </ModalButton>
          }
        />
      )}

      <AndereFinanciering />

      <LabeledCurrencyInput
        caption="Gewenste hypotheek"
        name="financieringsopzet.gewensteHypotheek"
        disabled={financieringsopzet.gewensteHypotheekBerekenen}
        fontWeight="bold"
        appendChildren={
          <BerekenCurrencyButton
            name="financieringsopzet.gewensteHypotheekBerekenen"
            berekenen={financieringsopzet.gewensteHypotheekBerekenen}
          />
        }
      />

      {(values.onderpand.gebruik === GebruikPandSoort.PrimaireWoning ||
        values.onderpand.gebruik === GebruikPandSoort.Geen) && <Eigenwoningschuld />}

      <BerekenCurrencyInput caption="Hypothecaire inschrijving" name="financieringsopzet.hypothecaireInschrijving" />

      <LabeledPercentageInput
        additionalInputClass={classNames({
          "input-rood":
            values.financieringsopzet.bevoorschottingspercentage &&
            values.financieringsopzet.maxBevoorschottingspercentage &&
            values.financieringsopzet.bevoorschottingspercentage >
              values.financieringsopzet.maxBevoorschottingspercentage
        })}
        caption="Bevoorschottingspercentage"
        name="financieringsopzet.bevoorschottingspercentage"
        decimalen={2}
        readonly={true}
        appendChildren={
          <>{loading &&  <LoadingSpinner size="S" />}</>
        }
      />
    </>
  );
};

export default connect<FinancieringsopzetNieuwbouwProps, FinancieringsbehoefteState>(Financieringsopzet);
