import { DekkingProps } from "./dekking";
import React, { ReactElement, useMemo } from "react";
import { LabeledRadioInput, LabeledCurrencyInput, LabeledPercentageInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import { AovsType } from "../infra/aov-schema";
import { AovKeuzeDekkingOptions } from "../../.generated/forms/formstypes";
import { UitkeringGedeeltelijkeAo } from "./uitkering-gedeeltelijke-ao";
import { getAovTextResources } from "../infra/aov-resources";
import { getDekkingGegevensVerzekerDefaults } from "../infra/defaults";
import { mapPartijProduct } from "../infra/map-aov-dl-to-ui";

export const DekkingWiaExcedent = ({ selected, kenmerken, verzekerde }: DekkingProps): ReactElement => {
  const { values } = useFormikContext<AovsType>();
  const dekkingGegevensVerzekerdeDefault = useMemo(
    () =>
      getDekkingGegevensVerzekerDefaults(
        mapPartijProduct(values.producten[selected].partijCode, values.producten[selected].productCode)
      ),
    [values.producten, selected]
  );
  const dekkingGegevens =
    verzekerde === 1
      ? values.producten[selected].dekking.dekkingGegevensVerzekerde1
      : values.producten[selected].dekking.dekkingGegevensVerzekerde2 ??
        /* istanbul ignore next*/ dekkingGegevensVerzekerdeDefault; //TODO: test schrijven
  return (
    <>
      <LabeledRadioInput
        caption="Keuze dekking"
        name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.keuzeDekking`}
        tooltip={getAovTextResources("TooltipKeuzeDekking")}
        options={[
          {
            label: "Vast bedrag",
            value: AovKeuzeDekkingOptions.VastBedrag
          },
          {
            label: "Percentage",
            value: AovKeuzeDekkingOptions.Percentage
          }
        ]}
      />

      {dekkingGegevens.keuzeDekking === AovKeuzeDekkingOptions.VastBedrag && (
        <LabeledCurrencyInput
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.vastBedrag`}
          caption="Vast bedrag"
          appendChildren={<span className="pl-2">Per maand</span>}
          verplicht
        />
      )}

      {dekkingGegevens.keuzeDekking === AovKeuzeDekkingOptions.Percentage && (
        <LabeledPercentageInput
          name={`producten[${selected}].dekking.dekkingGegevensVerzekerde${verzekerde}.percentage`}
          caption="Percentage"
          tooltip={getAovTextResources("DekkingPercentageTooltip")}
          decimalen={0}
        />
      )}
      <UitkeringGedeeltelijkeAo kenmerken={kenmerken} selected={selected} verzekerde={verzekerde} />
    </>
  );
};
