import React from "react";
import { Icon } from "adviesbox-shared";
import { UitslagOptions } from "../../../../.generated/forms/formstypes";

export const castUitslag2Icon = (uitslag: string, title: string | null = null): React.ReactElement => {
  let icon;

  switch (uitslag) {
    case UitslagOptions.Groen:
      icon = <Icon name="vink" alt={title ? title : "vink"} />;
      break;
    case UitslagOptions.Oranje:
      icon = <Icon name="uitroepteken" multiColor={true} alt={title ? title : "uitroepteken"} />;
      break;
    case UitslagOptions.Rood:
      icon = <Icon name="kruis" alt={title ? title : "kruis"} />;
      break;
    default:
      icon = <Icon name="uitroepteken" multiColor={true} alt={title ? title : "uitroepteken"} />;
      break;
  }

  return <div className="w-100 h-100 d-flex justify-content-center align-items-center">{icon}</div>;
};
