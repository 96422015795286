import React, { ReactElement } from "react";
import { FormikProps } from "formik";
import { CurrencyInput, Label } from "adviesbox-shared";
import {
  ScenarioInputParams,
  ScenarioWrappers,
  ScenarioCard
} from "../../shared/components/scenario-input/scenario-card";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import { BedragSpecificatieModalType } from "../infra/overige-posten-types";
import { bedragSpecificatieModalSchema } from "../infra/overige-posten-schema";

type BedragSpecificatieModalProps = {
  data: BedragSpecificatieModalType;
  entries: number;
  startBedrag: number | null;
  firstDisabled: boolean;
  onSave?: (data: BedragSpecificatieModalType) => void;
  closeModal?: () => void;
};

const BedragSpecificatieModal = ({
  data,
  entries,
  startBedrag,
  onSave,
  firstDisabled,
  closeModal
}: BedragSpecificatieModalProps): ReactElement => {
  const scenarioCardWrapper = (
    itemIndex: number,
    entries: number,
    scenarieInputParams: ScenarioInputParams
  ): ReactElement => {
    return (
      <div className="d-flex flex-wrap scenario-container" key={itemIndex}>
        <div className="pr-2 col-3">
          <Label caption={scenarieInputParams.captionStart + itemIndex} name={`scenario[${itemIndex}].bedrag`} />
        </div>
        <div className="pr-1">
          <CurrencyInput
            readonly={firstDisabled && itemIndex === 0}
            name={`scenario[${itemIndex}].bedrag`}
          />
        </div>
      </div>
    );
  };

  const scenarioHeaderWrapper = (columnIndex: number): ReactElement => {
    return (
      <div className="d-flex flex-wrap scenario-container" key={`header-${columnIndex}`}>
        <div className="pr-2 col-3">Jaar</div>
        <div className="pr-1">Bedrag</div>
      </div>
    );
  };

  const scenarioWrappers: ScenarioWrappers = {
    scenarioCardWrapper: scenarioCardWrapper,
    scenarioHeaderWrapper: scenarioHeaderWrapper
  };

  return (
    <AdviesBoxFormik<BedragSpecificatieModalType>
      initialValues={data}
      validationSchema={bedragSpecificatieModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<BedragSpecificatieModalType>): ReactElement => {
        const body = (
          <div className="scenario-read-only">
            <ScenarioCard
              name="scenario"
              scenarioWrappers={scenarioWrappers}
              entries={entries}
              startBedrag={startBedrag ?? undefined}
            />
          </div>
        );

        return <Modal title="Scenario" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />;
      }}
    />
  );
};

BedragSpecificatieModal.displayName = "BedragSpecificatieModal";

export default BedragSpecificatieModal;
