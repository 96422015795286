import { LocalDate } from "@js-joda/core";
import {
  Icon,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledRadioInput,
  LabeledToggleInput,
  LabeledJaarMaandInput,
  ModalButton,
  PercentageInput,
  TooltipWrap
} from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useCallback } from "react";
import { AoUitkeringswijze } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import SpecificatieAoUitkeringModal from "../../producten-overzicht/specificatie-ao-uitkering-modal/specificatie-ao-uitkering-modal";
import { SelectedProps } from "../../shared/types";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { bedragFormat } from "../../shared/utils/currency";
import { enum2options } from "../../shared/utils/enum-to-options";
import { getPensioenTextResources } from "../infra/pensioen-resources";
import {
  OuderdomspensioenModalType,
  PensioenAanvragerType,
  PensioenenState,
  PensioenState
} from "../infra/pensioen-schema";
import OuderdomspensioenModal from "../ouderdomspensioen-modal/ouderdomspensioen-modal";

export const warningButtonHandler = (
  pensioenen: PensioenState[],
  deelnemers: PensioenAanvragerType[],
  selected: number,
  ingangsdatum: LocalDate | null
): boolean => {
  const warningButtonCheck = (ingangsdatum: LocalDate | null): boolean => {
    const deelnemersArray = deelnemers.filter((entry): boolean => {
      return entry.klantId === pensioenen[selected].selectedDeelnemer;
    });
    if (deelnemersArray.length === 0) return false;

    const loondienstenArray = deelnemersArray[0].loondiensten.filter((entry): boolean => {
      return entry.loondienstId === pensioenen[selected].loondienstId;
    });

    return loondienstenArray.length > 0
      ? loondienstenArray[0].meetellenTotDatum !== null && ingangsdatum !== null
        ? loondienstenArray[0].meetellenTotDatum > ingangsdatum
        : false
      : false;
  };
  return warningButtonCheck(ingangsdatum);
};

export const prepensioenWarningButtonHandler = (pensioenen: PensioenState[], selected: number): boolean => {
  const prePensioenBedrag = pensioenen[selected].pensioentoezeggingen.prePensioenBedrag;
  const pensioengevendSalarisObj = pensioenen[selected].pensioengrondslag.pensioengevendSalaris;

  if (pensioengevendSalarisObj) {
    const pensioengevendSalaris = pensioengevendSalarisObj.bedrag;

    return prePensioenBedrag && pensioengevendSalaris && typeof pensioengevendSalaris === "number"
      ? prePensioenBedrag <= pensioengevendSalaris * 0.85
      : true;
  }

  return true;
};

export const pensioengevendSalaris85 = (pensioenen: PensioenState[], selected: number): string | null => {
  const pensioengevendSalarisObj = pensioenen[selected].pensioengrondslag.pensioengevendSalaris;
  if (pensioengevendSalarisObj) {
    return pensioengevendSalarisObj.bedrag ? `${bedragFormat(pensioengevendSalarisObj.bedrag * 0.85)}` : null;
  }
  return null;
};

const Pensioentoezeggingen = ({
  selected,
  formik: {
    setFieldValue,
    values: { pensioenen, deelnemers }
  }
}: SelectedProps & {
  formik: FormikContextType<PensioenenState>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  /* istanbul ignore next */
  if (selected >= pensioenen.length) return <></>;

  return (
    <>
      <div>
        <LabeledToggleInput
          caption="Ouderdomspensioen (OP) (excl. AOW)"
          name={`pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenIsEnabled`}
          labelColSize={12}
          tooltip={getPensioenTextResources("pensioentoezeggingenTooltipOuderdomspensioen")}
        />

        {pensioenen[selected].pensioentoezeggingen.ouderdomspensioenIsEnabled && (
          <div>
            <LabeledCurrencyInput
              name={`pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenBedrag`}
              caption="Bedrag"
              appendChildren={
                <ModalButton
                  parent={`pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenModal`}
                  content={<Icon name="specificatie" alt="Ouderdomspensioen" />}
                  resetSize={true}
                >
                  <OuderdomspensioenModal
                    data={pensioenen[selected].pensioentoezeggingen.ouderdomspensioenModal}
                    onSave={createSaveFunction(
                      `pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenModal`,
                      (result: OuderdomspensioenModalType): void => {
                        /*istanbul ignore next*/
                        if (result.indexeringen && result.indexeringen[0]) {
                          setFieldValue(
                            `pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenBedrag`,
                            result.indexeringen[0].bedrag
                          );
                          setFieldValue(
                            `pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenIngangsdatum`,
                            result.indexeringen[0].ingangsdatum
                          );
                        }
                      }
                    )}
                  />
                </ModalButton>
              }
            />
            <div>
              <LabeledDateInput
                caption="Ingangsdatum"
                name={`pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenIngangsdatum`}
                appendChildren={
                  !warningButtonHandler(
                    pensioenen,
                    deelnemers,
                    selected,
                    pensioenen[selected].pensioentoezeggingen.ouderdomspensioenIngangsdatum
                  ) ? null : (
                    <TooltipWrap
                      name={`pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioen-ingangsdatum-warning`}
                      warningText={getPensioenTextResources(
                        "pensioentoezeggingenWarningIngangsdatumOuderdomsPensioen"
                      ).replace("-vervang-", "ouderdomspensioen")}
                      placement="bottom"
                      iconType="waarschuwing"
                      tooltipClasses="mx-1"
                    />
                  )
                }
              />
              <LabeledJaarMaandInput
                caption="Leeftijd"
                name={`pensioenen[${selected}].pensioentoezeggingen.ouderdomspensioenLeeftijd`}
              />
            </div>
          </div>
        )}
      </div>

      <div>
        <LabeledToggleInput
          caption="Prépensioen"
          name={`pensioenen[${selected}].pensioentoezeggingen.prePensioenIsEnabled`}
          disabled={!pensioenen[selected].pensioentoezeggingen.ouderdomspensioenIsEnabled}
        />
        {pensioenen[selected].pensioentoezeggingen.prePensioenIsEnabled && (
          <div>
            <LabeledCurrencyInput
              name={`pensioenen[${selected}].pensioentoezeggingen.prePensioenBedrag`}
              caption="Bedrag"
              appendChildren={
                prepensioenWarningButtonHandler(pensioenen, selected) ? null : (
                  <TooltipWrap
                    name={`pensioenen[${selected}].pensioentoezeggingen.prepensioen-bedrag-warning`}
                    warningText={`Let op: opgegeven prépensioen is meer dan 85% (${pensioengevendSalaris85(
                      pensioenen,
                      selected
                    )}) van het pensioengevend inkomen. Weet u dit zeker?`}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="mx-1"
                  />
                )
              }
            />
            <LabeledDateInput
              caption="Ingangsdatum"
              name={`pensioenen[${selected}].pensioentoezeggingen.prePensioenIngangsdatum`}
              appendChildren={
                !warningButtonHandler(
                  pensioenen,
                  deelnemers,
                  selected,
                  pensioenen[selected].pensioentoezeggingen.prePensioenIngangsdatum
                ) ? null : (
                  <TooltipWrap
                    name={`pensioenen[${selected}].pensioentoezeggingen.pre-pensioen-ingangsdatum-warning`}
                    warningText={getPensioenTextResources("pensioentoezeggingenWarningIngangsdatumPrePensioen").replace(
                      "-vervang-",
                      "pré-pensioen"
                    )}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="mx-1"
                  />
                )
              }
            />
            <LabeledJaarMaandInput
              caption="Leeftijd"
              name={`pensioenen[${selected}].pensioentoezeggingen.prePensioenLeeftijd`}
            />
          </div>
        )}
      </div>

      <div>
        <LabeledToggleInput
          caption="Nabestaandenpensioen tot AOW bij overlijden voor AOW"
          name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled`}
          labelColSize={12}
        />
        <div>
          {pensioenen[selected].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowIsEnabled && (
            <LabeledCurrencyInput
              name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowBedrag`}
              caption="Bedrag"
              fieldSize="m"
              appendChildren={
                <div className="px-1">
                  <PercentageInput
                    fieldSize="s"
                    name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenVoorAowPercentage`}
                    decimalen={2}
                  />
                </div>
              }
            />
          )}
        </div>
      </div>

      <div>
        <LabeledToggleInput
          caption="Nabestaandenpensioen na AOW bij overlijden voor AOW"
          name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled`}
          labelColSize={12}
        />
        {pensioenen[selected].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowIsEnabled && (
          <div>
            <LabeledCurrencyInput
              caption="Bedrag"
              name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowBedrag`}
              fieldSize="m"
              appendChildren={
                <div className="px-1">
                  <PercentageInput
                    fieldSize="s"
                    name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenVoorAowPercentage`}
                    decimalen={2}
                  />
                </div>
              }
            />
          </div>
        )}
      </div>

      <div>
        <LabeledToggleInput
          caption="Nabestaandenpensioen tot AOW bij overlijden na AOW"
          name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled`}
          labelColSize={12}
        />
        {pensioenen[selected].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowIsEnabled && (
          <div>
            <LabeledCurrencyInput
              caption="Bedrag"
              name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowBedrag`}
              fieldSize="m"
              appendChildren={
                <div className="px-1">
                  <PercentageInput
                    fieldSize="s"
                    name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenTotAowOverlijdenNaAowPercentage`}
                    decimalen={2}
                  />
                </div>
              }
            />
          </div>
        )}
      </div>

      <div>
        <LabeledToggleInput
          caption="Nabestaandenpensioen na AOW bij overlijden na AOW"
          name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled`}
          labelColSize={12}
        />
        {pensioenen[selected].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowIsEnabled && (
          <div>
            <LabeledCurrencyInput
              name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowBedrag`}
              caption="Bedrag"
              fieldSize="m"
              appendChildren={
                <div className="px-1">
                  <PercentageInput
                    fieldSize="s"
                    name={`pensioenen[${selected}].pensioentoezeggingen.nabestaandenpensioenNaAowOverlijdenNaAowPercentage`}
                    decimalen={2}
                  />
                </div>
              }
            />
          </div>
        )}
      </div>

      <div>
        <LabeledToggleInput
          caption="ANW-compensatie tot AOW bij onvolledig ANW"
          name={`pensioenen[${selected}].pensioentoezeggingen.anwCompensatieIsEnabled`}
          labelColSize={12}
        />
        {pensioenen[selected].pensioentoezeggingen.anwCompensatieIsEnabled && (
          <div>
            <LabeledCurrencyInput
              caption="Bedrag"
              name={`pensioenen[${selected}].pensioentoezeggingen.anwCompensatie`}
            />
          </div>
        )}
      </div>

      <LabeledToggleInput
        caption="Wezenpensioen"
        name={`pensioenen[${selected}].pensioentoezeggingen.wezenpensioenIsEnabled`}
      />
      {pensioenen[selected].pensioentoezeggingen.wezenpensioenIsEnabled && (
        <div>
          <LabeledCurrencyInput
            name={`pensioenen[${selected}].pensioentoezeggingen.wezenpensioenBedrag`}
            caption="Bedrag"
            fieldSize="m"
            appendChildren={
              <div className="px-1">
                <PercentageInput
                  fieldSize="s"
                  name={`pensioenen[${selected}].pensioentoezeggingen.wezenpensioenPercentage`}
                  decimalen={2}
                />
              </div>
            }
          />
          <div>
            <LabeledNumberInput
              caption="Eindleeftijd wezenpensioen"
              name={`pensioenen[${selected}].pensioentoezeggingen.wezenpensioenEindleeftijd`}
              appendChildren={<span>Jaar</span>}
            />
          </div>
        </div>
      )}

      <LabeledToggleInput
        caption="Invaliditeitspensioen"
        name={`pensioenen[${selected}].pensioentoezeggingen.invaliditeitspensioenIsEnabled`}
        tooltip={getPensioenTextResources("pensioentoezeggingenTooltipInvaliditeits")}
      />
      {pensioenen[selected].pensioentoezeggingen.invaliditeitspensioenIsEnabled && (
        <div>
          <LabeledCurrencyInput
            caption="Bedrag"
            fieldSize="m"
            name={`pensioenen[${selected}].pensioentoezeggingen.invaliditeitspensioenBedrag`}
            appendChildren={
              <div className="px-1">
                <PercentageInput
                  fieldSize="s"
                  name={`pensioenen[${selected}].pensioentoezeggingen.invaliditeitspensioenPercentage`}
                  decimalen={2}
                />
              </div>
            }
          />
          <div>
            <LabeledRadioInput
              caption="Wijze van uitkering bij gedeeltelijke AO"
              name={`pensioenen[${selected}].pensioentoezeggingen.invaliditeitspensioenWijze`}
              options={enum2options(AoUitkeringswijze)}
              appendChildren={
                <div className="d-inline-block float-right mt-01 mr-23">
                  <ModalButton
                    parent="pensioentoezeggingen.invaliditeitspensioenWijze"
                    content={<Icon name="specificatie" alt="Invaliditeitspensioen" />}
                    resetSize={true}
                  >
                    <SpecificatieAoUitkeringModal
                      data={pensioenen[selected].pensioentoezeggingen.invaliditeitspensioenModal}
                      aoUitkeringswijze={pensioenen[selected].pensioentoezeggingen.invaliditeitspensioenWijze}
                      onSave={createSaveFunction(
                        `pensioenen[${selected}].pensioentoezeggingen.invaliditeitspensioenModal`
                      )}
                    />
                  </ModalButton>
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default connect<SelectedProps, PensioenenState>(Pensioentoezeggingen);
