import React, { ReactElement } from "react";
import { FormikProps } from "formik";

import {
  inkomstenUitBeleggingenModalSchema,
  InkomstenUitBeleggingenModalType
} from "../infra/inkomen-en-fiscus-schema";

import ScenarioInstellingen from "../infra/shared-components/scenario-instellingen";
import { LabeledCurrencyInput, LabeledDateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";

export type InkomstenUitBeleggingenModalData = {
  data: InkomstenUitBeleggingenModalType;
};

type InkomstenUitBeleggingenModalProps = {
  onSave?: (data: InkomstenUitBeleggingenModalType) => void;
  closeModal?: () => void;
};

const InkomstenUitBeleggingenModal = ({
  data,
  onSave,
  closeModal
}: InkomstenUitBeleggingenModalData & InkomstenUitBeleggingenModalProps): ReactElement => {
  const body = (
    <>
      <LabeledCurrencyInput caption="Inkomsten uit beleggingen" name="inkomstenUitBeleggingen" labelColSize={6} />
      <LabeledDateInput caption="Einddatum" name="eindDatum" labelColSize={6} />
      <ScenarioInstellingen name="scenarioInstellingen" />
    </>
  );

  return (
    <AdviesBoxFormik<InkomstenUitBeleggingenModalType>
      initialValues={{ ...data }}
      validationSchema={inkomstenUitBeleggingenModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<InkomstenUitBeleggingenModalType>): ReactElement => (
        <Modal title="Inkomsten uit beleggingen" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
      )}
    />
  );
};

export default InkomstenUitBeleggingenModal;
