/* istanbul ignore file */
import { Financieringsbehoefte } from "../../../../.generated/forms/formstypes";
import { GeldverstrekkerPartij } from "../../../../.generated/instellingen-forms/instellingen-formstypes";
import { productenFilter } from "../../../../shared/utils/instellingen-filters";
import {
  BerekenHypotheekOversluitenInput,
  BerekenHypotheekOversluitenResponse,
  FinancieringsBehoefteExtraPdfVelden,
  HypotheekOversluitenState,
  MappedBerekenHypotheekOversluitenResponse
} from "../../../infra/hypotheek-oversluiten-schema";

export const mapFormikValues = (value: HypotheekOversluitenState): BerekenHypotheekOversluitenInput => {
  return {
    gewensteRentevastperiodeInJaren: value.toetsrente.gewensteRentevastperiode
  };
};

export const mapHypotheekOversluitenResult = (
  apiRes: BerekenHypotheekOversluitenResponse[],
  beschikbareGeldverstrekkersInstellingen: GeldverstrekkerPartij[]
): MappedBerekenHypotheekOversluitenResponse[] => {
  return apiRes
    .filter(k =>
      productenFilter(beschikbareGeldverstrekkersInstellingen, k.geldverstrekkerCode ?? "", k.hypotheeklabelCode)
    )
    .filter(
      (r): boolean =>
        !!r.besparingRvp ||
        !!r.besparingPerMaand ||
        !!r.brutoRentelastPerMaand ||
        !!r.oversluitkostenBedrag ||
        !!r.rentepercentage ||
        !!r.terugverdienperiodeInMaanden ||
        !!r.terugverdienpercentage
    )
    .map(
      (r): MappedBerekenHypotheekOversluitenResponse => ({
        akkoordOversluiten: r.uitslag,
        besparingInRvp: r.besparingRvp,
        besparingMaand: r.besparingPerMaand,
        brutoMaandlast: r.brutoRentelastPerMaand,
        maatschappijNaam: r.hypotheeklabelNaam ?? "",
        oversluitkosten: r.oversluitkostenBedrag,
        rentePercentage: r.rentepercentage,
        terugverdienPeriodeInMaanden: r.terugverdienperiodeInMaanden,
        terugverdienPeriodePercentage: r.terugverdienpercentage
      })
    )
    .sort((a, b) => ((b.besparingMaand ?? 0) > (a.besparingMaand ?? 0) ? 1 : -1));
};

const calcAdvieskosten = (v: Financieringsbehoefte): number => {
  const advieskosten = v.advieskostenBedrag ? v.advieskostenBedrag - (v.advieskostenGespreidBedrag ?? 0) : 0;

  const bemiddelingsvergoeding = v.bemiddelingsvergoedingGeldleningBedrag
    ? v.bemiddelingsvergoedingGeldleningBedrag - (v.bemiddelingsvergoedingGeldleningGespreidBedrag ?? 0)
    : 0;

  const dossiervergoedingBedrag = v.dossiervergoedingBedrag ?? 0;

  const overige = v.overigeBemiddelingsvergoedingBedrag
    ? v.overigeBemiddelingsvergoedingBedrag - (v.overigeBemiddelingsvergoedingGespreidBedrag ?? 0)
    : 0;

  const totaal = advieskosten + bemiddelingsvergoeding + overige + dossiervergoedingBedrag;
  return totaal;
};

export const mapFinancieringsbehoefteResultaat = (
  fbResultaat: Financieringsbehoefte[]
): FinancieringsBehoefteExtraPdfVelden | null => {
  const fbObject = fbResultaat[0];
  const adviesKosten = calcAdvieskosten(fbObject);
  const dossiervergoedingBedrag = fbObject.dossiervergoedingBedrag;
  const taxatiekostenBedrag = fbObject.financieringsopzet.taxatiekostenBedrag;
  const nhgBedrag = fbObject.financieringsopzet.nhgBedrag;
  const arbeidskostenNotarisBedrag =
    (fbObject.financieringsopzet.arbeidskostenNotarisBedrag ?? 0) +
    (fbObject.financieringsopzet.hypotheekakteBedrag ?? 0) +
    (fbObject.financieringsopzet.transportakteBedrag ?? 0);

  return { adviesKosten, dossiervergoedingBedrag, taxatiekostenBedrag, nhgBedrag, arbeidskostenNotarisBedrag };
};
