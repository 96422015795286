import {
  AoIndexeringenType,
  aoIndexeringenSchema,
  AoUitkeringswijze,
  SpecificatieAoUitkeringModalType
} from "../infra/specificatie-ao-uitkering-schema";
import { GedeeltelijkeUitkeringAoOpties, AovSoortProductOptions } from "../../.generated/forms/formstypes";

const getStafelIndexeringen = (
  specificatieAoUitkeringModal: SpecificatieAoUitkeringModalType,
  soortProduct?: AovSoortProductOptions
): AoIndexeringenType[] => {
  const result: AoIndexeringenType[] = [];
  const proRataMinPercentage = specificatieAoUitkeringModal.proRataMinPercentage ?? 0;
  const proRataMaxPercentage = specificatieAoUitkeringModal.proRataTotPercentage ?? 100;

  result.push({
    ...aoIndexeringenSchema.default(),
    ondergrensPercentage: 0,
    bovengrensPercentage: proRataMinPercentage,
    uitkeringPercentage: 0
  });

  if (soortProduct === AovSoortProductOptions.WgaGatVerzekering) {
    /* istanbul ignore else */
    if (proRataMaxPercentage > 35 && proRataMinPercentage < 80) {
      result.push({
        ...aoIndexeringenSchema.default(),
        ondergrensPercentage: Math.max(proRataMinPercentage, 35),
        bovengrensPercentage: Math.min(proRataMaxPercentage, 80),
        uitkeringPercentage: 50
      });
    }
  } else {
    /* istanbul ignore else */
    if (proRataMaxPercentage > 35 && proRataMinPercentage < 45) {
      result.push({
        ...aoIndexeringenSchema.default(),
        ondergrensPercentage: Math.max(proRataMinPercentage, 35),
        bovengrensPercentage: Math.min(proRataMaxPercentage, 45),
        uitkeringPercentage: 40
      });
    }
    /* istanbul ignore else */
    if (proRataMaxPercentage > 45 && proRataMinPercentage < 55) {
      result.push({
        ...aoIndexeringenSchema.default(),
        ondergrensPercentage: Math.max(proRataMinPercentage, 45),
        bovengrensPercentage: Math.min(proRataMaxPercentage, 55),
        uitkeringPercentage: 50
      });
    }
    /* istanbul ignore else */
    if (proRataMaxPercentage > 55 && proRataMinPercentage < 65) {
      result.push({
        ...aoIndexeringenSchema.default(),
        ondergrensPercentage: Math.max(proRataMinPercentage, 55),
        bovengrensPercentage: Math.min(proRataMaxPercentage, 65),
        uitkeringPercentage: 60
      });
    }
    /* istanbul ignore else */
    if (proRataMaxPercentage > 65 && proRataMinPercentage < 80) {
      result.push({
        ...aoIndexeringenSchema.default(),
        ondergrensPercentage: Math.max(proRataMinPercentage, 65),
        bovengrensPercentage: Math.min(proRataMaxPercentage, 80),
        uitkeringPercentage: 75
      });
    }
  }

  result.push({
    ...aoIndexeringenSchema.default(),
    ondergrensPercentage: proRataMaxPercentage,
    bovengrensPercentage: 100,
    uitkeringPercentage: 100
  });

  return result;
};

const getWerkelijkIndexeringen = (
  specificatieAoUitkeringModal: SpecificatieAoUitkeringModalType,
  soortProduct?: AovSoortProductOptions
): AoIndexeringenType[] => {
  const result: AoIndexeringenType[] = [];
  const proRataMinPercentage = specificatieAoUitkeringModal.proRataMinPercentage ?? 0;
  const proRataMaxPercentage = specificatieAoUitkeringModal.proRataTotPercentage ?? 100;

  result.push({
    ...aoIndexeringenSchema.default(),
    ondergrensPercentage: 0,
    bovengrensPercentage: proRataMinPercentage,
    uitkeringPercentage: 0
  });

  result.push({
    ...aoIndexeringenSchema.default(),
    ondergrensPercentage: proRataMinPercentage,
    bovengrensPercentage: proRataMaxPercentage,
    uitkeringPercentage: soortProduct === AovSoortProductOptions.WgaGatVerzekering ? 70 : null
  });

  result.push({
    ...aoIndexeringenSchema.default(),
    ondergrensPercentage: proRataMaxPercentage,
    bovengrensPercentage: 100,
    uitkeringPercentage: 100
  });

  return result;
};

const getVolledigIndexeringen = (
  specificatieAoUitkeringModal: SpecificatieAoUitkeringModalType
): AoIndexeringenType[] => {
  const result: AoIndexeringenType[] = [];
  const proRataMinPercentage = specificatieAoUitkeringModal.proRataMinPercentage ?? 0;

  result.push({
    ...aoIndexeringenSchema.default(),
    ondergrensPercentage: 0,
    bovengrensPercentage: proRataMinPercentage,
    uitkeringPercentage: 0
  });

  result.push({
    ...aoIndexeringenSchema.default(),
    ondergrensPercentage: proRataMinPercentage,
    bovengrensPercentage: 100,
    uitkeringPercentage: 100
  });

  return result;
};

export const getDefaultIndexeringen = (
  aoUitkeringswijze: AoUitkeringswijze | null,
  specificatieAoUitkeringModal: SpecificatieAoUitkeringModalType,
  soortProduct?: AovSoortProductOptions
): AoIndexeringenType[] => {
  if (aoUitkeringswijze === AoUitkeringswijze.ProRata) {
    if (specificatieAoUitkeringModal.gedeeltelijkeUitkeringObv === GedeeltelijkeUitkeringAoOpties.Staffel) {
      return getStafelIndexeringen(specificatieAoUitkeringModal, soortProduct);
    } else {
      return getWerkelijkIndexeringen(specificatieAoUitkeringModal, soortProduct);
    }
  } else if (aoUitkeringswijze === AoUitkeringswijze.Volledig) {
    return getVolledigIndexeringen(specificatieAoUitkeringModal);
  }
  return [];
};
