import React, { ReactElement } from "react";
import { connect, FormikContextType, getIn } from "formik";
import { ScenarioInstellingenType } from "../inkomen-en-fiscus-schema";
import { FormikSideEffects } from "use-formik-side-effects";
import { determineScenarioInstellingenSideEffects } from "./determine-scenario-instellingen-side-effects";
import { LabeledDateInput, LabeledNumberInput, LabeledPercentageInput } from "adviesbox-shared";
import { LabeledBevestigingInput } from "adviesbox-shared";

export type ScenarioInstellingenProps = {
  name: string;
};

export type ObjectWithScenarioInstellingenData = {
  [index: string]: ScenarioInstellingenType;
};

const ScenarioInstellingen = ({
  name,
  formik
}: ScenarioInstellingenProps & { formik: FormikContextType<{}> }): ReactElement => {
  const scenario = getIn(formik.values, name) as ScenarioInstellingenType;
  return (
    <>
      <FormikSideEffects<ObjectWithScenarioInstellingenData>
        determineSideEffects={determineScenarioInstellingenSideEffects(name)}
      />
      <h3 className="mt-5">
        <b>Scenario instellingen</b>
      </h3>
      <LabeledBevestigingInput
        caption="Einde bij arbeidsongeschiktheid"
        name={`${name}.eindeBijArbeidsongeschiktheid`}
        labelColSize={6}
      />
      {!scenario.eindeBijArbeidsongeschiktheid && (
        <LabeledBevestigingInput
          caption="Regeling arbeidsongeschiktheid"
          labelColSize={6}
          name={`${name}.regelingArbeidsongeschiktheid`}
          labels={{
            false: "Loopt volledig door",
            true: "Afhankelijk van benutting verdiencapaciteit"
          }}
        />
      )}
      <LabeledBevestigingInput caption="Einde bij werkloosheid" name={`${name}.eindeWerkloosheid`} labelColSize={6} />
      {scenario.hasAanvrager2 && (
        <LabeledBevestigingInput
          caption="Overgang op partner bij overlijden"
          name={`${name}.overgangBijOverlijden`}
          labelColSize={6}
        />
      )}

      {scenario.overgangBijOverlijden && (
        <LabeledPercentageInput caption="Percentage" name={`${name}.percentage`} decimalen={0} labelColSize={6} />
      )}
      {scenario.overgangBijOverlijden && (
        <LabeledDateInput caption="Einddatum" name={`${name}.einddatum`} labelColSize={6} />
      )}
      {scenario.overgangBijOverlijden && (
        <LabeledNumberInput caption="Leeftijd" name={`${name}.leeftijd`} labelColSize={6} />
      )}
    </>
  );
};

export default connect<ScenarioInstellingenProps>(ScenarioInstellingen);
