import { LocalDate } from "@js-joda/core";
import {
  BerekenPercentageInput,
  Card,
  CardWrapper,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledJaarMaandInput,
  LabeledNumberInput,
  LabeledRadioInput,
  LabeledSelectInput,
  LabeledTextInput,
  PercentageInput,
  SettingsContext,
  useAdviesboxDataRepository,
  FetchDataButton
} from "adviesbox-shared";
import { RouteParams } from "adviesbox-shared/utils/types";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  FiscaleVoortzettingOptions,
  GebruikPandSoort,
  RentevariantOptions,
  AflossingsVormType
} from "../../.generated/forms/formstypes";
import { HypotheeksamenstellingOutput } from "../../.generated/hypotheek-vergelijker/hypotheek-vergelijkertypes";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { LabelValuePairs } from "../../shared/types";
import { hypotheekSchema } from "../infra/hypotheek-schema";
import { HypotheekType, HypotheekVormType } from "../infra/hypotheek-types";
import {
  calculateBelastingVerhouding,
  getPandRestant,
  getRestschuld,
  getLeningdeelSom,
  getAfrekbaarBedrag
} from "../infra/hypotheek-utils";
import { mapDlTargetToHypotheekUiField } from "../infra/map-hypotheek-dl-2-ui";
import { syncHypotheekVergelijkenModalSideEffects } from "./determine-sync-hypotheek-vergelijken-modal-side-effects";
import {
  HypotheekVergelijkenAflossingsvormOptions,
  HypotheekVergelijkenAflossingsvormRestschuldOptions
} from "./hypotheek-vergelijken-helper";
import classes from "./hypotheek-vergelijken-modal.module.scss";
import { HypotheekVergelijkenModalType } from "./hypotheek-vergelijken-types";
import HypotheekVergelijkerDataGrid from "./hypotheek-vergelijker-data-grid/hypotheek-vergelijker-data-grid";
import { mapHypotheekGenereerLeningdeelDl2Ui } from "./infra/map-hypotheek-genereer-dl-2-ui";

export function getHypotheekWithSumLeningdeelbedrag(hypotheek: HypotheekVergelijkenModalType): HypotheekType {
  const defaultHypotheek = hypotheekSchema.default();

  const gelinktePand = getPandRestant(hypotheek.panden, hypotheek.hypotheekSamenstelling, "voorstel");
  const isTweedeWoning = gelinktePand.gelinktePand.gebruikPand === GebruikPandSoort.TweedeWoning;
  const gewensteHypotheekBedrag = hypotheek.gewensteHypotheekBedrag;
  const totaalRestschuld = getRestschuld(hypotheek.hypotheekSamenstelling);
  const opgeteldeLeningdeelBedrag = (gelinktePand.opgeteldeLeningdrag || 0) + totaalRestschuld || null;

  const leningdeelSom = getLeningdeelSom(
    "voorstel",
    gewensteHypotheekBedrag,
    opgeteldeLeningdeelBedrag,
    getRestschuld(hypotheek.hypotheekSamenstelling),
    gelinktePand,
    hypotheek.hypotheekSamenstelling,
    null
  );

  const aftrekbaarBedrag =
    getAfrekbaarBedrag(hypotheek.eigenwoningschuldBedrag || 0, hypotheek.hypotheekSamenstelling, null) || 0;

  const { box1Bedrag, box3Bedrag, box1Percentage, box3Percentage } = calculateBelastingVerhouding(
    isTweedeWoning,
    aftrekbaarBedrag || 0,
    leningdeelSom || 0
  );

  return {
    ...defaultHypotheek,
    leningdeelgegevens: {
      ...defaultHypotheek.leningdeelgegevens,
      leningdeelHoofdsom: { bedrag: leningdeelSom, berekenen: false, berekendBedrag: leningdeelSom }
    },
    product: {
      ...defaultHypotheek.product,
      ingangsdatum: hypotheek.aanvangsDatum ?? LocalDate.now(),
      einddatum: (hypotheek.aanvangsDatum ?? LocalDate.now()).plusYears(defaultHypotheek.product.looptijd?.jaren ?? 0)
    },
    fiscalegegevens: {
      ...defaultHypotheek.fiscalegegevens,
      deelBox1Bedrag: box1Bedrag,
      deelBox3Bedrag: box3Bedrag,
      deelBox1Percentage: box1Percentage,
      deelBox3Percentage: box3Percentage,
      begindatumRenteaftrek: LocalDate.now()
        .plusMonths(2)
        .withDayOfMonth(1),
      einddatumRenteaftrek: LocalDate.now()
        .plusYears(defaultHypotheek.product.looptijd.jaren ?? 30)
        .plusMonths(2)
        .withDayOfMonth(1)
    }
  };
}

export const Stap1 = (): ReactElement => {
  const { voorstel } = useParams<RouteParams>();
  const { values: formikValues, setFieldValue } = useFormikContext<HypotheekVergelijkenModalType>();
  const { hypotheekvergelijkerOrigin } = useContext(SettingsContext);
  const selectedRowState = useState(0);
  const [selectedRow, setSelectedRow] = selectedRowState;

  const hypotheekSamenstelling = formikValues.hypotheekSamenstelling[selectedRow];
  const heeftLeningdeelgegevens = hypotheekSamenstelling && hypotheekSamenstelling?.leningdeelgegevens !== undefined;

  const url = `${hypotheekvergelijkerOrigin}/Voorstellen/${voorstel}/Hypotheekvergelijker/Hypotheeksamenstelling`;
  const { fetchData, loading } = useAdviesboxDataRepository<
    HypotheeksamenstellingOutput,
    HypotheekVergelijkenModalType
  >(url, {
    method: "POST",
    getDataId: () => "",
    mapUiToDl: () => null,
    mapDlToUi: (_, res) => {
      const leningdelen = res?.resultaat?.leningdelen ?? [];
      const nieuweLeningdelen: HypotheekType[] = leningdelen.map(c => mapHypotheekGenereerLeningdeelDl2Ui(c)) || [];

      // leningdelen in voorstel mogen geen 'hypotheekProductDetails' gespecificeerd hebben, waarin een relatie met pand is
      // er is namelijk altijd maar 1 pand, gespecificeerd op scherm: Analyse - Woning
      nieuweLeningdelen.forEach(c => (c.hypotheekProductDetails = null));

      const gegenereedeSamenstelling = nieuweLeningdelen;

      setFieldValue(`hypotheekSamenstelling`, gegenereedeSamenstelling);
      setSelectedRow(gegenereedeSamenstelling.length ? gegenereedeSamenstelling.length - 1 : 0);
      return null;
    },
    mapTargetToUiField: mapDlTargetToHypotheekUiField
  });

  const tonenConfigFiscaleVoortzetting =
    hypotheekSamenstelling &&
    !hypotheekSamenstelling.product.doorlopend &&
    (hypotheekSamenstelling.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaar ||
      hypotheekSamenstelling.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening);

  const hypotheekVergelijkenRentevariantOptions: LabelValuePairs = [
    {
      label: "Rentevast",
      value: RentevariantOptions.Rentevast
    },
    {
      label: "Variabel",
      value: RentevariantOptions.Variabel
    }
  ];

  const productKeuzes = formikValues.fiscaleVoortzettingKeuzes || [];
  const fiscaleVoortzettingOptions: LabelValuePairs = [
    {
      label: "Geen",
      value: FiscaleVoortzettingOptions.Geen
    },
    ...productKeuzes.map((keuze, index) => ({
      label: keuze.omschrijving || "",
      value: keuze.productId || `${index}`
    })),
    {
      label: "Eigen invoer",
      value: FiscaleVoortzettingOptions.EigenInvoer
    }
  ];

  const hypotheekLeningdeel = (
    hypotheekVorm: HypotheekVormType,
    doorlopend: boolean,
    starter: boolean,
    restschuld: boolean
  ): ReactElement => {
    return (
      <>
        {restschuld ? (
          <LabeledRadioInput
            caption="Aflossingsvorm"
            labelColSize={6}
            name={`hypotheekSamenstelling[${selectedRow}].hypotheekVorm.aflossingsvorm`}
            options={HypotheekVergelijkenAflossingsvormRestschuldOptions}
          />
        ) : starter ? (
          <LabeledTextInput
            caption="Aflossingsvorm"
            labelColSize={6}
            name={`hypotheekSamenstelling[${selectedRow}].hypotheekVorm.aflossingsvorm`}
            readonly={true}
          />
        ) : (
          <LabeledSelectInput
            caption="Aflossingsvorm"
            labelColSize={6}
            name={`hypotheekSamenstelling[${selectedRow}].hypotheekVorm.aflossingsvorm`}
            readonly={formikValues.hypotheekSamenstelling[selectedRow].product.doorlopend}
            options={HypotheekVergelijkenAflossingsvormOptions(hypotheekVorm)}
          />
        )}

        <LabeledCurrencyInput
          caption="Leningdeelbedrag"
          labelColSize={6}
          name={`hypotheekSamenstelling[${selectedRow}].leningdeelgegevens.leningdeelHoofdsom.bedrag`}
          readonly={doorlopend || starter || restschuld}
        />
        {doorlopend || starter || restschuld ? (
          <LabeledTextInput
            caption="Rentevariant"
            labelColSize={6}
            name={`hypotheekSamenstelling[${selectedRow}].leningdeelgegevens.renteVariant`}
            readonly={true}
          />
        ) : (
          <LabeledRadioInput
            caption="Rentevariant"
            labelColSize={6}
            name={`hypotheekSamenstelling[${selectedRow}].leningdeelgegevens.renteVariant`}
            options={hypotheekVergelijkenRentevariantOptions}
          />
        )}
        {formikValues.hypotheekSamenstelling[selectedRow].leningdeelgegevens.renteVariant ===
          RentevariantOptions.Rentevast && (
            <>
              <LabeledNumberInput
                caption="Rentevastperiode"
                labelColSize={6}
                name={`hypotheekSamenstelling[${selectedRow}].leningdeelgegevens.rentevastPeriodeJaar`}
                readonly={doorlopend || starter}
                appendChildren={<span className="px-1">Jaar</span>}
              />
              <LabeledDateInput
                caption="Einddatum rentevastperiode"
                labelColSize={6}
                name={`hypotheekSamenstelling[${selectedRow}].leningdeelgegevens.einddatum`}
                readonly={true}
              />
            </>
          )}

        {(doorlopend || starter || restschuld) && (
          <BerekenPercentageInput
            caption="Rentepercentage"
            labelColSize={6}
            name={`hypotheekSamenstelling[${selectedRow}].leningdeelgegevens.rentePercentage`}
            readonly={true}
            hideBerekenenButton={true}
            decimalen={2}
          />
        )}
      </>
    );
  };

  const fiscaleGegevens = (doorlopend: boolean, starter: boolean, pandId: string): ReactElement => {
    const tweedeWoning = pandId
      ? formikValues.panden.find(c => c.pandId === pandId)?.gebruikPand === GebruikPandSoort.TweedeWoning
      : false;

    return (
      <>
        <LabeledCurrencyInput
          caption="Deel box 1"
          name={`hypotheekSamenstelling[${selectedRow}].fiscalegegevens.deelBox1Bedrag`}
          allowNull={false}
          labelColSize={6}
          fieldSize="m"
          readonly={doorlopend || starter || tweedeWoning}
          appendChildren={
            <div className="d-inline-flex px-1">
              <PercentageInput
                name={`hypotheekSamenstelling[${selectedRow}].fiscalegegevens.deelBox1Percentage`}
                decimalen={2}
                fieldSize="s"
                readonly={doorlopend || starter || tweedeWoning}
                allowNull={false}
              />
            </div>
          }
        />
        <LabeledCurrencyInput
          caption="Deel box 3"
          name={`hypotheekSamenstelling[${selectedRow}].fiscalegegevens.deelBox3Bedrag`}
          allowNull={false}
          labelColSize={6}
          fieldSize="m"
          readonly={doorlopend || starter || tweedeWoning}
          appendChildren={
            <div className="d-inline-flex px-1">
              <PercentageInput
                name={`hypotheekSamenstelling[${selectedRow}].fiscalegegevens.deelBox3Percentage`}
                decimalen={2}
                fieldSize="s"
                readonly={doorlopend || starter || tweedeWoning}
                allowNull={false}
              />
            </div>
          }
        />
        <LabeledDateInput
          caption="Begindatum renteaftrek"
          labelColSize={6}
          name={`hypotheekSamenstelling[${selectedRow}].fiscalegegevens.begindatumRenteaftrek`}
          readonly={doorlopend || starter || tweedeWoning}
        />

        <LabeledDateInput
          caption="Einddatum renteaftrek"
          labelColSize={6}
          name={`hypotheekSamenstelling[${selectedRow}].fiscalegegevens.einddatumRenteaftrek`}
          readonly={doorlopend || starter || tweedeWoning}
        />
      </>
    );
  };

  return (
    <>
      <ISWSideEffects<HypotheekVergelijkenModalType>
        sync={syncHypotheekVergelijkenModalSideEffects({ selected: selectedRow, situatie: "voorstel" })}
      />
      <div>
        {heeftLeningdeelgegevens && <HypotheekVergelijkerDataGrid selected={selectedRowState} />}
        {!heeftLeningdeelgegevens && (
          <div className="d-flex align-items-center justify-content-center">
            <div className={classes.emptyGrid}>
              <div className={classes.header}>Er is nog geen leningdeel toegevoegd.</div>
              <div className={classes.text}>
                Gebruik de knop &apos;samenstelling genereren&apos; of &apos;leningdeel toevoegen&apos; om een
                samenstelling te genereren of leningdeel toe te voegen.
              </div>
              <div className={classes.text}>
                Er is minimaal één leningdeel nodig om door te kunnen gaan naar de voorwaarden selectie.
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className={classNames("button-container", classes.button_container)}>
          <FetchDataButton
            keepVisible
            onClick={fetchData}
            dataOutDated={false}
            initialText={"Samenstelling genereren"}
            invalid={false}
            loading={loading}
          />
          <Button
            id="btn-leningdeel-toevoegen"
            onClick={() => {
              setSelectedRow(formikValues.hypotheekSamenstelling.length);
              setFieldValue("hypotheekSamenstelling", [
                ...formikValues.hypotheekSamenstelling.concat(getHypotheekWithSumLeningdeelbedrag(formikValues))
              ]);
            }}
            className={"btn btn-primary ml-2"}
          >
            + Leningdeel toevoegen
          </Button>
        </div>
      </div>
      <div>
        {heeftLeningdeelgegevens && (
          <>
            <CardWrapper maxRowCount={0}>
              <Card title={formikValues.hypotheekSamenstelling[selectedRow].product.doorlopend ? "Huidig product" : "Nieuw product"}>
                <LabeledDateInput
                  caption="Ingangsdatum"
                  labelColSize={6}
                  name={`hypotheekSamenstelling[${selectedRow}].product.ingangsdatum`}
                  readonly={true}
                />
                <LabeledDateInput
                  caption="Einddatum"
                  labelColSize={6}
                  name={`hypotheekSamenstelling[${selectedRow}].product.einddatum`}
                  readonly={true}
                />

                <LabeledJaarMaandInput
                  caption="Looptijd"
                  labelColSize={6}
                  name={`hypotheekSamenstelling[${selectedRow}].product.looptijd`}
                  readonly={
                    formikValues.hypotheekSamenstelling[selectedRow].product.doorlopend ||
                    formikValues.hypotheekSamenstelling[selectedRow].hypotheekVorm.isStartersLening
                  }
                />
              </Card>
              <Card title="Leningdeelgegevens">
                {hypotheekLeningdeel(
                  formikValues.hypotheekSamenstelling[selectedRow].hypotheekVorm,
                  formikValues.hypotheekSamenstelling[selectedRow].product.doorlopend,
                  formikValues.hypotheekSamenstelling[selectedRow].hypotheekVorm.isStartersLening,
                  formikValues.hypotheekSamenstelling[selectedRow].hypotheekVorm.isRestschuldLening
                )}
              </Card>

              <Card title="Fiscale gegevens">
                {fiscaleGegevens(
                  formikValues.hypotheekSamenstelling[selectedRow].product.doorlopend,
                  formikValues.hypotheekSamenstelling[selectedRow].hypotheekVorm.isStartersLening,

                  (formikValues.hypotheekSamenstelling[selectedRow].hypotheekProductDetails &&
                    formikValues.hypotheekSamenstelling[selectedRow].hypotheekProductDetails?.hypotheekOpWoning !== ""
                    ? formikValues.hypotheekSamenstelling[selectedRow].hypotheekProductDetails?.hypotheekOpWoning
                    : formikValues.panden[0].pandId) || ""
                )}
              </Card>
              {tonenConfigFiscaleVoortzetting && (
                <Card title="Fiscale voortzetting">
                  <LabeledRadioInput
                    caption="Fiscale voortzetting"
                    labelColSize={6}
                    name={`hypotheekSamenstelling[${selectedRow}].fiscaleRegeling.productId`}
                    options={fiscaleVoortzettingOptions}
                  />
                  {formikValues.hypotheekSamenstelling[selectedRow].fiscaleRegeling?.productId !==
                    FiscaleVoortzettingOptions.Geen && (
                      <LabeledCurrencyInput
                        caption="Ingebrachte waarde"
                        labelColSize={6}
                        name={`hypotheekSamenstelling[${selectedRow}].fiscaleRegeling.ingebrachteWaardeBedrag`}
                        readonly={
                          formikValues.hypotheekSamenstelling[selectedRow].fiscaleRegeling?.productId !==
                          FiscaleVoortzettingOptions.EigenInvoer
                        }
                      />
                    )}
                </Card>
              )}
            </CardWrapper>
          </>
        )}
      </div>
    </>
  );
};
