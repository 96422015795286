import { Icon, LabeledJaarMaandInput, LabeledCurrencyInput, LabeledSelectInput, ModalButton, LabeledResult } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useCallback } from "react";
import { Button } from "react-bootstrap";
import { KapitaalopbouwOptions, BetalingsTermijnType, SoortBerekeningOptions } from "../../.generated/forms/formstypes";
import { PremieKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { getFormattedDate } from "../../shared/utils/dates";
import { VermogensType } from "../infra/vermogen-types";
import ExtraInlegSpecificatieModal from "./extra-inleg-specificatie-modal";

const stortingstermijnOptions = [
  { label: "Maand", value: BetalingsTermijnType.Maand },
  { label: "Kwartaal", value: BetalingsTermijnType.Kwartaal },
  { label: "Halfjaar", value: BetalingsTermijnType.HalfJaar },
  { label: "Jaar", value: BetalingsTermijnType.Jaar }
];

type InleggegevensProps = {
  selected: number;
  kenmerken: PremieKenmerken;
  berekenKnopText?: string;
};

const Inleggegevens = ({
  selected,
  kenmerken,
  berekenKnopText = "Inleg berekenen",
  formik: {
    setFieldValue,
    values: { producten }
  }
}: InleggegevensProps & {
  formik: FormikContextType<VermogensType>;
}): ReactElement => {
  const {
    inleggegevens,
    product,
    kapitaalopbouw: { soortBerekening },
    dataHasChanged,
    fiscaleRegeling
  } = producten[selected];
  const {
    extraInlegJaren,
    extraInlegJaren: { scenario }
  } = inleggegevens;
  const isAftrekbaar =
    fiscaleRegeling.lijfrenteclausule && fiscaleRegeling.kapitaalopbouw === KapitaalopbouwOptions.Box1;
  const duurHoogLaagInMaanden = jaarMaandInMaanden(inleggegevens.duurHoogLaag) ?? 0;
  const hoogLaagDuurAanwezig = duurHoogLaagInMaanden > 0;
  const einddatumStortingen = product.ingangsdatum?.plusMonths(jaarMaandInMaanden(inleggegevens.duur) ?? 0) ?? null;
  const einddatumStortingenHoogLaag = product.ingangsdatum?.plusMonths(duurHoogLaagInMaanden) ?? null;
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const totaalExtraInleg = React.useMemo(() => bedragFormat(optellen(scenario.map(e => e.bedrag)), 2), [scenario]);

  const berekenPremie = (): void => {
    setFieldValue(`producten[${selected}].dataHasChanged`, !dataHasChanged);
  };

  return (
    <>
      {kenmerken.duurPremiebetalingTonen && (
        <LabeledJaarMaandInput caption="Duur" name={`producten[${selected}].inleggegevens.duur`} />
      )}

      {kenmerken.einddatumPremiebetalingTonen && (
        <LabeledResult
          name={`producten[${selected}].onttrekkingen.einddatumStortingen`}
          caption="Einddatum stortingen"
          readonly
          result={(): string => (einddatumStortingen ? getFormattedDate(einddatumStortingen) : "")}
        />
      )}

      {kenmerken.hoogLaagConstructieTonen && (
        <LabeledJaarMaandInput caption="Duur hoog" name={`producten[${selected}].inleggegevens.duurHoogLaag`} />
      )}

      {kenmerken.hoogLaagConstructieTonen && hoogLaagDuurAanwezig && (
        <LabeledResult
          name={`producten[${selected}].onttrekkingen.einddatumStortingenHoogLaag`}
          caption="Hoog / laag constructie tot"
          readonly
          result={(): string => (einddatumStortingenHoogLaag ? getFormattedDate(einddatumStortingenHoogLaag) : "")}
        />
      )}

      <LabeledSelectInput
        caption="Stortingstermijn"
        name={`producten[${selected}].inleggegevens.stortingstermijn`}
        options={stortingstermijnOptions}
        readonly={!kenmerken.betalingstermijnEnabled}
      />

      <LabeledCurrencyInput
        caption={hoogLaagDuurAanwezig ? "Inleg laag" : "Inleg"}
        name={`producten[${selected}].inleggegevens.inleg`}
        decimalen={2}
        readonly={soortBerekening === SoortBerekeningOptions.Inleg}
      />

      {hoogLaagDuurAanwezig && (
        <LabeledCurrencyInput
          decimalen={2}
          caption="Inleg hoog"
          name={`producten[${selected}].inleggegevens.inlegHoog`}
        />
      )}

      {isAftrekbaar && (
        <LabeledCurrencyInput
          decimalen={2}
          caption="Aftrekbaar"
          name={`producten[${selected}].inleggegevens.aftrekbaar`}
        />
      )}

      <LabeledCurrencyInput
        decimalen={2}
        caption="Eerste inleg"
        name={`producten[${selected}].inleggegevens.eersteInleg`}
      />

      <LabeledResult
        caption="Extra inleg"
        name={`producten[${selected}].inleggegevens.extraInleg`}
        result={() => totaalExtraInleg}
        alignRight={true}
        appendChildren={
          <ModalButton
            resetSize={true}
            parent={`producten[${selected}].inleggegevens.extraInlegJaren`}
            size="lg"
            content={<Icon name="specificatie" alt="Extra inleg specificatie" />}
          >
            <ExtraInlegSpecificatieModal
              data={extraInlegJaren}
              entries={producten[selected].inleggegevens.duur.jaren ?? 30}
              onSave={createSaveFunction(`producten[${selected}].inleggegevens.extraInlegJaren`)}
            />
          </ModalButton>
        }
      />

      {kenmerken.berekenPremieKnopTonen && (
        <div className="button-container">
          <Button
            id="premie-berekenen"
            variant="primary"
            onClick={berekenPremie}
            disabled={hoogLaagDuurAanwezig && !inleggegevens.inlegHoog}
          >
            {berekenKnopText}
          </Button>
        </div>
      )}
    </>
  );
};

export default connect<InleggegevensProps, VermogensType>(Inleggegevens);
