import { mapJaarMaandInputFromLooptijdDl2Ui } from "adviesbox-shared";
import {
  Dashboard,
  DashboardOutput,
  DashboardScenarioArbeidsongeschiktheid,
  DashboardScenarioOverlijden,
  DashboardScenarioPensioen,
  DashboardScenarioWerkloosheid,
  DashboardUitgangspuntenArbeidsongeschiktheid,
  DashboardUitgangspuntenOverlijden,
  KlantnaamReadOnly,
  MotiveringBeschikbareJaarruimte,
  MotiveringControleHypotheeknorm,
  MotiveringControleGewenstInkomen
} from "../../.generated/forms/formstypes";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { FieldMap, mapBerekenInput, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { hasValue } from "../../shared/utils/helpers";
import { target2field } from "../../shared/utils/target-to-field";
import { controleNormSchema } from "../components/controle-norm/infra/controle-norm-schema";
import { scenarioVanSchema } from "../components/scenario-van/scenario-van-schema";
import {
  scenarioArbeidsongeschiktheidModalSchema,
  ScenarioDoorrekeningScenariosType,
  scenarioDoorrekeningSchema
} from "../scenario-arbeidsongeschiktheid-modal/infra/scenario-arbeidsongeschiktheid-modal-schema";
import {
  orvSchema,
  scenarioOverlijdenModalSchema
} from "../scenario-overlijden-modal/infra/scenario-overlijden-modal-schema";
import {
  beschikbareJaarruimteSchema,
  scenarioPensioenModalSchema
} from "../scenario-pensioen-modal/infra/scenario-pensioen-modal-schema";
import { scenarioWerkloosheidModalSchema } from "../scenario-werkloosheidheid-modal/infra/scenario-werkloosheid-modal-schema";
import { dashboardSchema } from "./dashboard-schema";
import { DashboardState } from "./dashboard-types";

type AanvragersContext = {
  aanvrager: KlantnaamReadOnly | null;
  partner: KlantnaamReadOnly | null;
};

export function mapDashboardDlTargetToUiField(target: string): UiName | null {
  const map: FieldMap<DashboardState> = {};

  /* istanbul ignore next */
  if (target === "Pensioen.Uitgangspunten.BeschikbareJaarruimteAanvrager1.PensioenuitkeringTotLeeftijdInMaanden")
    return {
      field: "pensioen.beschikbareJaarruimteAanvrager.gewenstePensioenLeeftijd",
      label: "Ongeldig pensioenleeftijd"
    };

  /* istanbul ignore next */
  if (target === "Pensioen.Uitgangspunten.BeschikbareJaarruimteAanvrager2.PensioenuitkeringTotLeeftijdInMaanden")
    return {
      field: "pensioen.beschikbareJaarruimtePartner.gewenstePensioenLeeftijd",
      label: "Ongeldig pensioenleeftijd"
    };

  return target2field(map, target);
}

const getAanvragers = (
  aanvrager1: KlantnaamReadOnly | null,
  aanvrager2: KlantnaamReadOnly | null
): AanvragersContext => {
  return { aanvrager: aanvrager1, partner: aanvrager2 };
};

const mapControleNorm = createMapToUi(controleNormSchema)
  .with<MotiveringControleGewenstInkomen>()
  .from<MotiveringControleHypotheeknorm>({
    controleMaandlast: v => hasValue(v.controleMaandlast),
    controleMaandLastKeuze: v => v.controleMaandlast,
    maximaleHypotheeksom: v => v.controleerMaximaleHypotheek,
    controleGewenstInkomen: (_, gewenstInkomen) => gewenstInkomen.controleGewenstInkomen,
    gewenstNettoBesteedbaarInkomen: (_, gewenstInkomen) => gewenstInkomen.controleGewenstNbi,
    margeOndergrensPercentage: (_, gewenstInkomen) => gewenstInkomen.margeOndergrensPercentage,
    margeBovengrensPercentage: (_, gewenstInkomen) => gewenstInkomen.margeBovengrensPercentage,
    gewijzigdeJaren: (_, gewenstInkomen) => gewenstInkomen.gewijzigdeJarenTonen,
    benodigdKapitaal: (_, gewenstInkomen) => gewenstInkomen.benodigdKapitaalTonen,
    gewenstPercentageHuidigNbi: (_, gewenstInkomen) => gewenstInkomen.huidigNbiPercentage,
    gewenstVastBedrag: (_, gewenstInkomen) => gewenstInkomen.vastBedrag,
    brutoInkomenswens: (_, gewenstInkomen) => gewenstInkomen.brutoInkomenswensPercentage
  });

const mapScenarioVan = createMapToUi(scenarioVanSchema)
  .with<AanvragersContext & { beiden: boolean }>()
  .from<string[]>({
    aanvrager1Geselecteerd: (v, aanvragersContext) =>
      !v.length ? true : v.filter(k => k === aanvragersContext.aanvrager?.klantId).length > 0,
    aanvrager2Geselecteerd: (v, aanvragersContext) =>
      v.filter(k => k === aanvragersContext.partner?.klantId).length > 0,
    beiden: (_, c) => c.beiden
  });

const mapOverlijden = createMapToUi(orvSchema).from<DashboardUitgangspuntenOverlijden>({
  aanvragerPercentage: v => v.werkenNaOverlijdenPartnerAanvrager1Percentage,
  partnerPercentage: v => v.werkenNaOverlijdenPartnerAanvrager2Percentage,
  leeftijd: v => mapJaarMaandInputFromLooptijdDl2Ui(v.overzichtTotLeeftijdInMaanden),
  gewensteDuurOverzicht: v => v.duurOverzicht,
  aantalJaar: v => v.overzichtDuurInJaren
});

const mapScenarioOverlijden = createMapToUi(scenarioOverlijdenModalSchema)
  .with<AanvragersContext>()
  .from<DashboardScenarioOverlijden>({
    controleNorm: v =>
      v.uitgangspunten?.controleGewenstInkomen &&
      mapControleNorm(v.uitgangspunten?.controleGewenstInkomen)(v.uitgangspunten?.controleHypotheeknorm),
    orv: v => mapOverlijden(v.uitgangspunten),
    scenarioVan: (v, c) =>
      mapScenarioVan({ ...c, beiden: false })(
        v.uitgangspunten?.scenarioVanKlantIds
      )
  });

const mapBeschikbareJaarruimte = createMapToUi(beschikbareJaarruimteSchema).from<MotiveringBeschikbareJaarruimte>({
  winstOndernemingsaftrek: v => mapBerekenInput(v.winstBedrag, v.winstOvernemen, v.winstBerekendBedrag),
  inkomenHuidigeDienstbetrekking: v =>
    mapBerekenInput(
      v.tegenwoordigeDienstbetrekkingBedrag,
      v.tegenwoordigeDienstbetrekkingOvernemen,
      v.tegenwoordigeDienstbetrekkingBerekendBedrag
    ),
  inkomenVroegereDienstbetrekking: v =>
    mapBerekenInput(
      v.vroegereDienstbetrekkingBedrag,
      v.vroegereDienstbetrekkingOvernemen,
      v.vroegereDienstbetrekkingBerekendBedrag
    ),
  buitenlandseInkomsten: v => v.buitenlandseInkomstenBedrag,
  reisaftrekWoonWerkverkeer: v => v.reisaftrekBedrag,
  resultaatOverigeWerkzaamheden: v =>
    mapBerekenInput(
      v.overigeWerkzaamhedenBedrag,
      v.overigeWerkzaamhedenOvernemen,
      v.overigeWerkzaamhedenBerekendBedrag
    ),
  periodiekeUitkering: v => v.periodiekeUitkeringenBedrag,
  premiegrondslagBedrag: v => v.premiegrondslagBedrag,
  maxPremieGrondslag: v => v.maxPremieGrondslag,
  franchiseBedrag: v => v.franchiseBedrag,
  subtotaalFranchise1Bedrag: v => v.subtotaalFranchise1Bedrag,
  premiegrondslagPercentage: v => v.premiegrondslagPercentage,
  subtotaalFranchise2Bedrag: v => v.subtotaalFranchise2Bedrag,
  pensioenaangroeiBedrag: v => v.pensioenaangroeiBedrag,
  pensioenaangroeiPercentage: v => v.pensioenaangroeiPercentage,
  subtotaalPensioenaangroeiBedrag: v => v.subtotaalPensioenaangroeiBedrag,
  toevoegingOudedagreserve: v => v.toevoegingOdrBedrag,
  jaarruimteBedrag: v => v.jaarruimteBedrag,
  benutteJaarruimteBedrag: v =>
    mapBerekenInput(v.benutteJaarruimteBedrag, v.benutteJaarruimteOvernemen, v.benutteJaarruimteBerekendBedrag),
  beschikbareJaarruimteBedrag: v => v.beschikbareJaarruimteBedrag,
  gewenstePensioenLeeftijd: v =>
    v.gewensteDuurPensioenUitkering === "TotLeeftijd" && v.pensioenuitkeringTotLeeftijdInMaanden
      ? v.pensioenuitkeringTotLeeftijdInMaanden / 12
      : null,
  gewenstePensioenUitkering: v => v.gewensteDuurPensioenUitkering
});

const mapPensioen = createMapToUi(scenarioPensioenModalSchema)
  .with<AanvragersContext>()
  .from<DashboardScenarioPensioen>({
    scenarioVan: (v, c) =>
      mapScenarioVan({ ...c, beiden: false })(
        v.uitgangspunten?.scenarioVanKlantIds
      ),
    controleNorm: v =>
      v.uitgangspunten?.controleGewenstInkomen &&
      mapControleNorm(v.uitgangspunten?.controleGewenstInkomen)(v.uitgangspunten?.controleHypotheeknorm),
    beschikbareJaarruimteAanvrager: v =>
      v.uitgangspunten && v.uitgangspunten.beschikbareJaarruimteAanvrager1
        ? mapBeschikbareJaarruimte(v.uitgangspunten.beschikbareJaarruimteAanvrager1)
        : null,
    beschikbareJaarruimtePartner: v =>
      v.uitgangspunten && v.uitgangspunten.beschikbareJaarruimteAanvrager2
        ? mapBeschikbareJaarruimte(v.uitgangspunten.beschikbareJaarruimteAanvrager2)
        : null
  });

const mapWerkloosheid = createMapToUi(scenarioWerkloosheidModalSchema)
  .with<AanvragersContext>()
  .from<DashboardScenarioWerkloosheid>({
    controleNorm: v =>
      v.uitgangspunten?.controleGewenstInkomen &&
      mapControleNorm(v.uitgangspunten?.controleGewenstInkomen)(v.uitgangspunten?.controleHypotheeknorm),
    scenarioVan: (v, c) =>
      mapScenarioVan({ ...c, beiden: false })(
        v.uitgangspunten?.scenarioVanKlantIds
      )
  });

function mapDoorrekeningScenarios(
  uitgangspunten: DashboardUitgangspuntenArbeidsongeschiktheid
): Array<ScenarioDoorrekeningScenariosType> {
  const result: Array<ScenarioDoorrekeningScenariosType> = [];

  result.push({
    aoPercentage: uitgangspunten.arbeidsongeschiktheidStandaardPercentage,
    benuttingRvc: uitgangspunten.benuttingVerdiencapaciteitStandaardPercentage || /* istanbul ignore next */ 0,
    duurzaamAo: uitgangspunten.duurzaamArbeidsongeschiktStandaard,
    soort: "Standaard"
  });

  if (
    uitgangspunten.arbeidsongeschiktheidExtra1Percentage ||
    uitgangspunten.benuttingVerdiencapaciteitExtra1Percentage ||
    uitgangspunten.duurzaamArbeidsongeschiktExtra1
  ) {
    result.push({
      aoPercentage: uitgangspunten.arbeidsongeschiktheidExtra1Percentage,
      benuttingRvc: uitgangspunten.benuttingVerdiencapaciteitExtra1Percentage || /* istanbul ignore next */ 0,
      duurzaamAo: uitgangspunten.duurzaamArbeidsongeschiktExtra1,
      soort: "Extra"
    });
  }

  if (
    uitgangspunten.arbeidsongeschiktheidExtra2Percentage ||
    uitgangspunten.benuttingVerdiencapaciteitExtra2Percentage ||
    uitgangspunten.duurzaamArbeidsongeschiktExtra2
  ) {
    result.push({
      aoPercentage: uitgangspunten.arbeidsongeschiktheidExtra2Percentage,
      benuttingRvc: uitgangspunten.benuttingVerdiencapaciteitExtra2Percentage || /* istanbul ignore next */ 0,
      duurzaamAo: uitgangspunten.duurzaamArbeidsongeschiktExtra2,
      soort: "Extra"
    });
  }

  return result;
}

const mapScenarioDoorrekening = createMapToUi(scenarioDoorrekeningSchema).from<
  DashboardUitgangspuntenArbeidsongeschiktheid
>({
  toetsjaarTekort: v => v.toetsjaar,
  scenarios: v => mapDoorrekeningScenarios(v)
});

const mapArbeidsongeschiktheid = createMapToUi(scenarioArbeidsongeschiktheidModalSchema)
  .with<AanvragersContext>()
  .from<DashboardScenarioArbeidsongeschiktheid>({
    scenarioVan: (v, c) =>
      mapScenarioVan({ ...c, beiden: false })(
        v.uitgangspunten?.scenarioVanKlantIds
      ),
    scenarioDoorrekening: v => mapScenarioDoorrekening(v.uitgangspunten),
    controleNorm: v =>
      v.uitgangspunten?.controleGewenstInkomen &&
      mapControleNorm(v.uitgangspunten?.controleGewenstInkomen)(v.uitgangspunten?.controleHypotheeknorm)
  });

const dl2ui = createMapToUi(dashboardSchema).from<Dashboard>({
  overlijden: v => mapScenarioOverlijden(getAanvragers(v.aanvrager1, v.aanvrager2))(v.overlijden),
  werkloosheid: v => mapWerkloosheid(getAanvragers(v.aanvrager1, v.aanvrager2))(v.werkloosheid),
  arbeidsongeschiktheid: v =>
    mapArbeidsongeschiktheid(getAanvragers(v.aanvrager1, v.aanvrager2))(v.arbeidsongeschiktheid),
  pensioen: v => mapPensioen(getAanvragers(v.aanvrager1, v.aanvrager2))(v.pensioen),

  initErrors: v => null,
  overlijdenWeergeven: v => null,
  overlijdenTekort: v => v.overlijden?.tekort,
  overlijdenUitgangspunten: v => v.overlijden?.uitgangspunten,

  arbeidsongeschiktheidWeergeven: v => null,
  arbeidsongeschiktheidTekort: v => v.arbeidsongeschiktheid?.tekort,
  arbeidsongeschiktheidUitgangspunten: v => v.arbeidsongeschiktheid?.uitgangspunten,

  werkloosheidWeergeven: v => null,
  werkloosheidTekort: v => v.werkloosheid?.tekort,
  werkloosheidUitgangspunten: v => v.werkloosheid?.uitgangspunten,

  pensioenWeergeven: v => null,
  pensioenTekort: v => v.pensioen?.tekort,
  pensioenUitgangspunten: v => v.pensioen?.uitgangspunten,

  aanvrager1: v => mapKlantnaamDl2Ui(v.aanvrager1),
  aanvrager2: v => mapKlantnaamDl2Ui(v.aanvrager2),

  data1Outdated: v => false,
  data2Outdated: v => false,
  dataBeideOutdated: v => false,

  version: v => 0
});

export function mapDashboardOutput(adviesVoorstelId: string, data: DashboardOutput): DashboardState | null {
  if (!data || !data.dashboards) return null;

  const dashboard = data.dashboards[adviesVoorstelId] || null;

  const res = dl2ui(dashboard);
  return res;
}

export function mapDashboardDlToUi(dashboard: Dashboard): DashboardState | null {
  /* istanbul ignore next */
  const res = dl2ui(dashboard);
  return res;
}
