/* istanbul ignore file */
import { useRequestInit } from "adviesbox-shared";
import { HypotheeklabelDetailsOutput } from "../../.generated/producten/productentypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { useFetchData } from "./usefetchdata";
import { useCallback, useState, useEffect } from "react";

export type useHypotheekDetailsResult = {
  loading: boolean;
  error: null | Error;
  data: HypotheeklabelDetailsOutput | null;
  refetch: () => void;
  requestDetails: {
    geldverstrekkerCode: string | null;
    hypotheeklabelCode: number | null;
  };
};

export function useHypotheekDetailsData(
  geldverstrekkerCode: string,
  hypotheeklabelCode: number | null
): useHypotheekDetailsResult {
  const {
    requestInit,
    settings: { productenOrigin }
  } = useRequestInit();

  const [requestDetails, setRequestDetails] = useState<{
    geldverstrekkerCode: string | null;
    hypotheeklabelCode: number | null;
  }>({
    geldverstrekkerCode: null,
    hypotheeklabelCode: null
  });

  const getRequestInit = useCallback(
    () => requestInit,
    [requestInit]
  );  

  const hypotheeklabel = geldverstrekkerCode === partijOnafhankelijk ? 0 : hypotheeklabelCode;
  const url = geldverstrekkerCode && hypotheeklabelCode
    ? `${productenOrigin}/MaatschappijCodes/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabel}/Details`
    : "";

  const { error, data, loading, refetch } = useFetchData<HypotheeklabelDetailsOutput>(url, getRequestInit, !!url);

  useEffect(() => {
    if (url) {
      setRequestDetails({
        geldverstrekkerCode: geldverstrekkerCode || null,
        hypotheeklabelCode: hypotheeklabel || null
      });
    }
  }, [geldverstrekkerCode, hypotheeklabel, url]);

  if (typeof data === "string") {
    return { 
      error: new Error("Fout bij het laden van hypotheekvorm"), 
      data: null, 
      loading: false,
      refetch,
      requestDetails
    };
  }

  return {
    loading,
    error: error ? new Error(error) : null,
    data: data || null,
    refetch,
    requestDetails
  };
}