import React, { ReactElement, useState } from "react";
import { FormikProps, Form, useFormikContext } from "formik";

import { SoortKredietProductOptions, Garantiesoort } from "../.generated/forms/formstypes";

import { SituatieSoort, ProductSelectieType } from "../producten-overzicht/infra/producten-overzicht-types";
import { PageLoading, DataGrid, AdviesBoxColumn, Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import KredietDetails from "./krediet-details/krediet-details";
import { bepaalKredietenKolommen } from "./bepaal-kredieten-kolommen";
import ProductSelectieAjax from "../producten-overzicht/product-selectie/product-selectie-ajax";
import { LocalDate } from "@js-joda/core";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { WithSaveData } from "../shared/utils/save-data";
import { SaveButton } from "../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { KredietType, bestedingsDoelAuto, KredietenType, KredietenSchemaContextType } from "./infra/kredieten-types";
import { kredietSchema, kredietenSchema } from "./infra/kredieten-schema";
import { partijOnafhankelijkFilter } from "../producten-overzicht/infra/producten-helper";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { Dashboard } from "../dashboard/dashboard";
import { useFeature } from "adviesbox-shared";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

export const newKredietObjectFactory = (
  productSelectie: ProductSelectieType,
  ingangsdatumVoorstel: LocalDate | null,
  situatie: SituatieSoort
): KredietType => {
  const nieuwKrediet = kredietSchema.default();
  nieuwKrediet.product.wijzigingenInDoorlopendProductOvernemen = null;
  nieuwKrediet.product.ingangsdatum =
    ingangsdatumVoorstel ??
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1);
  nieuwKrediet.soortProduct = productSelectie.codes.productVorm as SoortKredietProductOptions;

  if (nieuwKrediet.soortProduct === SoortKredietProductOptions.Privatelease) {
    nieuwKrediet.leningGegevens.bestedingsdoel = bestedingsDoelAuto;
    nieuwKrediet.leningGegevens.hoofdsom.berekenen = true;
  }

  if (
    nieuwKrediet.soortProduct === SoortKredietProductOptions.Studielening ||
    nieuwKrediet.soortProduct === SoortKredietProductOptions.Restschuldlening
  ) {
    nieuwKrediet.product.looptijd.jaren = 15;
  }

  if (nieuwKrediet.soortProduct === SoortKredietProductOptions.Restschuldlening) {
    nieuwKrediet.leningGegevens.garantie = Garantiesoort.Geen;
  }

  if (nieuwKrediet.soortProduct === SoortKredietProductOptions.DoorlopendKrediet) {
    nieuwKrediet.leningGegevens.aflossingPercentage = 2;
  }

  nieuwKrediet.leningGegevens.opgaveDatum = nieuwKrediet.product.ingangsdatum;

  return nieuwKrediet;
};

export const createDataGridValidationContext = (values: KredietenType): KredietenSchemaContextType => ({
  values
});

export const createFormValidationContext = (_: KredietenProps, values: KredietenType): KredietenSchemaContextType => ({
  values
});

export type KredietenProps = KredietenType & { situatie: SituatieSoort };

const Kredieten = (
  props: FormikProps<KredietenType> & WithSaveData<KredietenType> & { situatie: SituatieSoort }
): ReactElement => {
  const formik = useFormikContext<KredietenType>();
  const { isSubmitting, values, situatie } = props;
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { producten, ingangsdatumVoorstel } = values;
  const selectedProduct = producten && producten[selected];
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  const kredietenKolommen: AdviesBoxColumn[] = React.useMemo(() => bepaalKredietenKolommen(situatie), [situatie]);

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {isSubmitting && <PageLoading />}

            <CardWrapper className="px-3">
              <div className="text-container">
                <div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />

            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50" title="Kredieten">
                <DataGrid
                  masterDetail
                  rowCaption="Krediet"
                  columns={kredietenKolommen}
                  rowSelected={selectedState}
                  name="producten"
                  validationSchema={kredietSchema}
                  createValidationContext={createDataGridValidationContext}
                  popup={
                    <ProductSelectieAjax
                      situatie={situatie}
                      productSoort="krediet"
                      productFilter={partijOnafhankelijkFilter}
                      productFactory={productSelectie =>
                        newKredietObjectFactory(productSelectie, ingangsdatumVoorstel, situatie)
                      }
                    />
                  }
                  additionalButton={
                    situatie === "huidig" &&
                    selectedProduct &&
                    selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null ? (
                      <ProductWijzigingenMeenemenButton
                        name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
                      />
                    ) : (
                      undefined
                    )
                  }
                />
              </Card>
            </CardWrapper>

            {selectedProduct && <KredietDetails selected={selected} situatie={situatie} />}

            <DevDebug />
          </div>
          { !featureNewDashboard && (
            <Dashboard situatie={situatie} saveData={props.saveData} formikParent={formik} />
          )}
        </div>
      </Form>
    </FormFirstFocus>
  );
};

Kredieten.displayName = "Kredieten";

export default withAdviesboxFormik<KredietenProps & WithSaveData<KredietenType>, KredietenType>({
  // Transform outer props into form values
  mapPropsToValues: (props: KredietenProps): KredietenType => ({
    producten: props.producten,
    aanvrager1: props.aanvrager1,
    aanvrager2: props.aanvrager2,
    ingangsdatumVoorstel: props.ingangsdatumVoorstel
  }),
  validationSchema: kredietenSchema,
  createValidationContext: createFormValidationContext
})(withAITracking(insightsReactPlugin, Kredieten));
