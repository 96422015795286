import { hasValue } from "adviesbox-shared";
import * as Yup from "yup";
import { yupEnum, yupNullableEnum } from "../../../utils/yup-enum";
import { returnErrorText } from "./aanvullende-gegevens-schema-validator";

export enum ErrorDataType {
  HDNKeuzelijst = "HDNKeuzelijst",
  entityObject = "entityObject",
  date = "date",
  integer = "integer",
  decimal = "decimal",
  boolean = "boolean",
  alphanumeric = "alphanumeric",
  telefoon = "telefoon",
  email = "email",
  postcode = "postcode",
  IBAN = "IBAN"
}

export enum HdnValidatiemeldingFormApp {
  Advies = 1, // Advies
  Contract = 2, // Contract
  Instellingen = 3 // Instellingen
}

export enum HdnBerichtAanvullingAanvullingBerichtType {
  AX = "AX",
  LX = "LX",
  DX = "DX",
  KX = "KX"
}

export enum SoortHdnPartijOptions {
  Aanvrager = 1,
  Tussenpersoon = 2,
  Notaris = 3,
  Dataleverancier = 4
}

export interface HdnValidatiemeldingForm {
  // De app waarin de het veld ingevuld kan worden Advies = 1, Contract = 2, Instellingen = 3
  readonly app: HdnValidatiemeldingFormApp | null;
  // Locatie van het scherm binnen de app waar het veld ingevuld kan worden
  readonly locatie: string | null;
  // Relatieve URL van het scherm binnen de app waar het veld ingevuld kan worden
  readonly relativeUrl: string | null;
  // Gets or sets code
  code: string | null;
  // Gets or sets omschrijving
  omschrijving: string | null;
  // Gets or sets waarde van het veld waar de validatiemelding betrekking op heeft
  waarde: any | null;
  // Gets or sets gegevens van het veld waar de validatiemelding betrekking op heeft
  veldInfo: HdnVeldInfo | null;
  // Gets or sets de occurance/index van de node (binnen de nodes met hetzelfde XPath).
  occurance: number | null;
  // Gets or sets de occurance/index van de bovenliggende (parent) node (binnen de nodes met hetzelfde XPath).
  parentOccurance: number | null;
  // Gets or sets indien van toepassing: de foutmelding uit de controle-XML.
  foutmeldingControleSchema: string | null;
}

export const aanvullendeAntwoorden = Yup.object({
  xPath: Yup.string()
    .nullable()
    .default(null),
  parentOccurance: Yup.number()
    .nullable()
    .default(null),
  waarde: Yup.object()
    .nullable()
    .default(null)
});

// Bevat gegevens van een veld in een HDN berichtschema.
export interface HdnVeldInfo {
  // Gets or sets naam
  naam: string | null;
  // Gets or sets omschrijving
  omschrijving: string | null;
  // Gets or sets het (HDN) datatype
  datatype: string | null;
  // Gets or sets het XPath van het veld
  xPath: string | null;
  // Gets or sets indicatie dat het veld altijd verplicht is volgens de XSD
  verplicht: boolean | null;
  // Gets or sets indicatie dat het veld conditioneel verplicht is volgens de conditie-XML
  conditioneelVerplicht: boolean | null;
  // Gets or sets het aantal keer dat het veld mag voor komen, indien het een entiteit betreft
  maxOccurs: number | null;
  // Gets or sets indicatie dat de entiteit niet mag voor komen volgens de conditie-XML
  entiteitNietToegestaan: boolean | null;
}

export const aanvullendeGegevensErrorsFESchema = Yup.object({
  dataType: yupNullableEnum(ErrorDataType).default(null),
  veldNaam: Yup.string().default(""),
  maxDigits: Yup.number()
    .nullable()
    .default(0),
  maxAllowedDecimalpoints: Yup.number()
    .nullable()
    .default(0),
  waarde: Yup.mixed()
    .nullable()
    .default(null)
    .test({
      test: function(value: any) {
        const parentError = this.parent ? returnErrorText(this.parent) : null;
        const acceptNulls =
          this.parent.dataType === ErrorDataType.boolean || this.parent.dataType === ErrorDataType.entityObject;
        const notValid = (!hasValue(value) && !acceptNulls) || hasValue(parentError);
        if (notValid)
          return this.createError({
            message: parentError ?? `Veld mag niet leeg zijn: ${this.parent.veldNaam}`
          });

        return true;
      }
    })
});
export type AanvullendeGegevensErrorsFEType = Yup.InferType<typeof aanvullendeGegevensErrorsFESchema>;

export const errorSchema = Yup.object({
  errorId: Yup.string().default(""),
  bemiddelingId: Yup.string()
    .nullable()
    .default(null),
  app: yupNullableEnum(HdnValidatiemeldingFormApp).nullable(),
  locatie: Yup.string()
    .nullable()
    .default(null),
  relativeUrl: Yup.string()
    .nullable()
    .default(null),
  omschrijving: Yup.string()
    .nullable()
    .default(null),
  partijCode: Yup.string()
    .nullable()
    .default(null),
  parentOccurance: Yup.number()
    .nullable()
    .default(null),
  soortPartijRef: yupNullableEnum(SoortHdnPartijOptions)
    .nullable()
    .default(null),
  foutmeldingControleSchema: Yup.string()
    .nullable()
    .default(null),
  platformVeldInfo: Yup.object({
    naam: Yup.string()
      .nullable()
      .default(null),
    omschrijving: Yup.string()
      .nullable()
      .default(null),
    datatype: Yup.string()
      .nullable()
      .default(null),
    xPath: Yup.string()
      .nullable()
      .default(null),
    verplicht: Yup.bool().default(false),
    maxOccurs: Yup.number()
      .nullable()
      .default(null)
  }),
  FEVeldInfo: aanvullendeGegevensErrorsFESchema.nullable().default(null),
  extraInfo: Yup.string()
    .nullable()
    .default(null)
});
export type ErrorType = Yup.InferType<typeof errorSchema>; // should be equivalent to DossierbalkFoutenDropdownEntryData

export const aanvullendeGegevensSchema = Yup.object({
  modalFoutenArray: Yup.array(errorSchema).default([]),
  analyseId: Yup.string()
    .nullable()
    .default(null),
  dekkingId: Yup.string()
    .nullable()
    .default(null),
  vestigingId: Yup.string()
    .nullable()
    .default(null),
  adviesdossierId: Yup.string()
    .nullable()
    .default(null),
  ontvangerNaam: Yup.string()
    .nullable()
    .default(null),
  ontvangerNrHdn: Yup.string()
    .nullable()
    .default(null),
  maatschappijCode: Yup.string()
    .nullable()
    .default(null),
  softwareKoppelingId: Yup.string()
    .nullable()
    .default(null),
  nieuweVersie: Yup.boolean().default(false),
  externeReferentie: Yup.string()
    .nullable()
    .default(null),
  aanvullendeAntwoorden: Yup.array(aanvullendeAntwoorden).default([])
});
export type AanvullendeGegevensType = Yup.InferType<typeof aanvullendeGegevensSchema>;

export const HdnBerichtenSupplementSchema = Yup.object({
  ontbrekendeVelden: Yup.array(
    Yup.object({
      xPath: Yup.string()
        .nullable()
        .default(null),
      parentOccurance: Yup.number()
        .nullable()
        .default(null),
      waarde: Yup.mixed()
        .nullable()
        .default(null),
      errorId: Yup.string()
        .nullable()
        .default(null)
    })
  ).default([]),
  aanvullingBerichtType: yupEnum(HdnBerichtAanvullingAanvullingBerichtType).default(
    HdnBerichtAanvullingAanvullingBerichtType.AX
  ),
  bemiddelingProductId: Yup.string()
    .nullable()
    .default(null),
  analyseId: Yup.string()
    .nullable()
    .default(null),
  dekkingId: Yup.string()
    .nullable()
    .default(null)
});
export type HdnBerichtenSupplementType = Yup.InferType<typeof HdnBerichtenSupplementSchema>;
