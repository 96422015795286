import React, { ReactElement, useCallback, useMemo } from "react";
import { connect, FormikContextType } from "formik";
import {
  Icon,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledSelectInput,
  LabeledJaarMaandInput,
  ModalButton,
  LabeledResult
} from "adviesbox-shared";

import { LabelValuePairs } from "../../shared/types";
import { BetalingsTermijnType, SoortBerekeningOptions } from "../../.generated/forms/formstypes";
import { OnttrekkingenKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";

import ExtraOpnamesSpecificatieModal from "./extra-opnames-specificatie-modal";
import { bindSaveFunction } from "../../shared/utils/bind-save-function";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import { VermogensType } from "../infra/vermogen-types";

const onttrekkingstermijnOptions: LabelValuePairs = [
  { label: "Maand", value: BetalingsTermijnType.Maand },
  { label: "Kwartaal", value: BetalingsTermijnType.Kwartaal },
  { label: "Halfjaar", value: BetalingsTermijnType.HalfJaar },
  { label: "Jaar", value: BetalingsTermijnType.Jaar }
];

type OnttrekkingenProps = { selected: number; kenmerken: OnttrekkingenKenmerken };

const Onttrekkingen = ({
  selected,
  kenmerken,
  formik: {
    setFieldValue,
    values: { aanvrager1, aanvrager2, producten }
  }
}: OnttrekkingenProps & {
  formik: FormikContextType<VermogensType>;
}): ReactElement => {
  const createSaveFunction = useCallback(bindSaveFunction(setFieldValue), [setFieldValue]);
  const {
    product,
    onttrekkingen,
    kapitaalopbouw: { soortBerekening }
  } = producten[selected];
  const { onttrekkingenAanwezig, extraOpnames } = onttrekkingen;
  const extraOpnamesValue = useMemo(() => bedragFormat(optellen(extraOpnames.scenario.map(e => e.opnameBedrag)), 0, 0, false), [
    extraOpnames
  ]);

  return (
    <>
      <LabeledBevestigingInput
        caption="Onttrekking(en) aanwezig"
        name={`producten[${selected}].onttrekkingen.onttrekkingenAanwezig`}
      />

      {onttrekkingenAanwezig && kenmerken.begindatumTonen && (
        <LabeledDateInput caption="Begindatum" name={`producten[${selected}].onttrekkingen.begindatum`} />
      )}

      {onttrekkingenAanwezig && kenmerken.einddatumTonen && (
        <LabeledDateInput caption="Einddatum" name={`producten[${selected}].onttrekkingen.einddatum`} />
      )}

      {onttrekkingenAanwezig && kenmerken.duurTonen && (
        <LabeledJaarMaandInput caption="Duur" name={`producten[${selected}].onttrekkingen.duur`} />
      )}

      {onttrekkingenAanwezig && kenmerken.onttrekkingstermijnTonen && (
        <LabeledSelectInput
          caption="Onttrekkingstermijn"
          name={`producten[${selected}].onttrekkingen.onttrekkingstermijn`}
          options={onttrekkingstermijnOptions}
        />
      )}

      {onttrekkingenAanwezig && kenmerken.onttrekkingBedragTonen && (
        <LabeledCurrencyInput
          caption="Periodieke onttrekking"
          name={`producten[${selected}].onttrekkingen.onttrekkingBedrag`}
          readonly={soortBerekening === SoortBerekeningOptions.Onttrekking}
        />
      )}

      {onttrekkingenAanwezig && (
        <LabeledResult
          name={`producten[${selected}].onttrekkingen.extraOpnames`}
          caption="Extra opnames"
          currency={true}
          result={(): string => extraOpnamesValue}
          appendChildren={
            <>
              <ModalButton
                resetSize={true}
                parent={`producten[${selected}].onttrekkingen.extraOpnames`}
                content={<Icon name="specificatie" alt="Extra opnames specificatie" />}
                size="lg"
              >
                <ExtraOpnamesSpecificatieModal
                  extraOpnames={extraOpnames}
                  entries={product.looptijd.jaren ?? 99}
                  product={producten[selected]}
                  aanvrager1={aanvrager1}
                  aanvrager2={aanvrager2}
                  onSave={createSaveFunction(`producten[${selected}].onttrekkingen.extraOpnames`)}
                />
              </ModalButton>
            </>
          }
        />
      )}
    </>
  );
};

export default connect<OnttrekkingenProps, VermogensType>(Onttrekkingen);
