/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SettingsType } from "adviesbox-shared";
import { User } from "oidc-client";
import { AanvragenOutput } from "../../.generated/forms/formstypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";

import {
  AanvraagState,
  AanvraagStep,
  ProductIdsType,
  ProductType,
  ProductTypeName,
  serviceProvidersType,
  softwareKoppelingenType
} from "./aanvraag-schema";
import {
  mapAovProduct,
  mapBankgarantieProduct,
  mapHypotheek,
  mapOrvProduct,
  mapServiceProvider,
  mapTaxatieProduct,
  mapSoftwareKoppeling
} from "./map-aanvraag-dl-to-ui";
import { FormikContextType } from "formik";
import { NewOutput, NieuweAanvraagInput, AanvullendAntwoord } from "../../.generated/foundation/foundationtypes";
import { nieuweAanvraagType } from "../../shared/dossier-aanvraag-fouten/dossier-aanvraag-fouten-provider";
import { AanvraagType } from "../aanvraag-product/aanvraag-product";

export type Context = {
  adviesdossierId: string;
  vestigingId: string;
  settings: SettingsType;
  user: User | null;
  setFieldValue: FormikContextType<AanvraagState>["setFieldValue"];
  reloadNavigation: () => void;
  subscribeCorrelationId: (id: string) => void;
  setNieuweAanvraag: (nieuweAanvraag: nieuweAanvraagType) => void;
};

interface ProductInfo {
  ontvangerNrHdn: string | null;
  ontvangerNaam: string | null;
  softwareKoppelingId: string | null;
}

interface AanvraagProduct {
  aanvraagNaarSoftwareKoppelingId: string | null;
  aanvraagNaarServiceproviderId: string | null;
  methodeIndienen: string | null;
}
/* istanbul ignore next */
const setMethodeIndienen = (
  aanvraagProduct: AanvraagProduct,
  serviceproviders: serviceProvidersType[],
  softwareKoppelingen: softwareKoppelingenType[]
): void => {
  if (aanvraagProduct.aanvraagNaarServiceproviderId) {
    const serviceprovider = serviceproviders.find(
      x => x.serviceproviderId === aanvraagProduct.aanvraagNaarServiceproviderId
    );
    if (serviceprovider) {
      aanvraagProduct.methodeIndienen = `${AanvraagType.Serviceprovider}**${serviceprovider.naam}**${serviceprovider.serviceproviderId}**${serviceprovider.hdnNummer}`;
    }
  } else if (aanvraagProduct.aanvraagNaarSoftwareKoppelingId) {
    const softwareKoppeling = softwareKoppelingen.find(
      x => x.softwareKoppelingId === aanvraagProduct.aanvraagNaarSoftwareKoppelingId
    );
    if (softwareKoppeling) {
      aanvraagProduct.methodeIndienen = `${AanvraagType.SoftwareKoppeling}**${softwareKoppeling.naam}****${softwareKoppeling.softwareKoppelingId}`;
    }
  }
};

/* istanbul ignore next */
const getProductenByVoorstelId = createISWAsyncSideEffect<AanvraagState, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const Onafhankelijk = "Onafhankelijk";
    const response = await fetchData<AanvragenOutput>({
      url: `${settings.klantdossiersFormsOrigin}/voorstellen/${draft.aanvraagOpBasisVan}/Aanvraag`,
      method: "GET",
      extraHeaders: {
        vestigingId: context.vestigingId
      }
    });

    if (response.isValid && response.aanvragen && response.aanvragen[draft.aanvraagOpBasisVan]) {
      const data = response.aanvragen[draft.aanvraagOpBasisVan];
      const productIds: ProductIdsType[] = [];

      if (data.serviceproviders && data.serviceproviders.length > 0) {
        const serviceproviders: serviceProvidersType[] = [];
        data.serviceproviders.forEach(serviceprovider => {
          serviceproviders.push(mapServiceProvider(serviceprovider));
        });
        draft.serviceprovider = serviceproviders;
      }

      const softwareKoppelingen: softwareKoppelingenType[] = [];
      data.softwareKoppelingen?.forEach(softwareKoppeling => {
        softwareKoppelingen.push(mapSoftwareKoppeling(softwareKoppeling));
      });
      draft.softwareKoppelingen = softwareKoppelingen;

      if (data.hypotheek && data.hypotheek?.partijnaam !== Onafhankelijk) {
        draft.hypotheek = mapHypotheek(data.hypotheek);
        setMethodeIndienen(draft.hypotheek, draft.serviceprovider, draft.softwareKoppelingen);
        productIds.push({ productId: draft.aanvraagOpBasisVan, productType: ProductTypeName.hypotheek, volgnummer: 0 });
      }

      const aovProducten: ProductType[] = [];
      if (data.aov && data.aov.length > 0) {
        data.aov
          .filter(v => v?.partijnaam !== Onafhankelijk)
          .forEach((aov, i) => {
            const aovProduct = mapAovProduct(aov);
            setMethodeIndienen(aovProduct, draft.serviceprovider, draft.softwareKoppelingen);
            aovProducten.push(aovProduct);
            productIds.push({ productId: aov.productId, productType: ProductTypeName.AOV, volgnummer: i });
          });
      }
      draft.aovProduct = aovProducten;

      const orvProducten: ProductType[] = [];
      if (data.orv && data.orv.length > 0) {
        data.orv
          .filter(v => v?.partijnaam !== Onafhankelijk)
          .forEach((orv, i) => {
            const orvProduct = mapOrvProduct(orv);
            setMethodeIndienen(orvProduct, draft.serviceprovider, draft.softwareKoppelingen);
            orvProducten.push(orvProduct);
            productIds.push({ productId: orv.productId, productType: ProductTypeName.ORV, volgnummer: i });
          });
      }
      draft.orvProduct = orvProducten;

      if (data.bankgarantie && data.bankgarantie?.partijnaam !== Onafhankelijk) {
        draft.bankgarantieProduct = mapBankgarantieProduct(data.bankgarantie);
        productIds.push({ productId: data.bankgarantie.productId, productType: ProductTypeName.BG, volgnummer: 0 });
      }

      if (data.taxatie && data.taxatie?.partijnaam !== Onafhankelijk) {
        draft.taxatieProduct = mapTaxatieProduct(data.taxatie);
        productIds.push({ productId: data.taxatie.productId, productType: ProductTypeName.taxatie, volgnummer: 0 });
      }

      draft.softwareKoppelingen = softwareKoppelingen;
      draft.externeReferentie = data.externeReferentie;
      draft.isLoadingAanvraag = false;
      draft.productIdsEnTypes = productIds;
    }

    draft.fetchProducten = false;
  }
);

type ProductCommon = {
  aanvraagNaarHdnNummer: string | null;
  aanvraagNaar: string | null;
  softwareKoppelingId: string | null;
};

/* istanbul ignore next */
const getProductInfoForProduct = <T extends ProductCommon>(product: T | undefined | null): ProductInfo => {
  return {
    ontvangerNrHdn: product?.aanvraagNaarHdnNummer ?? null,
    ontvangerNaam: product?.aanvraagNaar ?? null,
    softwareKoppelingId: product?.softwareKoppelingId ?? null
  };
};

/* istanbul ignore next */
const getProductInfoProductType = (productType: ProductTypeName | null, draft: AanvraagState): ProductInfo => {
  switch (productType) {
    case ProductTypeName.hypotheek:
      return getProductInfoForProduct(draft.hypotheek);
    case ProductTypeName.ORV:
      const orvProduct = draft.orvProduct?.find(v => v.productId === draft.geselecteerdProduct);
      return getProductInfoForProduct(orvProduct);
    case ProductTypeName.AOV:
      const aovProduct = draft.aovProduct?.find(v => v.productId === draft.geselecteerdProduct);
      return getProductInfoForProduct(aovProduct);
    case ProductTypeName.BG:
      return getProductInfoForProduct(draft.bankgarantieProduct);
    case ProductTypeName.taxatie:
      return getProductInfoForProduct(draft.taxatieProduct);
    default:
      return {} as ProductInfo;
  }
};

/* istanbul ignore next */
const getProductInfo = (draft: AanvraagState): ProductInfo => {
  const product = draft.productIdsEnTypes.find(v => v.productId === draft.geselecteerdProduct);
  if (!product) {
    return { ontvangerNrHdn: null, ontvangerNaam: null, softwareKoppelingId: null };
  }
  return getProductInfoProductType(product.productType, draft);
};

/* istanbul ignore next */
const isVoorstelAanvraagId = (draft: AanvraagState): boolean => {
  return draft.aanvraagOpBasisVan === draft.geselecteerdProduct;
};

/* istanbul ignore next */
const mapNieuweAanvraagInput = (draft: AanvraagState, adviesdossierId: string): NieuweAanvraagInput => {
  const isVoorstelId = isVoorstelAanvraagId(draft);
  const productInfo = getProductInfo(draft);
  return {
    voorstelId: isVoorstelId ? draft.aanvraagOpBasisVan : null,
    adviesdossierId: adviesdossierId,
    ontvangerNaam: productInfo.ontvangerNaam,
    ontvangerNrHdn: productInfo.ontvangerNrHdn,
    softwareKoppelingId: productInfo.softwareKoppelingId,
    dekkingId: isVoorstelId ? null : draft.geselecteerdProduct,
    nieuweVersie: draft.nieuweVersieAanvraag,
    aanvullendeAntwoorden: [] as AanvullendAntwoord[],
    externeReferentie: draft.externeReferentie,
    maatschappijCode: draft.maatschappijCodes?.find(m => m.productId === draft.geselecteerdProduct)?.maatschappijCode
  } as NieuweAanvraagInput;
};

/* istanbul ignore next */
const productenOvernemen = createISWAsyncSideEffect<AanvraagState, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const product = draft.maatschappijCodes?.find(maatschappij => maatschappij.productId === draft.geselecteerdProduct);
    context.setFieldValue("aanvraagStep", null);
    draft.meenemenError = false;
    const url = `${settings.foundationOrigin}/HdnOnline/bus/NieuweAanvraag`;
    context.setNieuweAanvraag({
      isloading: true,
      partijCode: product?.maatschappijCode ?? null,
      productType: product?.productType ?? null,
      receivedId: null,
      closeModal: null
    });

    const body = mapNieuweAanvraagInput(draft, context.adviesdossierId);

    await fetchData<NewOutput>({
      url: url,
      method: "POST",
      extraHeaders: {
        vestigingId: context.vestigingId
      },
      body: JSON.stringify(body)
    }).then(
      result => {
        if (result.id) {
          context.subscribeCorrelationId(result.id);
        }
        context.setNieuweAanvraag({
          isloading: true,
          partijCode: product?.maatschappijCode ?? null,
          productType: product?.productType ?? null,
          receivedId: result.id,
          closeModal: null
        });
      },
      _ => {}
    );
  }
);

/* istanbul ignore next */
export const asyncAanvraagSideEffects = initISWAsyncSideEffect<AanvraagState, Context>(
  ({ has, runAsync, curr, context }) => {
    if (has.aanvraagOpBasisVan.changed || curr.fetchProducten) {
      /* istanbul ignore next */
      runAsync(getProductenByVoorstelId(context));
    }
    if (has.aanvraagStep.changed && curr.aanvraagStep) {
      if (curr.aanvraagStep === AanvraagStep.ProductOvernemen) {
        /* istanbul ignore next */
        runAsync(productenOvernemen(context));
      }
    }
  }
);
