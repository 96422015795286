import React, { ReactElement, useEffect, useMemo } from "react";

import AflossingProductKeuzelijstPresentation from "./AflossingProductKeuzelijstPresentation";
import { useProductenData } from "../../../shared/hooks/use-producten-data";
import { SituatieSoort } from "../../../producten-overzicht/infra/producten-overzicht-types";
import { FormikContextType, connect } from "formik";

import { sortByStringOrder } from "../../../shared/utils/sort-string";
import { HypotheekProductSelectieStateType, AflosProductType } from "../../infra/hypotheek-types";
import { filterAflosvormen } from "../../../shared/utils/aflosvorm-filter";
import { beschikbareHypotheekProducten, LoadingSpinner, FieldSize } from "adviesbox-shared";

export type AflossingProductKeuzelijstProps = {
  name: string;
  situatie: SituatieSoort;
  maatschappijCode: string;
  labelCode: string;
  readonly?: boolean;
  isAankoop?: boolean;
  visible?: boolean;
  fieldSize?: FieldSize;
  onChange?: (e: string) => void;
  isRestschuldHypotheek?: boolean;
  asSelectElement?: boolean;
};

const AflossingProductKeuzelijst = ({
  situatie,
  maatschappijCode,
  labelCode,
  name,
  readonly,
  formik,
  isAankoop,
  isRestschuldHypotheek,
  asSelectElement,
  fieldSize
}: AflossingProductKeuzelijstProps & {
  formik: FormikContextType<HypotheekProductSelectieStateType>;
}): ReactElement | null => {
  const { setFieldValue, values } = formik;
  const { error, aflosProductKeuzeLijst } = useProductenData(situatie, maatschappijCode, labelCode, isAankoop);
  // select the first item in the list if not already selected.

  const filteredAflosProductKeuzenLijst = useMemo(
    () => filterAflosvormen(aflosProductKeuzeLijst, maatschappijCode, labelCode, beschikbareHypotheekProducten),
    [aflosProductKeuzeLijst, maatschappijCode, labelCode]
  );

  const aflosProducten = useMemo(() => {
    return situatie === "huidig" && aflosProductKeuzeLijst ? aflosProductKeuzeLijst : filteredAflosProductKeuzenLijst;
  }, [situatie, aflosProductKeuzeLijst, filteredAflosProductKeuzenLijst]);

  useEffect(() => {
    if (!aflosProductKeuzeLijst || error) return;
    if (Object.keys(aflosProductKeuzeLijst).length === 0 && values.hasProducten) {
      setFieldValue("hasProducten", false);
      return;
    }

    if (!values.hasProducten && !!Object.keys(aflosProductKeuzeLijst).length) {
      setFieldValue("hasProducten", true);
    }
  }, [aflosProductKeuzeLijst, error, setFieldValue, values.hasProducten]);

  useEffect(() => {
    if (filteredAflosProductKeuzenLijst) {
      const guids = Object.keys(filteredAflosProductKeuzenLijst);
      const unfiltered = guids.map(c => filteredAflosProductKeuzenLijst[c]);
      const items = unfiltered.sort((a, b) => {
        return sortByStringOrder(`${a.productnaam}`, `${b.productnaam}`);
      });

      // if we have no current item items, and we're not loading... (-1)
      // effect will run twice => so make sure available items are for the right maatschappijCode.
      if (items[0]?.maatschappijCode === maatschappijCode && values.productCode.code === null) {
        setFieldValue(`productCode`, {
          code: items[0].code?.toString(),
          aflosvorm: items[0].aflossingsvorm?.toString(),
          productOmschrijving: items[0].productnaam?.toString(),
          renteBoxMaatschappijCode: items[0].renteboxMaatschappijCode,
          renteboxCode: items[0].renteboxCode
        } as AflosProductType);
      }
    }
  }, [maatschappijCode, filteredAflosProductKeuzenLijst, situatie, setFieldValue, values, labelCode]);

  if (error) {
    return <p>Er zijn geen producten beschikbaar</p>;
  }

  if (!aflosProductKeuzeLijst) {
    return <LoadingSpinner />;
  }

  if (Object.keys(aflosProductKeuzeLijst).length === 0) {
    return <p>Er zijn geen producten beschikbaar</p>;
  }

  return (
    <AflossingProductKeuzelijstPresentation
      name={name}
      aflosProductKeuzeLijst={aflosProducten}
      readonly={readonly}
      isRestschuldHypotheek={isRestschuldHypotheek}
      situatie={situatie}
      fieldSize={fieldSize}
      asSelectElement={asSelectElement}
    />
  );
};

AflossingProductKeuzelijst.displayName = "AflossingProductKeuzelijst";

export default connect<AflossingProductKeuzelijstProps, HypotheekProductSelectieStateType>(AflossingProductKeuzelijst);
