import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { LabeledTextInput } from "adviesbox-shared";
import { BankgarantieState } from "../infra/bankgarantie-schema";

const Verkopers = ({
  formik: {
    values: { verkopers }
  }
}: {
  formik: FormikContextType<BankgarantieState>;
}): ReactElement => {
  return (
    <>
      <LabeledTextInput caption="Bedrijfsnaam" name="verkopers.bedrijfsnaam" />

      <br />
      <span className="d-block font-italic px-1 ml-3">Verkoper</span>

      <LabeledTextInput caption="Achternaam" name="verkopers.verkoper1.achternaam" />

      <LabeledTextInput caption="Voorletters" name="verkopers.verkoper1.voorletters" />

      <LabeledTextInput caption="Tussenvoegsel" name="verkopers.verkoper1.tussenvoegsel" />

      <LabeledTextInput caption="Woonplaats" name="verkopers.verkoper1.woonplaats" />

      <br />
      <span className="d-block font-italic px-1 ml-3">Partner</span>

      <LabeledTextInput caption="Achternaam" name="verkopers.verkoper2.achternaam" />

      <LabeledTextInput caption="Voorletters" name="verkopers.verkoper2.voorletters" />

      <LabeledTextInput caption="Tussenvoegsel" name="verkopers.verkoper2.tussenvoegsel" />

      <LabeledTextInput caption="Woonplaats" name="verkopers.verkoper2.woonplaats" />
    </>
  );
};

export default connect<{}, BankgarantieState>(Verkopers);
