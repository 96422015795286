import { jaarMaandInMaanden } from "adviesbox-shared";
import { FormikTouched } from "formik";
import {
  AovKeuzeDekkingOptions,
  AovVerzekerdeDekkingenOptions,
  AovVoornaamsteInkomstenbronOptions,
  AovBetalingMiddelsOptions,
  AovSoortProductOptions
} from "../../.generated/forms/formstypes";
import { AovKenmerken } from "../../producten-overzicht/infra/product-kenmerken-types";
import { AoIndexeringenType } from "../../producten-overzicht/infra/specificatie-ao-uitkering-schema";
import { productDraftSideEffects } from "../../producten-overzicht/product/product-side-effects";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { AanvragerKeuze } from "../../shared/types";
import {
  AovsType,
  AovType,
  dekkingGegevensEaSchema,
  DekkingGegevensVerzekerdeType,
  DekkingType,
  PartijProduct,
  PremieBetalingstermijn,
  PremiegegevensType
} from "./aov-schema";
import { getDekkingGegevensVerzekerDefaults, getDekkingGegevensWwDefaults } from "./defaults";
import { mapPartijProduct } from "./map-aov-dl-to-ui";
import { getDefaultIndexeringen } from "../../producten-overzicht/specificatie-ao-uitkering-modal/indexering-helper";

type aovSideEffectsContext = {
  selected: number;
  kenmerken: AovKenmerken | null;
  touchedIndexeringen?: (FormikTouched<AoIndexeringenType> | undefined)[];
  partijProduct?: PartijProduct;
  soortProduct?: AovSoortProductOptions;
};

export const dekkingGegevensDekkingKeuzeSideEffects = createISWSideEffect<
  DekkingGegevensVerzekerdeType | null,
  aovSideEffectsContext
>(bag => {
  if (!bag.draft) return;
  const { draft, context, has } = bag;
  const isAo =
    draft.dekking === AovVerzekerdeDekkingenOptions.Ao ||
    draft.dekking === AovVerzekerdeDekkingenOptions.AoEa ||
    draft.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
    draft.dekking === AovVerzekerdeDekkingenOptions.AoWwEa;

  const isWw =
    draft.dekking === AovVerzekerdeDekkingenOptions.Ww ||
    draft.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
    draft.dekking === AovVerzekerdeDekkingenOptions.AoWwEa;
  const isAe =
    draft.dekking === AovVerzekerdeDekkingenOptions.AoEa || draft.dekking === AovVerzekerdeDekkingenOptions.AoWwEa;

  draft.ao =
    isAo && context?.soortProduct !== AovSoortProductOptions.Arbeidsongeschiktheidverzekering
      ? draft.ao ?? getDekkingGegevensVerzekerDefaults(context.partijProduct).ao
      : null;
  draft.ww = isWw ? draft.ww ?? getDekkingGegevensWwDefaults(context?.partijProduct) : null;
  draft.ea = isAe ? draft.ea ?? dekkingGegevensEaSchema.default() : null;

  if (
    isWw &&
    (has.ao.verzekerdBedrag.changed || has.dekking.changed || has.changed) &&
    (context?.partijProduct === PartijProduct.DazureInkomensbeschermer ||
      context?.partijProduct === PartijProduct.DazureWoonlasten)
  ) {
    if (draft.ww && draft.ao) {
      draft.ww.verzekerdBedrag = draft.ao.verzekerdBedrag;
    }
  }
});

export const dekkingGegevensVerzekerdeSideEffects = createISWSideEffect<
  DekkingGegevensVerzekerdeType | null,
  aovSideEffectsContext
>(bag => {
  if (!bag.draft) return;
  const { has, draft, subset, context } = bag;

  if (draft.ao && has.ao.uitkeringGedeeltelijkAo.changed) {
    draft.ao.uitkeringGedeeltelijkeAoModal.indexeringen = getDefaultIndexeringen(
      draft.ao.uitkeringGedeeltelijkAo,
      draft.ao.uitkeringGedeeltelijkeAoModal,
      context?.soortProduct
    );
  }
  if (draft.rubriekA && has.rubriekA.uitkeringGedeeltelijkAo.changed) {
    draft.rubriekA.uitkeringGedeeltelijkeAoModal.indexeringen = getDefaultIndexeringen(
      draft.rubriekA.uitkeringGedeeltelijkAo,
      draft.rubriekA.uitkeringGedeeltelijkeAoModal,
      context?.soortProduct
    );
  }

  if (has.keuzeDekking.changed) {
    switch (draft.keuzeDekking) {
      case AovKeuzeDekkingOptions.Percentage:
        draft.vastBedrag = null;
        draft.percentage = 0;
        break;
      case AovKeuzeDekkingOptions.VastBedrag:
        draft.percentage = null;
        draft.vastBedrag = 0;
        break;
    }
  }

  if (
    draft.voornaamsteInkomstenbron === AovVoornaamsteInkomstenbronOptions.Zelfstandige &&
    (draft.dekking === AovVerzekerdeDekkingenOptions.AoWw ||
      draft.dekking === AovVerzekerdeDekkingenOptions.AoWwEa ||
      draft.dekking === AovVerzekerdeDekkingenOptions.Ww)
  ) {
    draft.dekking = AovVerzekerdeDekkingenOptions.Ao;
  }

  if (draft.ww && has.ww.uitkeringsDuurInMaanden.changed) {
    if (draft.ww.uitkeringsDuurInMaanden === "12") {
      draft.ww.uitkeringsDuur = { jaren: 1, maanden: 0 };
    } else if (draft.ww.uitkeringsDuurInMaanden === "24") {
      draft.ww.uitkeringsDuur = { jaren: 2, maanden: 0 };
    }
  } else if (draft.ww && has.ww.uitkeringsDuur.changed) {
    draft.ww.uitkeringsDuurInMaanden = jaarMaandInMaanden(draft.ww.uitkeringsDuur)?.toString() ?? "";
  }
  if (context?.partijProduct) {
    dekkingGegevensDekkingKeuzeSideEffects(subset.createWithContext(context));
  }
});

export const premieGegevensBetalingstermijnSideEffects = createISWSideEffect<PremiegegevensType | null>(bag => {
  if (!bag.draft) return;
  const { has, draft } = bag;

  if (has.betalingMiddels.changed) {
    switch (draft.betalingMiddels) {
      case AovBetalingMiddelsOptions.Koopsom:
        draft.koopsomPeriodeInMaanden = null;
        draft.premieAo = null;
        draft.betalingsTermijn = null;
        break;
      case AovBetalingMiddelsOptions.Maandpremie:
        draft.koopsomPeriodeInMaanden = null;
        draft.koopsomBedrag = null;
        draft.koopsomAO = null;
        draft.betalingsTermijn = PremieBetalingstermijn.Maand;
        break;
      case AovBetalingMiddelsOptions.Combinatie:
        draft.betalingsTermijn = PremieBetalingstermijn.Maand;
        break;
    }
  }
});

export const dekkingSideEffects = createISWSideEffect<DekkingType, aovSideEffectsContext>((bag): void => {
  const { subset, context, has, draft } = bag;

  dekkingGegevensVerzekerdeSideEffects(subset.dekkingGegevensVerzekerde1.createWithContext(context));
  dekkingGegevensVerzekerdeSideEffects(subset.dekkingGegevensVerzekerde2.createWithContext(context));

  if (draft.dekkingGegevensVerzekerde1.rubriekA) {
    if (has.dekkingGegevensVerzekerde1.rubriekA.percentageVanInkomen.changed || has.brutoJaarinkomen.changed) {
      draft.dekkingGegevensVerzekerde1.rubriekA.verzekerdBedrag =
        ((draft.brutoJaarinkomen || 0) / 100) * (draft.dekkingGegevensVerzekerde1.rubriekA.percentageVanInkomen || 0);
    }
    if (has.dekkingGegevensVerzekerde1.rubriekA.verzekerdBedrag.changed) {
      draft.dekkingGegevensVerzekerde1.rubriekA.percentageVanInkomen =
        ((draft.dekkingGegevensVerzekerde1.rubriekA.verzekerdBedrag || 0) / (draft.brutoJaarinkomen || 0)) * 100;
    }
  }
  if (draft.dekkingGegevensVerzekerde1.rubriekB) {
    if (has.dekkingGegevensVerzekerde1.rubriekB.percentageVanInkomen.changed || has.brutoJaarinkomen.changed) {
      draft.dekkingGegevensVerzekerde1.rubriekB.verzekerdBedrag =
        ((draft.brutoJaarinkomen || 0) / 100) * (draft.dekkingGegevensVerzekerde1.rubriekB.percentageVanInkomen || 0);
    }
    if (has.dekkingGegevensVerzekerde1.rubriekB.verzekerdBedrag.changed) {
      draft.dekkingGegevensVerzekerde1.rubriekB.percentageVanInkomen =
        ((draft.dekkingGegevensVerzekerde1.rubriekB.verzekerdBedrag || 0) / (draft.brutoJaarinkomen || 0)) * 100;
    }
  }

  if (
    context?.partijProduct !== PartijProduct.DazureInkomensbeschermer &&
    has.maandinkomenGegarandeerd.changed &&
    draft.dekkingGegevensVerzekerde1.ao
  ) {
    draft.dekkingGegevensVerzekerde1.ao.verzekerdBedrag = draft.maandinkomenGegarandeerd;
  }
});

export const emptyCalculatedValuesSideEffect = createISWSideEffect<AovType>((bag): void => {
  const { has, draft } = bag;
  if (
    has.dekking.dekkingGegevensVerzekerde1.ao.beroepnaam.changed ||
    has.dekking.dekkingGegevensVerzekerde1.ao.risicoklasse.changed ||
    has.dekking.dekkingGegevensVerzekerde2.ao.beroepnaam.changed ||
    has.dekking.dekkingGegevensVerzekerde2.ao.risicoklasse.changed
  ) {
    return;
  }
  if (
    !has.toggleBerekenen.changed &&
    !(has.product.looptijd.changed && has.premiegegevens.changed) && // Via de async side-effects kunnen zowel de looptijd als de premie gewijzigd worden. De premie moet dan niet weer leeggemaakt worden.
    !has.dekking.verzekerdBedragVerzekerdBedragModalValues.scenario.changed &&
    (has.product.ingangsdatum.changed ||
      has.product.einddatum.changed ||
      has.product.looptijd.changed ||
      has.verzekerden.changed ||
      (has.dekking.changed &&
        !has.dekking.dekkingGegevensVerzekerde1.ww.changed &&
        !has.dekking.nettoMaandinkomen.changed &&
        !has.dekking.dekkingGegevensVerzekerde1.ao.verzekerdBedrag.changed &&
        !has.dekking.dekkingGegevensVerzekerde1.ao.verzekerdBedragGedeeltelijkAo.changed))
  ) {
    draft.premiegegevens.premie = null;
    draft.premiegegevens.premieAo = null;
    draft.premiegegevens.premieTop = null;
    draft.premiegegevens.premieWw = null;
    if (draft.dekking.verzekerdBedragVerzekerdBedragModalValues.scenario.length > 0) {
      draft.dekking.verzekerdBedragVerzekerdBedragModalValues.scenario = [];
      draft.dekking.dekkingGegevensVerzekerde1.ao &&
        (draft.dekking.dekkingGegevensVerzekerde1.ao.verzekerdBedrag = null);
    }
  }
});

export const productSideEffects = createISWSideEffect<AovType, aovSideEffectsContext>((bag): void => {
  const { subset, has, draft, prev, context } = bag;
  const partijProduct: PartijProduct = mapPartijProduct(draft.partijCode, draft.productCode);

  if (context.kenmerken?.premie.berekenPremieKnopTonen) {
    emptyCalculatedValuesSideEffect(bag);
  }

  premieGegevensBetalingstermijnSideEffects(subset.premiegegevens.create());

  productDraftSideEffects(subset.product.create());
  dekkingSideEffects(subset.dekking.createWithContext({ ...context, partijProduct }));

  if (has.verzekerden.changed) {
    if (
      prev.verzekerden.verzekerden === AanvragerKeuze.Beiden &&
      draft.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2 &&
      draft.dekking.dekkingGegevensVerzekerde2
    ) {
      draft.dekking.dekkingGegevensVerzekerde1 = { ...draft.dekking.dekkingGegevensVerzekerde2 };
    }
    if (draft.verzekerden.verzekerden === AanvragerKeuze.Aanvrager1) {
      draft.dekking.dekkingGegevensVerzekerde2 = null;
      draft.aanvullendeVragen.reorganisatieWerkgeverAanvrager2 = null;
      draft.aanvullendeVragen.verwachtingOntslagAanvrager2 = null;
      draft.aanvullendeVragen.strafrechtelijkVerledenAanvrager2 = null;
      draft.aanvullendeVragen.financieleProblemenWerkgeverAanvrager2 = null;
      draft.aanvullendeVragen.reorganisatieWerkgeverAanvrager1 = null;
      draft.aanvullendeVragen.verwachtingOntslagAanvrager1 = null;
      draft.aanvullendeVragen.strafrechtelijkVerledenAanvrager1 = null;
      draft.aanvullendeVragen.financieleProblemenWerkgeverAanvrager1 = null;
      draft.aanvullendeVragen.toelichtingGelezenAkkoordMetSlotverklaring = null;
    }

    if (draft.verzekerden.verzekerden === AanvragerKeuze.Aanvrager2) {
      draft.aanvullendeVragen.reorganisatieWerkgeverAanvrager1 = null;
      draft.aanvullendeVragen.verwachtingOntslagAanvrager1 = null;
      draft.aanvullendeVragen.strafrechtelijkVerledenAanvrager1 = null;
      draft.aanvullendeVragen.financieleProblemenWerkgeverAanvrager1 = null;
      draft.aanvullendeVragen.reorganisatieWerkgeverAanvrager2 = null;
      draft.aanvullendeVragen.verwachtingOntslagAanvrager2 = null;
      draft.aanvullendeVragen.strafrechtelijkVerledenAanvrager2 = null;
      draft.aanvullendeVragen.financieleProblemenWerkgeverAanvrager2 = null;
      draft.aanvullendeVragen.toelichtingGelezenAkkoordMetSlotverklaring = null;
    }
    if (draft.verzekerden.verzekerden === AanvragerKeuze.Beiden) {
      draft.dekking.dekkingGegevensVerzekerde2 = { ...getDekkingGegevensVerzekerDefaults(partijProduct) };
    } else {
      draft.dekking.dekkingGegevensVerzekerde2 = null;
    }
  }

  if (
    draft.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten.length === 1 &&
    has.verpanding.bedoeldVoorAflossing.changed
  ) {
    draft.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten[0].aflossen =
      draft.verpanding.bedoeldVoorAflossing;
  }
});

export const inkomenSideEffects = createISWSideEffect<AovsType, aovSideEffectsContext>(bag => {
  const { context, draft, has } = bag;

  // Wijzig indicatief jaarinkomen als er een andere verzekerde wordt geselecteerd
  if (has.producten[context.selected].verzekerden.changed) {
    switch (draft.producten[context.selected].verzekerden.verzekerden) {
      case AanvragerKeuze.Aanvrager1:
        draft.producten[context.selected].dekking.brutoJaarinkomen = draft.aanvrager1?.indicatiefJaarInkomen || 0;
        draft.producten[context.selected].dekking.maandinkomen.berekendBedrag =
          draft.aanvrager1?.indicatiefMaandInkomen || 0;
        break;
      case AanvragerKeuze.Aanvrager2:
        draft.producten[context.selected].dekking.brutoJaarinkomen = draft.aanvrager2?.indicatiefJaarInkomen || 0;
        draft.producten[context.selected].dekking.maandinkomen.berekendBedrag =
          draft.aanvrager2?.indicatiefMaandInkomen || 0;
        break;
      case AanvragerKeuze.Beiden:
        draft.producten[context.selected].dekking.brutoJaarinkomen =
          (draft.aanvrager1?.indicatiefJaarInkomen || 0) + (draft.aanvrager2?.indicatiefJaarInkomen || 0);
        draft.producten[context.selected].dekking.maandinkomen.berekendBedrag =
          (draft.aanvrager1?.indicatiefMaandInkomen || 0) + (draft.aanvrager2?.indicatiefMaandInkomen || 0);
        break;
    }
    draft.producten[context.selected].dekking.maandinkomen.berekenen = true;
  }
});

export const syncAovSideEffects = initISWSideEffect<AovsType, aovSideEffectsContext>(bag => {
  const { subset, context } = bag;

  inkomenSideEffects(subset.createWithContext(context));
  productSideEffects(subset.producten[context.selected].createWithContext(context));
});
