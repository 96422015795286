import React, { ReactElement } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { determineUitsluitingenSideEffects } from "./determine-uitsluitingen-side-effects";

import { getPersonaliaTextResources } from "../infra/personalia-resources";
import { TextInput, DateInput } from "adviesbox-shared";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import HdnKeuzelijst from "../../shared/components/hdn-keuzelijst/HdnKeuzelijst";
import { Debug } from "../../shared/components/formik/Debug";
import { Uitsluiting, uitsluitingenModalSchema } from "../infra/personalia-schema";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";

type UitsluitingenModalType = {
  list: Uitsluiting[];
};

export type UitsluitingenModalData = {
  data: Uitsluiting[];
};

type UitsluitingenModalProps = {
  onSave?: (data: Uitsluiting[]) => void;
  closeModal?: () => void;
};

const UitsluitingenModal = ({
  data,
  onSave,
  closeModal
}: UitsluitingenModalData & UitsluitingenModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<UitsluitingenModalType>
      initialValues={{ list: data }}
      validationSchema={uitsluitingenModalSchema}
      onSave={(values): void => onSave && onSave(values.list)}
      closeModal={closeModal}
      render={({ submitForm, values: { list } }: FormikProps<{ list: Uitsluiting[] }>): ReactElement => {
        const shouldShow = (index: number): boolean => {
          return list[index] ? !!list[index].maatschappijCode : false;
        };
        return (
          <>
            <ISWSideEffects<{ list: Uitsluiting[] }> sync={determineUitsluitingenSideEffects} />
            <Modal.Header closeButton>
              <Modal.Title>Uitsluitingen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="px-1 pb-4">{getPersonaliaTextResources("tekstUitsluitingenModal")}</div>
              <table className="mx-1 table-verzekeringen">
                <tbody>
                  <tr>
                    <th>Naam</th>
                    <th>Datum</th>
                    <th>Reden</th>
                  </tr>
                  {[...Array(9)].map(
                    (_, index): ReactElement => (
                      <tr key={index + 1}>
                        <td>
                          <HdnKeuzelijst
                            name={`list.[${index}].maatschappijCode`}
                            berichtSoortType="AX"
                            keuzelijst="MaatschappijType"
                            emptyValue="Geen"
                          />
                        </td>
                        <td>
                          <DateInput name={`list.[${index}].uitsluitingsdatum`} visible={shouldShow(index)} />
                        </td>
                        <td>
                          <TextInput name={`list.[${index}].reden`} visible={shouldShow(index)} />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <Debug />
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="btnannuleren" variant="link" onClick={closeModal} id="btnannuleren">
                Annuleren
              </Button>
              <Button data-testid="btnopslaan" variant="primary" onClick={submitForm} id="btnopslaan">
                Bevestigen
              </Button>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};

export default UitsluitingenModal;
